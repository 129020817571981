import React, { useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

import "tippy.js/dist/tippy.css";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Player } from "@lottiefiles/react-lottie-player";

const Classwork = () => {

    const getusers = useSelector((state) => state.user.value);

    const plan = getusers.plan;

    const teachid = getusers.teachid;

    const { id } = useParams();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const [cbt_name, setName] = useState(null);

    const [cbt_type, setCBTType] = useState('Classwork');

    const [studclass, setStudclass] = useState(null);

    const [subject, setSubject] = useState(null);

    const [duration, setTime] = useState(null);

    const [durations, setTimes] = useState(null);

    const [password, setPassword] = useState(null);

    const [status, setStat] = useState(false);

    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    if (duration > 10) {
        setTime("10");
        toast.error("Timer can't be more than 10 minutes", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 4000,
        });
    } else {
    }

    const onSubmit = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            cbt_name,
            cbt_type,
            studclass,
            subject,
            duration,
            password,
            teachid,
        };

        axios({
            method: "post",
            url: "/v2/exercise/create/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + subject + " CBT Folder has been created!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    setTimeout(function () {
                        setStat(false);
                        navigate("../" + id + "/exercise");
                    }, 2000);

                    localStorage.setItem("token", response.data.token);
                } else {
                    toast.error("Failed: Request Failed! CBT Folder already exists", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });


                    setTimeout(function () {
                        setStat(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    };

    const [subjects, setSubjects] = useState([{}]);

    useEffect(() => {
        axios({
            method: "post",
            url: "./v2/subject/list/",
            data: {
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSubjects(view);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    }, [teachid]);

    const renderSubject = () => {
        if (subjects == "") {
            return <></>;
        } else if (subjects) {
            return subjects.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <option value={task.subject}>{task.subject}</option>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };



    return (
        <>
            {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?
                <div className='py-6'>
                    <div className='mb-5'>
                        <div class="col-lg-12 mb-0">
                            <ul class="nav nav-lb-tab">
                                <li class="nav-item ms-0 me-3 fs-4">
                                    <div class="nav-link active px-3">
                                        Create Classwork
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <form className='px-lg-20' onSubmit={onSubmit}>

                        <div className="row g-4">
                            <div class="col-lg-12">
                                <label class="form-label fs-4 text-dark" for="number">
                                    Exercise Quiz Name
                                </label>
                                <input
                                    type="text"
                                    id="name-input1"
                                    autoComplete={false}
                                    class="form-control form-control-lg bg-back"
                                    placeholder="Enter preferred name"
                                    required
                                    style={{
                                        borderRadius: "24px"
                                    }}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div class="col-lg-12">
                                <label class="form-label fs-4 text-dark" for="number">
                                    Exercise Subject
                                </label>
                                <select
                                    class="form-select form-select-lg bg-back text-dark"
                                    aria-label="Default select example"
                                    style={{
                                        borderRadius: "24px"
                                    }}
                                    onChange={(e) => setSubject(e.target.value)}
                                >
                                    <option value="">Select Subject</option>
                                    {renderSubject()}
                                </select>
                            </div>

                            <div class="col-lg-6">
                                <label class="form-label fs-4 text-dark" for="number">
                                    Exercise Class Type
                                </label>
                                <select
                                    class="form-select form-select-lg bg-back text-dark"
                                    aria-label="Default select example"
                                    name="studclass"
                                    style={{
                                        borderRadius: "24px"
                                    }}
                                    onChange={(e) => setStudclass(e.target.value)}
                                >
                                    <option value="">Select Class</option>
                                    <option value="PlayGroup">PlayGroup</option>
                                    <option value="Reception">Reception</option>
                                    <option value="Pre Nursery">Pre Nursery</option>
                                    <option value="NUR 1">NUR 1</option>
                                    <option value="NUR 2">NUR 2</option>
                                    <option value="KG 1">KG 1</option>
                                    <option value="KG 2">KG 2</option>
                                    <option value="Grade 1">Grade 1</option>
                                    <option value="Grade 2">Grade 2</option>
                                    <option value="Grade 3">Grade 3</option>
                                    <option value="Grade 4">Grade 4</option>
                                    <option value="Grade 5">Grade 5</option>
                                    <option value="Grade 6">Grade 6</option>
                                    <option value="Jss1">JSS 1</option>
                                    <option value="Jss2">JSS 2</option>
                                    <option value="Jss3">JSS 3</option>
                                    <option value="sss1">SSS 1</option>
                                    <option value="sss2">SSS 2</option>
                                    <option value="sss3">SSS 3</option>
                                </select>
                            </div>

                            <div class="col-lg-6">
                                <label class="form-label fs-4 text-dark" for="number">
                                    Exercise Time Duration
                                </label>
                                <input
                                    type="number"
                                    maxLength={2}
                                    value={duration}
                                    autoComplete={false}
                                    class="form-control form-control-lg bg-back"
                                    placeholder="Enter Quiz Time"
                                    required
                                    style={{
                                        borderRadius: "24px"
                                    }}
                                    onChange={(e) => setTime(e.target.value)}
                                />
                            </div>

                            <div class="col-lg-12">
                                <label class="form-label fs-4 text-dark" for="number">
                                    Exercise Access Code
                                </label>
                                <div class="input-group" style={{
                                        borderRadius: "24px"
                                    }}>
                                    <input
                                        type={passwordType}
                                        class="form-control form-control-lg bg-back"
                                        placeholder="New password"
                                        required
                                        style={{
                                            borderRadius: "24px"
                                        }}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <span
                                        class="input-group-text bg-back cursor-pointer"
                                        id="basic-addon2"
                                        onClick={togglePassword}
                                    >
                                        {passwordType === "text" ? (
                                            <>
                                                <i className="bi bi-eye-slash me-2"></i> hide
                                            </>
                                        ) : (
                                            <>
                                                <i class="bi bi-eye me-2"></i> show
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div class="col-lg-12 mb-4 mt-6 text-center">
                                <button
                                    type="submit"
                                    class="btn btn-lg btn-success text-white mb-2 px-8"
                                    disabled={status ? true : false}
                                    data-bs-dismiss={duration ? "offcanvas" : ""}
                                    style={{
                                        borderRadius: "24px"
                                    }}
                                >
                                    {status ? (
                                        <>
                                            Creating...
                                            <span
                                                class="spinner-border spinner-border-sm ms-3"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        </>
                                    ) : (
                                        <>
                                            Create Task <i class="bi bi-arrow-right ms-1"></i>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
                :
                <>
                    <div className='py-6'>

                        <div className='mb-5'>
                            <div class="col-lg-12 mb-0">
                                <ul class="nav nav-lb-tab">
                                    <li class="nav-item ms-0 me-3 fs-4">
                                        <div class="nav-link active px-3">
                                            Upload Media Quiz
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <Player
                            autoplay={true}
                            loop={true}
                            controls={false}
                            src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                            style={{ height: "250px", width: "250px" }}
                        ></Player>

                        <div className="text-center mt-5">
                            <div className="text-purple2 fs-t fw-bold">
                                Access to this service is restricted.
                            </div>

                            <div className="text-gray-500 fs-4 mt-2">
                                For more details contact Smart Edu Team.
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Classwork