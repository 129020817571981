import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

const Header = () => {

    const getusers = useSelector((state) => state.user.value);

    const admin_name = getusers.username;

    const [fullname, setfullname] = useState("");

    useEffect(() => {
        setTimeout(function () {
            setfullname(admin_name.split(" ")[0].charAt(0).toUpperCase() +
                admin_name.split(" ")[1].charAt(0).toUpperCase());
        }, 1000);
    }, [admin_name]);

    return (
        <div>
            <div class="">
            <Tippy
                content={admin_name}
                interactive={true}
                interactiveBorder={400}
                delay={100}
              >
                <button
                    type="button"
                    class="btn btn-danger btn-sm text-white fs-3 rounded-circle"
                >
                    {fullname}
                </button>
                </Tippy>
            </div>
        </div>
    )
}

export default Header