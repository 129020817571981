import React from "react";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import { Link, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

const Header_ = () => {

  const { id } = useParams();

  const getusers = useSelector((state) => state.user.value);

  const admin_name = getusers.username;

  const teachid = getusers.teachid;


  return (
    <>
      <div class="navbar-nav ms-0 d-flex nav-top-wrap text-center d-none d-lg-block"></div>

      <ul class="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap text-end">

        <li class="me-lg-2 font-display2 d-none">

          <div
            class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 beamerTrigger"
          >
            <i class="bi bi-bell-fill text-purple3 fs-4"></i>
          </div>

        </li>

        <li class="me-lg-2 font-display2" data-bs-toggle="modal" data-bs-target="#Handle_Profile">
          <div class="position-relative">
            <img src={"../" + id + "/profiles/" + teachid + ".jpg"} alt="" class="rounded-circle avatar-md" />
          </div>
        </li>

      </ul>
    </>
  );
};

export default Header_;
