import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import Fetch from "./Fetch";

const Students = () => {
  const [loaders, setLoaders] = useState(true);

  const { id } = useParams();

  const getusers = useSelector((state) => state.user.value);

  const app_status = getusers.app_status;

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const [progress, setProgress] = useState(20);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
  }

  useEffect(() => {
    localStorage.setItem("id", teachid);
  }, [teachid]);

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  return (
    <>
      <Auth />
      <div
        id="db-wrapper"
        className={isMobile ? `` : ``}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#474787"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <nav class="navigation navbar-vertical-compact navbar-light bg-white shadow-sm">
            <Aside />
          </nav>

          <div id={`page-content-for-mini`} className="Stud bg-back">
            <div class="container-fluid px-4 px-lg-4">
              <div class="header py-lg-4 py-5 px-0">
                <nav
                  class={headers}
                  style={
                    isMobile
                      ? { background: "#f6f6f6" }
                      : { left: "74px", background: "#f6f6f6" }
                  }
                >
                  <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">
                    <div class="navbar-brand text-start me-3 d-lg-none">
                      <span
                        class="btn btn-purple4 btn-icon text-white rounded-br-lg rounded-bl-sm rounded-t-lg"
                        data-bs-toggle="modal"
                        data-bs-target="#menu"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                      >
                        <i class="bi bi-list fs-3 text-white cursor-pointer"></i>
                      </span>
                    </div>

                    <div className="fs-t text-dark me-auto">
                      <Link to={"../" + id + "/home"}>
                        <i class="bi bi-arrow-left"></i>
                      </Link>{" "}
                      Student Center
                    </div>
                  </div>

                  <Header_ />
                </nav>
              </div>

              {app_status === "InActive" ? (
                <StyleRoot>
                  <div style={styles.bounce}>
                    <Activate />
                  </div>
                </StyleRoot>
              ) : (
                <>
                  <StyleRoot>
                    <div style={styles.bounce} className="row px-lg-4 py-lg-8 py-5">
                      <Fetch />
                    </div>
                  </StyleRoot>
                </>
              )}
            </div>

            <Footer />
          </div>

          <Mobile />

          <ToastContainer />

          <Switcher />
        </>
      </div>
    </>
  );
};

export default Students;
