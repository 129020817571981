import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams } from "react-router-dom";

import { isMobile } from "react-device-detect";

import 'react-h5-audio-player/lib/styles.css';

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

import { Player } from "@lottiefiles/react-lottie-player";

import Forms from "./Forms";

const Exercise = ({ course, num_ques }) => {

    const { id } = useParams();

    const { token } = useParams();

    const { quill, quillRef } = useQuill();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const plan = getusers.plan;

    const teachid = getusers.teachid;

    const [fullscreen, SetFullScreen] = useState(false);

    const [media_task, setMedia] = useState([]);

    const FetchMedia = (course) => {
        axios({
            method: "post",
            url: "./v2/media/fetchs/",
            data: {
                token,
                teachid,
                course
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (!view.token) {
                    setTimeout(function () {
                        //navigate("./denied");
                    }, 3000);
                } else if (view.message === "failed") {
                } else {
                    setMedia(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const [timer, setTimer] = useState(null);

    const FetchNote = () => {

        axios({
            method: "post",
            url: "./v2/media/note/",
            data: {
                token,
                teachid,
                course
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {

                const view = response.data;

                setTimer(response.data.timer)

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    useEffect(() => {
        FetchMedia(course);
        FetchNote();
    }, [token, teachid]);

    const [task, setTask] = useState(null);

    const [ans1, setA] = useState(null);

    const [ans2, setB] = useState(null);

    const [ans3, setC] = useState(null);

    const [ans4, setD] = useState(null);

    const [anscor, setAns] = useState(null);

    const [question, setQues] = useState(null);

    const [status, setStat] = useState(false);

    useEffect(() => {
        if (quill) {
            //quill.clipboard.dangerouslyPasteHTML("<h1>React Hook for Quill!</h1>");

            quill.on("text-change", (delta, oldDelta, source) => {
                //console.log("Text change!");
                //console.log(quill.root.innerHTML); // Get innerHTML using quill
                //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                setQues(quillRef.current.firstChild.innerHTML);
            });
        }
    }, [quill]);

    const setQuestion = (ques) => {
        setQues(ques);
        setQuest(null);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const form = document.getElementById("form");

        setStat(true);

        const cbt = {
            ans1,
            ans2,
            ans3,
            ans4,
            anscor,
            question,
            teachid,
            token,
            status: task,
            course
        };

        //console.table(cbt);

        if (question === null) {
            toast.error("Empty: Field can't be empty!", {
                hideProgressBar: true,
                draggable: true,
                position: "bottom-right",
                icon: true,
                autoClose: 6000,
            });

            setTimeout(function () {
                setStat(false);
            }, 3000);
        } else {
            axios({
                method: "post",
                url: "/v2/media/question/",
                data: cbt,
                config: { headers: { "Content-Type": "multiport/form-data" } },
            })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.message === "success") {
                        toast.success("Success: Exercise Question created successfully!", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        //FetchMediaQues();

                        FetchMedia(course);

                        setTimeout(function () {
                            setStat(false);
                        }, 2000);

                        setQues(null);

                        setTask("Question");

                        if (quill) {
                            quill.clipboard.dangerouslyPasteHTML("");
                        }

                        localStorage.setItem("token", response.data.token);
                    } else {
                        toast.error("Failed: Request Failed! Please try again", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        setTimeout(function () {
                            setStat(false);
                        }, 3000);
                    }
                })
                .catch((err) => {
                    toast.error("Error: Request Failed! Check network connection.");
                });

            form.reset();
        }
    };

    const [files, setFile] = useState([]);

    const FetchMediaQues = (course) => {
        axios({
            method: "post",
            url: "./v2/media/question_list/",
            data: {
                token,
                course,
                teachid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setFile(response.data);
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };


    useEffect(() => {
        renderFiles();
    }, [files]);

    const renderFiles = () => {
        if (files == "") {
            return (
                <>

                </>
            );
        } else if (files) {
            return files.map((tasks, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {tasks.info === "Instruction" ? (
                            <>
                                <div className="col-lg-12">

                                    <div
                                        className="card p-2 p-lg-1 bg-gray-300 shadow-none curve1 cursor-pointer"
                                        data-bs-toggle="modal"
                                        data-bs-target="#HandleExerciseEdit"
                                        onClick={() => FetchQuesid(tasks.row)}
                                    >
                                        <div className="card p-4 bg-white shadow-sm">
                                            <div className="fs-t text-dark mb-4">Instruction</div>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: tasks.ques }}
                                            ></div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        ) : tasks.info === "Question" ? (
                            <>
                                <div className="col-lg-6 pt-3">

                                    <div
                                        className="card p-4 bg-white shadow-none cursor-pointer border border-light"
                                        data-bs-toggle="modal"
                                        data-bs-target="#HandleExerciseEdit"
                                        style={{ background: "transparent", borderRadius: "15px", }}
                                        onClick={() => FetchQuesid(tasks.row)}
                                    >
                                        <div className="fs-t text-dark mb-4">
                                            Question {tasks.id}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: tasks.ques }}></div>

                                        <div className="row g-2 mt-2">
                                            <div className="col-12 fs-4">A. {tasks.ans1}</div>

                                            <div className="col-12 fs-4">B. {tasks.ans2}</div>

                                            <div className="col-12 fs-4">C. {tasks.ans3}</div>

                                            <div className="col-12 fs-4">D. {tasks.ans4}</div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [info, setInfo] = useState(null);

    const [quesid, setID] = useState(null);

    const [rowid, setRowID] = useState(null);

    const [quest, setQuest] = useState(null);

    const FetchQuesid = (numid) => {
        axios({
            method: "post",
            url: "./v2/media/question_fetch/",
            data: {
                token,
                q: numid,
                course
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setQuest(view.ques);
                    setInfo(view.info);
                    setTask(view.info);
                    setID(view.id);

                    setA(view.ans1);
                    setB(view.ans2);
                    setC(view.ans3);
                    setD(view.ans4);

                    setRowID(numid);

                    setAns(view.anscor);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const onSubmit_ = (e) => {
        e.preventDefault();

        const form = document.getElementById("form");

        setStat(true);

        const cbt = {
            ans1,
            ans2,
            ans3,
            ans4,
            anscor,
            question,
            teachid,
            token,
            quesid: rowid,
            status: task,
            course
        };

        //console.table(cbt);

        if (question === null) {
            toast.error("Empty: Field can't be empty!", {
                hideProgressBar: true,
                draggable: true,
                position: "bottom-right",
                icon: true,
                autoClose: 6000,
            });

            setTimeout(function () {
                setStat(false);
            }, 3000);
        } else {
            axios({
                method: "post",
                url: "/v2/media/question_edit/",
                data: cbt,
                config: { headers: { "Content-Type": "multiport/form-data" } },
            })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.message === "success") {
                        toast.success("Success: Exercise Question updated successfully!", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        FetchMedia(course);

                        FetchMediaQues(course);

                        setTimeout(function () {
                            setStat(false);
                        }, 2000);

                        localStorage.setItem("token", response.data.token);

                    } else {
                        toast.error("Failed: Request Failed! Please try again", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        setTimeout(function () {
                            setStat(false);
                        }, 3000);
                    }
                })
                .catch((err) => {
                    toast.error("Error: Request Failed! Check network connection.");
                });

            form.reset();
        }
    };

    return (
        <div>

            <div className="col-lg-12 col-md-12 col-12 mt-lg-n8 mt-n4 text-center mb-5">
                <div className="fs-3 font-display2 mb-3 ms-lg-3 ms-2 text-start text-dark">
                    <i class="bi bi-columns-gap"></i> Lecture Exercise
                </div>
                {media_task.id >= 1 || num_ques >= 1 ?
                    <>
                        <div className='pt-8 pt-lg-2'>

                            <div lassName="cursor-pointer"
                                onClick={() => FetchMediaQues(course)}
                                data-bs-toggle="modal"
                                data-bs-target="#HandleExercisePreview">
                                <Player
                                    className="cursor-pointer"
                                    data-bs-toggle="modal"
                                    data-bs-target="#HandleExercisePreview"
                                    autoplay={true}
                                    loop={true}
                                    controls={false}
                                    src="https://assets4.lottiefiles.com/packages/lf20_OrVE6H/folder.json"
                                    style={{ height: "250px", width: "250px" }}
                                ></Player>
                            </div>

                            <div className="text-center mt-3">
                                <div className="text-dark fs-10">
                                    <i class="bi bi-arrow-up"></i>
                                </div>

                                <div className="text-primary fs-t fw-bold">
                                    Click folder image to access all exercise questions
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="text-center">
                        <img
                            src="../assets/images/background/4.png"
                            class=""
                            width={isMobile ? "45%" : "25%"}
                        />
                    </div>
                }

                <div className="fs-4 text-dark py-3">
                    To create exercise, click on the button below and setup exercise questions.
                    You have and option of 1 to 10 questions.
                </div>

                {num_ques === 10 ?
                    <></> :
                    <div className="btn btn-light btn-lg fs-3 font-display2 text-dark mt-2 fw-medium"
                        data-bs-toggle="modal"
                        data-bs-target={media_task.time ? "#HandleSetup" : "#HandleTimer"}
                        onClick={() => FetchMedia(course)}
                        style={{
                            borderRadius: "30px",
                            background: "transparent",
                            borderColor: "#000",
                            color: "#000",
                        }}>
                        <i class="bi bi-plus-lg"></i> Create Exercise
                    </div>
                }

            </div>

            <div class="modal fade" id="HandleSetup" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                    <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                        <div class="modal-header border-0">
                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                        </div>
                        <div class="modal-body">
                            <div className="mt-8 mt-lg-0 text-center">
                                <i class="bi bi-list-stars fs-1 text-success"></i>

                                <div className="fs-10 font-display2 text-dark mt-2">
                                    Setup Exercise Option
                                </div>

                                <div className="row px-lg-6 pt-4">
                                    <div className="col-6 col-md-6 col-lg-6">
                                        <div className="card py-4 px-1 shadow-none cursor-pointer bg-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#HandleExercise"
                                            style={{ borderRadius: "25px" }}>
                                            <div onClick={() => setTask("Question")}>
                                                <div className="text-white fs-t text-center">
                                                    <div class="bi bi-patch-question"></div>
                                                    <div className="fs-3">New Question</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6 col-md-6 col-lg-6">
                                        <div className="card py-4 px-1 shadow-none cursor-pointer bg-orange_"
                                            data-bs-toggle="modal"
                                            data-bs-target="#HandleExercise"
                                            style={{ borderRadius: "25px" }}>
                                            <div onClick={() => setTask("Instruction")}>
                                                <div className="text-white fs-t text-center">
                                                    <div class="bi bi-info-circle-fill"></div>
                                                    <div className="fs-3">Instruction</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="HandleExercise" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-fullscreen modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"}
                    role="document">
                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                        <div class="modal-header border-0">
                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                            <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                                <i class="bi bi-arrows-fullscreen text-purple fs-3"></i>
                            </div>
                        </div>
                        <div class="modal-header border-0 py-0">
                            <ul class="nav nav-lb-tab">
                                <li
                                    class="nav-item ms-0 me-3 fs-4"
                                    onClick={() => setTask("Question")}
                                >
                                    <div
                                        class={
                                            task === "Question"
                                                ? "nav-link active1 px-3"
                                                : "nav-link px-3"
                                        }
                                    >
                                        Question
                                    </div>
                                </li>

                                <li
                                    class="nav-item ms-0 me-3 fs-4"
                                    onClick={() => setTask("Instruction")}
                                >
                                    <div
                                        class={
                                            task === "Instruction"
                                                ? "nav-link active3 px-3"
                                                : "nav-link px-3"
                                        }
                                    >
                                        Instruction
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div class="modal-body py-0">

                            <form className="mt-lg-0" onSubmit={onSubmit} id="form">
                                <div className="pb-12">

                                    <div className="fs-10 font-display2 text-dark mt-3">
                                        Create {task} {task === "Question" ? <>{media_task.id + 1}</> : <></>}
                                    </div>

                                    {task === "Instruction" ? (
                                        <div className="mt-3 fs-5 font-display">
                                            Fill in the Instruction for the exercise below. Remember you can always make changes.
                                        </div>
                                    ) : (
                                        <div className="mt-3 fs-5 font-display">
                                            Fill in the Questions for the exercise below. Remember you can always make changes.
                                        </div>
                                    )}

                                    <div
                                        className={
                                            fullscreen ? "col-lg-8 pt-3" : "col-lg-12 pt-3"
                                        }
                                        style={{ height: 150 }}
                                    >
                                        <div ref={quillRef} />
                                    </div>

                                    {task === "Instruction" ? (
                                        <div className="pt-4 mt-12 mt-lg-6 mt-md-6"></div>
                                    ) : task === "Question" ? (
                                        <div className="row g-3 pt-12 mt-5 mt-lg-0 mt-md-0">
                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option A
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option A"
                                                    required
                                                    onChange={(e) => setA(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option B
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option B"
                                                    required
                                                    onChange={(e) => setB(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option C
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option C"
                                                    required
                                                    onChange={(e) => setC(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option D
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option D"
                                                    required
                                                    onChange={(e) => setD(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <label class="form-label fs-4 text-primary fw-bold">
                                                    Choose Correct Answer Option
                                                </label>
                                                <select
                                                    class="form-select bg-light text-dark rounded-3"
                                                    aria-label="Default select example"
                                                    onChange={(e) => setAns(e.target.value)}
                                                >
                                                    <option value="">Select Correct Option</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                </select>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div
                                        className={
                                            task === "Media"
                                                ? "col-lg-12 pt-8 mb-lg-0 mb-5 text-center d-none"
                                                : "col-lg-12 pt-8 mb-lg-0 mb-5 text-center"
                                        }
                                    >
                                        {num_ques === 10 ?
                                            <></> :
                                            <button
                                                className={
                                                    task === "Instruction"
                                                        ? "btn btn-danger shadow-sm btn-md fw-medium fs-4"
                                                        : "btn btn-primary shadow-sm btn-md fw-medium fs-4"
                                                }
                                                style={{ borderRadius: "25px" }}
                                                disabled={status ? true : false}
                                            >
                                                {status ? (
                                                    <>
                                                        Creating...
                                                        <span
                                                            class="spinner-border spinner-border-sm ms-3"
                                                            role="status"
                                                            aria-hidden="true"
                                                        ></span>
                                                    </>
                                                ) : (
                                                    <>
                                                        Save & Next Question{" "}
                                                        <i class="bi bi-arrow-right ms-1"></i>
                                                    </>
                                                )}
                                            </button>
                                        }
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="HandleExercisePreview" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-xl modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"}
                    role="document">
                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                        <div class="modal-header border-0 py-0 pt-4">
                            <h3 class="cursor-pointer text-dark fs-t" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                aria-label="Close">{course}</h3>
                            <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                                <i class="bi bi-arrows-fullscreen text-purple fs-3"></i>
                            </div>
                        </div>

                        <div class="modal-header border-0 py-0 pb-2">
                            <div className="fs-4 text-dark">
                                Click on each tab (either Instruction or question) to edit.
                            </div>
                        </div>

                        <div class="modal-body py-0">

                            <div className="p-lg-4 p-2 mb-4">
                                <div className="row g-4 pt-3 pt-lg-0">
                                    {renderFiles()}
                                </div>

                                <div className="text-end mt-5">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                                        style={{ borderRadius: "30px" }}>
                                        Close Exercise
                                    </button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="HandleExerciseEdit" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-fullscreen modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"}
                    role="document">
                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                        <div class="modal-header border-0">
                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                            <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                                <i class="bi bi-arrows-fullscreen text-purple fs-3"></i>
                            </div>
                        </div>
                        <div class="modal-header border-0 py-0">
                            <div className="fs-t fw-medium text-purple2">
                                Edit {info} {info === "Question" ? <>{quesid}</> : <></>}
                            </div>
                        </div>
                        <div class="modal-body py-0">

                            <div className="px-lg-2">
                                {info === "Media" ? (
                                    <div className="mt-3 fs-5 font-display">
                                        Select the file type you want to send, either video, audio, or
                                        document.
                                    </div>
                                ) : (
                                    <div className="mt-3 fs-5 font-display">
                                        Fill in the details below. Remember you can always make changes.
                                    </div>
                                )}

                                <form className="mt-lg-0" onSubmit={onSubmit_} id="form">

                                    <Forms setQuestion={setQuestion} quest={quest} />

                                    {info === "Instruction" ? (
                                        <div className="pt-4 mt-12 mt-lg-6 mt-md-6"></div>
                                    ) : info === "Question" ? (
                                        <div className="row g-3 pt-12 mt-5 mt-lg-0 mt-md-0">
                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option A
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option A"
                                                    required
                                                    value={ans1}
                                                    onChange={(e) => setA(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option B
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option B"
                                                    required
                                                    value={ans2}
                                                    onChange={(e) => setB(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option C
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option C"
                                                    required
                                                    value={ans3}
                                                    onChange={(e) => setC(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                    Option D
                                                </label>
                                                <input
                                                    type="text"
                                                    autoComplete={false}
                                                    class="form-control form-control-md bg-light rounded-3"
                                                    placeholder="Enter option D"
                                                    required
                                                    value={ans4}
                                                    onChange={(e) => setD(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-md-12">
                                                <label class="form-label fs-4 text-primary fw-bold">
                                                    Choose Correct Answer Option
                                                </label>
                                                <select
                                                    class="form-select bg-light text-dark rounded-3"
                                                    aria-label="Default select example"
                                                    onChange={(e) => setAns(e.target.value)}
                                                    value={anscor}
                                                >
                                                    <option value="">Select Correct Option</option>
                                                    <option value="A">A</option>
                                                    <option value="B">B</option>
                                                    <option value="C">C</option>
                                                    <option value="D">D</option>
                                                </select>
                                            </div>
                                        </div>
                                    ) : info === "Media" ? (
                                        <div className="pt-4 mt-2 mt-lg-4 mt-md-6">
                                            <div className="col-12 col-md-12 col-lg-6 offset-lg-3 text-center">
                                                <img
                                                    src="../../assets/images/background/coming.svg"
                                                    className=""
                                                    width="100%"
                                                />
                                                <div className="text-purple2 fs-3">
                                                    Amazing Feature Coming soon...
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div
                                        className={
                                            info === "Media"
                                                ? "col-lg-12 py-5 mb-lg-0 mb-5 text-end d-none"
                                                : "col-lg-12 py-5 mb-lg-0 mb-5 text-end"
                                        }
                                    >
                                        <button
                                            className={
                                                info === "Instruction"
                                                    ? "btn btn-success shadow-sm btn-md rounded-3 text-uppercase"
                                                    : "btn btn-primary shadow-sm btn-md rounded-3 text-uppercase"
                                            }
                                            data-bs-toggle="modal"
                                            data-bs-target="#HandleExercisePreview"
                                            disabled={status ? true : false}
                                        >
                                            {status ? (
                                                <>
                                                    Executing...
                                                    <span
                                                        class="spinner-border spinner-border-sm ms-3"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                </>
                                            ) : (
                                                <>
                                                    Save Changes <i class="bi bi-check-lg ms-1"></i>
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Exercise