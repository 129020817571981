import React, { useState, useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { fadeIn, slideInDown, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import Footer from "../Footer";

const Login_CBT = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const [studid, setID] = useState(null);

  const [password, setPassword] = useState(null);

  const [status, setStat] = useState(false);

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    const user = {
      studid,
      password,
    };

    axios({
      method: "post",
      url: "/v2/portal/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {

          toast.success("Success: Login was successfull!", {
            hideProgressBar: true,
            theme: "light",
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
            navigate("/"+id+"/quiz/home");
          }, 5000);

          localStorage.setItem("studid", response.data.studid);

          localStorage.setItem("studname", response.data.name);

          localStorage.setItem("studclass", response.data.studclass);

          localStorage.setItem("plan", response.data.plan);

        } else {
          toast.error("Failed: Login Failed! Incorrect details.", {
            hideProgressBar: true,
            theme: "dark",
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  return (
    <div class="bg-back py-10 py-lg-4">
      <div>

        <StyleRoot>

          <div class="container-fluid" style={styles.bounce}>
            <div className='row px-lg-20'>
              <div className='col-lg-6 offset-lg-3 mt-5'>
                <div className='card' style={{ borderRadius: "18px" }}>
                  <div className='card-body text-center py-8 px-6'>

                    <div class="mb-2">
                      <span class="avatar avatar-xl avatar-primary-soft">
                        <img alt="avatar" src={"../assets/images/school/" + id + ".png"} class="rounded-circle" />
                      </span>
                    </div>

                    <div className='fs-t text-purple3'>
                      School CBT Portal
                    </div>

                    <form className='mt-4 text-start' onSubmit={onSubmit}>

                      <div class="mb-5">
                        <div class="fs-4 mb-2" for="textInput">Enter Student ID</div>
                        <input type="text" id="studentid" class="form-control form-control-lg bg-back"
                          required placeholder="Enter your student ID"
                          onChange={(e) => setID(e.target.value)}
                          style={{ borderRadius: "24px" }} />
                      </div>

                      <div class="mb-5">
                        <div class="fs-4 mb-2" for="textInput">Enter Password</div>
                        <div class="input-group">
                          <input
                            type={passwordType}
                            class="form-control form-control-lg bg-back"
                            placeholder="Enter Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ borderRadius: "24px" }}
                          />
                          <span
                            class="input-group-text bg-white border-0 cursor-pointer"
                            id="basic-addon2"
                            onClick={togglePassword}
                          >
                            {passwordType === "text" ? (
                              <>
                                <i className="bi bi-eye-slash me-2"></i> hide
                              </>
                            ) : (
                              <>
                                <i class="bi bi-eye me-2"></i> show
                              </>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className='text-center d-grid pt-3'>
                        <button className={status ? 'btn btn-success font-display fs-3' : 'btn btn-warning font-display fs-3'}
                          style={{ borderRadius: "24px" }}
                          disabled={status ? true : false}>
                          {status ? (
                            <>
                              Executing...
                              <span
                                class="spinner-border spinner-border-sm ms-3"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            "Login"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </StyleRoot>
      </div>

      <div
        class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
      >
      </div>

      <ToastContainer />

      <Footer />
    </div>

  )
}

export default Login_CBT