import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Footer from "../Footer";

const Sheet = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const admin_name = getusers.username;

  const term = getusers.term;

  const [fullname, setfullname] = useState("{}");

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0]);
    }, 1000);

    localStorage.setItem("id", teachid);
  }, [admin_name, teachid]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [report, setReport] = useState([]);

  const [loader, setLoader] = useState(true);

  const FetchReport = (q) => {
    axios({
      method: "post",
      url: "./v2/report/sheet/",
      data: {
        teachid,
        q
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setReport(view);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport(null);
  }, [teachid]);

  const renderFolders = () => {
    if (report == "") {
      return <></>;
    } else if (report != "") {
      return report.map((report, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="d-flex align-items-center py-3 py-lg-3">
              <div className="col-1 col-lg-1">
                <div className="fs-4 text-purple2 fw-medium">{index + 1}</div>
              </div>

              <div className="col-3 col-lg-3 text-start">
                <div className="fs-4 text-purple2 fw-medium">
                  {report.fname} {report.username}
                </div>
              </div>

              <div className="col-1 col-lg-3 text-start fs-4 text-purple2 ms-1 ms-lg-0">
                {report.stud_class}
              </div>

              <div className="col-3 col-lg-3 text-start fs-4 text-purple2 ms-3 ms-lg-0">
                {report.teach_id}
              </div>

              <div className="col-2 col-lg-1 text-start text-lg-start">
                <Link to={"../" + id + "/report/sheet/" + report.teach_id} target="blank" className="btn btn-primary rounded-pill">
                  <i className="bi bi-arrow-right-circle-fill text-white"></i>
                </Link>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [tokenid, setToken] = useState(null);

  const [subject, setSubject] = useState(null);

  const Remove = (tokenid) => {
    const task = {
      teachid,
      tokenid,
    };

    axios({
      method: "post",
      url: "/v2/report/delete/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Deleted successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 2000,
          });

          FetchReport(null);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };


  const hash = window.location.hash.slice(5);

  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <div class="docs-header">
        <nav class="navbar navbar-expand-lg navbar-default bg-start fixed-top ms-0 py-3 py-lg-3 shadow-none">
          <a class="fs-10 text-white fw-medium">
            E-Report <span className="text-warning">Center</span>
          </a>
          <ul class="navbar-nav ms-auto flex-row d-none d-lg-flex">
            <Link to={"../" + id + "/report"}
              class={
                hash === "/report"
                  ? "nav-item docs-header-btn ms-6 text-warning"
                  : "nav-item docs-header-btn ms-6 text-white"
              }
            >
              <span className="fw-bold fs-4">Home</span>
            </Link>
          </ul>
        </nav>
      </div>

      <div class="bg-back">
        <div class="container-fluid px-lg-10 pt-lg-18 pt-4">
          <div className="d-flex align-items-center">
            <div className="col">
              <div className="fs-t text-purple3">
                Broadsheet |{" "}
                <span className="text-dark fs-5">
                  <i className="bi bi-table"></i> -- Broadsheet
                </span>
              </div>
            </div>

            <div className="col-end text-center"></div>
          </div>

          <div className="col-12 mt-8">
            <div
              className="card bg-white shadow-none"
              style={{ borderRadius: "20px" }}
            >
              <div className="card-body px-lg-10">
                <div className="fs-t text-purple3 mb-4 mt-2">
                  List of class results
                </div>

                <div className="mb-4">

                  <input
                    type="text"
                    autoComplete={false}
                    class="form-control form-control-lg text-dark border-dark rounded-pill"
                    placeholder="Search Reference"
                    onChange={(e) => FetchReport(e.target.value)}
                  />

                </div>

                <>
                  {loader ? (
                    <div className="col-lg-12 text-center py-lg-10 py-20">
                      <div
                        class="spinner-border text-purple3"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>{renderFolders()}</>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div
          class="modal"
          id="delete_menu"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-md modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-t text-purple2">
                  <i class="bi bi-trash"></i> Remove Student{" "}
                </div>

                <button
                  class="btn btn-warning btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                </button>
              </div>
              <div class="modal-body py-2">
                <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-4 text-center">
                  <img
                    src="../../assets/images/background/remove.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <div className="text-center px-lg-8 px-2 pt-2 mb-6 mb-lg-4">
                  <div className="fs-t text-purple3 fw-medium mb-2">
                    Do you want to remove {subject} result from list?
                  </div>
                </div>

                <div className="row text-center mb-lg-8">
                  <div className="col-6">
                    <button
                      className="btn btn-dark rounded-pill px-6"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      className="btn btn-danger rounded-pill px-6"
                      data-bs-dismiss="modal"
                      onClick={() => Remove(tokenid)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <ToastContainer />
    </div>
  );
};

export default Sheet;
