import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../Auth";

import axios from "axios";

const Settings = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const phone_num = getusers.phone;

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [steps, setStep] = useState(2);

  const [status, setStat] = useState(false);

  const [progress, setProgress] = useState(20);

  const [class_type, setClass_Type] = useState(null);

  const [stud_class, setStudclass] = useState(null);

  const [stud_num, setStudNum] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      class_type,
      stud_class,
      stud_num,
    };

    axios({
      method: "post",
      url: "/v2/report/manage/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStep(3);
            setStat(false);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const [phone, setWhatsapp] = useState(null);

  const onSubmit_ = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      phone,
    };

    axios({
      method: "post",
      url: "/v2/report/phone/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStep(4);
            setStat(false);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const [test_type, setTestType] = useState(null);

  const [termsession, setSession] = useState(null);

  const [term, setTerm] = useState(null);

  const onSubmit_0 = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      test_type,
      termsession,
      term,
    };

    axios({
      method: "post",
      url: "/v2/report/settings/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            navigate("../" + id + "/report");
            setStat(false);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  return (
    <div class="modal" tabindex="-1" id="settings">
      <div
        className={
          isMobile
            ? "modal-dialog modal-dialog-centered modal-fullscreen"
            : "modal-dialog modal-md modal-dialog-centered"
        }
      >
        <div
          class="modal-content"
          style={
            isMobile
              ? { border: "#fff" }
              : { borderRadius: "35px", border: "#fff" }
          }
        >
          {steps === 2 ? (
            <>
              <div class="modal-header border-0">
                <button
                  class="btn btn-dark btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                </button>

                <div
                  className="col-auto cursor-pointer"
                  onClick={() => setStep(3)}
                >
                  <i class="bi bi-arrow-right fs-3 text-dark"></i>
                </div>
              </div>

              <div className="modal-body">
                <div className="text-center px-lg-8 px-2">
                  <div className="fs-t text-purple3 fw-bold mb-2">
                    Are you a class teacher?
                  </div>
                </div>

                <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                  <img
                    src="../../assets/images/background/teach.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <form className="mt-4 mb-20" onSubmit={onSubmit}>
                  <div class="col-auto px-lg-6">
                    <select
                      class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                      aria-label="Default select example"
                      name="class_type"
                      required
                      onChange={(e) => setClass_Type(e.target.value)}
                    >
                      <option value="">Select Option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  {class_type === "Yes" ? (
                    <>
                      <div class="col-auto px-lg-6">
                        <select
                          class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                          aria-label="Default select example"
                          name="stud_class"
                          required
                          onChange={(e) => setStudclass(e.target.value)}
                        >
                          <option value="">Select Class</option>
                          <option value="PlayGroup">PlayGroup</option>
                          <option value="Reception">Reception</option>
                          <option value="Pre Nursery">Pre Nursery</option>
                          <option value="NUR 1">NUR 1</option>
                          <option value="NUR 2">NUR 2</option>
                          <option value="KG 1">KG 1</option>
                          <option value="KG 2">KG 2</option>
                          <option value="Grade 1">Grade 1</option>
                          <option value="Grade 2">Grade 2</option>
                          <option value="Grade 3">Grade 3</option>
                          <option value="Grade 4">Grade 4</option>
                          <option value="Grade 5">Grade 5</option>
                          <option value="Grade 6">Grade 6</option>
                          <option value="Jss1">JSS 1</option>
                          <option value="Jss2">JSS 2</option>
                          <option value="Jss3">JSS 3</option>
                          <option value="sss1">SSS 1</option>
                          <option value="sss2">SSS 2</option>
                          <option value="sss3">SSS 3</option>
                        </select>
                      </div>

                      <div class="col-auto px-lg-6">
                        <input
                          type="number"
                          autoComplete={false}
                          class="form-control form-control-lg text-dark rounded-3 border-dark mb-4"
                          placeholder="No. of students"
                          required
                          onChange={(e) => setStudNum(e.target.value)}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div className="d-grid mt-5 px-lg-6">
                    <button
                      type="submit"
                      disabled={status ? true : false}
                      className="btn btn-warning rounded-pill fs-3 text-dark"
                    >
                      {status ? (
                        <>
                          <span
                            class="spinner-border spinner-border-md mt-1 text-success"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        <>
                          Next <i className="bi bi-arrow-right"></i>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : steps === 3 ? (
            <>
              <div class="modal-header border-0">
                <div
                  className="col-auto cursor-pointer"
                  onClick={() => setStep(2)}
                >
                  <i class="bi bi-arrow-left fs-3 text-dark"></i>
                </div>

                <div
                  className="col-auto cursor-pointer"
                  onClick={() => setStep(4)}
                >
                  <i class="bi bi-arrow-right fs-3 text-dark"></i>
                </div>
              </div>

              <div className="modal-body">
                <div className="text-center px-lg-8 px-2">
                  <div className="fs-t text-purple3 fw-bold mb-2">
                    Enter your valid WhatsApp number.
                  </div>
                </div>

                <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                  <img
                    src="../../assets/images/background/whatsapp.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <form className="mt-4" onSubmit={onSubmit_}>
                  <div class="col-auto px-lg-6">
                    <div class="input-group">
                      <span
                        class="input-group-text text-dark border border-0 fw-bold cursor-pointer"
                        id="basic-addon2"
                      >
                        +234
                      </span>
                      <input
                        type="number"
                        autoComplete={false}
                        class="form-control form-control-lg text-dark border-dark rounded-3"
                        placeholder="Enter Phone Number"
                        required
                        onChange={(e) => setWhatsapp(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="d-grid mt-5 px-lg-6">
                    <button
                      type="submit"
                      disabled={status ? true : false}
                      className="btn btn-warning rounded-pill fs-3 text-dark"
                    >
                      {status ? (
                        <>
                          <span
                            class="spinner-border spinner-border-md mt-1 text-white"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        <>
                          Next <i className="bi bi-arrow-right"></i>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : steps === 4 ? (
            <>
              <div class="modal-header border-0">
                <div
                  className="col-auto cursor-pointer"
                  onClick={() => setStep(3)}
                >
                  <i class="bi bi-arrow-left fs-3 text-dark"></i>
                </div>

                <div className="">

                </div>
              </div>

              <div className="modal-body">
                
                <div className="text-center px-lg-8 px-2">
                  <div className="fs-t text-purple3 fw-bold mb-2">
                    Configure E-Report settings.
                  </div>
                </div>

                <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-2 text-center">
                  <img
                    src="../../assets/images/background/settings.webp"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <form className="mt-4" onSubmit={onSubmit_0}>
                  <div class="col-auto px-lg-6">
                    <select
                      class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                      aria-label="Default select example"
                      name="class_type"
                      required
                      onChange={(e) => setTestType(e.target.value)}
                    >
                      <option value="">Select Test Type</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </select>
                  </div>

                  <div class="col-auto px-lg-6">
                    <select
                      class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                      aria-label="Default select example"
                      name="term"
                      required
                      onChange={(e) => setTerm(e.target.value)}
                    >
                      <option value="">Select Term</option>
                      <option value="First Term">First Term</option>
                      <option value="Second Term">Second Term</option>
                      <option value="Third Term">Third Term</option>
                    </select>
                  </div>

                  <div class="col-auto px-lg-6">
                    <div class="input-group">
                      <input
                        type="text"
                        autoComplete={false}
                        class="form-control form-control-lg text-dark border-dark rounded-3"
                        placeholder="What session? E.g: 20**/20**"
                        required
                        onChange={(e) => setSession(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="d-grid mt-5 px-lg-6">
                    <button
                      type="submit"
                      disabled={status ? true : false}
                      className="btn btn-warning rounded-pill fs-3 text-dark"
                    >
                      {status ? (
                        <>
                          <span
                            class="spinner-border spinner-border-md mt-1 text-white"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        <>
                          Next <i className="bi bi-arrow-right"></i>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
