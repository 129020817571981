import React, { useState, useEffect } from "react";

import { useParams, Link } from "react-router-dom";

import axios from "axios";

import { isMobile } from "react-device-detect";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

const Forms = ({ setQuestion, quest }) => {
  const { quill, quillRef } = useQuill();

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(quest);
    }
  }, [quill, quest]);

  if (quill) {
    quill.on("text-change", function () {
      //console.log("Text change!");
      //console.log(quill.root.innerHTML); // Get innerHTML using quill
      //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
      setQuestion(quillRef.current.firstChild.innerHTML);
    });
  }

  return (
    <>
      <div className="col-lg-12 pt-3 mb-14 mb-lg-0" style={isMobile ? { height: 130 } : {height: 100 }}>
        <div ref={quillRef} />
      </div>
    </>
  );
};

export default Forms;
