import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useDispatch, useSelector } from "react-redux";

import { register } from "./features/Users";

const Auth = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const dispatch = useDispatch();

  const [users, setUser] = useState({});

  dispatch(register(users));

  //console.table(users);

  axios.defaults.baseURL = id; 

  const tokenid = localStorage.getItem("token");

  const teachid = localStorage.getItem("id");

  if (!tokenid){
    navigate("../"+id);
  }

  const hash = window.location.hash.slice(2);

  const fetchData = () => {
    axios({
      method: "post",
      url: "/v2/auth/",
      data: {
        token: tokenid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error(
            "Authorization: Session Expired! We are logging you out now!"
          );
          setTimeout(function () {
            //navigate("../"+id);
          }, 3000);
        } else {
          setUser(view.user_data.data);
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Check your connection..");
      });
  };


  useEffect(() => {
    setInterval(function () {
      if (users) {
        Reload();
      } else {
        //navigate("../"+id);
      }
    }, 20000);
  }, []);

  
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    Reload();
    setTimeout(function () {
      if (users) {
        if(hash === 'home'){
        toast.success(
          "Success: Application workspace successfully authorized",
          {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 3000,
          }
        );
        }
      } else {
       // navigate("../"+id);
      }
    }, 3000);
  }, []);

  //console.log(users);

  const Reload = () => {
    axios({
      method: "post",
      url: "/v2/auth2/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
        } else if (view.message === "timeout") {
          navigate("../"+id);
        }else {
          setUser(view.user_data);
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Check your connection..");
      });
  };

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default Auth;
