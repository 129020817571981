import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Footer from "../Footer";

const Report_Results = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("{}");

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0]);
    }, 1000);

    localStorage.setItem("id", teachid);
  }, [admin_name, teachid]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [report, setReport] = useState([]);

  const [loader, setLoader] = useState(true);

  const FetchReport = () => {
    axios({
      method: "post",
      url: "./v2/report/fetchs/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setReport(view);

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport();
  }, [teachid]);

  const renderFolders = () => {
    if (report == "") {
      return <></>;
    } else if (report != "") {
      return report.map((report, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="col-12 col-lg-4 me-4 me-lg-6 mb-6 mb-lg-5">
              <div
                class={
                  index % 2 == 0
                    ? "card bg-light-primary shadow-none"
                    : "card bg-light-success shadow-none"
                }
                style={{ borderRadius: "20px" }}
              >
                <div className="card-body py-6">
                  <div className="row align-items-center">
                    <div className="col-4 text-center">
                      <img
                        src="../../assets/images/background/result.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div className="col-6">
                      <div className="fs-t text-purple3">{report.subject}</div>
                    </div>

                    <div className="col-2 ms-n3">
                      <Link
                        to={"../" + id + "/report/create/" + report.token}
                        className="btn btn-warning rounded-pill text-dark fs-3"
                      >
                        <i class="bi bi-arrow-right-circle-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <Header />

      <Sidebar />

      <div class="docs-wrapper bg-back">
        <div class="container-fluid px-4 px-lg-10 pt-lg-14 pt-4">
          <div className="d-flex align-items-center">
            <div className="col">
              <div className="fs-t text-purple3">
                Results |{" "}
                <span className="text-dark fs-5">
                  <i className="bi bi-ui-checks"></i> -- Results
                </span>
              </div>
            </div>

            <div className="col-end text-center">
              <Link
                to={"../" + id + "/report/create"}
                className="btn btn-success rounded-pill text-white fs-4 fw-medium"
              >
                New Report <i class="bi bi-arrow-right-circle-fill"></i>
              </Link>
            </div>
          </div>

          <div className="px-lg-12 mt-lg-8 mt-8">
            <div className="col-lg-12 mb-5 mb-lg-8">
              <div
                className="card bg-white shadow-none"
                style={{ borderRadius: "20px" }}
              >
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-5 text-start mt-lg-n8 ms-n3">
                      <img
                        src="../../assets/images/background/send.webp"
                        alt=""
                        class="img-fluid"
                        width={isMobile ? "100%" : "60%"}
                      />
                    </div>

                    <div className="col-6 ms-lg-n15 fw-medium ms-n3">
                      <div
                        className={
                          isMobile
                            ? "fs-5 text-purple2"
                            : "fs-4 text-start text-purple2"
                        }
                      >
                        Click on button to send students results to admin for
                        processing.
                      </div>
                    </div>

                    <div className="col-1 ms-n2 ms-lg-n0">
                      <Link
                        to={"../" + id + "/report/send"}
                        className="btn btn-warning rounded-pill text-dark fs-3"
                      >
                        <i class="bi bi-arrow-right-circle-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col mb-3 mt-lg-4">
            <div className="fs-3 text-purple3">All Uploads</div>
          </div>

          <div className="col-lg-6 mb-8">
            <input
              className="form-control form-control-lg rounded-pill"
              placeholder="Search Lists"
              onChange={(e) => FetchReport(e.target.value)}
            />
          </div>

          <>
            {loader ? (
              <div className="col-lg-12 text-center py-lg-10 py-20">
                <div
                  class="spinner-border text-purple3"
                  style={{ width: "4rem", height: "4rem" }}
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <>
                <SimpleBar forceVisible="y" autoHide={true}>
                  <div class="d-lg-flex align-items-center hoverable">
                    {renderFolders()}
                  </div>
                </SimpleBar>
              </>
            )}
          </>
        </div>

        <Footer />
      </div>

      <ToastContainer />
    </div>
  );
};

export default Report_Results;
