import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import { fadeIn, slideInUp, zoomIn } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

const Read = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  useEffect(() => {
    FetchNote();
  }, [token]);

  const [note_task, setNoteTask] = useState([]);

  const FetchNote = () => {
    axios({
      method: "post",
      url: "./v2/note/fetch/",
      data: {
        token,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (!view.token) {
          setTimeout(function () {
            //navigate("./denied");
          }, 3000);
        } else if (view.message === "failed") {
        } else {
          setNoteTask(view);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [studid, setStudID] = useState(null);

  const [status, setStat] = useState(false);

  const Handle_login = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      studid,
      token,
    };

    axios({
      method: "post",
      url: "/v2/note/access/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Access granted! Redirecting you...", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
            navigate("../" + id + "/read/open");
          }, 5000);

          sessionStorage.setItem("token", token);

          sessionStorage.setItem("teach_id", response.data.teachid);

          sessionStorage.setItem("fname", response.data.name);

          sessionStorage.setItem("stud_id", studid);
        } else {
          toast.error("Failed: Request Failed! Please try again", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <StyleRoot>
        <div className="row align-items-center" style={styles.bounce}>
          <div
            className={
              isMobile
                ? "col-lg-6 bg-white pt-2 pb-8 pb-lg-20"
                : "col-lg-6 bg-white pt-4 pb-8 pb-lg-20 curve"
            }
          >
            <div className="container">
              <div className="row px-2">
                <div className="col-12 px-4">
                  <div className="text-purple3 fs-2">{note_task.title}</div>
                </div>

                <div className="px-4 px-lg-8 pb-6 pt-lg-12 pt-4">
                  <div className="py-6 px-4 px-lg-5 pb-lg-10">
                    <div className="fs-t2 text-purple3 mb-5 fw-medium">
                      Hello there! 👋
                    </div>

                    <div className="fs-3 text-purple2">
                      Welcome to Note Reader! Here you get to read note prepared
                      by your tutors and also comment and ask them direct
                      questions.
                    </div>
                  </div>

                  {isMobile ? (
                    <>
                      <div class="text-start mb-6">
                        <p class="mb-0 text-dark fs-4">
                          <img
                            src="../assets/images/school/sac.png"
                            width={isMobile ? "40" : "50"}
                            alt=""
                            className="me-2"
                          />
                          Powered by {note_task.school} School.
                        </p>
                      </div>
                    </>
                  ) : (
                    <div class="mb-n10 mt-8">
                      <p class="mb-0 text-dark fs-4">
                        <img
                          src={"../assets/images/school/" + id + ".png"}
                          width={isMobile ? "40" : "50"}
                          alt=""
                          className="me-2"
                        />
                        Powered by {note_task.school} School.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mt-n14 mt-lg-0">
            <div className="card bg-back shadow-none">
              <div className="card-body py-6 px-7 px-lg-12 pb-10">
                <div className="fs-10 text-purple2 fw-medium pt-0 pt-lg-10">
                  <i class="bi bi-shield-lock"></i> Access Note
                </div>

                <form className="pt-4 pt-lg-5" onSubmit={Handle_login}>
                  <div className="col-lg-12">
                    <div class="fs-4 text-dark mb-4" for="textInput">
                      Student ID
                    </div>
                    <input
                      type="text"
                      id="studid"
                      class={
                        isMobile
                          ? "form-control form-control-lg py-3 bg-white text-dark"
                          : "form-control form-control-lg bg-white text-dark border border-dark"
                      }
                      onChange={(e) => setStudID(e.target.value)}
                      required
                      placeholder="Fill Your Student ID...."
                      style={{ borderRadius: "24px" }}
                    />
                  </div>

                  {isMobile ? (
                    <>
                      <div className="col-lg-12 d-grid px-4 pb-4 fixed-bottom mt-n16">
                        <button
                          className="btn btn-dark-primary btn-lg rounded-pill px-4 shadow-none"
                          type="submit"
                          disabled={status ? true : false}
                        >
                          {status ? (
                            <>
                              Opening...
                              <span
                                class="spinner-border spinner-border-sm ms-3"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            <>
                              Proceed{" "}
                              <span className="bi bi-arrow-right"></span>
                            </>
                          )}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-12 text-end pt-6 pt-lg-8">
                        <button
                          className="btn btn-dark-primary btn-lg rounded-pill px-4 shadow-none"
                          type="submit"
                          disabled={status ? true : false}
                        >
                          {status ? (
                            <>
                              Opening...
                              <span
                                class="spinner-border spinner-border-sm ms-3"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            <>
                              Proceed{" "}
                              <span className="bi bi-arrow-right"></span>
                            </>
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </StyleRoot>

      <ToastContainer />
    </>
  );
};

export default Read;
