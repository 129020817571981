import React from "react";

import { useParams, useNavigate } from "react-router-dom";

const Logout_Quiz = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  setTimeout(function () {
    localStorage.clear();
    navigate("../"+id+"/quiz");
  }, 5000);

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top bg-back">
        <div className="container-fluid px-0 pt-2">
          <div class="navbar-brand">
            <span className="fs-t text-dark"></span>
          </div>

          <div class="text-dark fs-5 d-block d-xl-none">Loading..</div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <div class="ms-auto mt-3 mt-lg-0">
              <div class="text-dark fs-4">Loading..</div>
            </div>
          </div>
        </div>
      </nav>

      <div class="py-15 py-lg-10 text-center">
        <div class="col-md-12 text-center py-15">
          <div className="col-lg-12 text-center py-lg-8 py-8">
            <div
              class="spinner-border text-danger"
              style={{ width: "4rem", height: "4rem" }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="display-6 fw-bold mb-3 text-dark ls-sm mt-8">
              <span class="headingTyped text-dark">Logging Off....</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout_Quiz;
