import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

const Results = ({ studid }) => {

    const { teachid } = useParams();

    const [report, setReport] = useState([]);

    const [loader, setLoader] = useState(true);

    const FetchReport = () => {
        axios({
            method: "post",
            url: "./v2/report/sheet/result/",
            data: {
                teachid,
                studid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                setReport(view);

                setTimeout(function () {
                    setLoader(false);
                }, 4000);
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    useEffect(() => {
        FetchReport();
    }, [teachid, studid]);

    const renderFolders = () => {
        if (report == "") {
            return <></>;
        } else if (report != "") {
            return report.map((report, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <tr>
                            <td colspan="3">
                                <table class="table table-borderless mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" >{report.subject}</th>
                                            <th scope="col">{report.test}</th>
                                            <th scope="col">{report.exam}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </td>
                        </tr>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    return (
        <>
            {loader ? (
                <div className="col-lg-12 text-center py-lg-10 py-20">
                    <div
                        class="spinner-border text-purple3"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>{renderFolders()}</>
            )}
        </>
    );
};

export default Results;
