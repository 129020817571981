import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import Exist from "./Exist";

const Upload_Report = () => {
  const { id } = useParams();

  const { token } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const term = getusers.term;

  const test_type = getusers.test_type;

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("");

  useEffect(() => {
    setTimeout(function () {
      setfullname(
        admin_name.split(" ")[0].charAt(0).toUpperCase() +
        admin_name.split(" ")[1].charAt(0).toUpperCase()
      );
    }, 1000);
  }, [admin_name]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [loader, setLoader] = useState(true);

  const [report, setReport] = useState([]);

  const FetchReport = () => {
    axios({
      method: "post",
      url: "./v2/report/fetch/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setReport(view);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [students, setStudents] = useState([]);

  const FetchStudents = () => {
    axios({
      method: "post",
      url: "./v2/report/student/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudents(view);

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [add_student, setAddStudent] = useState([]);

  const SearchStudents = (search) => {
    axios({
      method: "post",
      url: "./v2/report/students/",
      data: {
        token,
        teachid,
        q: search,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        if (search) {
          setAddStudent(view);
        } else {
          setAddStudent([]);
        }

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport();
    FetchStudents();
  }, [teachid, token]);

  const renderFolders = () => {
    if (students == "") {
      return <></>;
    } else if (students != "") {
      return students.map((stud, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="d-flex align-items-center py-3 py-lg-3">
              <div className="col-1 col-lg-1">
                <div className="fs-4 text-purple2 fw-medium">{index + 1}</div>
              </div>

              <div className="col-0 col-lg-1 text-start d-none d-lg-block">
                <span class="avatar avatar-lg avatar-primary-soft">
                  <span class="avatar-initials rounded-circle text-primary fs-4 fw-medium">
                    {stud.fname.split(" ")[0].charAt(0).toUpperCase() +
                      stud.fname.split(" ")[1].charAt(0).toUpperCase()}
                  </span>
                </span>
              </div>

              <div className="col-4 col-lg-3 text-start">
                <div className="fs-4 text-purple2 fw-medium">{stud.fname}</div>
                <div className="fs-4 text-primary">{stud.studid}</div>
              </div>

              <div className="col-3 col-lg-3 text-start ms-lg-3">
                {term === "First Term" ? (
                  <div className="fs-4 text-purple3 fw-bold">
                    {stud.test1 + stud.test2 + stud.score1}
                  </div>
                ) : term === "Second Term" ? (
                  <div className="fs-4 text-purple3 fw-bold">
                    {parseInt(stud.test3) +
                      parseInt(stud.test4) +
                      parseInt(stud.score2)}
                  </div>
                ) : term === "Third Term" ? (
                  <div className="fs-4 text-purple3 fw-bold">
                    {parseInt(stud.test5) +
                      parseInt(stud.test6) +
                      parseInt(stud.score3)}
                  </div>
                ) : (
                  <></>
                )}
                <div className="text-dark">{term} Score</div>
              </div>

              <div
                className="col-2 col-lg-2 text-center text-lg-start ms-n3"
                data-bs-toggle="modal"
                data-bs-target="#upload_Result"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => FetchStudent(stud.studid)}
              >
                <button className="btn btn-success rounded-pill">Edit</button>
              </div>

              <div
                className="col-2 col-lg-2 text-center text-lg-start ms-2"
                data-bs-toggle="modal"
                data-bs-target="#delete_menu"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => setStudid(stud.studid)}
              >
                <button className="btn btn-danger rounded-pill">Remove</button>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const renderAdd = () => {
    if (add_student == "") {
      return <></>;
    } else if (add_student != "") {
      return add_student.map((stud, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="d-flex align-items-center py-3 py-lg-3">
              <div className="col-2 col-lg-1 text-start d-none d-lg-block">
                <span class="avatar avatar-lg avatar-primary-soft">
                  <span class="avatar-initials rounded-circle text-primary fs-4 fw-medium">
                    {stud.fname.split(" ")[0].charAt(0).toUpperCase() +
                      stud.fname.split(" ")[1].charAt(0).toUpperCase()}
                  </span>
                </span>
              </div>

              <div className="col-5 col-lg-4 text-start">
                <div className="fs-4 text-purple2 fw-medium">{stud.fname}</div>
                <div className="fs-4 text-primary">{stud.studid}</div>
              </div>

              <div className="col-4 col-lg-4 text-start ms-lg-3 text-dark">
                Click on Add button to add to list
              </div>

              <div className="col-4 col-lg-3 text-center text-lg-start ms-lg-n3">
                <button
                  className="btn btn-primary rounded-pill px-4"
                  onClick={() => {
                    setStudid(stud.studid);
                    setFname(stud.fname);
                    Add(stud.studid);
                  }}
                  disabled={stud.studid === studid ? true : false}
                >
                  {stud.studid === studid ? (
                    <div className="col-lg-12 text-center">
                      <div
                        class="spinner-border text-white"
                        style={{ width: "1rem", height: "1rem" }}
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>Add</>
                  )}
                </button>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [studid, setStudid] = useState(null);

  const [fname, setFname] = useState(null);

  const Remove = (studid) => {
    const task = {
      teachid,
      studid,
      token,
    };

    axios({
      method: "post",
      url: "/v2/report/remove/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Removed successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 2000,
          });

          FetchReport();
          FetchStudents();
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [block, setBlock] = useState(false);

  const [exist, setExist] = useState(false);

  const [phone, setPhone] = useState(null);

  const [teach, setTeach] = useState(null);

  const Add = (studid) => {
    const task = {
      teachid,
      studid,
      token,
    };

    axios({
      method: "post",
      url: "/v2/report/add/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Student was successfully Added!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 2000,
          });

          FetchReport();
          FetchStudents();

          setTimeout(function () {
            setStudid(null);
          }, 2000);
        } else if (response.data.message === "exist") {
          setExist(true);
          setPhone(response.data.phone);
          setTeach(response.data.teach);

          setTimeout(function () {
            setStudid(null);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStudid(null);
          }, 2000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [student, setStudent] = useState([]);

  const FetchStudent = (studid) => {
    axios({
      method: "post",
      url: "./v2/report/fetch_student/",
      data: {
        token,
        teachid,
        studid,
        subject: report.subject,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudent(view);

        setLoader(true);

        if (term === "First Term") {
          setTest1(view.test1);
          setTest2(view.test2);
          setExam1(view.score1);
        } else if (term === "Second Term") {
          setTest1(view.test3);
          setTest2(view.test4);
          setExam1(view.score2);
        } else if (term === "Third Term") {

          firstsetTest1(view.test1);
          firstsetTest2(view.test2);
          firstsetExam1(view.score1);

          secondsetTest1(view.test3);
          secondsetTest2(view.test4);
          secondsetExam1(view.score2);

          setTest1(view.test5);
          setTest2(view.test6);
          setExam1(view.score3);

        }

        setTimeout(function () {
          setLoader(false);
        }, 1000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [status, setStat] = useState(false);

  const [test1, setTest1] = useState(null);

  const [test2, setTest2] = useState(null);

  const [cbt_test, setCBT] = useState(0);

  const [score1, setExam1] = useState(0);

  const [firsttest1, firstsetTest1] = useState(null);

  const [firsttest2, firstsetTest2] = useState(null);

  const [firstscore1, firstsetExam1] = useState(0);

  const [secondtest1, secondsetTest1] = useState(null);

  const [secondtest2, secondsetTest2] = useState(null);

  const [secondscore1, secondsetExam1] = useState(0);

  const Submit = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      test1,
      test2,
      score1,
      firsttest1,
      firsttest2,
      firstscore1,
      secondtest1,
      secondtest2,
      secondscore1,
      cbt_test,
      studid,
      token,
      subject: report.subject,
    };

    axios({
      method: "post",
      url: "/v2/report/upload/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Result submittrd successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 2000,
          });

          setTimeout(function () {
            setStat(false);
          }, 2000);
          FetchReport();
          FetchStudents();
          FetchStudent(null);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const Reset = () => {
    setStat(true);

    const task = {
      teachid,
      token,
    };

    axios({
      method: "post",
      url: "/v2/report/reset/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setTimeout(function () {
            setStat(false);
          }, 2000);

          FetchStudent(null);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const renderUpload = () => {
    if (student == "") {
      return <></>;
    } else if (student != "") {
      return (
        <>
          {student.fname ? (
            <>
              <div className="fs-10 text-purple3 px-lg-7">{student.fname}</div>

              <form className="row px-lg-8 px-2" onSubmit={Submit}>
                <div className="col-12 mt-3 mb-4 mt-lg-2 mb-lg-4">
                  <div className="fs-4 text-purple2">
                    <span className="fs-3 text-primary">{term} </span> - Type in
                    the score accordingly.
                  </div>
                </div>

                {term == 'Third Term' ?
                  <>

                    <div className="col-12 text-purple2 fs-3 mb-2">
                      First Term Result Below
                    </div>

                    <div className="col-lg-6 mb-5">
                      <div class="">
                        <div className="fs-4 text-dark mb-2">Test Score</div>
                        <input
                          type="text"
                          autoComplete={false}
                          class="form-control form-control-lg text-dark border-dark rounded-3"
                          placeholder="Test Score 1"
                          required
                          value={firsttest1}
                          onChange={(e) => {
                            firstsetTest1(e.target.value);
                            setCBT(student.cbt_test);
                          }}
                        />
                      </div>
                    </div>

                    {test_type === 2 ? (
                      <div className="col-lg-6 mb-5">
                        <div class="">
                        <div className="fs-4 text-dark mb-2">Test Score 2</div>
                          <input
                            type="text"
                            autoComplete={false}
                            class="form-control form-control-lg text-dark border-dark rounded-3"
                            placeholder="Test Score 2"
                            value={firsttest2}
                            onChange={(e) => firstsetTest2(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="col-lg-6 mb-5">
                      <div class="">
                        <div className="fs-4 text-dark mb-2">Exam Score</div>
                        <input
                          type="text"
                          autoComplete={false}
                          class="form-control form-control-lg text-dark border-dark rounded-3"
                          placeholder="Exam Score"
                          value={firstscore1}
                          onChange={(e) => firstsetExam1(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 text-purple2 fs-3 mb-2">
                      Second Term Result Below
                    </div>

                    <div className="col-lg-6 mb-5">
                      <div class="">
                        <div className="fs-4 text-dark mb-2">Test Score</div>
                        <input
                          type="text"
                          autoComplete={false}
                          class="form-control form-control-lg text-dark border-dark rounded-3"
                          placeholder="Test Score 1"
                          required
                          value={secondtest1}
                          onChange={(e) => {
                            secondsetTest1(e.target.value);
                            setCBT(student.cbt_test);
                          }}
                        />
                      </div>
                    </div>

                    {test_type === 2 ? (
                      <div className="col-lg-6 mb-5">
                        <div class="">
                        <div className="fs-4 text-dark mb-2">Test Score 2</div>
                          <input
                            type="text"
                            autoComplete={false}
                            class="form-control form-control-lg text-dark border-dark rounded-3"
                            placeholder="Test Score 2"
                            value={secondtest2}
                            onChange={(e) => secondsetTest2(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="col-lg-6 mb-5">
                      <div class="">
                        <div className="fs-4 text-dark mb-2">Exam Score</div>
                        <input
                          type="text"
                          autoComplete={false}
                          class="form-control form-control-lg text-dark border-dark rounded-3"
                          placeholder="Exam Score"
                          value={secondscore1}
                          onChange={(e) => secondsetExam1(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-12 text-purple2 fs-3 mb-2">
                      Third Term Result Below
                    </div>

                  </>

                  :

                  <></>

                }


                <div className="col-lg-6 mb-5">
                  <div class="">
                    <div className="fs-4 text-dark mb-2">Test Score</div>
                    <input
                      type="text"
                      autoComplete={false}
                      class="form-control form-control-lg text-dark border-dark rounded-3"
                      placeholder="Test Score 1"
                      required
                      value={test1}
                      onChange={(e) => {
                        setTest1(e.target.value);
                        setCBT(student.cbt_test);
                      }}
                    />
                  </div>
                </div>

                {test_type === 2 ? (
                  <div className="col-lg-6 mb-5">
                    <div class="">
                    <div className="fs-4 text-dark mb-2">Test Score 2</div>
                      <input
                        type="text"
                        autoComplete={false}
                        class="form-control form-control-lg text-dark border-dark rounded-3"
                        placeholder="Test Score 2"
                        value={test2}
                        onChange={(e) => setTest2(e.target.value)}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="col-lg-6 mb-5">
                  <div class="">
                    <div className="fs-4 text-dark mb-2">Exam Score</div>
                    <input
                      type="text"
                      autoComplete={false}
                      class="form-control form-control-lg text-dark border-dark rounded-3"
                      placeholder="Exam Score"
                      value={score1}
                      onChange={(e) => setExam1(e.target.value)}
                    />
                  </div>
                </div>

                <div
                  className={
                    isMobile ? "col-12 fixed-bottom mb-3" : "col-12 mt-4 mb-4"
                  }
                >
                  <div className="d-grid px-2 px-lg-1">
                    <button
                      className="btn btn-success rounded-pill fs-3"
                      onClick={() => setStudid(student.studid)}
                    >
                      {status ? (
                        <>
                          <span
                            class="spinner-border spinner-border-md mt-1 text-white"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        <>
                          Save Result <i className="bi bi-arrow-right"></i>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <>
              <>
                <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-4 text-center">
                  <img
                    src="../../assets/images/background/success.png"
                    alt=""
                    class="img-fluid"
                    width={isMobile ? "100%" : "50%"}
                  />
                </div>

                <div className="text-center px-lg-8 px-2 pt-2 mb-6 mb-lg-4">
                  <div className="fs-3 text-purple3 fw-medium mb-2">
                    You have reached the end of the list. Click button below to
                    begin another parameter upload.
                  </div>
                </div>

                <div
                  className={
                    isMobile ? "col-12 fixed-bottom mb-4" : "col-12 mt-4 mb-6"
                  }
                >
                  <div className="d-grid px-4 px-lg-8">
                    <button
                      className="btn btn-warning rounded-pill text-dark fs-3"
                      onClick={() => Reset()}
                    >
                      {status ? (
                        <>
                          <span
                            class="spinner-border spinner-border-md mt-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        <>Get Started</>
                      )}
                    </button>
                  </div>
                </div>
              </>
            </>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  const addItem = (inputValue) => { };

  const deleteItem = (inputValue) => { };

  return (
    <div className="" onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      {exist ? (
        <Exist fname={fname} setExist={setExist} phone={phone} teach={teach} />
      ) : (
        <>
          {isMobile ? (
            <div class="docs-header">
              <nav class="navbar navbar-expand-lg navbar-default bg-success fixed-top ms-0 py-3 py-lg-3 shadow-none">
                <Link
                  to={"../" + id + "/report/results"}
                  class="fs-t text-white"
                >
                  <i className="bi bi-arrow-left fs-10"></i> Back
                </Link>

                <div class="navbar-nav flex-row ms-4">
                  <Tippy
                    content={admin_name}
                    interactive={true}
                    interactiveBorder={400}
                    delay={100}
                  >
                    <span class="avatar avatar-lg avatar-dark">
                      <span class="avatar-initials rounded-circle text-white fs-3 fw-medium">
                        {fullname}
                      </span>
                    </span>
                  </Tippy>
                </div>
              </nav>
            </div>
          ) : (
            <Header />
          )}
          <Sidebar />
          {isMobile ? (
            <div class="px-lg-10">
              <div className="col-12">
                <div className="card bg-white shadow-none py-20 mt-n12">
                  <div className="card-header py-0 border-0 px-4">
                    <div className="d-flex align-items-center">
                      <div className="col">
                        <div className="fs-t text-purple3">Result Upload</div>
                      </div>

                      <div className="col-end text-center">
                        <div className="fs-5 text-purple2">Students no.</div>

                        <div className="fs-10 text-purple3 fw-bold">
                          {report.stud_num}
                        </div>
                      </div>
                    </div>

                    <div className="text-start mt-6">
                      <div className="fs-t text-purple3 mb-2">
                        {report.class} - {report.subject} Result
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-8 mb-2">
                        <form class="d-flex align-items-center">
                          <span class="position-absolute ps-3 search-icon">
                            <i class="fe fe-search"></i>
                          </span>
                          <input
                            type="search"
                            class="form-control form-control-lg py-3 ps-6 fs-4 bg-back rounded-pill text-dark"
                            placeholder="Search New Student and Add"
                            onChange={(e) => SearchStudents(e.target.value)}
                          />
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="card-body mb-0 px-5">
                    {loader ? (
                      <div className="col-lg-12 text-center py-lg-10 py-20">
                        <div
                          class="spinner-border text-purple3"
                          style={{ width: "4rem", height: "4rem" }}
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {add_student == "" ? (
                          <></>
                        ) : (
                          <>
                            <div className="fs-3 text-purple3 mb-4 fw-medium">
                              Search Results
                            </div>
                            <div className="mb-6">{renderAdd()}</div>
                          </>
                        )}

                        <div className="fs-3 text-purple3 mb-4 fw-medium">
                          List of student added
                        </div>
                        <div class="hoverable">{renderFolders()}</div>
                      </>
                    )}
                  </div>

                  <div
                    className="card fixed-bottom py-2 bg-back shadow-lg"
                    style={{
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                    }}
                  >
                    <div className="d-grid px-4 mt-2 mb-2">
                      <button
                        className="btn btn-dark-primary fs-3 rounded-pill"
                        data-bs-toggle="modal"
                        data-bs-target="#upload_Result"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        onClick={() => FetchStudent(null)}
                      >
                        Upload Scores
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="docs-wrapper bg-back">
              <div class="container-fluid">
                <div className="pt-14">
                  <div className="px-lg-8">
                    <div className="d-flex align-items-center mb-4">
                      <div className="col">
                        <div className="fs-t text-purple3">
                          Result Upload |{" "}
                          <span className="text-dark fs-5">
                            <i className="bi bi-ui-checks"></i> -- Result
                          </span>
                        </div>
                      </div>

                      <div className="col-end text-center">
                        <div className="fs-5 text-purple2">Students no.</div>

                        <div className="fs-10 text-purple3 fw-bold">
                          {report.stud_num}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-12">
                      <div
                        className="card bg-white shadow-none py-7"
                        style={{ borderRadius: "16px" }}
                      >
                        <div className="card-header px-lg-6 py-0 border-0">
                          <div className="text-start">
                            <div className="fs-3 text-purple3 mb-2">
                              <i class="bi bi-cloud-upload"></i> {report.class}{" "}
                              - {report.subject} Result
                            </div>
                          </div>

                          <div className="row mt-4 px-lg-0">
                            <div className="col-lg-8 mb-4">
                              <form class="d-flex align-items-center">
                                <span class="position-absolute ps-3 search-icon">
                                  <i class="fe fe-search"></i>
                                </span>
                                <input
                                  type="search"
                                  class="form-control form-control-lg py-3 ps-6 fs-4 bg-back rounded-pill text-dark"
                                  placeholder="Search New Student and Add"
                                  onChange={(e) =>
                                    SearchStudents(e.target.value)
                                  }
                                />
                              </form>
                            </div>
                          </div>
                        </div>

                        <div className="card-body px-lg-7 py-0">
                          <div className="col-lg-12 mb-3">
                            {loader ? (
                              <div className="col-lg-12 text-center py-lg-10 py-20">
                                <div
                                  class="spinner-border text-purple3"
                                  style={{ width: "4rem", height: "4rem" }}
                                  role="status"
                                >
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                {add_student == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <div className="fs-3 text-purple3 mb-4 fw-medium">
                                      Search Results
                                    </div>
                                    <div className="mb-6">{renderAdd()}</div>
                                  </>
                                )}

                                <div className="fs-3 text-purple3 mb-4 mt-2 fw-medium">
                                  List of student added
                                </div>
                                <div class="">{renderFolders()}</div>
                              </>
                            )}
                          </div>

                          <div className="d-grid px-lg-10 mt-6">
                            <button
                              className="btn btn-dark-primary fs-4 rounded-pill"
                              data-bs-toggle="modal"
                              data-bs-target="#upload_Result"
                              data-bs-backdrop="static"
                              data-bs-keyboard="false"
                              onClick={() => FetchStudent(null)}
                            >
                              Upload Scores
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <div
        class="modal"
        id="delete_menu"
        tabindex="-1"
        role="dialog"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class={
            isMobile
              ? "modal-dialog modal-dialog-centered modal-fullscreen"
              : "modal-dialog modal-md modal-dialog-centered"
          }
          role="document"
        >
          <div
            class="modal-content"
            style={
              isMobile
                ? { border: "#fff" }
                : { borderRadius: "35px", border: "#fff" }
            }
          >
            <div class="modal-header border-0 py-4">
              <div className="fs-t text-purple2">
                <i class="bi bi-trash"></i> Remove Student{" "}
              </div>

              <button
                class="btn btn-warning btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
              </button>
            </div>
            <div class="modal-body py-2">
              <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-4 text-center">
                <img
                  src="../../assets/images/background/remove.png"
                  alt=""
                  class="img-fluid"
                />
              </div>

              <div className="text-center px-lg-8 px-2 pt-2 mb-6 mb-lg-4">
                <div className="fs-t text-purple3 fw-medium mb-2">
                  Do you want to remove {fname} result from list?
                </div>
              </div>

              <div className="row text-center mb-lg-8">
                <div className="col-6">
                  <button
                    className="btn btn-dark rounded-pill px-6"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>

                <div className="col-6">
                  <button
                    className="btn btn-danger rounded-pill px-6"
                    data-bs-dismiss="modal"
                    onClick={() => Remove(studid)}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal"
        id="upload_Result"
        tabindex="-1"
        role="dialog"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class={
            isMobile
              ? "modal-dialog modal-dialog-centered modal-fullscreen"
              : "modal-dialog modal-lg modal-dialog-centered"
          }
          role="document"
        >
          <div
            class={isMobile ? "modal-content" : "modal-content curve6"}
            style={
              isMobile
                ? { border: "#fff" }
                : { borderRadius: "35px", border: "#fff" }
            }
          >
            <div class="modal-header border-0 py-4">
              <div className="fs-t text-purple2"></div>

              <button
                class="btn btn-dark btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
              </button>
            </div>
            <div class="modal-body py-0">
              <div className="mb-4">
                {loader ? (
                  <div className="col-lg-12 text-center py-lg-10 py-20">
                    <div
                      class="spinner-border text-purple3"
                      style={{ width: "4rem", height: "4rem" }}
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  renderUpload()
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload_Report;
