import React, { useState, useEffect } from "react";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { Player } from "@lottiefiles/react-lottie-player";

import Footer from "./Footer";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Chart from "react-apexcharts";

const Result_Quiz = () => {
  const [loaders, setLoaders] = useState(true);

  const [loader, setLoader] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  axios.defaults.baseURL = id;

  const [progress, setProgress] = useState(20);

  const [status, setStat] = useState(false);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers =
      "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
  }

  const username = localStorage.getItem("studname");

  const studid = localStorage.getItem("studid");

  const studclass = localStorage.getItem("studclass");

  const plan = localStorage.getItem("plan");

  if (!studid) {
    navigate("../" + id + "/quiz/");
  }

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  useEffect(() => {
    FetchCBTResult();

    setLoader(true);
  }, []);

  var height = parseInt("100");
  var labelColor = "#2d3436";
  var borderColor = "#00b894";
  var baseColor = "#706fd3";
  var lightColor = "#706fd3";

  const subject = [];

  const score = [];

  const [options, setOption] = useState({});

  const [series, setSeries] = useState([]);

  const [mark, setMark] = useState([]);

  const [marks, setMarks] = useState([]);

  const FetchCBTResult = () => {
    axios({
      method: "post",
      url: "./v2/cbt/student_mark/",
      data: {
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setMark(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });

    axios({
      method: "post",
      url: "./v2/cbt/student_result/",
      data: {
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setMarks(response.data);

        setTimeout(function () {
          {
            setLoader(false);
          }
        }, 2000);

        response.data.map((item) => {
          subject.push(item.subject);
          score.push(item.score);
        });

        setOption({
          chart: {
            fontFamily: "inherit",
            type: "area",
            height: height,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {},
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            type: "solid",
            opacity: 4,
          },
          stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            colors: [baseColor],
          },
          xaxis: {
            categories: subject,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              style: {
                colors: "#000",
                fontSize: "12px",
              },
            },
            crosshairs: {
              position: "front",
              stroke: {
                color: baseColor,
                width: 1,
                dashArray: 3,
              },
            },
            tooltip: {
              enabled: true,
              formatter: undefined,
              offsetY: 0,
              style: {
                fontSize: "12px",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: labelColor,
                fontSize: "12px",
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            hover: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: "none",
                value: 0,
              },
            },
          },
          tooltip: {
            style: {
              fontSize: "12px",
            },
            y: {
              formatter: function (val) {
                return "" + val + " Marks";
              },
            },
          },
          colors: [lightColor],
          grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
          markers: {
            strokeColor: baseColor,
            strokeWidth: 3,
          },
        });

        setSeries([
          {
            name: "Marks",
            data: score,
          },
        ]);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const config = {
    series: [mark.fail, mark.pass],
    options: {
      labels: ["Failed", "Passed"],
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        colors: ["#fed330", "#706fd3"],
      },
      dataLabels: {
        enabled: false,
      },
      cutout: "10%",
      cutoutPercentage: 10,
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
      plugins: {
        legend: {
          display: false,
          show: true,
        },
      },
      responsive: [
        {
          series: [mark.fail, mark.pass],
          breakpoint: 400,
          options: {
            labels: ["Failed", "Passed"],
            chart: {
              width: 130,
              height: 130,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "." : str;
  };

  const renderFolders = () => {
    if (marks == "") {
      return <></>;
    } else if (marks) {
      return marks.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <tr>
            <th scope="row">{index + 1}</th>
            <td className="text-dark">{task.subject}</td>
            <td className="text-success">{task.score}</td>
            <td className="text-danger">{10 - task.score}</td>
          </tr>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div
        id="docs-main-wrapper"
        className={isMobile ? `` : ``}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#706fd3"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <StyleRoot>
            <div style={styles.bounce}>
              <div class="docs-header">
                <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-4 shadow-none">
                  <a
                    class="fs-10 text-purple2 font-display2 cursor-pointer"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#edit_room"
                    aria-controls="offcanvasLeft"
                  >
                    <span class="avatar avatar-md avatar-primary-soft">
                      <img
                        alt="avatar"
                        src={"../assets/images/school/" + id + ".png"}
                        class="rounded-circle"
                      />
                    </span>
                    <span className="fs-t text-dark ms-2">
                      CBT Portal Result
                    </span>
                  </a>
                  <ul class="navbar-nav ms-auto flex-row">
                    <li class="nav-item docs-header-btn d-none d-lg-block">
                      <Link
                        to={"../" + id + "/quiz/logout"}
                        className="btn btn-danger btn-md fw-medium font-display"
                        style={{ borderRadius: "24px" }}
                      >
                        <span className="bi bi-power"></span> Logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="docs-nav-sidebar border-0">
                <div class="py-5"></div>
                <div class=" docs-nav " data-simplebar>
                  <nav class="navigation navbar navbar-expand-sm">
                    <div class="collapse navbar-collapse " id="navbarNav">
                      <ul
                        class="navbar-nav flex-column text-start"
                        id="sidebarnav"
                      >
                        <li class="navbar-header mt-6">
                          <h5 class="heading"></h5>
                        </li>

                        {plan === "Activated" ? (
                          <>
                            <li class="nav-item cursor-pointer mb-4">
                              <Link
                                to={"../" + id + "/quiz/home"}
                                class="nav-link fs-4 text-dark"
                              >
                                <i class="bi bi-house fs-3"></i> Dashboard
                              </Link>
                            </li>

                            <li class="nav-item cursor-pointer">
                              <Link
                                to={"../" + id + "/quiz/result"}
                                class="nav-link fs-4 text-dark"
                              >
                                <i class="bi bi-ui-checks fs-3"></i> Result
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
                <Footer />
              </div>

              <div class="docs-wrapper bg-back">
                <div class="container-fluid px-3 px-lg-12">
                  {plan === "Activated" ? (
                    <>
                      <div className="row g-4 py-6 py-lg-15">
                        <div className="col-lg-6">
                          <div className="card bg-white rounded-3">
                            <div className="card-body">
                              <div className="fs-3 text-purple font-display">
                                Performance Statistics
                              </div>

                              <div className="mt-2">
                                <Chart
                                  options={options}
                                  series={series}
                                  type="line"
                                  height={270}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="card bg-white rounded-3">
                            <div className="card-body">
                              <div className="fs-3 text-purple font-display">
                                CBT Score Grades
                              </div>

                              <div className="mt-8">
                                <div className="align-items-center">
                                  <div class="hstack gap-3">
                                    <div className="text-center text-dark">
                                      <Chart
                                        options={config.options}
                                        series={config.series}
                                        type="donut"
                                        height={250}
                                      />
                                    </div>

                                    <div class="vr ms-auto"></div>

                                    <div className="ms-4 ms-lg-auto text-start">
                                      <div className="text-dark">
                                        <div className="fs-3 fw-medium">
                                          Failed Percentage
                                        </div>

                                        <div className="display-5 text-warning fw-medium">
                                          {mark.fail}%
                                        </div>
                                      </div>

                                      <div className="text-dark">
                                        <div className="fs-3 fw-medium">
                                          Passed Percentage
                                        </div>

                                        <div className="display-5 text-primary fw-medium">
                                          {mark.pass}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mt-lg-8">
                          <div className="fs-3 text-dark">All Tests/Exams</div>
                        </div>

                        <div className="col-lg-12">
                          <div class="table-responsive">
                            <SimpleBar
                              style={{ maxHeight: 350, padding: "4px" }}
                              forceVisible="y"
                              autoHide={true}
                            >
                              <table class="table fs-4 text-purple3 py-4">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Pass Mark</th>
                                    <th scope="col">Fail Mark</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {loader ? (
                                    <th colSpan={"6"}>
                                      <div className="col-lg-12 text-center py-lg-10 pt-12">
                                        <div
                                          class="spinner-border text-primary"
                                          style={{
                                            width: "4rem",
                                            height: "4rem",
                                          }}
                                          role="status"
                                        >
                                          <span class="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </div>
                                    </th>
                                  ) : (
                                    <>{renderFolders()}</>
                                  )}
                                </tbody>
                              </table>
                            </SimpleBar>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pt-8 pt-lg-16">
                        <Player
                          autoplay={true}
                          loop={true}
                          controls={false}
                          src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                          style={{ height: "250px", width: "250px" }}
                        ></Player>

                        <div className="text-center mt-5">
                          <div className="text-purple2 fs-t fw-bold">
                            Access to this service is restricted.
                          </div>

                          <div className="text-gray-500 fs-4 mt-2">
                            For more details contact Smart Edu Team.
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </StyleRoot>

          <div
            class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
          ></div>

          <ToastContainer />

          <div
            class="btn btn-primary btn-md fs-t text-white shadow-lg rounded-br-lg2 rounded-bl-sm2 
                    rounded-t-lg2 float mb-lg-0 d-block d-lg-none"
            data-bs-toggle="modal"
            data-bs-target="#MobileMenu"
          >
            <i class="bi bi-columns-gap"></i>
          </div>

          <div
            class="modal fade"
            id="MobileMenu"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class={
                isMobile
                  ? "modal-dialog modal-dialog-centered modal-fullscreen"
                  : "modal-dialog modal-md modal-dialog-centered"
              }
              role="document"
            >
              <div
                class="modal-content"
                style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}
              >
                <div class="modal-header border-0">
                  <h3
                    class="cursor-pointer text-dark"
                    id="exampleModalCenterTitle"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg"></i>
                  </h3>
                  <button
                    class="btn btn-pink btn-md shadow-lg text-white font-display fw-medium"
                    style={{
                      borderRadius: "30px",
                    }}
                    type="button"
                  >
                    <span className="bi bi-power"></span> Logout{" "}
                  </button>
                </div>
                <div class="modal-body py-0">
                  <div className="mt-0 mt-lg-0 text-center">
                    <i class="bi bi-columns-gap fs-1 text-success"></i>

                    <div className="fs-10 font-display2 text-dark mt-2">
                      Dashboard Menu
                    </div>

                    <div className="row px-lg-6 pt-4 g-4 pb-10">
                      <div className="col-6 col-md-6 col-lg-6">
                        <div
                          className="card py-4 px-1 shadow-none cursor-pointer bg-light"
                          data-bs-dismiss="modal"
                          style={{ borderRadius: "25px" }}
                        >
                          <Link to={"../" + id + "/quiz/home"}>
                            <div className="text-dark fs-t text-center">
                              <div class="bi bi-house"></div>
                              <div className="fs-3">Dashboard</div>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="col-6 col-md-6 col-lg-6">
                        <div
                          className="card py-4 px-1 shadow-none cursor-pointer bg-dark-info"
                          data-bs-dismiss="modal"
                          style={{ borderRadius: "25px" }}
                        >
                          <Link to={"../" + id + "/quiz/result"}>
                            <div className="text-white fs-t text-center">
                              <div class="bi bi-ui-checks"></div>
                              <div className="fs-3">Result</div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer border-0">
                  <p class="mb-0 text-dark fs-4">
                    {" "}
                    Powered by{" "}
                    <a
                      href="https://smartedung.com"
                      target="_blank"
                      className="fw-bold text-success font-display"
                    >
                      <img
                        src="../assets/smlogo.png"
                        width={isMobile ? "100" : "80"}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Result_Quiz;
