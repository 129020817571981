import React, { useState, useEffect } from "react";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useNavigate, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import AudioPlayer from 'react-h5-audio-player';

import 'react-h5-audio-player/lib/styles.css';

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

import { Player } from "@lottiefiles/react-lottie-player";

import Exercise from "./Exercise";

import QRCode from "react-qr-code";

const View = () => {
    const [loaders, setLoaders] = useState(true);

    const { id } = useParams();

    const { token } = useParams();

    const { course } = useParams();

    const { quill, quillRef } = useQuill();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const plan = getusers.plan;

    const teachid = getusers.teachid;

    const [progress, setProgress] = useState(20);

    const [status, setStat] = useState(false);

    const [fullscreen, SetFullScreen] = useState(false);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
    }

    useEffect(() => {
        localStorage.setItem("id", teachid);
    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + ".." : str;
    };

    const truncate_ = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + ".......<span class='cursor-pointer text-primary' data-bs-toggle='modal' data-bs-target='#ViewNote'><u>View More</u></span>" : str;
    };


    const [color, SetColor] = useState(false);

    const [subjects, setSubjects] = useState([{}]);

    useEffect(() => {
        axios({
            method: "post",
            url: "./v2/subject/list/",
            data: {
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSubjects(view);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    }, [teachid]);

    const renderSubject = () => {
        if (subjects == "") {
            return <></>;
        } else if (subjects) {
            return subjects.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <option value={task.subject}>{task.subject}</option>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [media_task, setMedia] = useState([]);

    //const [home_task, setHome] = useState([]);

    const FetchMedia = () => {
        axios({
            method: "post",
            url: "./v2/media/fetchs/",
            data: {
                token,
                teachid,
                course
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setMedia(view);

                setNewCoursename('Course ' + view.count)
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const [timer, setTimer] = useState(null);

    const FetchNote = (course) => {

        axios({
            method: "post",
            url: "./v2/media/note/",
            data: {
                token,
                teachid,
                course
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setTimer(response.data.timer)

                if (quill) {
                    quill.clipboard.dangerouslyPasteHTML(response.data.passage);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });


    };

    useEffect(() => {
        FetchMedia();
        setTimeout(function () {
            setLoader(false);
            setLoader_(false);
            setLoader_1(false)
        }, 2000);

    }, [token, teachid, course]);

    const [loader, setLoader] = useState(true);

    const [loader_, setLoader_] = useState(true);

    const [loader_1, setLoader_1] = useState(true);

    const [roomname, setRoomName] = useState(null);

    const [studclass, setStudclass] = useState(null);

    const [subject, setSubject] = useState(null);

    const [password, setPassword] = useState(null);

    const HandleEdit = (e) => {
        e.preventDefault();

        const task = {
            roomname,
            studclass,
            subject,
            password,
            teachid,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + roomname + " Media Folder has been modified!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia();

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const [coursename, setCourseName] = useState('');

    const HandleCourseName = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            coursename,
            teachid,
            course,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + coursename + " has been updated!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia();



                    setTimeout(function () {
                        setStat(false);
                    }, 2000);

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const [video, setVideo] = useState(null);

    const HandleVideoLink = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            video,
            teachid,
            course,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + course + " video has been updated!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia();

                    setLoader(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader(false);
                    }, 2000);

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const [audio, setAudio] = useState(null);

    const handleFileSelect = (event) => {
        setAudio(event.target.files[0])

        //console.log(selectedFile)
    }

    const HandleAudioFile = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            file: audio,
            teachid,
            course,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/audio/",
            data: task,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + course + " audio has been updated!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia();

                    setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);

                    localStorage.setItem("token", response.data.token);

                } else if (response.data.message === "exist") {
                    toast.error("Failed: Audio already exist in folder!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);
                }

                else if (response.data.message === "size") {
                    toast.error("Failed: Audio size is too large!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);
                }

                else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const [passage, setPassage] = useState(null);

    useEffect(() => {
        if (quill) {
            //quill.clipboard.dangerouslyPasteHTML("<h1>React Hook for Quill!</h1>");

            quill.on("text-change", (delta, oldDelta, source) => {
                //console.log("Text change!");
                //console.log(quill.root.innerHTML); // Get innerHTML using quill
                //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                setPassage(quillRef.current.firstChild.innerHTML);
            });
        }
    }, [quill]);

    const HandlePassage = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            passage,
            teachid,
            course,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + course + " note has been updated!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia();

                    setLoader_1(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_1(false);
                    }, 2000);

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    if (timer > 10) {
        setTimer("10");
        toast.error("Timer can't be more than 10 minutes", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 4000,
        });
    } else {
    }

    const HandleTimer = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            timer,
            teachid,
            course,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + course + " timer has been updated!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia();

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }


    const renderCourse = () => {
        if (media_task == "") {
            return (
                <>

                </>
            );
        } else if (media_task) {
          
                return (

                    <>
                        <div class="row g-4 px-2 pt-lg-0">

                            <div class="col-lg-6 col-md-12 col-12">

                                {loader ? (
                                    <div className="text-center pt-lg-16 pt-8">
                                        <div
                                            class="spinner-border text-danger"
                                            style={{ width: "4rem", height: "4rem" }}
                                            role="status"
                                        >
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="mt-4 fs-4 text-dark">Loading...</div>
                                    </div>
                                ) : (
                                    <div className="card border shadow-none" style={{ borderRadius: "15px" }}>
                                        <div className="card-body">
                                            <div className="fs-3 font-display2 mb-3 text-dark">
                                                <i class="bi bi-asterisk"></i> Video Lecture
                                            </div>
                                            <div class="rounded-3 position-relative w-100 d-block overflow-hidden p-0 ms-auto"
                                                style={{ height: "300px" }}>
                                                <iframe class="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100"
                                                    src={media_task.video}></iframe>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div className="col-lg-6 col-md-12 col-12">

                                {loader_ ? (
                                    <div className="text-center pt-lg-16 pt-8">
                                        <div
                                            class="spinner-border text-danger"
                                            style={{ width: "4rem", height: "4rem" }}
                                            role="status"
                                        >
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                        <div className="mt-4 fs-4 text-dark">Loading...</div>
                                    </div>
                                ) : (
                                    <div className={color ? "card bg-dark-success shadow-none" :
                                        "card bg-dark-primary shadow-none"} style={{ borderRadius: "15px" }}>
                                        <div className="card-body">
                                            <div className="fs-3 font-display2 mb-3 text-white">
                                                <i class="bi bi-asterisk"></i> Audio Lecture
                                            </div>
                                            <AudioPlayer
                                                autoPlay={false}
                                                src={"/uploads/" + media_task.audio}
                                                onPlay={e => console.log("onPlay")}
                                                theme="light"
                                                onListen={() => SetColor(true)}
                                                onPause={() => SetColor(false)}
                                                onEnded={() => SetColor(false)}
                                            // other props here
                                            />

                                            <p className="text-white fs-4 mt-4">
                                                Your audio plays here! Make use of the controls to preview your audio
                                                lecture. For further information conatct: <br /> <span className="text-light fw-bold">
                                                    Smart Edu Customer Service</span>
                                            </p>

                                            <p className="text-white fs-4 mt-4">
                                                Click the button below to change audio file
                                            </p>

                                            <button className="btn btn-white"
                                                data-bs-toggle="modal"
                                                data-bs-target="#HandleAudio"
                                                style={{ borderRadius: "20px" }}>
                                                Change Audio
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="py-lg-8 py-4 mb-10">
                                    <div className="fs-3 font-display2 mb-3 ms-lg-3 ms-2 text-dark text-start">
                                        <i class="bi bi-asterisk"></i> Lecture Note
                                    </div>

                                    {loader_1 ? (
                                        <div className="text-center pt-lg-16 pt-8 pb-lg-10 pb-8">
                                            <div
                                                class="spinner-border text-danger"
                                                style={{ width: "4rem", height: "4rem" }}
                                                role="status"
                                            >
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <div className="mt-4 fs-4 text-dark">Loading...</div>
                                        </div>
                                    ) : (
                                        <>
                                            {media_task.passage ?
                                                <div className="">
                                                    <div className="card py-5 border bg-white">
                                                        <div className="px-3 px-lg-6 font-display2">
                                                            <div className="font-display2"
                                                                dangerouslySetInnerHTML={{ __html: truncate_(media_task.passage, 300) }}
                                                            ></div>
                                                        </div>
                                                    </div>

                                                    <div className="text-end px-lg-1 mt-5">
                                                        <span className="fs-4 text-dark me-3">
                                                            To edit note, click on the button below.
                                                        </span>
                                                        <div className="btn btn-light btn-md fs-4 font-display2 text-dark fw-medium mt-2 mt-lg-0"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#HandleNote"
                                                            onClick={() => FetchNote(media_task.course)}
                                                            style={{
                                                                borderRadius: "30px",
                                                                background: "transparent",
                                                                borderColor: "#000",
                                                                color: "#000",
                                                            }}>
                                                            <i class="bi bi-card-text"></i> Edit Note
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="text-center">
                                                    <div className="text-center">
                                                        <img
                                                            src="../assets/images/background/admin.png"
                                                            class=""
                                                            width={isMobile ? "45%" : "25%"}
                                                        />
                                                    </div>

                                                    <div className="fs-4 text-dark py-3">
                                                        To create note, click on the button below and make your note.
                                                        You now have Ms-Word like controls to work with.
                                                    </div>

                                                    <div className="btn btn-light btn-lg fs-3 font-display2 text-dark mt-2 fw-medium"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#HandleNote"
                                                        onClick={() => FetchNote(media_task.course)}
                                                        style={{
                                                            borderRadius: "30px",
                                                            background: "transparent",
                                                            borderColor: "#000",
                                                            color: "#000",
                                                        }}>
                                                        <i class="bi bi-card-text"></i> Create Note
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    )}
                                </div>


                            </div>

                            <Exercise course={media_task.course} num_ques={media_task.id} />

                        </div>
                    </>
                );
            
        } else {
            return <></>;
        }
    };

    const [new_coursename, setNewCoursename] = useState('');

    const HandleNew = (e) => {

        e.preventDefault();

        setStat(true);

        const task = {
            file: audio,
            video,
            teachid,
            course: new_coursename,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/new/",
            data: task,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + new_coursename + " was created successfully!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    // setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);



                    localStorage.setItem("token", response.data.token);

                } else if (response.data.message === "exist") {
                    toast.error("Failed: Audio already exist in folder!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setLoader_(false);

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                } else if (response.data.message === "size") {
                    toast.error("Failed: Audio size is too large!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);
                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }


    const url_link = "app.smartedung.com/#/" + token;

    return (
        <>
            <Auth />
            <div
                id="docs-main-wrapper"
                className={isMobile ? `` : ``}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#474787"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <>

                    {app_status === "InActive" ? (
                        <StyleRoot>
                            <div class="container-fluid px-4 px-lg-4" style={styles.bounce}>
                                <Activate />
                            </div>
                        </StyleRoot>
                    ) : (
                        <>
                            <StyleRoot>

                                <div style={styles.bounce}>
                                    <div class="docs-header">
                                        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-3 shadow-sm">
                                            <a class="fs-10 text-purple2 font-display2 cursor-pointer">
                                                Edit {course} </a>

                                        </nav>
                                    </div>

                                    <div class="docs-nav-sidebar">
                                        <div class="py-5"></div>
                                        <div class=" docs-nav " data-simplebar>
                                            <nav class="navigation navbar navbar-expand-md">
                                                <div class="collapse navbar-collapse " id="navbarNav">
                                                    <ul class="navbar-nav flex-column" id="sidebarnav">
                                                        <li class="navbar-header mt-4">
                                                            <h5 class="heading"></h5>
                                                        </li>

                                                        {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?
                                                            <>
                                                                <li class="nav-item cursor-pointer"><Link 
                                                                to={"../" + id + "/media/q/" + token} class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-arrow-left"></i> Go Back</Link></li>

                                                                <li class="nav-item cursor-pointer" data-bs-toggle="modal"
                                                                    data-bs-target="#HandleVideo"><div class="nav-link fs-4 text-dark 
                                                        py-4 fw-medium"><i class="bi bi-play-circle"></i> Edit Video</div></li>

                                                                <li class="nav-item cursor-pointer" data-bs-toggle="modal"
                                                                    data-bs-target="#HandleAudio"><div class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-mic"></i> Edit Audio</div></li>

                                                            </> : <></>}

                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="nav-footer">
                                            <p class="mb-0 text-dark"> Powered by <a href="https://smartedung.com" target="_blank"
                                                className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                        </div>
                                    </div>

                                    <div class="docs-wrapper bg-back" >

                                        <div class="container-fluid px-3 px-lg-4">

                                            {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?

                                                <div className="pt-6 pt-md-12 py-lg-12 py-10">
                                                    {renderCourse()}
                                                </div>

                                                :

                                                <>
                                                    <div className='pt-8 pt-lg-16'>

                                                        <Player
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={false}
                                                            src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                                                            style={{ height: "250px", width: "250px" }}
                                                        ></Player>

                                                        <div className="text-center mt-5">
                                                            <div className="text-purple2 fs-t fw-bold">
                                                                Access to this service is restricted.
                                                            </div>

                                                            <div className="text-gray-500 fs-4 mt-2">
                                                                For more details contact Smart Edu Team.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            }

                                        </div>

                                    </div>

                                </div>
                            </StyleRoot>
                        </>
                    )}

                    <div
                        class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
                    >
                    </div>

                    {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?

                        <>

                            <div class="modal fade" id="HandleTimer" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content" style={{ borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mt-0 text-center">
                                                <i class="bi bi-stopwatch fs-1 text-primary"></i>

                                                <div className="fs-10 font-display2 text-dark mt-2">
                                                    Setup Exercise Timer
                                                </div>

                                                <form className="mt-lg-0" onSubmit={HandleTimer}>
                                                    <div class="mb-3 mt-3 px-lg-8 px-4">
                                                        <input type="text" id="textInput" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            value={timer}
                                                            required
                                                            onChange={(e) => setTimer(e.target.value)}
                                                            placeholder="Input Exercise Timer" />
                                                        <p className="mt-2">
                                                            N.B: Timer is in minutes.
                                                        </p>
                                                    </div>

                                                    <div class="mb-3 mt-5">
                                                        <button className="btn btn-purple3 text-purple2"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#HandleSetup"
                                                            style={{ borderRadius: "30px" }}>
                                                            {status ? (
                                                                <>
                                                                    Executing...
                                                                    <span
                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Confirm Timer
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="HandleVideo" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content" style={{ borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mt-0 text-center">
                                                <i class="bi bi-play-circle fs-1 text-danger"></i>

                                                <div className="fs-10 font-display2 text-dark mt-2">
                                                    Change Video Link
                                                </div>

                                                <form className="mt-lg-0" onSubmit={HandleVideoLink}>
                                                    <div class="mb-3 mt-3 px-lg-8 px-4">
                                                        <input type="text" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="video"
                                                            onChange={(e) => setVideo(e.target.value)}
                                                            placeholder="Input Video Link" />

                                                        <p className="mt-2 fs-4 text-dark">
                                                            N.B: Input youtube, vimoe video link.
                                                        </p>
                                                    </div>

                                                    <div class="mb-3 mt-4">
                                                        <button className="btn btn-purple3 text-purple2"
                                                            data-bs-dismiss={video ? "modal" : ""}
                                                            style={{ borderRadius: "30px" }}>
                                                            {status ? (
                                                                <>
                                                                    Executing...
                                                                    <span
                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Save Link
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="HandleAudio" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content" style={{ borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mt-0 text-center">
                                                <i class="bi bi-mic fs-1 text-success"></i>

                                                <div className="fs-10 font-display2 text-dark mt-2">
                                                    Change Audio File
                                                </div>

                                                <form className="mt-lg-0" onSubmit={HandleAudioFile}>
                                                    <div class="mb-3 mt-3 px-lg-8 px-4">
                                                        <input type="file" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="audio"
                                                            accept="audio/*"
                                                            required
                                                            onChange={handleFileSelect}
                                                            placeholder="Input Audio File" />

                                                        <p className="mt-2 fs-4 text-dark">
                                                            N.B: (Upload file limit - 40MB)
                                                        </p>
                                                    </div>

                                                    <div className='mb-3 mt-3 px-lg-8 px-4'>
                                                        <label class="form-label fs-4 text-dark" for="video">Record Audio</label>
                                                        <div className="btn btn-success btn-sm ms-3"
                                                            style={{
                                                                borderRadius: "30px"
                                                            }}>
                                                            Record
                                                        </div>
                                                    </div>


                                                    <div class="mb-3 mt-4">
                                                        <button className="btn btn-purple3 text-purple2"
                                                            data-bs-dismiss={audio ? "modal" : ""}
                                                            style={{ borderRadius: "30px" }}>
                                                            {status ? (
                                                                <>
                                                                    Executing...
                                                                    <span
                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Save File
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="HandleNote" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-xl modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"}
                                    role="document">
                                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                            <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                                                <i class="bi bi-arrows-fullscreen text-primary fs-3"></i>
                                            </div>
                                        </div>
                                        <div class="modal-body py-0">
                                            <div className="mt-0 text-center">

                                                <i class="bi bi-card-text fs-1 text-success"></i>

                                                <div className="fs-10 font-display2 text-dark mt-0 mb-5">
                                                    Note Maker
                                                </div>

                                                <form className="mt-lg-0" onSubmit={HandlePassage}>

                                                    <div className="px-0 px-lg-3"
                                                        style={isMobile ? { height: 280 } : { height: 250 }}>
                                                        <div ref={quillRef} />
                                                    </div>

                                                    <div class="mb-8 mt-18 mt-lg-13">
                                                        <button className="btn btn-dark btn-lg fw-medium"
                                                            data-bs-dismiss={passage ? "modal" : ""}
                                                            style={{ borderRadius: "30px" }}>
                                                            {status ? (
                                                                <>
                                                                    Executing...
                                                                    <span
                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Save Note
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>

                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="ViewNote" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-lg modal-dialog-centered" : "modal-dialog modal-md modal-dialog-centered"}
                                    role="document">
                                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0 pt-4">
                                            <h3 class="cursor-pointer text-dark fs-t" id="exampleModalCenterTitle">
                                                {course}</h3>
                                            <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                                                <i class="bi bi-arrows-fullscreen text-primary fs-3"></i>
                                            </div>
                                            <button className="btn btn-orange text-white btn-sm font-display2 fw-medium"
                                                data-bs-toggle='modal' data-bs-target='#ShareNote'
                                                style={{ borderRadius: "30px" }}>
                                                <span className="bi bi-share"></span> Share Note
                                            </button>
                                        </div>

                                        <div class="modal-body py-0 pb-6">
                                            <div className="border p-lg-4 p-3 rounded-3 mt-3 mt-lg-0">
                                                <div className="font-display2"
                                                    dangerouslySetInnerHTML={{ __html: media_task.passage }}
                                                ></div>
                                            </div>

                                            <div className="text-end mt-5">
                                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                                                    style={{ borderRadius: "30px" }}>
                                                    Close Note
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="ShareNote" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                                    <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mt-8 mt-lg-0 text-center">
                                                <i class="bi bi-share fs-1 text-primary"></i>

                                                <div className="fs-10 font-display2 text-dark mt-2">
                                                    Share {media_task.course} Note
                                                </div>

                                                <div className="row px-lg-6 py-4 g-4">

                                                    <div className="col-lg-4 col-4">
                                                        <div className="btn btn-light-primary rounded-circle btn-md">
                                                            <span className="fas fa-copy fs-3 py-2"></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-4">
                                                        <div className="btn btn-light-success rounded-circle btn-md">
                                                            <span className="bi bi-whatsapp fs-3 py-2"></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-4">
                                                        <div className="btn btn-light-info rounded-circle btn-md">
                                                            <span className="bi bi-telegram fs-3 py-2"></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 text-dark">
                                                        OR
                                                    </div>

                                                    <div className="col-lg-6 offset-lg-3 col-8 offset-2 text-center">
                                                        <div className="border border-dark py-lg-3 py-2 rounded-3">
                                                            <QRCode
                                                                value={url_link}
                                                                size={"150"}
                                                                fgColor={"#2c4f40"}
                                                            />
                                                        </div>
                                                        <div className="fs-4 text-dark mt-2">
                                                            Scan QR Code.
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                        :

                        <></>
                    }

                    <Mobile />

                    <ToastContainer />

                    <Switcher />


                    <div class="btn btn-orange btn-md fs-t text-white shadow-lg rounded-br-lg2 rounded-bl-sm2 
                    rounded-t-lg2 float mb-lg-0 d-block d-lg-none"
                        data-bs-toggle="modal"
                        data-bs-target="#MobileMenu">
                        <i class="bi bi-columns-gap"></i>
                    </div>


                    <div class="modal fade" id="MobileMenu" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                            <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0">
                                    <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                        aria-label="Close"><i class="bi bi-x-lg"></i></h3>

                                </div>
                                <div class="modal-body py-0">
                                    <div className="mt-0 mt-lg-0 text-center">
                                        <i class="bi bi-columns-gap fs-1 text-success"></i>

                                        <div className="fs-10 font-display2 text-dark mt-2">
                                            Dashboard Menu
                                        </div>

                                        <div className="row px-lg-6 pt-4 g-4 pb-10">

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-light"
                                                data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/q/" + token}>
                                                        <div className="text-dark fs-t text-center">
                                                            <div class="bi bi-arrow-left"></div>
                                                            <div className="fs-3">Go Back</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-primary"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#HandleVideo"
                                                    style={{ borderRadius: "25px" }}>
                                                    <div>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-play-circle"></div>
                                                            <div className="fs-3">Edit Video</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-orange_"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#HandleAudio"
                                                    style={{ borderRadius: "25px" }}>
                                                    <div>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-mic"></div>
                                                            <div className="fs-3">Edit Audio</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="modal-footer border-0">
                                    <p class="mb-0 text-dark fs-4"> Powered by <a href="https://smartedung.com" target="_blank"
                                        className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        </>
    );
};

export default View;
