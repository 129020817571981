import React, { useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

import axios from "axios";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

import { Toggle } from "../Toggle";

import Chart from "react-apexcharts";

const Forms = () => {
  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const classwork = getusers.classwork;

  const homework = getusers.homework;

  const cbt = getusers.cbt;

  const note = getusers.note;

  const config = {
    series:
      !classwork && !homework && !cbt && !note
        ? [1]
        : [
            parseInt(classwork),
            parseInt(homework),
            parseInt(cbt),
            parseInt(note),
          ],
    options: {
      labels:
        !classwork && !homework && !cbt && !note
          ? ["Empty"]
          : ["Classwork", "Homework", "CBT", "Note"],
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      fill: {
        colors:
          !classwork && !homework && !cbt && !note
            ? ["#57606f"]
            : ["#f3533a", "#fa9f42", "#8ad879", "#ff9ff3"],
      },
      dataLabels: {
        enabled: false,
      },
      cutout: 200,
      cutoutPercentage: "200%",
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
      plugins: {
        legend: {
          display: false,
          show: true,
        },
      },
      responsive: [
        {
          series:
            !classwork && !homework && !cbt && !note
              ? [1]
              : [
                  parseInt(classwork),
                  parseInt(homework),
                  parseInt(cbt),
                  parseInt(note),
                ],
          breakpoint: 400,
          options: {
            labels:
              !classwork && !homework && !cbt && !note
                ? ["Empty"]
                : ["Test", "Homework", "CBT", "Note"],
            chart: {
              width: 130,
              height: 130,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  const { id } = useParams();

  const navigate = useNavigate();

  axios.defaults.baseURL = id;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 4000);
    FetchExerciseList();
  }, [teachid]);

  const [folders2, setFolders2] = useState([]);

  const FetchExerciseList = () => {
    setLoader(true);

    axios({
      method: "post",
      url: "./v2/exercise/list/",
      data: {
        teachid,
        task: "CLASSWORK",
        q: null,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setTimeout(function () {
          setLoader(false);
        }, 4000);

        if (view.message !== "failed") {
          setFolders2(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const SearchExercisList = (task) => {
    setLoader(true);

    axios({
      method: "post",
      url: "./v2/exercise/list/",
      data: {
        teachid,
        task: "CLASSWORK",
        q: task,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setTimeout(function () {
          setLoader(false);
        }, 4000);

        if (view.message !== "failed") {
          setFolders2(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [url_link, setLink] = useState(null);

  const Play = (url_link) => {
    navigate("../" + id + "/q/" + url_link);
  };

  const Edit = (url_link) => {
    navigate("../" + id + "/cbt/access/" + url_link);
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const [menu, setMenu] = useState(true);

  const renderFolders = () => {
    if (folders2 == "") {
      return (
        <>
          {isMobile ? (
            <div className="">
              <div
                className="shadow-none text-center"
                style={{ borderRadius: "15px" }}
              >
                <div className="p-5">
                  <div className="">
                    <img
                      src="../../assets/images/svg/3d-girl-seeting.svg"
                      width={isMobile ? "80%" : "40%"}
                      alt=""
                    />
                  </div>
                  <div className="text-dark fs-t fw-medium">
                    No Results Found
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="mt-5">
                <div className="shadow-none" style={{ borderRadius: "15px" }}>
                  <div className="">
                    <div className="text-dark fs-t fw-medium">
                      No Results Found
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    } else if (folders2) {
      return folders2.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            {task.type === "CLASSWORK" ? (
              <div className="col-11">
                <div
                  class={
                    index % 2 == 0
                      ? "container bg-back rounded-3"
                      : "container bg-back rounded-3"
                  }
                >
                  <div class="row align-items-center py-3 py-lg-4 px-lg-4 px-2">
                    <div class="col-lg-12 col-12">
                      <div class="text-dark p-5 p-lg-0">
                        <h2 class="h3 text-dark fw-medium">
                          {" "}
                          {task.class} {truncate(task.subject, 8)}: {task.title}
                        </h2>
                        <div className="row">
                          <div className="col-8">
                            <p class="mb-0 fs-3 mt-2">Free Space</p>
                            <p class="mb-0 fs-3 mt-2">
                              {10 - task.id} out of 10
                            </p>
                          </div>
                          <div className="col-4">
                            <img
                              src="../../assets/images/background/2.png"
                              alt=""
                              class="img-fluid mt-n1"
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <div
                            class="progress rounded-pill"
                            style={{ height: "0.5rem" }}
                          >
                            <div
                              class="progress-bar bg-dark rounded-pill"
                              role="progressbar"
                              style={
                                task.id === 0
                                  ? { width: "100%" }
                                  : { width: (task.id / 10) * 100 }
                              }
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <a class="btn btn-light-dark mt-4 rounded-pill">
                          Continue Task...
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const GoLink = (url) => {
    navigate(url);
  };

  return (
    <>
      <div
        class="modal fade"
        id="Classwork"
        tabindex="-1"
        role="dialog"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class={
            isMobile
              ? "modal-dialog modal-dialog-centered modal-fullscreen"
              : "modal-dialog modal-lg modal-dialog-centered"
          }
          role="document"
        >
          <div
            class="modal-content px-lg-4"
            style={
              isMobile
                ? { border: "#fff" }
                : { borderRadius: "35px", border: "#fff" }
            }
          >
            <div class="modal-header border-0 py-4">
              <div
                class="fs-10 text-purple3 fw-medium"
                id="exampleModalCenterTitle"
              >
                {" "}
                <span className="mt-n6">Classwork Uploads</span>{" "}
              </div>

              <div
                class="cursor-pointer fs-t text-dark-danger fw-medium"
                id="exampleModalCenterTitle"
                data-bs-dismiss="modal"
              >
                {" "}
                <span className="mt-n6">
                  <i class="bi bi-x-lg"></i>
                </span>{" "}
              </div>
            </div>
            <div class="modal-body py-0">
              <div className="py-2 mb-lg-8">
                <div className="row">
                  <div className="col-lg-12">
                    <form class="d-flex align-items-center">
                      <span class="position-absolute ps-3 search-icon">
                        <i class="fe fe-search"></i>
                      </span>
                      <input
                        type="search"
                        onKeyUp={(e) => SearchExercisList(e.target.value)}
                        style={{ borderRadius: "24px" }}
                        class="form-control form-control-lg ps-6 fs-4 bg-back"
                        placeholder="Search Dashboard"
                      />
                    </form>
                  </div>
                </div>
                <div className="mt-lg-6 mt-8">
                  {loader ? (
                    <div className="col-lg-12 text-center mt-14">
                      <div
                        class="spinner-border text-primary"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div className="mt-4 fs-3 text-dark">Loading...</div>
                    </div>
                  ) : (
                    <div
                      className={
                        folders2
                          ? "row align-items-center"
                          : "row align-items-center"
                      }
                    >
                      <Carousel
                        responsive={responsive}
                        itemClass="carousel-item-padding-10-px text-dark"
                        className="py-2"
                        arrows={true}
                        autoPlay={false}
                        rewind={false}
                      >
                        {folders2 != "" ? renderFolders() : ""}
                      </Carousel>
                    </div>
                  )}
                </div>

                <div className="col-lg-12 mt-lg-6 mt-8">
                  <div class="container bg-white-soft rounded-3">
                    <div class="row align-items-center py-2">
                      <div class="col-lg-12 col-12">
                        <div class="text-dark">
                          <h2 class="h3 text-dark">Need Help?</h2>
                          <p class="mb-0 fs-4">
                            Do you want to know how to use the App more better?
                            Don't worry we got you covered.
                          </p>
                          <a class="btn btn-primary rounded-3 mt-3">Help me?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toggle />

      <div
        class="modal"
        id="Handlesettings"
        tabindex="-1"
        role="dialog"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          class={
            isMobile
              ? "modal-dialog modal-dialog-centered modal-fullscreen"
              : "modal-dialog modal-md modal-dialog-centered"
          }
          role="document"
        >
          <div
            class="modal-content"
            style={
              isMobile
                ? { border: "#fff" }
                : { borderRadius: "35px", border: "#fff" }
            }
          >
            <div class="modal-header border-0 py-4">
              <div class="fs-10 text-purple3" id="exampleModalCenterTitle">
                {" "}
                <span className="mt-n6">Switch Dashboard</span>{" "}
              </div>

              <div
                class="cursor-pointer fs-t text-purple2"
                id="exampleModalCenterTitle"
                data-bs-dismiss="modal"
              >
                {" "}
                <span className="mt-n6">
                  <i class="bi bi-x-lg"></i>
                </span>{" "}
              </div>
            </div>
            <div class="modal-body py-0">
              <div className="py-2 mb-6 px-lg-4">
                <div className="text-start mb-4">
                  <div className="text-dark fs-4">
                    Click on the 'Dashboard' you want to work on to switch to
                    its operation. Remember to save your works on previous
                    dashboard before proceeding to new one.
                  </div>
                </div>

                <div className="row g-4">
                  <div className="col-6">
                    <div
                      className={
                        menu
                          ? "card py-2 shadow-none cursor-pointer bg-light-primary"
                          : "card py-2 shadow-none cursor-pointer bg-back"
                      }
                      style={{ borderRadius: "18px" }}
                    >
                      <div className="card-body">
                        <div className="fs-2 text-dark text-center">
                          <div class="bi bi-ui-checks"></div>
                          <div className="mt-2 fs-t">E-Learning</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-6"
                    onClick={() => GoLink("../" + id + "/report/start")}
                    data-bs-dismiss="modal"
                  >
                    <div
                      className="card py-2 shadow-none cursor-pointer bg-back"
                      style={{ borderRadius: "18px" }}
                    >
                      <div className="card-body">
                        <div className="fs-2 text-dark text-center">
                          <div class="bi bi-ui-checks"></div>
                          <div className="mt-2 fs-t">E-Report</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isMobile ? (
                  <div className="col-12 mt-10">
                    <div className="text-start mb-6">
                      <div className="text-dark fw-medium fs-t">
                        Statistic/Progress Record
                      </div>
                    </div>

                    <div>
                      <div className="col-lg-12 mb-5">
                        <Chart
                          options={config.options}
                          series={config.series}
                          type="donut"
                          height={250}
                        />
                      </div>

                      <div className="row g-4">
                        <div className="col-lg-6 col-6">
                          <div
                            className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="card-body text-center">
                              <div className="h1 text-dark">{classwork}</div>
                              <div className="fs-4 text-dark">Classwork</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-6">
                          <div
                            className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="card-body text-center">
                              <div className="h1 text-dark">{homework}</div>
                              <div className="fs-4 text-dark">Homework</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-6">
                          <div
                            className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="card-body text-center">
                              <div className="h1 text-dark">{cbt}</div>
                              <div className="fs-4 text-dark">CBT</div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6 col-6">
                          <div
                            className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back"
                            style={{ borderRadius: "10px" }}
                          >
                            <div className="card-body text-center">
                              <div className="h1 text-dark">{note}</div>
                              <div className="fs-4 text-dark">Note</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forms;
