import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import { fadeIn, slideInUp, zoomIn } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import { Player } from "@lottiefiles/react-lottie-player";

const Read = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const teachid = sessionStorage.getItem("teach_id");

  const token = sessionStorage.getItem("token");

  const fname = sessionStorage.getItem("fname");

  const studid = sessionStorage.getItem("stud_id");

  const audio = new Audio("./assets/sound/new.mp3");

  useEffect(() => {
    FetchNote();

    if (note_task.response) {
      audio.play();
    }
  }, [token, teachid]);

  const [loaders, setLoaders] = useState(true);

  const [note_task, setNoteTask] = useState([]);

  const FetchNote = () => {
    axios({
      method: "post",
      url: "./v2/note/",
      data: {
        token,
        teachid,
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (!view.token) {
          setTimeout(function () {
            //navigate("./denied");
          }, 3000);
        } else if (view.message === "failed") {
        } else {
          setNoteTask(view);

          setValue(view.comment !== "" ? view.comment : null);

          setTimeout(function () {
            setLoaders(false);
          }, 3000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const hours = new Date().getHours();

  const isDayTime = hours > 6 && hours < 20;

  const [value, setValue] = useState(null);

  const [status, setStat] = useState(false);

  const Handle_comment = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      studid,
      token,
      comment: value,
    };

    if (!value) {
      toast.error("Empty: Field can't be empty!", {
        hideProgressBar: true,
        draggable: true,
        position: "top-right",
        icon: true,
        autoClose: 6000,
      });

      setTimeout(function () {
        setStat(false);
      }, 3000);
    } else {
      axios({
        method: "post",
        url: "/v2/note/update/",
        data: task,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          //console.log(response);
          if (response.data.message === "success") {
            toast.success(
              "Success: You message has been sent and delievered! You would get response soon.",
              {
                hideProgressBar: true,
                draggable: true,
                position: "top-right",
                icon: true,
                autoClose: 6000,
              }
            );

            FetchNote();

            setTimeout(function () {
              setStat(false);
            }, 3000);
          } else {
            toast.error("Failed: Request Failed! Please try again", {
              hideProgressBar: true,
              draggable: true,
              position: "top-right",
              icon: true,
              autoClose: 6000,
            });

            setTimeout(function () {
              setStat(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error("Error: Request Failed! Check network connection.");

          setTimeout(function () {
            setStat(false);
          }, 3000);
        });
    }
  };

  return (
    <>
      {!teachid || !studid || !token || !fname ? (
        <>
          <div className="container">
            <div className="row py-16 py-lg-15 px-4 px-lg-12">
              <div className="col-12">
                <div className="">
                  <Player
                    autoplay={true}
                    loop={true}
                    controls={false}
                    src="https://assets8.lottiefiles.com/packages/lf20_ztbkglhq.json"
                    style={{ height: "300px", width: "300px" }}
                  ></Player>
                </div>

                <div className="fs-t text-dark mt-2 text-center">
                  Oops! Your connection to this note is lost. Click on the link
                  sent to you login and access note again.
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <StyleRoot>
            <div className="row g-0">
              <div
                className="col-12 fixed-bottom d-block d-lg-none"
                data-bs-toggle="modal"
                data-bs-target="#HandleOpen"
                onClick={() => {
                  FetchNote();
                  if (note_task.response) {
                    audio.play();
                  }
                }}
              >
                <div className="card bg-white py-2 px-2">
                  <div className="card bg-dark-primary rounded-pill smooth-shadow-sm">
                    <div className="px-2 py-2">
                      <div className="text-white fs-10 text-center">
                        Comment On Note
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7">
                {loaders ? (
                  <div className="card shadow-none bg-white px-4 px-lg-6 py-20 py-lg-12 pb-20">
                    <div className="col-12 text-center py-20 mb-12">
                      <div class="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>

                      <div className="mt-2">
                        <div className="fs-3 text-purple2">
                          Fetching out Note..
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card shadow-none bg-white px-4 px-lg-6 pt-3 pb-20 pt-lg-5 curve_">
                    <div className="col-12">
                      <div className="text-purple3 fs-2">{note_task.title}</div>
                    </div>

                    <div className="col-12 mt-4 mb-20">
                      <div className="px-3">
                        <div className="fs-10 text-purple3 mb-4 mt-1 mt-lg-3 mb-lg-5">
                          Hello <span className="text-success">{fname}</span>,
                        </div>

                        <div
                          className="row bg-light-primary px-3 px-lg-1 py-3 mb-4"
                          style={{ borderRadius: "24px" }}
                        >
                          <div className="col-lg-6 fs-3 text-dark mb-3 mb-lg-0">
                            {note_task.class} - {note_task.subject} Note
                          </div>

                          <div className="col-lg-6 fs-3 text-dark text-lg-end">
                            Teacher - {note_task.fname}
                          </div>
                        </div>
                      </div>

                      <div className="px-3">
                        <div
                          dangerouslySetInnerHTML={{ __html: note_task.note }}
                        ></div>
                      </div>
                    </div>

                    <div class="col-12 text-start px-2 mb-n6 mb-lg-n16">
                      <div class="mb-0 text-dark fs-4">
                        <img
                          src={"../assets/images/school/" + id + ".png"}
                          width={isMobile ? "40" : "50"}
                          alt=""
                          className="me-2 mt-n3"
                        />
                        Powered by {note_task.school} School.
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-5 d-none d-lg-block">
                {loaders ? (
                  <div className="card shadow-none bg-back px-4 px-lg-6 py-20 py-lg-12 pb-20">
                    <div className="col-12 text-center py-20 mb-12">
                      <div class="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>

                      <div className="mt-2">
                        <div className="fs-3 text-purple2">Please wait...</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card bg-back shadow-none pt-3 pb-20 position-fixed px-4">
                    <div className="col-12">
                      <div className="text-purple2 fs-10">
                        Do you want to ask question or comment on NOTE?
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <div
                        className="bg-white px-4 py-4 mb-4"
                        style={{ borderRadius: "15px" }}
                      >
                        <div className="mb-4">
                          <div className="fs-4 text-dark">
                            You can now comment on the note, ask teacher
                            questions, and get instant response to your WhatsApp
                            line. Below are the provided options.
                          </div>
                        </div>

                        <div className="pb-10">
                          <div className="fs-3 text-dark mb-2">
                            Write Comment or Question..
                          </div>

                          <form onSubmit={Handle_comment}>
                            {note_task.comment ? (
                              <div className="">
                                <ReactQuill
                                  theme="snow"
                                  value={value}
                                  onChange={setValue}
                                  style={
                                    isMobile ? { height: 150 } : { height: 100 }
                                  }
                                />
                              </div>
                            ) : (
                              <div className="">
                                <ReactQuill
                                  theme="snow"
                                  value={value}
                                  onChange={setValue}
                                  style={
                                    isMobile ? { height: 150 } : { height: 100 }
                                  }
                                />
                              </div>
                            )}

                            <div className="col-12 text-start mt-10">
                              <button
                                type="submit"
                                className="btn btn-dark-primary"
                                style={{ borderRadius: "12px" }}
                                disabled={status ? true : false}
                              >
                                {status ? (
                                  <>
                                    Sending...
                                    <span
                                      class="spinner-border spinner-border-sm ms-3"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </>
                                ) : (
                                  <>
                                    Send Your Comment{" "}
                                    <i class="bi bi-arrow-right"></i>
                                  </>
                                )}
                              </button>

                              {note_task.comment ? (
                                <button
                                  type="button"
                                  className="btn btn-light ms-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#Preview"
                                  style={{ borderRadius: "12px" }}
                                >
                                  View Comment
                                  {note_task.response ? (
                                    <span class="badge rounded-pill bg-danger ms-2">
                                      +1 New
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </form>
                        </div>

                        <div className="row d-flex align-items-center mt-n2">
                          <div class="col-2 position-relative">
                            <img
                              src={"../" + id + "/profiles/" + teachid + ".jpg"}
                              alt=""
                              class="rounded-circle avatar-lg"
                            />
                          </div>

                          <div className="col-6 text-dark">
                            <div className="fs-4 fw-medium">
                              {note_task.fname}
                            </div>

                            <div className="fs-4">{note_task.ref}</div>
                          </div>

                          {isDayTime ? (
                            <div className="col-4 text-end">
                              <a
                                target="_blank"
                                href={
                                  "https://wa.me/" +
                                  note_task.phone +
                                  "?text=Good%20day!%20My%20Name%20is%20" +
                                  fname +
                                  ".%20I%20have%20a%20question%20concerning%20this%20note%20" +
                                  note_task.title
                                }
                                className="btn btn-success rounded-pill"
                              >
                                <i class="bi bi-whatsapp"></i> Chat Me
                              </a>
                            </div>
                          ) : (
                            <div
                              className="col-4 text-end"
                              data-bs-toggle="modal"
                              data-bs-target="#CloseHour"
                            >
                              <div className="btn btn-success rounded-pill">
                                <i class="bi bi-whatsapp"></i> Chat Me
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </StyleRoot>

          <div
            class="modal"
            id="HandleOpen"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class={
                isMobile
                  ? "modal-dialog modal-dialog-centered modal-fullscreen"
                  : "modal-dialog modal-md modal-dialog-centered"
              }
              role="document"
            >
              <div
                class="modal-content curve_"
                style={
                  isMobile
                    ? { border: "#fff" }
                    : { borderRadius: "35px", border: "#fff" }
                }
              >
                <div class="modal-header border-0 py-3">
                  <div className="fs-t text-purple2 fw-medium">
                    Do you want to ask question or comment on NOTE?
                  </div>

                  <button
                    class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                  </button>
                </div>
                <div class="modal-body py-0">
                  <div className="pt-3 px-2 pb-18">
                    <div className="col-12">
                      <div>
                        <div className="mb-5">
                          <div className="fs-3 text-dark">
                            You can now comment on the note, ask teacher
                            questions, and get instant response to your WhatsApp
                            line. Below are the provided options.
                          </div>
                        </div>

                        <div className="pb-10">
                          <div className="fs-3 text-dark mb-3">
                            Write Comment or Question..
                          </div>

                          <form onSubmit={Handle_comment}>
                            {note_task.comment ? (
                              <div className="">
                                <ReactQuill
                                  theme="snow"
                                  value={value}
                                  onChange={setValue}
                                  style={
                                    isMobile ? { height: 150 } : { height: 100 }
                                  }
                                />
                              </div>
                            ) : (
                              <div className="">
                                <ReactQuill
                                  theme="snow"
                                  onChange={setValue}
                                  style={
                                    isMobile ? { height: 150 } : { height: 100 }
                                  }
                                />
                              </div>
                            )}

                            <div className="col-12 text-start mt-12">
                              <button
                                type="submit"
                                className="btn btn-dark-primary"
                                style={{ borderRadius: "12px" }}
                                disabled={status ? true : false}
                              >
                                {status ? (
                                  <>
                                    Sending...
                                    <span
                                      class="spinner-border spinner-border-sm ms-3"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </>
                                ) : (
                                  <>
                                    Send Your Comment{" "}
                                    <i class="bi bi-arrow-right"></i>
                                  </>
                                )}
                              </button>

                              {note_task.comment ? (
                                <button
                                  type="button"
                                  className="btn btn-light ms-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#Preview"
                                  style={{ borderRadius: "12px" }}
                                >
                                  View Comment
                                  {note_task.response ? (
                                    <span class="badge rounded-pill bg-danger ms-2">
                                      +1 New
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          </form>
                        </div>

                        <div className="row d-flex align-items-center mt-3">
                          <div class="col-3 position-relative">
                            <img
                              src={"../" + id + "/profiles/" + teachid + ".jpg"}
                              alt=""
                              class="rounded-circle avatar-lg"
                            />
                          </div>

                          <div className="col-9 text-dark">
                            <div className="fs-4 fw-medium">
                              {note_task.fname}
                            </div>

                            <div className="fs-4">{note_task.ref}</div>
                          </div>

                          {isDayTime ? (
                            <div className="col-12 mb-3 d-grid fixed-bottom px-4">
                              <a
                                target="_blank"
                                href={
                                  "https://wa.me/" +
                                  note_task.phone +
                                  "?text=Good%20day!%20My%20Name%20is%20" +
                                  fname +
                                  ".%20I%20have%20a%20question%20concerning%20this%20note%20" +
                                  note_task.title
                                }
                                className="btn btn-success rounded-pill btn-lg"
                              >
                                Questions? <i class="bi bi-whatsapp"></i> Chat
                                Me
                              </a>
                            </div>
                          ) : (
                            <div
                              className="col-12 mb-3 d-grid fixed-bottom px-4"
                              data-bs-toggle="modal"
                              data-bs-target="#CloseHour"
                            >
                              <div className="btn btn-success rounded-pill btn-lg">
                                Questions? <i class="bi bi-whatsapp"></i> Chat
                                Me
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal"
            id="CloseHour"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class={
                isMobile
                  ? "modal-dialog modal-dialog-centered modal-fullscreen"
                  : "modal-dialog modal-lg modal-dialog-centered"
              }
              role="document"
            >
              <div
                class="modal-content"
                style={
                  isMobile
                    ? { border: "#fff" }
                    : { borderRadius: "35px", border: "#fff" }
                }
              >
                <div class="modal-header border-0 py-3">
                  <div className="fs-t text-purple2 fw-medium"></div>

                  <button
                    class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                  </button>
                </div>
                <div class="modal-body py-0">
                  <div className="mb-6 mb-lg-4 px-2 px-lg-6">
                    <div className="fs-2 text-purple3">
                      Oops! Its Late Hours!
                    </div>
                  </div>

                  <div className="pt-3">
                    <Player
                      autoplay={true}
                      loop={true}
                      controls={false}
                      src="https://assets5.lottiefiles.com/packages/lf20_fVE8NQRi10.json"
                      style={{ height: "250px", width: "250px" }}
                    ></Player>
                  </div>

                  <div className="px-2 px-lg-6 mt-8 mt-lg-4 mb-lg-5">
                    <div className="fs-3 text-purple2">
                      We are sorry but it is past working hours to talk to our
                      teachers, Please make use of the Comment section to ask
                      your questions, you would definitely get a response once
                      they are back online.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal"
            id="Preview"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class={
                isMobile
                  ? "modal-dialog modal-dialog-centered modal-fullscreen"
                  : "modal-dialog modal-lg modal-dialog-centered"
              }
              role="document"
            >
              <div
                class="modal-content"
                style={
                  isMobile
                    ? { border: "#fff" }
                    : { borderRadius: "35px", border: "#fff" }
                }
              >
                <div class="modal-header border-0 py-3">
                  <div className="fs-t text-purple2 fw-medium">
                    Ticket - #{note_task.ticket}
                  </div>

                  <button
                    class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                  </button>
                </div>
                <div class="modal-body py-0 px-lg-6">
                  <ul class="list-group list-group-flush list-timeline-activity mt-8 mt-lg-3 mb-lg-4">
                    <li class="list-group-item px-0 pt-0 border-0 mb-4">
                      <div class="row">
                        <div class="col-auto mt-2">
                          <span class="avatar avatar-lg avatar-primary">
                            <span class="avatar-initials rounded-circle">
                              <i class="bi bi-chat-quote fs-t"></i>
                            </span>
                          </span>
                        </div>
                        <div class="col ms-n2">
                          <div className="fs-2 text-purple3">Your Comment.</div>
                          <div className="fs-3 text-purple2">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: note_task.comment,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item px-0 pt-0 border-0">
                      <div class="row">
                        <div class="col-auto mt-2">
                          <span class="avatar avatar-lg avatar-success-soft">
                            <span class="avatar-initials rounded-circle">
                              <i class="bi bi-chat-quote fs-t"></i>
                            </span>
                          </span>
                        </div>
                        <div class="col ms-n2">
                          <div className="fs-2 text-purple">
                            Response{" "}
                            <span className="fs-t text-danger">
                              - #{note_task.ticket}
                            </span>
                          </div>

                          {note_task.response ? (
                            <div className="fs-3 text-purple2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: note_task.response,
                                }}
                              ></div>
                            </div>
                          ) : (
                            <div className="fs-3 text-dark">
                              No response yet, we would notify you once there is
                              any response.
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {isMobile && note_task.response ? (
            <div
              class="btn btn-dark fs-10 text-white shadow-lg rounded-circle float mb-14 end-0 me-2"
              data-bs-toggle="modal"
              data-bs-target="#Preview"
              onClick={() => audio.play()}
            >
              <span class="position-absolute top-0 translate-middle badge rounded-pill bg-danger">
                +1 Response
              </span>
              <i class="bi bi-chat-quote"></i>
            </div>
          ) : (
            <></>
          )}

          <ToastContainer />
        </>
      )}
    </>
  );
};

export default Read;
