import React from 'react'

import Chart from "react-apexcharts";

import { useSelector } from "react-redux";

const Statistic = () => {

    const getusers = useSelector((state) => state.user.value);

    const classwork = getusers.classwork;

    const homework = getusers.homework;

    const cbt = getusers.cbt;

    const note = getusers.note;

    const config = {
        series: !classwork && !homework && !cbt && !note ? [1] : [parseInt(classwork), parseInt(homework), parseInt(cbt), parseInt(note)],
        options: {
            labels: !classwork && !homework && !cbt && !note ? ['Empty'] : ['Classwork', 'Homework', 'CBT', 'Note'],
            chart: {
                type: "donut",
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false
            },
            fill: {
                colors: !classwork && !homework && !cbt && !note ? ['#57606f'] : ['#f3533a', '#fa9f42', '#8ad879', '#ff9ff3']
            },
            dataLabels: {
                enabled: false,
            },
            cutout: 200,
            cutoutPercentage: "200%",
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                animateScale: true,
                animateRotate: true,
            },
            tooltips: {
                enabled: true,
                intersect: false,
                mode: "nearest",
                bodySpacing: 5,
                yPadding: 10,
                xPadding: 10,
                caretPadding: 0,
                displayColors: false,
                cornerRadius: 4,
                footerSpacing: 0,
                titleSpacing: 0,
            },
            plugins: {
                legend: {
                    display: false,
                    show: true
                },
            },
            responsive: [
                {
                    series: !classwork && !homework && !cbt && !note ? [1] : [parseInt(classwork), parseInt(homework), parseInt(cbt), parseInt(note)],
                    breakpoint: 400,
                    options: {
                        labels: !classwork && !homework && !cbt && !note ? ['Empty'] : ['Test', 'Homework', 'CBT', 'Note'],
                        chart: {
                            width: 130,
                            height: 130,
                        },
                        legend: {
                            show: false
                        },
                    },
                },
            ],
        },
    };

    return (
        <div>
            <div className="col-lg-12 mb-5">
                <Chart
                    options={config.options}
                    series={config.series}
                    type="donut"
                    height={250}
                />
            </div>

            <div className="row g-4">

                <div className="col-lg-6 col-6">
                    <div className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back"
                        style={{ borderRadius: "10px", }}>
                        <div className="card-body text-center">
                            <div className="h1 text-dark">
                                {classwork}
                            </div>
                            <div className="fs-4 text-dark">
                                Classwork
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-6">
                    <div className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back" 
                    style={{ borderRadius: "10px", }}>
                        <div className="card-body text-center">
                            <div className="h1 text-dark">
                                {homework}
                            </div>
                            <div className="fs-4 text-dark">
                                Homework
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-6">
                    <div className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back" 
                    style={{ borderRadius: "10px", }}>
                        <div className="card-body text-center">
                            <div className="h1 text-dark">
                                {cbt}
                            </div>
                            <div className="fs-4 text-dark">
                                CBT
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-6">
                    <div className="card cursor-pointer shadow-none rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 bg-back" 
                    style={{ borderRadius: "10px", }}>
                        <div className="card-body text-center">
                            <div className="h1 text-dark">
                                {note}
                            </div>
                            <div className="fs-4 text-dark">
                                Note
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Statistic