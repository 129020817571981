import React from 'react'

import { Player } from "@lottiefiles/react-lottie-player";

import { isMobile } from "react-device-detect";

const Footer = () => {
    return (
        <>
            <div class="nav-footer border-0 mb-8">
                <div className="mt-0">
                    <div className="card">
                        <div className="card-body bg-light-primary rounded-3 shadow-none">
                            <div className="mb-2">

                            </div>
                            <div className="fs-3 text-dark mb-3 font-display">
                                Amazing prizes to be won!
                            </div>

                            <div className="mt-3">
                                <Player
                                    autoplay={true}
                                    loop={true}
                                    controls={false}
                                    src="https://assets2.lottiefiles.com/packages/lf20_LJ6eMa.json"
                                    style={{ width: "250px" }}
                                ></Player>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="nav-footer border-0">
                <p class="mb-0 text-dark"> Powered by <a href="https://smartedung.com" target="_blank"
                    className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
            </div>
        </>
    )
}

export default Footer