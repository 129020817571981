import React, { useState, useEffect } from "react";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams, Link } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { Player } from "@lottiefiles/react-lottie-player";

import Chart from "react-apexcharts";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import Header from "./Header";

const Home = () => {
    const [loaders, setLoaders] = useState(true);

    const { id } = useParams();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const plan = getusers.plan;

    const wallet = getusers.wallet;

    const income = getusers.income;

    const teachid = getusers.teachid;

    const media = getusers.media;

    const media_per = media/100 * 100

    const [progress, setProgress] = useState(20);

    const [status, setStat] = useState(false);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
    }

    useEffect(() => {
        localStorage.setItem("id", teachid);

        FetchMedia();

    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    const [subjects, setSubjects] = useState([{}]);

    useEffect(() => {
        axios({
            method: "post",
            url: "./v2/subject/list/",
            data: {
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSubjects(view);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    }, [teachid]);

    const renderSubject = () => {
        if (subjects == "") {
            return <></>;
        } else if (subjects) {
            return subjects.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <option value={task.subject}>{task.subject}</option>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [media_task, setMedia] = useState([]);

    const FetchMedia = () => {
        axios({
            method: "post",
            url: "./v2/media/fetch/",
            data: {
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setMedia(response.data);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };


    const config = {
        series: [parseInt(income), parseInt(wallet)],
        options: {
            labels: ['Total Income', 'Your Take Away'],
            chart: {
                type: "donut",
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false
            },
            fill: {
                colors: ['#706fd3', '#33d9b2']
            },
            dataLabels: {
                enabled: false,
            },
            cutout: "10%",
            cutoutPercentage: 10,
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                animateScale: true,
                animateRotate: true,
            },
            tooltips: {
                enabled: true,
                intersect: false,
                mode: "nearest",
                bodySpacing: 5,
                yPadding: 10,
                xPadding: 10,
                caretPadding: 0,
                displayColors: false,
                cornerRadius: 4,
                footerSpacing: 0,
                titleSpacing: 0,
            },
            plugins: {
                legend: {
                    display: false,
                    show: true
                },
            },
            responsive: [
                {
                    series: [parseInt(income), parseInt(wallet)],
                    breakpoint: 400,
                    options: {
                        labels: ['Total Income', 'Your Take Away'],
                        chart: {
                            width: 130,
                            height: 130,
                        },
                        legend: {
                            show: false
                        },
                    },
                },
            ],
        },
    };

    function getUnique(array) {
        var uniqueArray = [];

        // Loop through array values
        for (var value of array) {
            if (uniqueArray.indexOf(value) === -1) {
                uniqueArray.push(value);
            }
        }
        return uniqueArray;
    }

    const Unique_task = getUnique(media_task);


    const renderCourse = () => {
        if (Unique_task == "") {
            return (
                <>

                </>
            );
        } else if (Unique_task) {
            return Unique_task.map((task, index) => {
                //console.log(school.tm_date)
                return (

                    <>
                        <div className="col-lg-11 col-10 mb-4">
                            <Link to={"../" + id + "/media/q/" + task.token} className={index % 2 == 0 ?
                                "card shadow-none bg-orange cursor-pointer" : "card shadow-none bg-success cursor-pointer curve"}
                                style={{ borderRadius: "14px" }}>
                                <div className="card-body text-center">
                                    <div className="display-3">
                                        <i class="bi bi-file-earmark-play-fill text-white"></i>
                                    </div>
                                    <div className="display-6 text-white fw-medium">
                                        {truncate(task.title, 15)}
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };


    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + ".." : str;
    };

    return (
        <>
            <Auth />
            <div
                id="docs-main-wrapper"
                className={isMobile ? `` : ``}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#474787"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <>

                    {app_status === "InActive" ? (
                        <StyleRoot>
                            <div class="container-fluid px-4 px-lg-4" style={styles.bounce}>
                                <Activate />
                            </div>
                        </StyleRoot>
                    ) : (
                        <>
                            <StyleRoot>

                                <div style={styles.bounce}>
                                    <div class="docs-header">
                                        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-3 shadow-sm">
                                            <a class="fs-10 text-purple2 font-display2 cursor-pointer" data-bs-toggle="offcanvas"
                                                data-bs-target="#edit_room" aria-controls="offcanvasLeft">
                                                <i class="bi bi-file-earmark-play-fill fs-t text-pink"></i> Smart Classroom Home </a>
                                            <ul class="navbar-nav ms-auto flex-row">
                                                <li class="nav-item docs-header-btn d-none d-lg-block">
                                                    <Header/>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <div class="docs-nav-sidebar">
                                        <div class="py-5"></div>
                                        <div class=" docs-nav " data-simplebar>
                                            <nav class="navigation navbar navbar-expand-md">
                                                <div class="collapse navbar-collapse " id="navbarNav">
                                                    <ul class="navbar-nav flex-column" id="sidebarnav">
                                                        <li class="navbar-header mt-4">
                                                            <h5 class="heading"></h5>
                                                        </li>

                                                        {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?
                                                            <>
                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/home"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-house"></i> Dashboard</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/preview"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-easel2"></i> Preview Media</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/result"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-ui-checks"></i> Result</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/billing"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-cash-coin"></i> Billing Area</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/invite"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-person-plus"></i> Invite Learners</Link></li>

                                                            </> : <></>}

                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="nav-footer">
                                            <p class="mb-0 text-dark"> Powered by <a href="https://smartedung.com" target="_blank"
                                                className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                        </div>
                                    </div>

                                    <div class="docs-wrapper bg-back" >

                                        <div class="container-fluid px-3 px-lg-4">

                                            {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?

                                                <>

                                                    <div className="pt-lg-12 pt-4 py-12 py-lg-8">
                                                        <div className="row g-3 d-flex align-items-center pb-5">
                                                            <div className="col-lg-8 text-start">
                                                                <div className="display-5 fw-medium mb-4">
                                                                    Welcome {admin_name},
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 text-end">
                                                                <Link to={"../" + id + "/new/Media"}>
                                                                    <button className="btn btn-purple4 shadow text-white 
                                                                    fw-medium font-display2"
                                                                        style={{ borderRadius: "30px" }}>
                                                                        <span className="bi bi-plus-lg"></span> Add New Media
                                                                    </button>
                                                                </Link>
                                                            </div>
                                                        </div>

                                                        <div className="row g-4">

                                                            <div className="col-lg-6 mb-4">
                                                                <div className="card shadow-none bg-dark-primary curve1"
                                                                    style={{ borderRadius: "15px" }}>
                                                                    <div className="card-body">
                                                                        <div className="fs-t text-white">
                                                                            Activity Representation
                                                                        </div>

                                                                        <div className="px-lg-2 py-3">

                                                                            <div className="pt-3">
                                                                                <div className="fs-4 text-white mb-2 font-display2">
                                                                                    All Created Classrooms
                                                                                </div>
                                                                                <div class="progress" style={{ height: "16px" }}>
                                                                                    <div class="progress-bar progress-bar-animated bg-warning" role="progressbar"
                                                                                        style={{ width: media_per }} aria-valuenow={media_per} aria-valuemin="0" aria-valuemax="100">{media_per}%</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="pt-3">
                                                                                <div className="fs-4 text-white mb-2 font-display2">
                                                                                    All Paid Users
                                                                                </div>
                                                                                <div class="progress" style={{ height: "16px" }}>
                                                                                    <div class="progress-bar progress-bar-animated bg-success" role="progressbar"
                                                                                        style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="pt-3">
                                                                                <div className="fs-4 text-white mb-2 font-display2">
                                                                                    All Linked Users
                                                                                </div>
                                                                                <div class="progress" style={{ height: "16px" }}>
                                                                                    <div class="progress-bar progress-bar-animated bg-info" role="progressbar"
                                                                                        style={{ width: "25%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 mb-4">
                                                                <div className="card shadow-none bg-white curve4"
                                                                    style={{ borderRadius: "15px" }}>
                                                                    <div className="card-body">
                                                                        <div className="fs-t text-purple3">
                                                                            Wallet
                                                                        </div>

                                                                        <div className="px-lg-2 py-2">

                                                                            <div className="pt-3">
                                                                                <div className="fs-5 text-purple3">
                                                                                    Your total balance
                                                                                </div>
                                                                                <div className="display-3 text-dark-primary fw-medium">
                                                                                    ₦{wallet}
                                                                                </div>
                                                                            </div>

                                                                            <div className="row align-items-center">

                                                                                <div className="col-lg-7 pt-3 ms-lg-n0 ms-n3">
                                                                                    <div className="row">

                                                                                        <div className="col-5 ms-lg-3">
                                                                                            <Chart
                                                                                                options={config.options}
                                                                                                series={config.series}
                                                                                                type="donut"
                                                                                                height={120}
                                                                                            />
                                                                                        </div>

                                                                                        <div className="col-7 ms-lg-n4 ms-n4">
                                                                                            <div className="mt-1 mt-lg-0">
                                                                                                <div className="fs-5 text-dark"> <span className="bi bi-dot fs-t 
                                                                                text-success"></span>Your Take Away</div>
                                                                                            </div>

                                                                                            <div className="mt-1">
                                                                                                <div className="fs-5 text-dark"> <span className="bi bi-dot fs-t 
                                                                                text-primary"></span>Total Income</div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>

                                                                                <div className="col-lg-5 pt-3 text-end">
                                                                                    <div>
                                                                                        <button className="btn btn-dark"
                                                                                            style={{ borderRadius: "24px" }}>
                                                                                            Withdraw Fund
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="display-6 fw-medium mb-4">
                                                                    Recent Uploads,
                                                                </div>
                                                            </div>

                                                            <Carousel
                                                                responsive={responsive}
                                                                itemClass="carousel-item-padding-10-px"
                                                                className="pt-0"
                                                                arrows={true}
                                                            >
                                                                {renderCourse()}

                                                            </Carousel>

                                                        </div>

                                                    </div>
                                                </>

                                                :

                                                <>
                                                    <div className='pt-8 pt-lg-16'>

                                                        <Player
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={false}
                                                            src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                                                            style={{ height: "250px", width: "250px" }}
                                                        ></Player>

                                                        <div className="text-center mt-5">
                                                            <div className="text-purple2 fs-t fw-bold">
                                                                Access to this service is restricted.
                                                            </div>

                                                            <div className="text-gray-500 fs-4 mt-2">
                                                                For more details contact Smart Edu Team.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            }

                                        </div>

                                    </div>

                                </div>
                            </StyleRoot>
                        </>
                    )}

                    <div
                        class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
                    >
                    </div>



                    <Mobile />

                    <ToastContainer />

                    <Switcher />


                    <div class="btn btn-primary btn-md fs-t text-white shadow-lg rounded-br-lg2 rounded-bl-sm2 
                    rounded-t-lg2 float mb-lg-0 d-block d-lg-none"
                        data-bs-toggle="modal"
                        data-bs-target="#MobileMenu">
                        <i class="bi bi-columns-gap"></i>
                    </div>


                    <div class="modal fade" id="MobileMenu" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                            <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0">
                                    <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                        aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                    <button class="btn btn-pink btn-md shadow-lg text-white font-display fw-medium"
                                        style={{
                                            borderRadius: "30px"
                                        }}
                                        type="button"><span className="bi bi-share"></span> Share Now </button>
                                </div>
                                <div class="modal-body py-0">
                                    <div className="mt-0 mt-lg-0 text-center">
                                        <i class="bi bi-columns-gap fs-1 text-success"></i>

                                        <div className="fs-10 font-display2 text-dark mt-2">
                                            Dashboard Menu
                                        </div>

                                        <div className="row px-lg-6 pt-4 g-4 pb-10">

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-light"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/home"}>
                                                        <div className="text-dark fs-t text-center">
                                                            <div class="bi bi-house"></div>
                                                            <div className="fs-3">Dashboard</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-dark-info"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/result"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-ui-checks"></div>
                                                            <div className="fs-3">Result</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-4 shadow-none cursor-pointer bg-blue"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/preview"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-easel2"></div>
                                                            <div className="fs-3">Preview Media</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-4 shadow-none cursor-pointer bg-purple3"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/billing"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-cash-coin"></div>
                                                            <div className="fs-3">Billing Area</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>


                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-2 shadow-none cursor-pointer bg-yellow"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/invite"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-person-plus"></div>
                                                            <div className="fs-3">Invite Learners</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="modal-footer border-0">
                                    <p class="mb-0 text-dark fs-4"> Powered by <a href="https://smartedung.com" target="_blank"
                                        className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        </>
    );
};

export default Home;
