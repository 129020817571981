import React, { useState, useEffect } from "react";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import Classwork from "./Classwork";

const New = () => {
    const [loaders, setLoaders] = useState(true);

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const teachid = getusers.teachid;

    const app_id = getusers.app_id;

    const [progress, setProgress] = useState(20);

    const [fullname, setfullname] = useState("{}");

    const [selected, setSelected] = useState("Media");

    const { id } = useParams();

    const { type } = useParams();

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3 px-6";
    }

    useEffect(() => {
        setTimeout(function () {
            setfullname(admin_name.split(" ")[0]);
            setSelected(type);
        }, 1000);

        localStorage.setItem("id", teachid);
    }, [admin_name, teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    return (
        <>
            <Auth />
            <div
                className={isMobile ? `bg-white` : `bg-white`}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#474787"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <nav class={headers}
                    style={
                        isMobile
                            ? {  }
                            : {  }
                    }>
                    <div className="container">
                        <div class="navbar-brand">

                            <span className="fs-t text-purple3"><i class="bi bi-plus-lg"></i> Create New Task</span>

                        </div>

                        <div className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                            <Link to={"../" + id + "/exercise"}
                                class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                            >
                                <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                            </Link>
                        </div>

                    </div>
                </nav>

                <div className="Home">

                    <div class="container-fluid px-0 px-lg-4 pt-14 pt-lg-15">

                        {app_status === "InActive" ? (
                            <StyleRoot>
                                <div class="container-fluid px-4 px-lg-4" style={styles.bounce}>
                                    <Activate />
                                </div>
                            </StyleRoot>
                        ) : (
                            <>
                                <StyleRoot>
                                    <div class="container-fluid px-4 px-lg-20" style={styles.bounce}>

                                        <Carousel
                                            responsive={responsive}
                                            itemClass="carousel-item-padding-10-px text-center"
                                            className="py-2"
                                            arrows={false}
                                            autoPlay={false}
                                            rewind={false}
                                        >

                                            <Link className="" to={"../" + id + "/new/Classwork"}>
                                                <button class="btn fw-medium shadow px-lg-5"
                                                    style={selected === "Classwork" ? {
                                                        borderRadius: "30px",
                                                        background: "#000",
                                                        borderColor: "#000",
                                                        color: "#fff"
                                                    } : {
                                                        borderRadius: "30px",
                                                        background: "transparent",
                                                        borderColor: "#000",
                                                        color: "#000",
                                                    }} onClick={() => setSelected("Classwork")}>
                                                    <i class="bi bi-ui-radios text-success fs-t"></i> Classwork
                                                </button>
                                            </Link>

                                            <Link to={"../" + id + "/new/Homework"} className="">
                                                <button class="btn fw-medium shadow px-lg-5"
                                                    style={selected === "Homework" ? {
                                                        borderRadius: "30px",
                                                        background: "#000",
                                                        borderColor: "#000",
                                                        color: "#fff"
                                                    } : {
                                                        borderRadius: "30px",
                                                        background: "transparent",
                                                        borderColor: "#000",
                                                        color: "#000",
                                                    }} onClick={() => setSelected("Homework")}>
                                                    <i class="bi bi-ui-radios text-warning fs-t"></i> Homework
                                                </button>
                                            </Link>

                                            <Link to={"../" + id + "/new/CBT"} className="">
                                                <button class="btn fw-medium shadow px-lg-5"
                                                    style={selected === "CBT" ? {
                                                        borderRadius: "30px",
                                                        background: "#000",
                                                        borderColor: "#000",
                                                        color: "#fff"
                                                    } : {
                                                        borderRadius: "30px",
                                                        background: "transparent",
                                                        borderColor: "#000",
                                                        color: "#000",
                                                    }} onClick={() => setSelected("CBT")}>
                                                    <i class="bi bi-list-check text-info fs-t"></i> CBT Exam
                                                </button>
                                            </Link>

                                        </Carousel>

                                    </div>
                                </StyleRoot>

                                <StyleRoot>
                                    <div class="container-fluid px-4 px-lg-20" style={styles.bounce}>
                                        {selected === "Classwork" ? <Classwork/> : <></>}
                                    </div>
                                </StyleRoot>
                            </>
                        )}

                    </div>

                    <Footer />
                </div>

                <Mobile />

                <ToastContainer />

                <Switcher />
            </div>
        </>
    );
};

export default New;

