import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  username: "",
  phone: "",
  email: "",
  teachclass: "",
  teachid: "",
  image: "",
  term: "",
  year: "",
  app_status: "",
  cbt: "",
  classwork: "",
  homework: "",
  note: "",
  media: "",
  token: "",
  app_id: "",
  plan: "",
  wallet: "",
  income: "",
  school_name: "",
  app_verify: "",
  test_type: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState: { value: initialStateValue },
  reducers: {
    register: (state, action) => {
      state.value = action.payload;
    },

    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { register, logout } = userSlice.actions;

export default userSlice.reducer;
