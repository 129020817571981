import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Chart from "react-apexcharts";

const Result = () => {
  const [loaders, setLoaders] = useState(true);

  const { id } = useParams();

  const getusers = useSelector((state) => state.user.value);

  const app_status = getusers.app_status;

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const [progress, setProgress] = useState(20);

  const [fullscreen, SetFullScreen] = useState(false);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
  }

  useEffect(() => {
    localStorage.setItem("id", teachid);
  }, [teachid]);

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  axios.defaults.baseURL = id;

  const [folders, setFolders] = useState([]);

  const [Quiz_id, setQuizID] = useState(null);

  const [timer, setTime] = useState(null);

  const [loader, setLoader] = useState(false);

  const [tables, setTable] = useState([]);

  const FetchCBTList = () => {
    axios({
      method: "post",
      url: "./v2/cbt/list/",
      data: {
        teachid,
        q: null,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setFolders(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchCBTList();
    SearchList(null);
  }, [teachid]);

  const renderFolders = () => {
    if (folders == "") {
      return <></>;
    } else if (folders) {
      return folders.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            {task.token === "DELETED" ? (
              <></>
            ) : (
              <>
                <option
                  value={task.title}
                  onClick={() => {
                    FetchTable(task.title);
                    setQuizID(task.title);
                  }}
                >
                  {task.title}
                </option>
              </>
            )}
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const FetchTable = (Quiz_id) => {
    console.log(Quiz_id);

    setLoader(true);

    axios({
      method: "post",
      url: "./v2/cbt/results/",
      data: {
        teachid,
        Quiz_id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setTable(view);

          setLoader(true);

          setTimeout(function () {
            setLoader(false);
          }, 1000);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderTable = () => {
    if (tables == "") {
      return (
        <th colSpan={"6"}>
          <div className="col-12 col-md-12 col-lg-4 offset-lg-4 pt-8 pt-lg-4">
            <div className="text-center">
              <img
                src="../../assets/images/background/wait_.svg"
                className=""
                width="100%"
              />
              <div className="pt-4 fs-t text-purple3">
                No results found...
              </div>
            </div>
          </div>
        </th>
      );
    } else if (tables) {
      return tables.map((table, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{table.name}</td>
              <td>{table.subject}</td>
              <td>{table.class}</td>
              <td>{table.total}</td>
              <td>{table.time} Mins</td>
            </tr>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const Print = () => {
    let printContents = document.getElementById("result_table").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print(); // PRINT THE CONTENTS.

    document.body.innerHTML = originalContents;
  };


  const [menu, setMenu] = useState("Results");

  const [tables_, setTable_] = useState([]);

  const [search, setSearch] = useState([]);

  const SearchList = (studid) => {

    setLoader(true);

    axios({
      method: "post",
      url: "./v2/cbt/result/",
      data: {
        teachid,
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setTable_(view);

          setLoader(true);

          setTimeout(function () {
            setLoader(false);
          }, 1000);

        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderTable_ = () => {
    if (tables_ == "") {
      return (
        <th colSpan={"6"}>
          <div className="col-12 col-md-12 col-lg-4 offset-lg-4 pt-8 pt-lg-4">
            <div className="text-center">
              <img
                src="../../assets/images/background/wait_.svg"
                className=""
                width="100%"
              />
              <div className="pt-4 fs-t text-purple3">
                No results found...
              </div>
            </div>
          </div>
        </th>
      );
    } else if (tables_) {
      return tables_.map((table, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{table.name}</td>
              <td>{table.subject}</td>
              <td>{table.class}</td>
              <td>{table.time} Mins</td>
              <td>
                <button className="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#HandlePreview"
                  style={{ borderRadius: "30px" }}>
                  Open
                </button>
              </td>
            </tr>
          </>
        );
      });
    } else {
      return <></>;
    }
  };


  const config = {
    series: [60, 40, 60],
    options: {
      labels: ['Potiential score', 'No. of questions answered', 'No. of questions unanswered'],
      chart: {
        type: "donut",
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false
      },
      fill: {
        colors: ['#33d9b2', '#fed330', '#706fd3']
      },
      dataLabels: {
        enabled: false,
      },
      cutout: "10%",
      cutoutPercentage: 10,
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        animateScale: true,
        animateRotate: true,
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: "nearest",
        bodySpacing: 5,
        yPadding: 10,
        xPadding: 10,
        caretPadding: 0,
        displayColors: false,
        cornerRadius: 4,
        footerSpacing: 0,
        titleSpacing: 0,
      },
      plugins: {
        legend: {
          display: false,
          show: true
        },
      },
      responsive: [
        {
          series: [45, 72],
          breakpoint: 400,
          options: {
            labels: ['Total Income', 'Your Take Away'],
            chart: {
              width: 130,
              height: 130,
            },
            legend: {
              show: false
            },
          },
        },
      ],
    },
  };


  return (
    <>
      <Auth />
      <div
        id="db-wrapper"
        className={isMobile ? `` : ``}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#474787"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <nav class="navigation navbar-vertical-compact navbar-light bg-white shadow-sm">
            <Aside />
          </nav>

          <div id={`page-content-for-mini`} className="cbt bg-back">
            <div class="container-fluid px-4 px-lg-4">
              <div class="header py-lg-4 py-5 px-0">
                <nav
                  class={headers}
                  style={
                    isMobile
                      ? { background: "#f6f6f6" }
                      : { left: "74px", background: "#f6f6f6" }
                  }
                >
                  <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">
                    <div class="navbar-brand text-start me-3 d-lg-none">
                      <span
                        class="btn btn-purple4 btn-icon text-white rounded-br-lg rounded-bl-sm rounded-t-lg"
                        data-bs-toggle="modal"
                        data-bs-target="#menu"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                      >
                        <i class="bi bi-list fs-3 text-white cursor-pointer"></i>
                      </span>
                    </div>

                    <div className="fs-t text-dark me-auto">
                      <Link to={"../" + id + "/home"}>
                        <i class="bi bi-arrow-left"></i>
                      </Link>{" "}
                      CBT Results
                    </div>
                  </div>

                  <Header_ />
                </nav>
              </div>

              {app_status === "InActive" ? (
                <StyleRoot>
                  <div style={styles.bounce}>
                    <Activate />
                  </div>
                </StyleRoot>
              ) : (
                <>
                  <StyleRoot>
                    <div
                      style={styles.bounce}
                      className="row px-lg-4 py-lg-8 py-6"
                    >

                      <div class="col-lg-12 mb-6">
                        <ul class="nav nav-lb-tab">
                          <li class="nav-item ms-0 me-3 fs-4" onClick={() => setMenu("Results")}>
                            <div class={menu === "Results" ? "nav-link active px-3" : "nav-link px-3"}>
                              Results
                            </div>
                          </li>

                          {teachid === 'SAC0000' || teachid === 'SAC0044' ?
                            <li class="nav-item ms-0 me-3 fs-4" onClick={() => setMenu("Control")}>
                              <div class={menu === "Control" ? "nav-link active px-3" : "nav-link px-3"}>
                                Control Center
                              </div>
                            </li>
                            :
                            <></>
                          }
                        </ul>
                      </div>

                      {menu === "Results" ?
                        <>
                          <div className="col-lg-12">
                            <div className="col-lg-3 col-md-8 col-8">
                              <div class="mb-3 mt-3">
                                <select
                                  class="form-select bg-dark text-white rounded-3 fs-4"
                                  aria-label="Default select example"
                                  onChange={(e) => {
                                    FetchTable(e.target.value);
                                    setQuizID(e.target.value);
                                  }}
                                >
                                  <option selected value={null}>Select CBT Result</option>
                                  {renderFolders()}
                                </select>
                              </div>
                            </div>
                          </div>

                          {loader ? (
                            <div className="col-lg-12 text-center py-lg-10 pt-12">
                              <div
                                class="spinner-border text-danger"
                                style={{ width: "4rem", height: "4rem" }}
                                role="status"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              <div className="mt-4 fs-t text-dark">Loading...</div>
                            </div>
                          ) : (
                            <>
                              <div
                                className="col-lg-12 mt-3 px-lg-4"
                                id="result_table"
                              >
                                <div className="fs-t text-purple3 py-4">
                                  {Quiz_id}
                                </div>
                                {Quiz_id ? (
                                  <>
                                    <div class="table-responsive">
                                      <table class="table fs-4 text-purple3">
                                        <thead>
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Class</th>
                                            <th scope="col">Score</th>
                                            <th scope="col">Time Finished</th>
                                          </tr>
                                        </thead>
                                        <tbody>{renderTable()}</tbody>
                                      </table>
                                    </div>
                                  </>
                                ) : (
                                  <>{renderTable()}</>
                                )}
                              </div>
                              <div className="">
                                {tables == "" ?
                                  <></>
                                  :
                                  <div className="text-end">
                                    <button
                                      className="btn btn-purple2 text-white rounded-3 shadow mt-6"
                                      onClick={() => Print()}
                                    >
                                      Print Out File
                                    </button>
                                  </div>
                                }
                              </div>
                            </>
                          )}
                        </>
                        :
                        <>

                          <div className="col-md-5 text-start mb-6">
                            <div class="input-group input-group-lg has-validation rounded-3 text-dark border border-dark">
                              <input
                                type="search"
                                class="form-control form-control-md"
                                placeholder="Search with Name, and other reference.."
                                defaultValue={search}
                                onKeyUp={(e) => { SearchList(e.target.value); setSearch(e.target.value) }}
                              />
                              <span class="input-group-text" id="inputGroupPrepend">
                                <span className="bi bi-search"></span>
                              </span>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div class="table-responsive">
                              <SimpleBar
                                style={{ maxHeight: 450, padding: "4px" }}
                                forceVisible="y"
                                autoHide={true}
                              >
                                <table class="table fs-4 text-purple3 py-4">
                                  <thead>
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Name</th>
                                      <th scope="col">Subject</th>
                                      <th scope="col">Class</th>
                                      <th scope="col">Time Left</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>

                                    {loader ? (
                                      <th colSpan={"6"}>
                                        <div className="col-lg-12 text-center py-lg-10 pt-12">
                                          <div
                                            class="spinner-border text-danger"
                                            style={{ width: "4rem", height: "4rem" }}
                                            role="status"
                                          >
                                            <span class="visually-hidden">Loading...</span>
                                          </div>
                                          <div className="mt-4 fs-t text-dark">Loading...</div>
                                        </div>
                                      </th>
                                    ) :
                                      <>

                                        {renderTable_()}

                                      </>

                                    }

                                  </tbody>

                                </table>
                              </SimpleBar>
                            </div>
                          </div>
                        </>
                      }


                    </div>
                  </StyleRoot>
                </>
              )}
            </div>

            <div class="modal fade" id="HandlePreview" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true">
              <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-xl modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"}
                role="document">
                <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                  <div class="modal-header border-0">
                    <h3 class="cursor-pointer text-purple3 fs-t font-display2" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                      aria-label="Close"><i class="bi bi-x-lg me-2 fs-3 text-dark"></i>
                      Omolade Paul Progress Status
                    </h3>
                    <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                      <i class="bi bi-arrows-fullscreen text-primary fs-3"></i>
                    </div>
                  </div>
                  <div class="modal-body py-0">

                    <div className="px-lg-4 py-3">

                      <div className="align-items-center">

                        <div class="hstack gap-3">


                          <div className="text-center text-dark">
                            <div className="fs-3 fw-medium text-start">
                              Graphical illustration
                            </div>

                            <Chart
                              options={config.options}
                              series={config.series}
                              type="donut"
                              height={230}
                            />
                          </div>

                          <div class="vr ms-auto"></div>

                          <div className="ms-4 ms-lg-auto text-start">
                            <div className="text-dark">
                              <div className="fs-3 fw-medium">
                                Potiential score
                              </div>

                              <div className="display-5 text-success fw-medium">
                                60 Marks
                              </div>
                            </div>


                            <div className="text-dark">
                              <div className="fs-3 fw-medium">
                                No. of questions answered
                              </div>

                              <div className="display-5 text-warning fw-medium">
                                40%
                              </div>
                            </div>

                            <div className="text-dark">
                              <div className="fs-3 fw-medium">
                                No. of questions unanswered
                              </div>

                              <div className="display-5 text-primary fw-medium">
                                60%
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                      <div className="py-5">

                        <div className="fs-3 fw-medium text-start mb-4">
                          Questions Progress
                        </div>

                        <div class="table-responsive">
                          <SimpleBar
                            style={{ maxHeight: 350, padding: "4px" }}
                            forceVisible="y"
                            autoHide={true}
                          >
                            <table class="table fs-4 text-purple3 py-4">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Question</th>
                                  <th scope="col">Answer</th>
                                  <th scope="col">Mark</th>
                                  <th scope="col">Status</th>
                                </tr>
                              </thead>

                              <tbody>

                                {loader ? (
                                  <th colSpan={"6"}>
                                    <div className="col-lg-12 text-center py-lg-10 pt-12">
                                      <div
                                        class="spinner-border text-success"
                                        style={{ width: "4rem", height: "4rem" }}
                                        role="status"
                                      >
                                        <span class="visually-hidden">Loading...</span>
                                      </div>
                                    </div>
                                  </th>
                                ) :
                                  <>
                                    <tr>
                                      <th scope="row">1</th>
                                      <td>What is 2x + 4 = 12?</td>
                                      <td> x = 4</td>
                                      <td>2 Marks</td>
                                      <td><i class="bi bi-check-lg text-success"></i></td>
                                    </tr>

                                    <tr>
                                      <th scope="row">2</th>
                                      <td>What is 2x + 3 = 11?</td>
                                      <td> x = 4</td>
                                      <td>2 Marks</td>
                                      <td><i class="bi bi-check-lg text-success"></i></td>
                                    </tr>

                                    <tr>
                                      <th scope="row">3</th>
                                      <td>What is 4x + 4 = 12?</td>
                                      <td> x = 2</td>
                                      <td>0 Marks</td>
                                      <td><i class="bi bi-x-lg text-danger"></i></td>
                                    </tr>

                                  </>
                                }

                              </tbody>

                            </table>
                          </SimpleBar>
                        </div>
                      </div>

                      <div className="mt-2 mb-4 text-end">

                        <button className="btn btn-success btn-md fw-medium"
                          style={{ borderRadius: "30px" }}>
                          Fix Score
                        </button>

                        <button className="btn btn-primary btn-md fw-medium ms-4"
                          style={{ borderRadius: "30px" }}>
                          Restart Student
                        </button>


                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>

          <Mobile />

          <ToastContainer />

          <Switcher />
        </>
      </div>
    </>
  );
};

export default Result;
