import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Footer from "../Footer";

const Transfer = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("{}");

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0]);
    }, 1000);

    localStorage.setItem("id", teachid);
  }, [admin_name, teachid]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [report, setReport] = useState([]);

  const [loader, setLoader] = useState(true);

  const FetchReport = (q) => {
    axios({
      method: "post",
      url: "./v2/report/fetchs/",
      data: {
        teachid,
        q,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setReport(view);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport();
    FetchTeach();
    FetchTransfer();
  }, [teachid]);

  const renderFolders = () => {
    if (report == "") {
      return <></>;
    } else if (report != "") {
      return report.map((report, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="d-flex align-items-center py-3 py-lg-3">
              <div className="col-1 col-lg-1">
                <div className="fs-4 text-purple2 fw-medium">{index + 1}</div>
              </div>

              <div className="col-4 col-lg-3 text-start">
                <div className="fs-4 text-purple2 fw-medium">
                  {report.subject}
                </div>
              </div>

              <div className="col-1 col-lg-3 text-start fs-4 text-purple2 ms-n2 ms-lg-0">
                {report.class}
              </div>

              <div className="col-4 col-lg-3 text-start fs-4 text-purple2 ms-2 ms-lg-0">
                {report.teacher}
              </div>

              <div
                className="col-2 col-lg-3 text-start text-lg-start ms-2"
                data-bs-toggle="modal"
                data-bs-target="#view_result"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => {
                  FetchStudents(report.token);
                  setSubject(report.subject);
                }}
              >
                <button className="btn btn-info rounded-pill">
                  <i className="bi bi-arrow-right-circle-fill text-white"></i>
                </button>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [students, setStudents] = useState([]);

  const [tokenid, setToken] = useState(null);

  const [subject, setSubject] = useState(null);

  const FetchStudents = (token) => {
    setToken(token);

    axios({
      method: "post",
      url: "./v2/report/student/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudents(view);

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const Action = (status, studid) => {
    const task = {
      teachid,
      tokenid,
      status,
      studid,
    };

    axios({
      method: "post",
      url: "/v2/report/config/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          FetchStudents(tokenid);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const renderStudents = () => {
    if (students == "") {
      return <></>;
    } else if (students != "") {
      return students.map((stud, index) => {
        //console.log(school.tm_date)
        return (
          <tr className="">
            <td>
              {stud.id !== "" ? (
                <button
                  className="btn btn-dark-success rounded-pill"
                  onClick={() => Action("Added", stud.studid)}
                >
                  Add <span className="bi bi-check-lg"></span>
                </button>
              ) : (
                <button
                  className="btn btn-dark-danger rounded-pill"
                  onClick={() => Action("Remove", stud.studid)}
                >
                  Remove
                </button>
              )}
            </td>
            <th scope="row">{index + 1}</th>
            <td>{stud.fname}</td>
            <td>{stud.studid}</td>
          </tr>
        );
      });
    } else {
      return <></>;
    }
  };

  const [teacher, setTeacher] = useState([{}]);

  const FetchTeach = () => {
    axios({
      method: "post",
      url: "./v2/report/teachers/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setTeacher(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderTeacher = () => {
    if (teacher == "") {
      return <></>;
    } else if (teacher) {
      return teacher.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <option value={task.teachid}>
              {task.fname + " " + task.username}
            </option>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [transfer, setTransfer] = useState([{}]);

  const FetchTransfer = (q) => {
    axios({
      method: "post",
      url: "./v2/report/transfers/",
      data: {
        teachid,
        q,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setTransfer(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderTransfer = () => {
    if (transfer == "") {
      return <></>;
    } else if (transfer != "") {
      return transfer.map((transfer, index) => {
        //console.log(school.tm_date)
        return (
          <tr className="">
            <th scope="row">{index + 1}</th>
            <td>{transfer.subject}</td>
            <td>{transfer.class}</td>
            <td>{transfer.teacher}</td>
            <td>
              {isMobile ? (
                <button
                  className="btn btn-white border-danger rounded-3 text-danger"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                  aria-controls="offcanvasBottom"
                  onClick={() => {
                    setSubject(transfer.subject);
                    setToken(transfer.token);
                  }}
                >
                  Revoke
                </button>
              ) : (
                <button
                  className="btn btn-white border-danger rounded-3 text-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#revoke_result"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  onClick={() => {
                    setSubject(transfer.subject);
                    setToken(transfer.token);
                  }}
                >
                  Revoke
                </button>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return <></>;
    }
  };

  const [teach, setTeach] = useState(null);

  const [status, setStat] = useState(null);

  const Transfer = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      tokenid,
      teach,
    };

    axios({
      method: "post",
      url: "/v2/report/transfer/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Result transferred successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 2000);

          FetchStudents(tokenid);

          FetchTransfer();
          
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const Revoke = () => {
    setStat(true);

    const task = {
      teachid,
      tokenid,
    };

    axios({
      method: "post",
      url: "/v2/report/revoke/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Result was successfully restored!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 2000);

          FetchTransfer();

        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <Header />

      <Sidebar />

      <div class="docs-wrapper bg-back">
        <div class="container-fluid px-lg-10 pt-lg-14 pt-4">
          <div className="d-flex align-items-center">
            <div className="col">
              <div className="fs-t text-purple3">
                Transfer |{" "}
                <span className="text-dark fs-5">
                  <i className="bi bi-bezier2"></i> -- Transfer
                </span>
              </div>
            </div>

            <div className="col-end text-center"></div>
          </div>

          <div className="col-12 mt-6">
            <div
              className="card bg-white shadow-none"
              style={{ borderRadius: "20px" }}
            >
              <div className="card-body px-lg-10">
                <div className="fs-t text-purple3 mb-4 mt-2">
                  List of results uploads
                </div>

                <div className="mb-4">
                  <input
                    className="form-control form-control-lg bg-back rounded-pill"
                    placeholder="Search Lists"
                    onChange={(e) => FetchReport(e.target.value)}
                  />
                </div>

                <>
                  {loader ? (
                    <div className="col-lg-12 text-center py-lg-10 py-20">
                      <div
                        class="spinner-border text-purple3"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>{renderFolders()}</>
                  )}
                </>
              </div>
            </div>
          </div>

          <div className="col-12 mt-10">
            <div
              className="card bg-white shadow-none"
              style={{ borderRadius: "20px" }}
            >
              <div className="card-body px-lg-10">
                <div className="fs-t text-purple3 mb-4 mt-2">
                  List of transferred results
                </div>

                <div className="mb-4">
                  <input
                    className="form-control form-control-lg bg-back rounded-pill"
                    placeholder="Search Lists"
                    onChange={(e) => FetchTransfer(e.target.value)}
                  />
                </div>

                <div className="table-responsive py-0 py-lg-0">
                  <table class="table caption-top fs-4 text-purple2">
                    <thead className="text-purple2">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Class</th>
                        <th scope="col">Transferred To</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <>
                      {loader ? (
                        <tbody>
                          <td
                            rowSpan="5"
                            colSpan="5"
                            className="col-lg-12 text-center py-lg-10 py-20"
                          >
                            <div
                              class="spinner-border text-purple3"
                              style={{ width: "4rem", height: "4rem" }}
                              role="status"
                            >
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </td>
                        </tbody>
                      ) : (
                        <tbody>{renderTransfer()}</tbody>
                      )}
                    </>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div
          class="modal"
          id="view_result"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-lg modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-t text-purple2">{subject}</div>

                <button
                  class="btn btn-dark btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                </button>
              </div>
              <div class="modal-body py-0 px-lg-8">
                <div className="mb-20 mb-lg-6">
                  <div className="table-responsive py-5 py-lg-0">
                    <table class="table caption-top fs-4 text-purple2">
                      <thead className="text-purple2">
                        <tr>
                          <th scope="col">Action</th>
                          <th scope="col">#</th>
                          <th scope="col">Student</th>
                          <th scope="col">Student ID</th>
                        </tr>
                      </thead>
                      <tbody>{renderStudents()}</tbody>
                    </table>
                  </div>

                  <form
                    className={
                      isMobile ? "fixed-bottom bg-white shadow-lg" : ""
                    }
                    style={{
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                    }}
                    onSubmit={Transfer}
                  >
                    <div className="px-5 mt-3">
                      <label
                        class="form-label fs-3 fw-medium text-purple2"
                        for="number"
                      >
                        Choose Teacher To Transfer To;
                      </label>
                      <select
                        class="form-select form-select-lg text-dark"
                        aria-label="Default select example"
                        style={{
                          borderRadius: "24px",
                        }}
                        required
                        onChange={(e) => setTeach(e.target.value)}
                      >
                        <option value="">Select Teacher</option>
                        {renderTeacher()}
                      </select>
                    </div>
                    <div className="d-grid px-5 mb-2 mt-4">
                      <button
                        type="submit"
                        disabled={status ? true : false}
                        className="btn btn-warning text-dark rounded-pill fs-3"
                      >
                        {status ? (
                          <>
                            <span
                              class="spinner-border spinner-border-md mt-1 text-white"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <>Transfer Result</>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile ? (
          <div
            class="offcanvas offcanvas-bottom offcanvas-lg bg-white"
            tabindex="-1"
            id="offcanvasBottom"
            aria-labelledby="offcanvasBottomLabel"
            style={{
              height: "300px",
              borderTopLeftRadius: "40px",
              borderTopRightRadius: "40px",
            }}
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasBottomLabel"></h5>

              <button
                class="btn btn-danger btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
              </button>
            </div>
            <div class="offcanvas-body py-0">
              <div className="">
                <div className="fs-10 fw-medium text-purple3">
                  Revoke Result
                </div>

                <div className="fs-4 text-purple2 mt-3">
                  Click proceed button to reverse transfer of{" "}
                  <span className="fw-bold">{subject}</span> result.
                </div>

                <div className="d-grid mt-5">
                  <button
                    className="btn btn-dark rounded-pill"
                    disabled={status ? true : false}
                    onClick={() => Revoke()}
                  >
                    {status ? (
                      <>
                        <span
                          class="spinner-border spinner-border-md mt-1 text-white"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      <>Proceed</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            class="modal"
            id="revoke_result"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class={
                isMobile
                  ? "modal-dialog modal-dialog-centered modal-fullscreen"
                  : "modal-dialog modal-md modal-dialog-centered"
              }
              role="document"
            >
              <div
                class="modal-content"
                style={
                  isMobile
                    ? { border: "#fff" }
                    : { borderRadius: "35px", border: "#fff" }
                }
              >
                <div class="modal-header border-0 py-4">
                  <div className="fs-t text-purple2"></div>

                  <button
                    class="btn btn-dark btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                  </button>
                </div>
                <div class="modal-body py-0 px-lg-8">
                  <div className="mb-20 mb-lg-6">
                    <div className="fs-10 fw-medium text-purple3">
                      Revoke Result
                    </div>

                    <div className="fs-4 text-purple2 mt-3">
                      Click proceed button to reverse transfer of{" "}
                      <span className="fw-bold">{subject}</span> result.
                    </div>

                    <div className="mt-5">
                      <button
                        className="btn btn-danger rounded-pill me-4"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>

                      <button
                        className="btn btn-dark rounded-pill"
                        disabled={status ? true : false}
                        onClick={() => Revoke()}
                      >
                        {status ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm mt-1 text-white"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <>Proceed</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <ToastContainer />
    </div>
  );
};

export default Transfer;
