import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { isMobile } from "react-device-detect";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

import Auth from "../../Auth";

import Activate from "../Activate";

import Chart from "react-apexcharts";

import Footer from "../Footer";

const Results = () => {
    const { quill, quillRef } = useQuill();

    const navigate = useNavigate();

    const getusers = useSelector((state) => state.user.value);

    const cbt = getusers.cbt;

    const teachid = getusers.teachid;

    const app_status = getusers.app_status;

    const { id } = useParams();

    const { token } = useParams();

    axios.defaults.baseURL = id;

    const [progress, setProgress] = useState(20);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-dark-primary shadow-none py-3";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-dark-primary shadow-none py-3 px-6";
    }

    const [loader, setLoader] = useState(true);

    const [cbt_task, setCBT] = useState([]);

    const [students, setStudents] = useState([]);

    useEffect(() => {
        setTimeout(function () {
            setLoader(false);
        }, 4000);
        FetchStudents();
        FetchCBT();

        setInterval(function () {
            FetchCBT();
          }, 12000);

    }, [token, teachid, cbt_task.title]);

    const FetchCBT = () => {
        axios({
            method: "post",
            url: "./v2/exercise/fetchs/",
            data: {
                token,
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (!view.token) {
                    setTimeout(function () {
                        //navigate("./denied");
                    }, 3000);
                } else if (view.message === "failed") {
                } else {
                    setCBT(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const FetchStudents = () => {
        axios({
            method: "post",
            url: "./v2/exercise/results/",
            data: {
                teachid,
                Quiz_id: cbt_task.title,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setStudents(view);

                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const renderStudents = () => {
        if (students == "") {
            return (
                <div className="col-12">
                    <div className="text-center mb-lg-20">

                        <div className="p-5">
                            <div className="py-4">
                                <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "20%"} alt="" />
                            </div>
                            <div className="text-dark fs-3">
                                No Attempts yet! Try reaching out to your learners!
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (students) {
            return students.slice(0, 10).map((student, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <div className="col-lg-4 me-0 me-lg-6 mb-6 cursor-pointer" data-bs-toggle="modal" data-bs-target="#Open_Result">
                            <div className={(index + 1) % 4 == 0 || index === 0 ? "card bg-light-primary shadow-none"
                                : (index + 1) % 2 == 0 ? "card bg-light-warning shadow-none"
                                    : "card bg-light-info shadow-none"
                            }
                                onClick={() => FetchStudents(student.studid)}
                                style={{ borderRadius: "18px" }}>
                                <div className="card-body">
                                    <div className="col-4 col-lg-3">
                                        <img src="../../assets/images/background/2.png" alt=""
                                            class="img-fluid" />
                                    </div>
                                    <div className="row d-flex fs-t text-dark fw-medium mt-2">
                                        <div className="col-10 text-start">{truncate(student.name, 20)}</div>
                                        <div className="col-2 text-end">
                                            <i class="bi bi-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };


    const [student, setStudent] = useState([]);

    const FetchStudent = (studid) => {
        axios({
            method: "post",
            url: "./v2/exercise/result/",
            data: {
                token,
                studid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setStudent(view);

                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const config = {
        series: [student.total !== "" ? parseInt(student.total) : 0,
        student.total !== "" ? parseInt(100 - student.total) : 100],
        options: {
            labels: ['Pass Mark', 'Fail Mark'],
            chart: {
                type: "donut",
                toolbar: {
                    show: false,
                },
            },
            legend: {
                show: false
            },
            fill: {
                colors: ['#4fe0b6', '#be375f']
            },
            dataLabels: {
                enabled: false,
            },
            cutout: 200,
            cutoutPercentage: "200%",
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                animateScale: true,
                animateRotate: true,
            },
            tooltips: {
                enabled: true,
                intersect: false,
                mode: "nearest",
                bodySpacing: 5,
                yPadding: 10,
                xPadding: 10,
                caretPadding: 0,
                displayColors: false,
                cornerRadius: 4,
                footerSpacing: 0,
                titleSpacing: 0,
            },
            plugins: {
                legend: {
                    display: false,
                    show: true
                },
            },
            responsive: [
                {
                    series: [parseInt(student.total), parseInt(100 - student.total)],
                    breakpoint: 400,
                    options: {
                        labels: ['Pass Mark', 'Fail Mark'],
                        chart: {
                            width: 130,
                            height: 130,
                        },
                        legend: {
                            show: false
                        },
                    },
                },
            ],
        },
    };

    return (
        <>
            <Auth />
            <div
                className={isMobile ? `bg-white` : ``}
                onLoad={() => setProgress(100)}
            >

                <nav class={headers}
                    style={
                        isMobile
                            ? {}
                            : {}
                    }>
                    <div className="container-fluid">
                        <div class="navbar-brand">
                            <span className="fs-t text-white"><i class="bi bi-card-checklist"></i>
                                {" "}
                                {cbt_task.title} Result</span>
                        </div>

                        <div className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                            <Link to={"../" + id + "/exercise"}
                                class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                            >
                                <i class="bi bi-x-lg fw-bold fs-4 text-dark"></i>
                            </Link>
                        </div>

                    </div>
                </nav>

                <div className="">

                    <div class="py-8">

                        {app_status === "InActive" ? (
                            <div class="container-fluid px-4 px-lg-4">
                                <Activate />
                            </div>
                        ) : (
                            <div className="mb-8 mb-lg-3">
                                <div className="container pt-8 pt-md-8 px-lg-6 px-4">
                                    <div className="row g-3">
                                        <div className="col-lg-12 mb-2">
                                            <div className="fs-5 text-dark">
                                                Total Users
                                            </div>
                                            <div className="fs-t fw-bold text-purple3">
                                                {cbt_task.result_num} Attempts
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="fs-3 text-dark mb-3">
                                                Search Reference
                                            </div>
                                            <form class="d-flex align-items-center">
                                                <span class="position-absolute ps-3 search-icon">
                                                    <i class="fe fe-search"></i>
                                                </span>
                                                <input className={isMobile ? "form-control form-control-lg bg-back rounded-pill ps-6" :
                                                    "form-control form-control-lg rounded-pill ps-6"} type="search"
                                                    onKeyUp={(e)=>FetchStudent(e.target.value)}
                                                    placeholder="Search with student name or ID"
                                                    required />
                                            </form>
                                        </div>

                                        <div className="col-lg-12 mt-7">

                                            <SimpleBar forceVisible="y" autoHide={true}>
                                                <div class="d-md-flex d-block align-items-center hoverable">
                                                    {loader ? (
                                                        <div className="col-lg-12 text-center py-lg-10 py-8">
                                                            <div
                                                                class="spinner-border text-success"
                                                                style={{ width: "4rem", height: "4rem" }}
                                                                role="status"
                                                            >
                                                                <span class="visually-hidden">Loading...</span>
                                                            </div>
                                                            <div className="mt-4 fs-3 text-dark">Fetching results..</div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {renderStudents()}
                                                        </>)
                                                    }
                                                </div>
                                            </SimpleBar>

                                        </div>

                                    </div>
                                </div>

                                <div className="">
                                    <Footer />
                                </div>
                            </div>
                        )}

                    </div>

                    <div class="modal" id="Open_Result" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" :
                            "modal-dialog modal-md modal-dialog-centered"}
                            role="document">
                            <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0 py-4">
                                    <div className="fs-t text-purple2">
                                        <i class="bi bi-graph-up-arrow"></i> {student.name} Result {" "}
                                    </div>

                                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                        data-bs-dismiss="modal" aria-label="Close">
                                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                    </button>

                                </div>
                                <div class="modal-body py-0">
                                    <div className="mb-6 mt-6">
                                        <Chart
                                            options={config.options}
                                            series={config.series}
                                            type="donut"
                                            height={250}
                                        />
                                    </div>

                                    <div className="row px-lg-6 mb-6 pt-2 pt-lg-0">
                                        <div className="col-6">
                                            <div className="card bg-back shadow-none"
                                                style={{ borderRadius: "18px" }}>
                                                <div className="card-body text-center">
                                                    <div className="fs-1 text-success">
                                                        {student.total !== "" ? student.total
                                                            : <>0</>}%
                                                    </div>
                                                    <div className="fs-3 text-dark">
                                                        Pass Mark
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="card bg-back shadow-none"
                                                style={{ borderRadius: "18px" }}>
                                                <div className="card-body text-center">
                                                    <div className="fs-1 text-danger">
                                                        {student.total !== "" ? 100 - student.total
                                                            : <>100</>}%
                                                    </div>
                                                    <div className="fs-3 text-dark">
                                                        Fail Mark
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 mt-lg-4 mt-6">
                                            <div className="card bg-pink shadow-none"
                                                style={{ borderRadius: "18px" }}>
                                                <div className="card-body text-start">
                                                    <div className="fs-4 text-white">
                                                        Total Extimated Time
                                                    </div>
                                                    <div className="fs-t text-white">
                                                        Finished Test in 8mins.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <ToastContainer />

            </div>
        </>
    );
};

export default Results;
