import React from 'react'

import { isMobile } from "react-device-detect";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

const Test = ({ folders, loader, FetchCBT }) => {

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const renderFolders = () => {
        if (folders == "") {
            return (
                <>
                    {isMobile ?
                        <div className="">
                            <div className="shadow-none text-center">
                                <div className="p-5">
                                    <div className="">
                                        <img src="../../assets/images/svg/3d-girl-seeting.svg" width="60%" alt="" />
                                    </div>
                                    <div className="text-dark fs-t fw-medium mt-3">
                                        Oops! No Uploads Yet!
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="col-12">
                                <div className="shadow-none text-center">
                                    <div className="p-5">
                                        <div className="">
                                            <img src="../../assets/images/svg/3d-girl-seeting.svg" width="20%" alt="" />
                                        </div>
                                        <div className="text-dark fs-t fw-medium mt-3">
                                            Oops! No Uploads Yet!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            );
        } else if (folders) {
            return folders.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {task.access === 'DELETED' ?
                            <></>
                            :
                            <>
                                {task.type === "TEST" ? (
                                    <>
                                        <div className="col-lg-4 col-12 cursor-pointer me-lg-6 mb-6" data-bs-toggle="modal" data-bs-target="#Open_CBT">
                                            <div className={(index + 1) % 4 == 0 || index === 0 ? "card bg-light-primary shadow-none curve4"
                                                : (index + 1) % 2 == 0 ? "card bg-light-info shadow-none curve1"
                                                    : "card bg-light-warning shadow-none curve4"
                                            } onClick={() => FetchCBT(task.token)}
                                                style={{ borderRadius: "25px" }}>
                                                <div className="card-body">
                                                    <div className="col-4 col-lg-5">
                                                        <img src="../../assets/images/background/10.png" alt=""
                                                            class="img-fluid" />
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-10 fs-t text-dark">
                                                            {truncate(task.title, 15)}
                                                        </div>
                                                        <div className="col-2 fs-t text-dark fw-medium text-end">
                                                            <span className="bi bi-arrow-right"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </>
                        }
                    </>
                );
            });
        } else {
            return (
                <>
                    {isMobile ?
                        <div className="">
                            <div className="shadow-none text-center" style={{ borderRadius: "15px" }}>
                                <div className="p-5">
                                    <div className="">
                                        <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "40%"} alt="" />
                                    </div>
                                    <div className="text-dark fs-t fw-medium">
                                        No Results Found
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="mt-8">
                                <div className="shadow-none">
                                    <div className="">
                                        <div className="text-dark fs-t">
                                            No Results Found....
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            );
        }
    };

    return (
        <div>

            {loader ? (
                <div className="col-lg-12 text-center py-lg-10 py-8">
                    <div
                        class="spinner-border text-purple3"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <SimpleBar forceVisible="y" autoHide={true}>
                    <div class="d-lg-flex align-items-center hoverable mb-lg-6">
                        {renderFolders()}
                    </div>
                </SimpleBar>
            )}

        </div>
    )
}

export default Test