import React from "react";

import { Link, useParams } from "react-router-dom";

const Lock = () => {
  const { id } = useParams();

  return (
    <>
      <div id="db-wrapper" className="">
        <div id={``} className="Lock bg-back">
          <div class="container-fluid px-4 px-lg-4 my-12 my-lg-12">
            <div className="row align-items-center">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="text-center">
                  <img
                    src="../../assets/images/background/unlink.svg"
                    className=""
                    width="80%"
                  />
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="text-center">
                  <div className="display-3 text-dark">
                    Link Broken! Access Denied!
                  </div>

                  <div className="fs-3 text-dark mt-4 mb-4">
                    You have NO access to this page!
                  </div>

                  <Link to={"../" + id + "/home"} className="fs-t text-dark">
                    <span className="bi bi-arrow-left"></span>{" "}
                    <span className="fs-t fw-medium">Go Back</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lock;
