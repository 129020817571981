import React, { useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

import axios from "axios";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

const Homework = ({ FetchCBT, folders_, SearchExerciseList_, loader }) => {

    const getusers = useSelector((state) => state.user.value);

    const homework = getusers.homework;

    const teachid = getusers.teachid;

    const { id } = useParams();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const renderFolders = () => {
        if (folders_ == "") {
            return (
                <>
                    {isMobile ?
                        <div className="">
                            <div className="shadow-none text-center" style={{ borderRadius: "15px" }}>
                                <div className="p-5">
                                    <div className="">
                                        <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "40%"} alt="" />
                                    </div>
                                    <div className="text-dark fs-t fw-medium">
                                        No Results Found
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="col-lg-12 py-8 text-center">
                                <div className="shadow-none">
                                    <div className="">
                                        <div className="text-dark fs-t">
                                            No Results Found....
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            );
        } else if (folders_) {
            return folders_.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {task.type === "HOMEWORK" ? (
                            <div className="col-12 col-lg-6 mb-6 me-3">
                            <div class={index % 2 == 0 ? "container bg-light-primary curve4" : "container bg-light-info curve1"}
                            style={{ borderRadius: "18px" }}>

                                <div class="row align-items-center py-4 py-lg-4 px-lg-4 px-4">

                                    <div class="col-lg-12 col-12">
                                        <div class="text-dark">
                                            <div class="fs-3 text-dark fw-medium"> {task.class} {truncate(task.subject, 15)}: {truncate(task.title, 14)}</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 text-dark">
                                                <p class="mb-0 fs-3 mt-2">Free Space - {10 - task.id} out of 10</p>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div class="progress rounded-pill"
                                                style={{ height: "0.5rem" }}>
                                                <div class="progress-bar bg-dark rounded-pill" role="progressbar" style={task.id === 0
                                                    ? { width: "0%" } : { width: task.id / 10 * 100 }}
                                                    aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-12">
                                        {isMobile ?
                                            <button class="btn btn-white mt-4 rounded-pill"
                                                data-bs-toggle="modal" data-bs-target="#HandleOpen"
                                                onClick={() => FetchCBT(task.token)}>Open Exercise <span className="bi bi-arrow-right"></span></button>
                                            :
                                            <button class="btn btn-white mt-4 rounded-pill"
                                                onClick={() => FetchCBT(task.token)}>Open Exercise <span className="bi bi-arrow-right"></span></button>}
                                    </div>

                                </div>
                            </div>
                        </div>
                        ) : (
                            <>
                            </>
                        )}
                    </>
                );
            });
        } else {
            return (
                <>
                    {isMobile ?
                        <div className="">
                            <div className="shadow-none text-center" style={{ borderRadius: "15px" }}>
                                <div className="p-5">
                                    <div className="">
                                        <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "40%"} alt="" />
                                    </div>
                                    <div className="text-dark fs-t fw-medium">
                                        No Results Found
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="col-lg-12 py-8 text-center">
                                <div className="shadow-none">
                                    <div className="">
                                        <div className="text-dark fs-t">
                                            No Results Found....
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </>
            );
        }
    };

    const [search, setSearch] = useState(null);

    const [Quiz_id, setQuizID] = useState(null);

    const [tables, setTable] = useState([]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    return (
        <>

            <div className="row pt-lg-10 pt-8 pb-6 pb-lg-6 px-2 px-md-3">
                <div className="col-lg-8">
                    <form class="d-flex align-items-center">
                        <span class="position-absolute ps-3 search-icon">
                            <i class="fe fe-search"></i>
                        </span>
                        <input type="search"
                            onKeyUp={(e) => SearchExerciseList_(e.target.value)}
                            style={{ borderRadius: "24px" }}
                            class={isMobile ? "form-control form-control-lg py-3 ps-6 fs-3 bg-back rounded-pill text-dark" :
                                "form-control form-control-lg ps-6 fs-3 bg-white rounded-pill text-dark"} placeholder="Search Exercise" />
                    </form>
                </div>


                <div className="col-md-4">
                    <div className="text-end pt-lg-0 pt-4">
                        <Link to={"../" + id + "/new/Homework"} className="btn btn-dark btn-md shadow-none rounded-3 mt-1"
                        >
                            <i class="bi bi-plus-lg cursor-pointer text-success"></i> Create Homework
                        </Link>
                    </div>
                </div>
            </div>



            <div className="row pb-lg-20 pt-3 pt-lg-5">

                {loader ? (
                    <div className="col-lg-12 text-center py-lg-10 py-8">
                        <div
                            class="spinner-border text-purple3"
                            style={{ width: "4rem", height: "4rem" }}
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <SimpleBar forceVisible="y" autoHide={true}>
                        <div class="d-lg-flex align-items-center hoverable">
                            {renderFolders()}
                        </div>
                    </SimpleBar>
                )}


            </div>

            <ToastContainer />


        </>
    )
}

export default Homework