import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from "react-share";

const Profile = () => {

    const [loaders, setLoaders] = useState(true);

    const navigate = useNavigate();

    const { id } = useParams();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const teachid = getusers.teachid;

    const phone = getusers.phone;

    const email = getusers.email;

    const school_name = getusers.school_name;

    const [progress, setProgress] = useState(20);

    const [fullscreen, setFullscreen] = useState(false);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-2";
    }

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    useEffect(() => {
        localStorage.setItem("id", teachid);

        setName(admin_name);

        setPhone(phone);

        setEmail(email)

    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const [status, setStat] = useState(false);

    const [action, setAct] = useState('Updating...');

    const [name, setName] = useState(null);

    const [phone_, setPhone] = useState(null);

    const [email_, setEmail] = useState(null);

    const [address, setAdd] = useState(null);

    const [pass1, setPass1] = useState(null);

    const [pass2, setPass2] = useState(null);


    const onSubmit = (e) => {

        e.preventDefault();

        setStat(true);

        const task = {
            username: name,
            phone: phone_,
            email: email_,
            address,
            teachid,
        };

        axios({
            method: "post",
            url: "/v2/settings/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: Profile was updated successfully!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    setAct("Updated")

                    setTimeout(function () {
                        setStat(false);
                    }, 3000);

                    localStorage.setItem("token", response.data.token);
                } else {
                    toast.error("Failed: Request Failed! Please try again..", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    }

    const onSubmit_ = (e) => {

        e.preventDefault();

        setStat(true);

        const task = {
            pass1,
            teachid,
        };

        if (pass1 !== pass2) {

            toast.error(
                "Error: Password do not match!",
                {
                    hideProgressBar: true,
                    draggable: true,
                    position: "top-right",
                    icon: true,
                    autoClose: 6000,
                }
            );

            setTimeout(function () {
                setStat(false);
            }, 2000);

        } else {

            axios({
                method: "post",
                url: "/v2/settings/",
                data: task,
                config: { headers: { "Content-Type": "multiport/form-data" } },
            })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.message === "success") {
                        toast.success(
                            "Success: Password changed successfully!",
                            {
                                hideProgressBar: true,
                                draggable: true,
                                position: "top-right",
                                icon: true,
                                autoClose: 6000,
                            }
                        );

                        setAct("Updated")

                        setTimeout(function () {
                            setStat(false);
                        }, 3000);

                        localStorage.setItem("token", response.data.token);
                    } else {
                        toast.error("Failed: Request Failed! Please try again..", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        setTimeout(function () {
                            setStat(false);
                        }, 3000);
                    }
                })
                .catch((err) => {
                    toast.error("Error: Request Failed! Check network connection.");
                });

        }
    }


    const [menu, setMenu] = useState('Account');


    return (
        <>
            <Auth />
            <div
                id="db-wrapper"
                className={isMobile ? `bg-white` : `bg-back`}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#ff793f"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <div class="">
                    <nav
                        class={headers}
                        style={
                            isMobile
                                ? {}
                                : {}
                        }
                    >
                        <div className={isMobile ? "container" : "container-fluid"}>

                            <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">

                                <div class="navbar-brand ms-0">
                                    <div className="fs-10 text-purple2 mt-1 mt-lg-0 ">{school_name}</div>
                                </div>

                            </div>

                            <div class="ms-auto d-flex d-lg-none d-md-none align-items-center">
                                <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2" type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#menu"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false">
                                    <i class="bi bi-list fw-bold fs-10 text-purple2"></i>
                                </button>
                            </div>

                        </div>

                    </nav>
                </div>

                <>
                    <nav class="navigation navbar-vertical-compact navbar-light bg-primary shadow-none">
                        <Aside />
                    </nav>

                    <div id={`page-content-for-mini`} className="">

                        <div class="container py-8">

                            {app_status === "InActive" ? (
                                <StyleRoot>
                                    <div class="px-4 px-lg-4" style={styles.bounce}>
                                        <Activate />
                                    </div>
                                </StyleRoot>
                            ) : (
                                <>
                                    <StyleRoot>
                                        <div className="row g-6 pt-lg-6 pt-6 pb-lg-8">

                                            <div className="col-lg-6 px-lg-8 pb-lg-8 pb-8 px-6">
                                                <ul class="nav nav-lb-tab">
                                                    <li class="nav-item ms-0 me-3 fs-4 cursor-pointer"
                                                        onClick={() => setMenu('Account')}>
                                                        <div class={menu === 'Account' ? "nav-link active px-3"
                                                            : "nav-link px-3"}>
                                                            Account Profile
                                                        </div>
                                                    </li>

                                                    <li class="nav-item ms-0 me-3 fs-4 cursor-pointer"
                                                        onClick={() => setMenu('Password')}>
                                                        <div class={menu === 'Password' ? "nav-link active px-3"
                                                            : "nav-link px-3"}>
                                                            Authentication settings
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            {menu === 'Account' ?
                                                <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
                                                    <div className="card bg-white shadow-none curve3" style={{ borderRadius: "25px" }}>
                                                        <div className="text-center mt-n6">
                                                            <img src="../assets/images/avatar/avatar-3.jpg"
                                                                class="avatar-xl rounded-circle border border-4 border-white" alt="avatar" />
                                                        </div>

                                                        <div className="mt-4 mb-4">
                                                            <form className="row g-4 g-lg-4 px-lg-8 px-5 pb-5" onSubmit={onSubmit}>
                                                                <div className="col-12">
                                                                    <div className="fs-4 text-dark mt-3 mt-lg-1">
                                                                        You have full control to manage your own account setting. Manage your account
                                                                        by editing the details below.
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Teacher's Name</div>
                                                                    <input type="text" id="name" class="form-control form-control-lg bg-back text-dark"
                                                                        required placeholder="Type your name...."
                                                                        value={name}
                                                                        onChange={(e) => setName(e.target.value)}
                                                                        style={{ borderRadius: "24px" }} />
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Teacher's ID</div>
                                                                    <input type="text" id="title" class="form-control form-control-lg bg-primary text-white"
                                                                        value={teachid}
                                                                        style={{ borderRadius: "24px" }} disabled />
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Phone no.</div>
                                                                    <input type="text" id="phone" class="form-control form-control-lg bg-back text-dark"
                                                                        required placeholder="Type your phone number...."
                                                                        value={phone_}
                                                                        onChange={(e) => setPhone(e.target.value)}
                                                                        style={{ borderRadius: "24px" }} />
                                                                    <span className="text-primary">
                                                                        Must start with 234 (8xxx - xxxx - xxx).
                                                                    </span>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Email</div>
                                                                    <input type="text" id="phone" class="form-control form-control-lg bg-back text-dark"
                                                                        required placeholder="Type your email address...."
                                                                        value={email_}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                        style={{ borderRadius: "24px" }} />
                                                                    <span className="text-primary">
                                                                        Must end with @gmail, @yahoo, etc.
                                                                    </span>
                                                                </div>

                                                                <div className="col-lg-12">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Home Address</div>
                                                                    <input type="text" id="address" class="form-control form-control-lg bg-back text-dark"
                                                                        required placeholder="Type your home address...."
                                                                        onChange={(e) => setAdd(e.target.value)}
                                                                        style={{ borderRadius: "24px" }} />
                                                                    <span className="text-dark">
                                                                        You data is safe with us.
                                                                    </span>
                                                                </div>

                                                                <div className="col-lg-12 text-end mt-7 mt-lg-5">
                                                                    <button
                                                                        type="submit"
                                                                        class="btn btn-lg btn-success text-white rounded-pill"
                                                                        disabled={status ? true : false}
                                                                    >
                                                                        {status ? (
                                                                            <>
                                                                                {action}
                                                                                <span
                                                                                    class="spinner-border spinner-border-sm ms-3"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                ></span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Save Changes <i class="bi bi-arrow-right ms-1"></i>
                                                                            </>
                                                                        )}
                                                                    </button>
                                                                </div>

                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                <div className="col-12 col-md-12 col-lg-8 offset-lg-2">
                                                    <div className="card bg-white shadow-none curve3" style={{ borderRadius: "25px" }}>
                                                        <div className="text-center mt-n6">
                                                            <button className="avatar-xl rounded-circle border border-4 border-white 
                                                            bg-light-primary">
                                                                <i class="bi bi-shield-fill-check fs-t2 text-purple3"></i>
                                                            </button>
                                                        </div>

                                                        <div className="mt-4 mb-4">
                                                            <form className="row g-4 g-lg-4 px-lg-8 px-5 pb-5" onSubmit={onSubmit_}>
                                                                <div className="col-12">
                                                                    <div className="fs-4 text-dark mt-3 mt-lg-1">
                                                                        Make changes to your authorised password. Note that password are
                                                                        case sensitive. Our customer representative is available for any
                                                                        questions you have.
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-12 mb-3">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Teacher's ID</div>
                                                                    <input type="text" id="title" class="form-control form-control-lg bg-primary text-white"
                                                                        value={teachid}
                                                                        style={{ borderRadius: "24px" }} disabled />
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">New Password</div>
                                                                    <input type="password" id="phone" class="form-control form-control-lg bg-back text-dark"
                                                                        required placeholder="Type your new password.."
                                                                        onChange={(e) => setPass1(e.target.value)}
                                                                        style={{ borderRadius: "24px" }} />
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div class="fs-3 text-dark mb-2" for="textInput">Confirm Password</div>
                                                                    <input type="password" id="phone" class="form-control form-control-lg bg-back text-dark"
                                                                        required placeholder="Confirm your new password.."
                                                                        onChange={(e) => setPass2(e.target.value)}
                                                                        style={{ borderRadius: "24px" }} />
                                                                </div>

                                                                <div className="col-lg-12 text-end mt-7 mt-lg-5">
                                                                    <button
                                                                        type="submit"
                                                                        class="btn btn-lg btn-success text-white rounded-pill"
                                                                        disabled={status ? true : false}
                                                                    >
                                                                        {status ? (
                                                                            <>
                                                                                {action}
                                                                                <span
                                                                                    class="spinner-border spinner-border-sm ms-3"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                ></span>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                Save Password <i class="bi bi-arrow-right ms-1"></i>
                                                                            </>
                                                                        )}
                                                                    </button>
                                                                </div>

                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </StyleRoot>
                                </>
                            )}

                            <Footer />

                        </div>

                    </div>

                    <Mobile />

                    <ToastContainer />
                </>
            </div>
        </>
    );
};

export default Profile;
