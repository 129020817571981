import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import Classwork from "./Classwork";

import Homework from "./Homework";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";

const Exercise = () => {
  const [loaders, setLoaders] = useState(true);

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const getusers = useSelector((state) => state.user.value);

  const app_status = getusers.app_status;

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const school_name = getusers.school_name;

  const [progress, setProgress] = useState(20);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers =
      "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-2";
  }

  useEffect(() => {
    localStorage.setItem("id", teachid);
  }, [teachid]);

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  const [toggle, setToggle] = useState("Classwork");

  const [typing, setTyping] = useState(false);

  const [trash, setTrash] = useState(false);

  const [share, setShare] = useState(false);

  const [result, setResult] = useState(false);

  const [cbt_task, setCBT] = useState([]);

  const FetchCBT = (token) => {
    axios({
      method: "post",
      url: "./v2/exercise/fetchs/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (!view.token) {
          setTimeout(function () {
            //navigate("./denied");
          }, 3000);
        } else if (view.message === "failed") {
        } else {
          setCBT(view);

          setTyping(true);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 4000);
    SearchExerciseList(null);
    SearchExerciseList_(null);
  }, [teachid]);

  const [loader, setLoader] = useState(true);

  const [folders, setFolders] = useState([]);

  const [folders_, setFolders_] = useState([]);

  const SearchExerciseList = (search) => {
    axios({
      method: "post",
      url: "./v2/exercise/list/",
      data: {
        teachid,
        q: search,
        task: "CLASSWORK",
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setFolders(view);

          setLoader(true);

          setTimeout(function () {
            setLoader(false);
          }, 1000);
        } else {
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const SearchExerciseList_ = (search) => {
    axios({
      method: "post",
      url: "./v2/exercise/list/",
      data: {
        teachid,
        q: search,
        task: "HOMEWORK",
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setFolders_(view);

          setLoader(true);

          setTimeout(function () {
            setLoader(false);
          }, 1000);
        } else {
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const Delete = (token) => {
    axios({
      method: "post",
      url: "/v2/exercise/delete/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Exercise task was deleted!", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });

          SearchExerciseList(null);
        } else {
          toast.error("Failed: Request Failed! Please try again", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [shortenedLink, setShortenedLink] = useState("");

  const longUrl = "https://app.smartedung.com/#/"+id+"/work/"+cbt_task.token;

 
  const fetchData = () => {
    axios
      .get(`https://api.shrtco.de/v2/shorten?url=${longUrl}`)
      .then((responseData) => {
        console.log(responseData);

        //setShortenedLink(response.data.result.full_short_link);
      })
      .catch((err) => {
       // toast.error("Error: Request Failed! Check network connection.");

        console.log(err);
      });

      setShortenedLink(longUrl);
  };

  const text =
    cbt_task.title +
    "\n\n" +
    cbt_task.subject +
    " " +
    cbt_task.type +
    ". Click the link below to access the exercise; \n\n" +
    shortenedLink +
    "\n\n Using your student ID and the password for the exercise is; *" +
    cbt_task.password +
    "*";

  const [copied, setCopied] = useState(false);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

  return (
    <>
      <Auth />
      <div
        id="db-wrapper"
        className={isMobile ? `bg-white` : `bg-back`}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#ff793f"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <nav class="navigation navbar-vertical-compact navbar-light bg-success shadow-none">
            <Aside />
          </nav>

          <div class="">
            <nav class={headers} style={isMobile ? {} : {}}>
              <div className={isMobile ? "container" : "container-fluid"}>
                <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">
                  <div class="navbar-brand ms-0">
                    <div className="fs-10 text-purple3 mt-1 mt-lg-0">
                      {school_name}
                    </div>
                  </div>
                </div>

                <div class="ms-auto d-flex d-lg-none d-md-none align-items-center">
                  <button
                    class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#menu"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                  >
                    <i class="bi bi-list fw-bold fs-10 text-purple2"></i>
                  </button>
                </div>

                {isMobile ? <></> : <Header_ />}
              </div>
            </nav>
          </div>

          <div id={`page-content-for-mini`} className="">
            <div class="container py-8">
              {app_status === "InActive" ? (
                <StyleRoot>
                  <div class="px-4 px-lg-4" style={styles.bounce}>
                    <Activate />
                  </div>
                </StyleRoot>
              ) : (
                <>
                  <StyleRoot>
                    <div class="px-3 px-lg-4 mb-lg-0">
                      <div className="row pt-md-8 pt-5 g-4">
                        <div className="col-lg-8 mt-4 mb-0 mb-lg-0 me-lg-4">
                          <div className="row pt-6 pt-lg-3 px-2">
                            <div className="col-lg-8 col-6">
                              <div className="fs-t text-purple2">Exercise</div>
                            </div>

                            <div className="col-lg-2 col-3 mt-2">
                              <button
                                className={
                                  toggle === "Classwork"
                                    ? "btn btn-success btn-sm rounded-pill"
                                    : isMobile
                                    ? "btn btn-light btn-sm rounded-pill"
                                    : "btn btn-white btn-sm rounded-pill"
                                }
                                onClick={() => setToggle("Classwork")}
                              >
                                Classwork
                              </button>
                            </div>

                            <div className="col-lg-2 col-3 mt-2">
                              <button
                                className={
                                  toggle === "Homework"
                                    ? "btn btn-success btn-sm rounded-pill"
                                    : isMobile
                                    ? "btn btn-light btn-sm rounded-pill"
                                    : "btn btn-white btn-sm rounded-pill"
                                }
                                onClick={() => setToggle("Homework")}
                              >
                                Homework
                              </button>
                            </div>
                          </div>

                          {toggle === "Classwork" ? (
                            <Classwork
                              FetchCBT={FetchCBT}
                              folders={folders}
                              SearchExerciseList={SearchExerciseList}
                              loader={loader}
                            />
                          ) : (
                            <Homework
                              FetchCBT={FetchCBT}
                              folders_={folders_}
                              SearchExerciseList_={SearchExerciseList_}
                              loader={loader}
                            />
                          )}
                        </div>

                        {isMobile ? (
                          <></>
                        ) : (
                          <div className="col-lg-3">
                            <div className="card position-fixed bg-white mt-lg-n8 shadow-none p-lg-4">
                              <div className="mb-4 py-3">
                                {typing ? (
                                  <div className="mt-0 d-grid">
                                    <div className="fs-t text-purple2 mb-3">
                                      <i class="bi bi-grid-1x2"></i> Task View{" "}
                                      {cbt_task.task_type ? (
                                        <span className="bg-light-success py-2 px-2 text-dark rounded-pill fs-5">
                                          {cbt_task.task_type === "Multi" ? (
                                            <>Multi Choice</>
                                          ) : (
                                            <>Open End</>
                                          )}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </div>

                                    <div className="row pt-5 px-2">
                                      <div className="col-7 fs-3 text-purple2 mb-3 rounded-pill bg-back py-2">
                                        <div className="px-4">
                                          {" "}
                                          Subject: {cbt_task.subject}{" "}
                                        </div>
                                      </div>

                                      <div className="col-5 fs-3 text-purple2 mb-3 rounded-pill bg-back py-2">
                                        <div className="px-4">
                                          {" "}
                                          Class: {cbt_task.class}{" "}
                                        </div>
                                      </div>

                                      <div className="fs-3 text-purple2 mb-3 rounded-pill bg-back py-3">
                                        <div className="px-4">
                                          {" "}
                                          Title: {cbt_task.title}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row py-4 g-2">
                                      <div
                                        className="col-lg-4 cursor-pointer"
                                        data-bs-toggle="modal"
                                        data-bs-target="#HandleDelete"
                                      >
                                        <div
                                          className={
                                            trash
                                              ? "card shadow-none bg-light-danger text-danger"
                                              : "card shadow-none bg-back text-dark"
                                          }
                                          onMouseOver={() => setTrash(true)}
                                          onMouseLeave={() => setTrash(false)}
                                          style={{ borderRadius: "18px" }}
                                        >
                                          <div className="card-body text-center">
                                            <div className="fs-2">
                                              <i class="bi bi-trash3 text-danger"></i>
                                            </div>
                                            <div className="fs-3">Delete</div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="col-lg-4 cursor-pointer"
                                        data-bs-toggle="modal"
                                        data-bs-target="#HandleShare"
                                        onClick={() => fetchData()}
                                      >
                                        <div
                                          className={
                                            share
                                              ? "card shadow-none bg-light-success text-success"
                                              : "card shadow-none bg-back text-dark"
                                          }
                                          onMouseOver={() => setShare(true)}
                                          onMouseLeave={() => setShare(false)}
                                          style={{ borderRadius: "18px" }}
                                        >
                                          <div className="card-body text-center">
                                            <div className="fs-2">
                                              <i class="bi bi-share text-success"></i>
                                            </div>
                                            <div className="fs-3">Share</div>
                                          </div>
                                        </div>
                                      </div>

                                      <Link
                                        to={
                                          "../" +
                                          id +
                                          "/exercise/" +
                                          cbt_task.token +
                                          "/result"
                                        }
                                        target="_blank"
                                        className="col-lg-4 cursor-pointer"
                                      >
                                        <div
                                          className={
                                            result
                                              ? "card shadow-none bg-light-primary text-primary"
                                              : "card shadow-none bg-back text-dark"
                                          }
                                          onMouseOver={() => setResult(true)}
                                          onMouseLeave={() => setResult(false)}
                                          style={{ borderRadius: "18px" }}
                                        >
                                          <div className="card-body text-center">
                                            <div className="fs-2">
                                              <i class="bi bi-card-checklist text-primary"></i>
                                            </div>
                                            <div className="fs-3">Result</div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>

                                    <div className="d-grid">
                                      <Link
                                        to={
                                          cbt_task.task_type
                                            ? "../" +
                                              id +
                                              "/exercise/" +
                                              cbt_task.token +
                                              "/preview"
                                            : "../" +
                                              id +
                                              "/exercise/" +
                                              cbt_task.token +
                                              "/start"
                                        }
                                        className="btn btn-dark-danger btn-lg rounded-pill fs-3 px-4 fw-medium text-white"
                                      >
                                        Open Exercise Workspace
                                      </Link>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="pt-0">
                                    <div className="fs-t text-purple2 mb-3">
                                      <i class="bi bi-grid-1x2"></i> Task View
                                    </div>

                                    <div className="text-center pt-6">
                                      <div className="p-2">
                                        <div className="py-3">
                                          <img
                                            src="../../assets/images/svg/3d-girl-seeting.svg"
                                            width={isMobile ? "80%" : "60%"}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="d-grid pt-6">
                                      <div className="col-12">
                                        <div class="container bg-light-warning rounded-3">
                                          <div class="row align-items-center py-3 py-lg-4 px-3 px-lg-3">
                                            <div class="text-dark">
                                              <div class="mb-0 fs-4">
                                                Sharing of exercise has beeen
                                                made simple and easy now! Click
                                                on "Open Exercise" button from
                                                the exercise tiles to get
                                                started!
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        <div class="col-lg-12 col-12 mt-lg-n16 mt-8 mb-4 px-4">
                          <div class="text-dark">
                            <h2 class="h3 text-dark">Need Help?</h2>
                            <p class="mb-0 fs-4">
                              Do you want to know how to use the App more
                              better? Don't worry we got you covered.
                            </p>
                            <a class="btn btn-primary rounded-3 mt-3">
                              Help me?
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </StyleRoot>
                </>
              )}

              <Footer />
            </div>

            <div
              class="modal"
              id="HandleOpen"
              tabindex="-1"
              role="dialog"
              data-bs-backdrop="static"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                class={
                  isMobile
                    ? "modal-dialog modal-dialog-centered modal-fullscreen"
                    : "modal-dialog modal-lg modal-dialog-centered"
                }
                role="document"
              >
                <div
                  class="modal-content"
                  style={
                    isMobile
                      ? { border: "#fff" }
                      : { borderRadius: "35px", border: "#fff" }
                  }
                >
                  <div class="modal-header border-0 py-4">
                    <div className="fs-t text-purple2 mb-3">
                      <i class="bi bi-grid-1x2"></i> Task View{" "}
                      {cbt_task.task_type ? (
                        <span className="bg-light-success py-2 px-3 text-dark rounded-pill fs-4">
                          {cbt_task.task_type === "Multi" ? (
                            <>Multi Choice</>
                          ) : (
                            <>Open End</>
                          )}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>

                    <button
                      class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                    </button>
                  </div>
                  <div class="modal-body py-0">
                    <div className="mb-4 py-3">
                      {typing ? (
                        <div className="mt-0 d-grid">
                          <div className="row pt-6 px-2">
                            <div className="col-7 fs-t text-purple2 mb-5 rounded-pill bg-back py-2">
                              <div className="px-4">
                                {" "}
                                Subject: {cbt_task.subject}{" "}
                              </div>
                            </div>

                            <div className="col-5 fs-t text-purple2 mb-5 rounded-pill bg-back py-2">
                              <div className="px-4">
                                {" "}
                                Class: {cbt_task.class}{" "}
                              </div>
                            </div>

                            <div className="fs-t text-purple2 mb-5 rounded-pill bg-back py-3">
                              <div className="px-4">
                                {" "}
                                Title: {cbt_task.title}
                              </div>
                            </div>
                          </div>

                          <div className="row py-5 mb-8">
                            <div
                              className="col-6 cursor-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#HandleDelete"
                            >
                              <div
                                className={
                                  trash
                                    ? "card shadow-none bg-light-danger text-danger"
                                    : "card shadow-none bg-back text-danger"
                                }
                                onMouseOver={() => setTrash(true)}
                                onMouseLeave={() => setTrash(false)}
                                style={{ borderRadius: "18px" }}
                              >
                                <div className="card-body text-center">
                                  <div className="fs-1">
                                    <i class="bi bi-trash3"></i>
                                  </div>
                                  <div className="fs-3 fw-medium">Delete</div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-6 cursor-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#HandleShare"
                              onClick={() => fetchData()}
                            >
                              <div
                                className="card shadow-none bg-back"
                                style={{ borderRadius: "18px" }}
                              >
                                <div className="card-body text-center">
                                  <div className="fs-1">
                                    <i class="bi bi-share text-success"></i>
                                  </div>
                                  <div className="fs-3 text-dark fw-medium">
                                    Share
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-6 mt-4 cursor-pointer"
                              data-bs-dismiss="modal"
                            >
                              <Link
                                to={
                                  "../" +
                                  id +
                                  "/exercise/" +
                                  cbt_task.token +
                                  "/result"
                                }
                              >
                                <div
                                  className={
                                    result
                                      ? "card shadow-none bg-light-primary text-primary"
                                      : "card shadow-none bg-back text-dark"
                                  }
                                  onMouseOver={() => setResult(true)}
                                  onMouseLeave={() => setResult(false)}
                                  style={{ borderRadius: "18px" }}
                                >
                                  <div className="card-body text-center">
                                    <div className="fs-1">
                                      <i class="bi bi-card-checklist text-primary"></i>
                                    </div>
                                    <div className="fs-3">Result</div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>

                          <div
                            className="card d-grid fixed-bottom py-4 px-3"
                            data-bs-dismiss="modal"
                          >
                            <Link
                              to={
                                cbt_task.task_type
                                  ? "../" +
                                    id +
                                    "/exercise/" +
                                    cbt_task.token +
                                    "/preview"
                                  : "../" +
                                    id +
                                    "/exercise/" +
                                    cbt_task.token +
                                    "/start"
                              }
                              className="btn btn-dark-danger btn-lg rounded-pill fs-3 px-4 py-3"
                            >
                              Open Exercise Workspace
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="pt-0">
                          <div className="fs-t text-purple2 mb-3">
                            <i class="bi bi-grid-1x2"></i> Task View
                          </div>

                          <div className="text-center pt-6">
                            <div className="p-2">
                              <div className="py-3">
                                <img
                                  src="../../assets/images/svg/3d-girl-seeting.svg"
                                  width={isMobile ? "80%" : "60%"}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>

                          <div className="d-grid pt-6">
                            <div className="col-12">
                              <div class="container bg-light-info rounded-3">
                                <div class="row align-items-center py-3 py-lg-4 px-3 px-lg-3">
                                  <div class="text-dark">
                                    <div class="mb-0 fs-4">
                                      Sharing of exercise has beeen made simple
                                      and easy now! Click on "Open Exercise"
                                      button from the exercise tiles to get
                                      started!
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              id="HandleDelete"
              tabindex="-1"
              role="dialog"
              data-bs-backdrop="static"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                class={
                  isMobile
                    ? "modal-dialog modal-dialog-centered modal-fullscreen"
                    : "modal-dialog modal-md modal-dialog-centered"
                }
                role="document"
              >
                <div
                  class="modal-content curve1"
                  style={
                    isMobile
                      ? { border: "#fff" }
                      : { borderRadius: "35px", border: "#fff" }
                  }
                >
                  <div class="modal-header border-0 py-4">
                    <div className="fs-t text-purple2">
                      <i class="bi bi-trash"></i> Delete Task{" "}
                    </div>

                    <button
                      class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                    </button>
                  </div>
                  <div class="modal-body py-0">
                    <div className="col-12 mb-0 mb-lg-6 mt-20 mt-lg-0">
                      <div className="mt-2 text-center">
                        <div className="fs-3 text-purple2 mb-6 mb-lg-3">
                          Are you sure you want to delete?
                        </div>

                        <div className="px-lg-6">
                          <div className="col-12 fs-t text-purple2 rounded-pill bg-back mt-4 py-3 py-lg-2 mb-7 mb-lg-6">
                            <div className="fs-t text-purple3">
                              {cbt_task.title}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 text-center">
                        <div className="">
                          <div className="">
                            <button
                              className="btn btn-light-danger rounded-pill btn-lg"
                              onClick={() => Delete(cbt_task.token)}
                            >
                              Delete
                            </button>

                            <button
                              type="button"
                              className="btn btn-light-primary rounded-pill ms-4 btn-lg"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal"
              id="HandleShare"
              tabindex="-1"
              role="dialog"
              data-bs-backdrop="static"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                class={
                  isMobile
                    ? "modal-dialog modal-dialog-centered modal-fullscreen"
                    : "modal-dialog modal-md modal-dialog-centered"
                }
                role="document"
              >
                <div
                  class="modal-content curve1"
                  style={
                    isMobile
                      ? { border: "#fff" }
                      : { borderRadius: "35px", border: "#fff" }
                  }
                >
                  <div class="modal-header border-0 py-4">
                    <div className="fs-t text-purple2">
                      <i class="bi bi-share"></i> Share Task{" "}
                    </div>

                    <button
                      class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                    </button>
                  </div>
                  <div class="modal-body py-0">
                    <div className="px-lg-6 mb-lg-6 pt-4 pt-lg-2 px-2">
                      <div className="fs-3 text-dark pb-4 pt-4 pt-lg-2 pb-lg-2">
                        Copy Link
                      </div>

                      <div className="col-12 fs-t text-purple2 rounded-pill bg-dark mt-4 py-2">
                        <div className="d-flex px-4 row align-items-center">
                          <span className="text-white fs-4 col-8">
                            {truncate(shortenedLink, 25)}
                          </span>
                          <CopyToClipboard
                            text={shortenedLink}
                            onCopy={() => {
                              toast.success(
                                "Success: Link copied successfully!",
                                {
                                  hideProgressBar: true,
                                  draggable: true,
                                  position: "bottom-right",
                                  icon: true,
                                  autoClose: 3000,
                                }
                              );
                              setCopied(true);
                              setTimeout(function () {
                                setCopied(false);
                              }, 4000);
                            }}
                          >
                            <button className="btn btn-success rounded-pill col-4">
                              {copied ? (
                                <>
                                  <i class="bi bi-clipboard-check"></i> Copied
                                </>
                              ) : (
                                <>
                                  <i class="bi bi-clipboard"></i> Copy
                                </>
                              )}
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>

                      <div className="mb-lg-5 mt-5 mt-lg-4">
                        <div className="fs-4 text-dark">
                          Click the copy button to copy the link and share now
                          to students to attempt exercise.
                        </div>
                      </div>

                      <div class="text-center mt-5 mt-lg-3 lh-2">
                        <span class="text-dark px-2 fs-3 rounded">OR</span>
                      </div>

                      <div className="row g-3 text-center mt-5 mt-lg-3">
                        <div className="col-6">
                          <WhatsappShareButton url={text}>
                            <div
                              class="btn btn-light-success btn-sm shadow-none rounded-circle"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i class="bi bi-whatsapp fs-t"></i>
                            </div>
                          </WhatsappShareButton>
                          <div className="fs-4 text-dark mt-2">WhatsApp</div>
                        </div>

                        <div className="col-6">
                          <TelegramShareButton url={text}>
                            <div
                              class="btn btn-light-info btn-sm shadow-none rounded-circle"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            >
                              <i class="bi bi-telegram fs-t"></i>
                            </div>
                          </TelegramShareButton>
                          <div className="fs-4 text-dark mt-2">Telegram</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Mobile />

          <ToastContainer />

          <Switcher />
        </>
      </div>
    </>
  );
};

export default Exercise;
