import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";
import { isMobile } from "react-device-detect";

const Header = () => {
  const getusers = useSelector((state) => state.user.value);

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("");

  useEffect(() => {
    setfullname(!admin_name ? "" :
      admin_name.split(" ")[0].charAt(0).toUpperCase() +
      admin_name.split(" ")[1].charAt(0).toUpperCase()
    );
  }, [admin_name]);

  const { id } = useParams();

  const hash = window.location.hash.slice(5);

  // console.log(hash);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setInterval(function () {
      setToggle(toggle ? false : true);
    }, 60000);
  }, [toggle]);

  return (
    <div class="docs-header">
      <nav class="navbar navbar-expand-lg navbar-default bg-start fixed-top ms-0 py-3 py-lg-3 shadow-none">
        <a class="fs-10 text-white fw-medium">
          E-Report <span className="text-warning">Center</span>
        </a>
        <ul class="navbar-nav ms-auto flex-row d-none d-lg-flex">
          <Link to={"../" + id + "/report"}
            class={
              hash === "/report"
                ? "nav-item docs-header-btn ms-4 text-warning"
                : "nav-item docs-header-btn ms-4 text-white"
            }
          >
            <span className="fw-bold fs-4">Home</span>
          </Link>

          <Link to={"../" + id + "/report/assign"}
            class={
              hash === "/report/assign"
                ? "nav-item docs-header-btn ms-4 text-warning"
                : "nav-item docs-header-btn ms-4 text-white"
            }
          >
            <span className="fw-bold fs-4">Assign</span>
          </Link>

          <Link to={"../" + id + "/report/transfer"}
            class={
              hash === "/report/transfer"
                ? "nav-item docs-header-btn ms-4 text-warning"
                : "nav-item docs-header-btn ms-4 text-white"
            }
          >
            <span className="fw-bold fs-4">Transfer</span>
          </Link>

          <Link to={"../" + id + "/report/send"}
            class={
              hash === "/report/send"
                ? "nav-item docs-header-btn ms-4 text-warning"
                : "nav-item docs-header-btn ms-4 text-white"
            }
          >
            <span className="fw-bold fs-4">Dispatch</span>
          </Link>

          <li
            class="nav-item docs-header-btn ms-4 text-white"
            data-bs-toggle="modal"
            data-bs-target="#settings"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
          >
            <span className="fw-bold fs-4">Settings</span>
          </li>
        </ul>

        <div class="navbar-nav flex-row ms-4">
          {isMobile ? (
            <div
              data-bs-toggle="modal"
              data-bs-target="#menu"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
            >
              {toggle ? (
                <Tippy
                  content={admin_name}
                  interactive={true}
                  interactiveBorder={400}
                  delay={100}
                >
                  <span class="avatar avatar-lg avatar-warning">
                    <span
                      class="avatar-initials rounded-circle 
              text-white fs-3 fw-medium"
                    >
                      {fullname}
                    </span>
                  </span>
                </Tippy>
              ) : (
                <>
                  <Tippy
                    content={"Menu"}
                    interactive={true}
                    interactiveBorder={400}
                    delay={100}
                  >
                    <span class="avatar avatar-lg avatar-success">
                      <span class="avatar-initials rounded-circle text-white fs-10 fw-medium">
                        <i class="bi bi-list"></i>
                      </span>
                    </span>
                  </Tippy>
                </>
              )}
            </div>
          ) : (
            <>
              <Tippy
                content={admin_name}
                interactive={true}
                interactiveBorder={400}
                delay={100}
              >
                <span class="avatar avatar-lg avatar-warning">
                  <span class="avatar-initials rounded-circle text-white fs-3 fw-medium">
                    {fullname}
                  </span>
                </span>
              </Tippy>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Header;
