import React, { useState, useEffect, useRef } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

const Chat_list = ({ chatid }) => {

    const getusers = useSelector((state) => state.user.value);

    const teachid = getusers.teachid;

    const { id } = useParams();

    axios.defaults.baseURL = id;

    const [user, setUser] = useState([]);

    useEffect(() => {
        Fetch_users();
    }, [teachid, chatid]);

    const Fetch_users = () => {

        axios({
            method: "post",
            url: "./v2/chat/user/",
            data: {
                teachid,
                chatid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setUser(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const TimeDate = new Date(user.time);

    const time = TimeDate.toLocaleTimeString('default', {
        hour: '2-digit',
        minute: '2-digit',
    });

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "...." : str;
    };

    const message = user.message ? user.message.replaceAll(/<\/?[^>]+(>|$)/gi, "") : '';

    return (

        <div>
            <div class="d-flex justify-content-between align-items-center">

                <a class="text-link contacts-link cursor-pointer"
                    data-bs-toggle="modal" data-bs-target="#Handle_Msg">
                    <div class="d-flex">
                        <div class="avatar avatar-md">
                            <img src={"../" + id + "/profiles/" + user.receiv_id + (user.receiv_id === "MALE" ||
                                user.receiv_id === "FEMALE" ? ".png" : ".jpg")} alt="" class="rounded-circle" />
                        </div>

                        <div class="ms-3">
                            <h5 class="mb-0 fw-bold text-purple3">
                                {user.receive}
                            </h5>
                            {user.status === 'WAITING' ?
                                <p class="mb-0 fs-4 text-dark fw-medium text-truncate">
                                    New message
                                </p>
                                :
                                <p class="mb-0 fs-4 text-muted text-truncate">
                                    {truncate(message, 12)}
                                </p>
                            }
                        </div>
                    </div>
                </a>

                <div>
                    <small class="text-muted">{time}</small>
                    <div class="text-end">
                        {user.chat_num >= 1 ?
                            <span class="icon-shape icon-xs text-white bg-danger
                            rounded-circle fw-bold fs-6"> {user.chat_num} </span>
                            : ''}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat_list