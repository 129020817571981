import React from "react";

import {useNavigate} from "react-router-dom";

import Footer from "./Dashboard/Footer";

const NoPage = () => {

    const navigate = useNavigate();

    const year = new Date().getFullYear();

  return (
    <div>
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center py-10">
          <div class="offset-xl-1 col-xl-4 col-lg-6 col-md-12 col-12 text-center text-lg-start">
            <h1 class="display-1 mb-3 text-purple3">404</h1>

            <p class="mb-5 text-purple2">
              Oops! Sorry, we couldn’t find the page you were looking for. If
              you think this is a problem with us, please School admin for help.
            </p>
            <div onClick={() => navigate(-1)} class="btn btn-dark-primary rounded-pill btn-lg">
              Back to Safety
            </div>
          </div>

          <div class="offset-xl-1 col-xl-6 col-lg-6 col-md-12 col-12 mt-8 mt-lg-0">
            <img
              src="../assets/images/error/404-error-img.svg"
              alt=""
              class="w-100"
            />
          </div>
        </div>

        <div className="mt-lg-n15">
        <Footer />
        </div>
      </div>
    </div>
  );
};

export default NoPage;
