import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { isMobile } from "react-device-detect";

import { wobble, bounceInLeft } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { useSelector } from "react-redux";

import { motion } from "framer-motion"

const Footer = () => {
  const year = new Date().getFullYear();

  const { token } = useParams();

  const { id } = useParams();

  const navigate = useNavigate();

  const styles = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(wobble, "bounce"),
    },
  };

  const [move, setMove] = useState(false);

  const hash = window.location.hash.slice(2);

  //console.log(hash)

  const GoLink = (url) => {
    navigate(url);
  };

  const getusers = useSelector((state) => state.user.value);

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const teachclass = getusers.teachclass;

  const [fullname, setfullname] = useState("");

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0].charAt(0).toUpperCase() +
        admin_name.split(" ")[1].charAt(0).toUpperCase());
    }, 1000);
  }, [admin_name]);


  return (
    <>
      <div className="container-fluid">
        <div className="row g-3 pt-12 pt-lg-4 pb-lg-10 pb-12 px-lg-4 px-2">
          <div className="col-lg-6">
            <div class="mb-4">
              <img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" />
            </div>
            <div className="text-purple2 fs-4">© 2022 Powered and developed with ❤️. All rights reserved</div>
          </div>
        </div>
      </div>

      <div class="modal" tabindex="-1" id="menu">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header shadow-none" style={{ padding: "1rem" }}>
              <div class="modal-title fs-10 text-purple3">Menu</div>

              <button
                type="button"
                class="btn btn-white text-danger fs-t"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <i class="bi bi-x-lg text-purple2"></i>
              </button>
            </div>

            <div class="modal-body px-4 text-center py-6">
              <div className="pt-0">
                <div class="d-flex">
                  <div class="col-auto mb-0 mb-md-0">
                    <div class="position-relative">
                      <img src={"../" + id + "/profiles/" + teachid + ".jpg"} alt="" class="rounded-circle avatar-lg" />
                    </div>
                  </div>

                  <div class="col ms-3 w-100 mt-0 mt-xl-0">
                    <div class="d-flex justify-content-between mb-5">
                      <div>
                        <div class="mb-1 fs-3 text-purple3">
                          {admin_name}
                        </div>
                        <div>
                          <div className="fs-4 text-dark ms-2">
                            {teachclass ?
                              <span>
                                {teachclass.toUpperCase()} Class Teacher
                              </span>
                              :
                              <span>
                                Subject Teacher
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center gy-6 pt-3">
                <div className="col-6 col-md-6 col-lg-4">
                  <div className={hash === id + '/home' ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary" :
                    "card py-4 px-2 shadow-sm cursor-pointer bg-back"}
                    style={{ borderRadius: "25px" }}>
                    <div
                      onClick={() => GoLink("../" + id + "/home")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-columns-gap"></div>
                        <div className="fs-3">Home</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div className={hash === id + '/exercise' ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary" :
                    "card py-4 px-2 shadow-sm cursor-pointer bg-back"}
                    style={{ borderRadius: "25px" }}>
                    <div
                      onClick={() => GoLink("../" + id + "/exercise")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-ui-radios"></div>
                        <div className="fs-3">Exercise</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div className={hash === id + '/note' ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary" :
                    "card py-4 px-2 shadow-sm cursor-pointer bg-back"}
                    style={{ borderRadius: "25px" }}>
                    <div
                      onClick={() => GoLink("../" + id + "/note")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-card-text"></div>
                        <div className="fs-3">Note Maker</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div className={hash === id + '/cbt' ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary" :
                    "card py-4 px-2 shadow-sm cursor-pointer bg-back"}
                    style={{ borderRadius: "25px" }}>
                    <div
                      onClick={() => GoLink("../" + id + "/cbt")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-list-check"></div>
                        <div className="fs-3">CBT Center</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div className="card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    style={{ borderRadius: "25px" }}>
                    <div
                      onClick={() => GoLink("../" + id + "/settings")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-gear"></div>
                        <div className="fs-3">Settings</div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-6 col-md-6 col-lg-4">
                  <div className="card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    style={{ borderRadius: "25px" }}>
                    <div
                      onClick={() => GoLink("../" + id + "/logout")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-power"></div>
                        <div className="fs-3">Logout</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div onClick={() => GoLink("../" + id + "/chatroom/response")}
                  data-bs-dismiss="modal" className="col-lg-12">
                  <div class="container bg-primary shadow-sm"
                    style={{ borderRadius: "25px" }}>

                    <div class="row align-items-center py-3">

                      <div class="col-4">
                        <div class="">
                          <img src="../../assets/images/background/admin.png" alt="" class="img-fluid mt-n5" />
                        </div>
                      </div>

                      <div class="col-lg-6 col-8">
                        <div class="text-start p-2 p-lg-0">
                          <div class="fs-t text-white fw-medium">Chat Room
                            <span class="position-absolute translate-middle badge rounded-pill bg-white text-danger fs-5 ms-3">
                              99+
                              <span class="visually-hidden">unread messages</span>
                            </span>
                          </div>
                          <div className="fs-4 text-white">
                            Respond to all messages, notifications with ease!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      {
        hash !== id + "/works/" + token ? (
          <></>
        ) : (
          <StyleRoot>
            <div
              class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 float mb-lg-0"
              onMouseOver={() => setMove(true)}
              onMouseLeave={() => setMove(false)}
              style={move ? styles.bounce : {}}
            >
              <i class="bi bi-chat-quote"></i>
            </div>
          </StyleRoot>
        )}

      <div class="modal" tabindex="-1" id="chat_app">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div class="modal-content">
            <div class="modal-body px-lg-8">
              <div className="text-end">
                <div
                  class="btn btn-purple4 text-white shadow-none btn-sm rounded-circle"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fs-4 text-white"></i>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mt-5 d-block d-lg-none mb-7">
                  <div className="">
                    <img
                      src="../../assets/images/background/chat.gif"
                      width="100%"
                    />
                  </div>
                </div>

                <div className="col-lg-5 mt-5 d-none d-lg-block">
                  <div className="text-start">
                    <img
                      src="../../assets/images/background/chat.gif"
                      width="100%"
                    />
                  </div>
                </div>

                <div className="col-lg-6 offset-lg-1">
                  <div className="display-4 text-purple2 d-block d-lg-none">
                    Communication made easier!
                  </div>

                  <div className="display-3 text-purple2 d-none d-lg-block">
                    Communication made easier!
                  </div>

                  <div className="col-lg-9">
                    <div className="fs-3 text-dark pt-6">
                      Chat and share now with students and teachers easily with{" "}
                      <span className="fw-bold">Chat Room</span>. A platform
                      powered to help manage classroom online and engage with
                      interactive activities.
                      <br />
                      <br />
                      <span className="fw-bold">Try it out!</span>
                    </div>
                  </div>

                  <div className="col-lg-6 cursor-pointer pt-7">
                    <div className="card shadow-sm p-2 p-lg-2 bg-white border border-gray-800 rounded-pill">
                      <div className="">
                        <img
                          src="../../assets/images/background/chatroom.png"
                          className="ms-1"
                          width="20%"
                        />
                        <span className="fs-t ms-1 text-purple2 mt-3">
                          {" "}
                          Open Chat Room
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="Handle_Profile" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"}
          role="document">
          <div class="modal-content curve1 " style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
            <div class="modal-header border-0 py-4">

              <div className="fs-10 text-purple2 fw-medium">

              </div>

              <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                data-bs-dismiss="modal" aria-label="Close">
                <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
              </button>

            </div>
            <div class="modal-body py-0 px-8">

              <div className="fs-t2 text-purple2">
                Profile
              </div>

              <div className="row px-4 pb-lg-6 pb-md-4 pt-5 pt-lg-4">

                <div className="col-12 pb-6 pb-lg-4">
                  <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                    <div className="fs-3 text-dark">
                      Name - {admin_name}
                    </div>
                  </div>
                </div>

                <div className="col-12 pb-6 pb-lg-4">
                  <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                    <div className="fs-3 text-dark">
                      Class Teacher Of - {teachclass}
                    </div>
                  </div>
                </div>

                <div className="col-12 pb-6 pb-lg-4">
                  <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                    <div className="fs-3 text-dark">
                      Teacher ID - {teachid}
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Footer;
