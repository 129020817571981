import React from "react";
import { isMobile } from "react-device-detect";

const Exist = ({ fname, setExist, phone, teach }) => {
  return (
    <>
      {isMobile ? (
        <div className="px-lg-10 position-fixed">
          <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
            <div
              className="card bg-white py-20 mt-n20"
              style={{ borderRadius: "16px" }}
            >
              <div className="card-body">
                <div className="d-flex align-items-center mb-4">
                  <div
                    className="col-auto cursor-pointer fs-t text-purple2"
                    onClick={() => setExist(false)}
                  >
                    <i class="bi bi-arrow-left"></i> Back
                  </div>
                </div>

                <div className="col-12 px-4 pt-lg-0 pt-2 text-center">
                  <img
                    src="../../assets/images/background/exist.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <div className="text-center">
                  <div className="fs-t text-purple3 fw-medium mb-2">
                    Results of {fname} exist already on this subject.
                  </div>
                </div>

                <div className="px-3 mt-8">
                  <div className="fs-3 text-purple2">Follow up details;</div>

                  <div className="mt-3 d-flex align-items-center">
                    <div class="avatar avatar-lg avatar-warning">
                      <span class="avatar-initials rounded fs-3">
                      {teach ? teach.split(" ")[0].charAt(0).toUpperCase() + teach.split(" ")[1].charAt(0).toUpperCase() : ''}
                      </span>
                    </div>

                    <div className="ms-3">
                        <div className="fs-4 fw-bold text-purple3">
                            {teach}
                        </div>
                        <div className="fs-4 text-purple3">
                            Subject Teacher
                        </div>
                    </div>

                    <div className="col text-end">
                       <a href={"tel:"+phone} className="btn btn-light border-dark text-purple2 rounded-pill fs-3">
                       <i class="bi bi-telephone-outbound"></i>
                       </a>
                    </div>

                  </div>
                </div>

                <div
                  className="card fixed-bottom bg-back shadow-lg"
                  style={{
                    borderTopRightRadius: "40px",
                    borderTopLeftRadius: "40px",
                  }}
                >
                  <div className="d-grid px-5 mt-3 mb-3">
                    <a href={'https://wa.me/'+phone+'?text=Hello,+I+can+you+delete+or+transfer+'+fname+'+result+of+my+subejct+so+i+can+upload+the+score'} className="btn btn-success rounded-pill fs-3 shadow-lg">
                    <i class="bi bi-whatsapp"></i> Chat Teacher
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-light-primary pt-lg-0 px-lg-10 pt-5 px-3">
          <div className="py-15 py-md-5">
            <div className="col-12 mt-5 mt-lg-0 mb-4">
              <div className="text-center">
                <div className="fs-t2 text-success fw-bold">Class Managing</div>
              </div>
            </div>

            <div className="px-lg-10">
              <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div
                  className="card bg-white sh"
                  style={{ borderRadius: "16px" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4">
                      <div
                        className="col-auto cursor-pointer"
                        onClick={() => setExist(false)}
                      >
                        <i class="bi bi-arrow-left fs-3 text-dark"></i>
                      </div>

                      <div className="col text-end">
                        <div className="fs-5 text-dark">1 of 3</div>
                      </div>
                    </div>

                    <div className="text-center px-lg-8 px-2">
                      <div className="fs-t text-purple3 fw-bold mb-2">
                        Are you a class teacher?
                      </div>
                    </div>

                    <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                      <img
                        src="../../assets/images/background/teach.png"
                        alt=""
                        class="img-fluid"
                        width={"90%"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Exist;
