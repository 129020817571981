import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { isMobile } from "react-device-detect";

const Login = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const [teachid, setID] = useState(null);

  const [password, setPassword] = useState(null);

  const [status, setStat] = useState(false);

  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    localStorage.clear();
  }, []);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSubmit_ = (e) => {
    e.preventDefault();

    setStat(true);

    const user = {
      teachid,
      password,
    };

    axios({
      method: "post",
      url: "/v2/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setTimeout(function () {
            setStat(false);
            navigate("./auth/" + response.data.token);
          }, 2000);

          localStorage.setItem("token", response.data.token);
        } else {
          toast.error("Failed: Login Failed! Incorrect details.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const [school, setSchool] = useState([]);

  const [lock, setLock] = useState(false);

  const fetchSchool = () => {
    axios({
      method: "post",
      url: "./v2/school/",
      data: {
        school_id: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSchool(view);
        } else {
          setLock(true);
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    fetchSchool();
  }, [id]);

  return (
    <div>
      {lock ? (
        <></>
      ) : (
        <>
          <>
            {isMobile ? (
              <div className="">
                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="card bg-white py-20 mt-n20">
                    <div className="card-body">
                      <div className="text-center mb-10 mt-4">
                        <div className="fs-t2 text-purple2 fw-medium">
                          {school.schoolname}
                        </div>
                      </div>

                      <form className="mb-20" onSubmit={onSubmit_}>
                        <div className="fs-10 fw-medium text-purple3 text-center">
                          <span className="text-success">Te</span>
                          <span className="text-warning">ac</span>
                          <span className="text-danger">her</span> Login
                        </div>
                        <div className="mt-3 fs-4 text-purple2 text-center mb-5">
                          Fill in your details below to access App.
                        </div>

                        <div class="mb-8">
                          <input
                            type="text"
                            maxLength={8}
                            id="name-input"
                            autoComplete={false}
                            name="adminid"
                            class="form-control form-control-lg text-dark fs-3 bg-back rounded-pill"
                            placeholder="Enter your teacher ID"
                            required
                            onChange={(e) => setID(e.target.value)}
                          />
                        </div>

                        <div class="mb-0">
                          <div class="d-flex align-items-center">
                            <input
                              type={passwordType}
                              class="form-control form-control-lg text-dark fs-3 bg-back rounded-pill"
                              placeholder="Type your password"
                              required
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>

                        <div class="card fixed-bottom py-6 px-4">
                          <button
                            className="btn btn-lg btn-dark-primary rounded-pill shadow-lg"
                            type="submit"
                            disabled={status ? true : false}
                          >
                            {status ? (
                              <>
                                Executing...
                                <span
                                  class="spinner-border spinner-border-sm ms-3"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </>
                            ) : (
                              "Login To Account"
                            )}
                          </button>

                          <div className="mt-5">
                            <div className="px-2">
                              <div className="col-auto">
                                <div class="mb-2">
                                  <img
                                    src="../assets/smlogo.png"
                                    width={isMobile ? "76" : "80"}
                                    alt=""
                                  />
                                </div>

                                <div className="text-purple2 fs-4">
                                  © 2022 Powered and developed with ❤️. All
                                  rights reserved
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="bg-light-primary pt-lg-0 px-lg-10 pt-5 px-3">
                  <div className="py-15 py-md-5">
                    <div className="col-12 mt-5 mt-lg-0 mb-4">
                      <div className="text-center">
                        <div className="fs-t2 text-purple3 fw-medium">
                          {school.schoolname}
                        </div>
                      </div>
                    </div>

                    <div className="px-lg-10">
                      <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div
                          className="card bg-white sh"
                          style={{ borderRadius: "16px" }}
                        >
                          <div className="card-body px-8 mt-6">

                            <form className="" onSubmit={onSubmit_}>
                              <div className="fs-10 fw-medium text-purple3 text-center">
                                <span className="text-success">Te</span>
                                <span className="text-warning">ac</span>
                                <span className="text-danger">her</span> Login
                              </div>
                              <div className="mt-3 fs-4 text-purple2 text-center mb-5">
                                Fill in your details below to access App.
                              </div>

                              <div class="mb-6">
                                <input
                                  type="text"
                                  maxLength={8}
                                  id="name-input"
                                  autoComplete={false}
                                  name="adminid"
                                  class="form-control form-control-lg text-dark fs-3 bg-back rounded-pill"
                                  placeholder="Enter your teacher ID"
                                  required
                                  onChange={(e) => setID(e.target.value)}
                                />
                              </div>

                              <div class="mb-8">
                                <div class="d-flex align-items-center">
                                  <input
                                    type={passwordType}
                                    class="form-control form-control-lg text-dark fs-3 bg-back rounded-pill"
                                    placeholder="Type your password"
                                    required
                                    onChange={(e) =>
                                      setPassword(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div class="d-grid">
                                <button
                                  className="btn btn-lg btn-dark-success rounded-pill shadow-lg"
                                  type="submit"
                                  disabled={status ? true : false}
                                >
                                  {status ? (
                                    <>
                                      Executing...
                                      <span
                                        class="spinner-border spinner-border-sm ms-3"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    "Login To Account"
                                  )}
                                </button>

                                <div className="mt-8">
                                  <div className="px-2">
                                    <div className="col-auto">
                                      <div class="mb-2">
                                        <img
                                          src="../assets/smlogo.png"
                                          width={isMobile ? "76" : "80"}
                                          alt=""
                                        />
                                      </div>

                                      <div className="text-purple2 fs-4">
                                        © 2022 Powered and developed with ❤️.
                                        All rights reserved
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>

          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default Login;
