import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Footer from "../Footer";

import Results from "./Results";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

const Broadsheet = () => {
    const { teachid } = useParams();

    const navigate = useNavigate();

    const getusers = useSelector((state) => state.user.value);

    const admin_name = getusers.username;

    const [fullname, setfullname] = useState("{}");

    useEffect(() => {
        setTimeout(function () {
            setfullname(admin_name.split(" ")[0]);
        }, 1000);

        localStorage.setItem("id", teachid);
    }, [admin_name, teachid]);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    const [progress, setProgress] = useState(20);

    const [report, setReport] = useState([]);

    const [loader, setLoader] = useState(true);

    const FetchReport = () => {
        axios({
            method: "post",
            url: "./v2/report/sheet/results/",
            data: {
                teachid,
                studid: null
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                setReport(view);

                setTimeout(function () {
                    setLoader(false);
                }, 4000);
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    useEffect(() => {
        FetchReport();
    }, [teachid]);

    let newArray = [];

    let newArrayname = [];

    let objTitle;

    let objTitlename;

    let i;

    let iname;

    // Declare an empty object
    let uniqueObject = {};

    let uniqueObjectname = {};


    // Loop for the array elements
    for (let i in report) {

        // Extract the title
        objTitle = report[i]['subject'];

        // Use the title as the index
        uniqueObject[objTitle] = report[i];
    }

    // Loop to push unique object into array
    for (i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }


    // Loop for the array elements
    for (let iname in report) {

        // Extract the title
        objTitlename = report[iname]['fname'];

        // Use the title as the index
        uniqueObjectname[objTitlename] = report[iname];
    }

    // Loop to push unique object into array
    for (iname in uniqueObjectname) {
        newArrayname.push(uniqueObjectname[iname]);
    }

    const rendersubject = () => {
        if (newArray == "") {
            return <></>;
        } else if (newArray) {
            return [...new Set(newArray.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <th scope="col">{task.subject}</th>
                    </>
                );
            }))]
        } else {
            return <></>;
        }
    };

    const rendername = () => {
        if (newArrayname == "") {
            return <></>;
        } else if (newArrayname) {
            return [...new Set(newArrayname.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <div className="col-3 mb-4">
                            <tr>
                                <td colspan="3">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">{task.fname}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="3">
                                    <table class="table table-borderless mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">Subject</th>
                                                <th scope="col" className="text-center">Test</th>
                                                <th scope="col" className="text-center">Exam</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>

                            <Results studid={task.studid} />

                        </div>
                    </>
                );
            }))]
        } else {
            return <></>;
        }
    };

    const Print = () => {
        let printContents = document.getElementById("result_table").innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;

        window.print(); // PRINT THE CONTENTS.

        document.body.innerHTML = originalContents;
    };

    return (
        <div onLoad={() => setProgress(100)}>
            <Auth />
            <LoadingBar
                color="#ff793f"
                progress={progress}
                onLoaderFinished={() => setProgress(0)}
            />

            <div class="docs-header">
                <nav class="navbar navbar-expand-lg navbar-default bg-success fixed-top ms-0 py-3 py-lg-3 shadow-none">
                    <a class="fs-10 text-white fw-medium">
                        E-Report Center
                    </a>
                    <ul class="navbar-nav ms-auto flex-row d-none d-lg-flex">

                    </ul>
                </nav>
            </div>

            <div class="bg-back">
                <div class="container-fluid px-lg-10 pt-lg-15 pt-4">
                    <div className="d-flex align-items-center">
                        <div className="col">
                            <div className="fs-t text-purple3">
                                Broadsheet |{" "}
                                <span className="text-dark fs-5">
                                    <i className="bi bi-table"></i> -- Broadsheet
                                </span>
                            </div>
                        </div>

                        <div className="col-end text-center"></div>
                    </div>

                    <div className="col-12 mt-4">
                        <div
                            className="card bg-white shadow-none"
                            style={{ borderRadius: "20px" }}
                        >
                            <div className="card-body px-lg-10">

                                <div className="" id="result_table">

                                    <div className="fs-t text-purple3 mb-6 mt-2">
                                        Jss1 Broadsheet 2023
                                    </div>

                                    <SimpleBar forceVisible="y" autoHide={true}>
                                        <div class="d-flex align-items-center hoverable">
                                            {rendername()}
                                        </div>
                                    </SimpleBar>
                                    
                                </div>

                                <div className="col text-end mt-6">
                                    <button className="btn btn-purple rounded-pill" onClick={() => Print()}>
                                        Print Broadsheet
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>

            <ToastContainer />
        </div>
    );
};

export default Broadsheet;
