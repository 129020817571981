import React, { useState, useEffect } from "react";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams, Link } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { Player } from "@lottiefiles/react-lottie-player";

import QRCode from "react-qr-code";

const Media = () => {
    const [loaders, setLoaders] = useState(true);

    const { id } = useParams();

    const { token } = useParams();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const plan = getusers.plan;

    const teachid = getusers.teachid;

    const [progress, setProgress] = useState(20);

    const [status, setStat] = useState(false);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
    }

    useEffect(() => {
        localStorage.setItem("id", teachid);
    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + ".." : str;
    };


    const [subjects, setSubjects] = useState([{}]);

    useEffect(() => {
        axios({
            method: "post",
            url: "./v2/subject/list/",
            data: {
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSubjects(view);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    }, [teachid]);

    const renderSubject = () => {
        if (subjects == "") {
            return <></>;
        } else if (subjects) {
            return subjects.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <option value={task.subject}>{task.subject}</option>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [media_task, setMedia] = useState([]);

    const FetchMedia = (course) => {
        axios({
            method: "post",
            url: "./v2/media/fetchs/",
            data: {
                token,
                teachid,
                course: course ? course : null
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setMedia(view);

                setRoomName(view.course)

                setSubject(view.subject)

                setStudclass(view.class)

                setNewCoursename('Course ' + view.count)
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    useEffect(() => {
        FetchCourse();
        FetchMedia(course);
    }, [token, teachid]);

    const [course, setCourse] = useState('');

    const [loader_, setLoader_] = useState(true);

    const [roomname, setRoomName] = useState(null);

    const [studclass, setStudclass] = useState(null);

    const [subject, setSubject] = useState(null);

    const [password, setPassword] = useState(null);

    const HandleEdit = (e) => {
        e.preventDefault();

        const task = {
            roomname,
            studclass,
            subject,
            password,
            teachid,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + roomname + " Media Folder has been modified!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia(course);

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const [coursename, setCourseName] = useState('');

    const HandleCourseName = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            coursename,
            teachid,
            course,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/edit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + coursename + " has been updated!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    FetchMedia(course);

                    FetchCourse();

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);

                    localStorage.setItem("token", response.data.token);

                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const [courses, setCourses] = useState([]);

    const FetchCourse = () => {
        axios({
            method: "post",
            url: "./v2/media/fetch_course/",
            data: {
                token,
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setCourses(response.data);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const renderCourse = () => {
        if (courses == "") {
            return (
                <>

                </>
            );
        } else if (courses) {
            return courses.map((task, index) => {
                //console.log(school.tm_date)
                return (

                    <>
                        <div className="col-lg-12 mb-5">
                            <div className="card shadow-sm p-4 rounded-3"
                                onClick={() => { setCourse(task.course); setCourseName(task.course); }}>
                                <div className="align-items-center d-flex">
                                    <div className="col-8 text-start">
                                        <div className="display-6 cursor-pointer text-purple2 fw-medium" data-bs-toggle="modal"
                                            data-bs-target="#HandleName">
                                            {!task.course ? <>Course {index + 1}</> :
                                                task.course} <i class="bi bi-pencil-square"></i>
                                        </div>
                                    </div>

                                    <div className="col-4 text-end">
                                        <Link to={"../" + id + "/media/" + token + "/view/" + task.course}
                                            className="btn btn-lg btn-light-primary rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 display-6 cursor-pointer text-purple2 fw-medium">
                                            <span className="bi bi-arrow-right"></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [new_coursename, setNewCoursename] = useState('');

    const [video, setVideo] = useState(null);

    const [audio, setAudio] = useState(null);

    const handleFileSelect = (event) => {
        setAudio(event.target.files[0])

        //console.log(selectedFile)
    }

    const HandleNew = (e) => {

        e.preventDefault();

        setStat(true);

        const task = {
            file: audio,
            video,
            teachid,
            course: new_coursename,
            token
        };

        axios({
            method: "post",
            url: "/v2/media/new/",
            data: task,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + new_coursename + " was created successfully!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    // setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);

                    FetchCourse();

                    localStorage.setItem("token", response.data.token);

                } else if (response.data.message === "exist") {
                    toast.error("Failed: Audio already exist in folder!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setLoader_(false);

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                } else if (response.data.message === "size") {
                    toast.error("Failed: Audio size is too large!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setLoader_(true);

                    setTimeout(function () {
                        setStat(false);
                        setLoader_(false);
                    }, 2000);
                } else {
                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 2000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    }

    const url_link = "app.smartedung.com/#/" + token;

    const [PaymentStat, SetPaymentStat] = useState('OFF');

    return (
        <>
            <Auth />
            <div
                id="docs-main-wrapper"
                className={isMobile ? `` : ``}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#474787"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <>

                    {app_status === "InActive" ? (
                        <StyleRoot>
                            <div class="container-fluid px-4 px-lg-4" style={styles.bounce}>
                                <Activate />
                            </div>
                        </StyleRoot>
                    ) : (
                        <>
                            <StyleRoot>

                                <div style={styles.bounce}>
                                    <div class="docs-header">
                                        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-3 shadow-sm">
                                            <a class="fs-10 text-purple2 font-display2 cursor-pointer" data-bs-toggle="offcanvas"
                                                data-bs-target="#edit_room" aria-controls="offcanvasLeft">
                                                <i class="bi bi-bullseye text-pink"></i> {truncate(media_task.title, 13)} Room <i class="bi bi-pencil-square"></i>
                                                {!media_task.status ? <span class="badge rounded-pill bg-primary fs-5 ms-3">In Progress</span>
                                                    : <span class="badge rounded-pill bg-success fs-5 ms-3">Finished</span>} </a>
                                            <ul class="navbar-nav ms-auto flex-row">
                                                <li class="nav-item docs-header-btn d-none d-lg-block">
                                                    <button class="btn btn-light btn-md shadow-lg text-purple3 me-2 me-lg-0 font-display 
                                                    fw-medium border border-dark"
                                                        data-bs-toggle='modal' data-bs-target='#ShareRoom'
                                                        style={{
                                                            borderRadius: "30px"
                                                        }}
                                                        type="button"><span className="bi bi-share"></span> Share Now </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <div class="docs-nav-sidebar">
                                        <div class="py-5"></div>
                                        <div class=" docs-nav " data-simplebar>
                                            <nav class="navigation navbar navbar-expand-md">
                                                <div class="collapse navbar-collapse " id="navbarNav">
                                                    <ul class="navbar-nav flex-column" id="sidebarnav">
                                                        <li class="navbar-header mt-4">
                                                            <h5 class="heading"></h5>
                                                        </li>

                                                        {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?
                                                            <>
                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/home"}
                                                                    class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-house"></i> Home</Link></li>

                                                                <li class="nav-item cursor-pointer" data-bs-toggle="modal"
                                                                    data-bs-target="#HandleNew"><div class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-plus-lg"></i> Add New Course</div></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/preview"}
                                                                    class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-easel2"></i> Preview Media</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/result"}
                                                                    class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-ui-checks"></i> Result</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/billing"}
                                                                    class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-cash-coin"></i> Billing Area</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/invite"}
                                                                    class="nav-link fs-4 text-dark
                                                        py-4 fw-medium"><i class="bi bi-person-plus"></i> Invite Learners</Link></li>

                                                            </> : <></>}

                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="nav-footer">
                                            <p class="mb-0 text-dark"> Powered by <a href="https://smartedung.com" target="_blank"
                                                className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                        </div>
                                    </div>

                                    <div class="docs-wrapper bg-back" >

                                        <div class="container-fluid px-3 px-lg-4">

                                            {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?

                                                <div className="pt-12 pt-md-12 py-lg-12 py-8">

                                                    <div className="row g-3 d-flex align-items-center pb-8 pb-lg-5">

                                                        <div className="col-lg-4 col-6 text-start">
                                                            <button className="btn btn-purple4 text-white font-display2 
                                                                fw-medium" style={{ borderRadius: "10px" }} data-bs-toggle="modal"
                                                                data-bs-target="#HandleNew">
                                                                <span className="bi bi-plus-lg"></span> Add New Course
                                                            </button>
                                                        </div>

                                                        <div className="col-lg-8 col-6 text-end">
                                                            <button className="btn btn-purple text-white font-display2 
                                                                fw-medium" style={{ borderRadius: "10px" }} data-bs-toggle="modal"
                                                                data-bs-target="#HandleSettings">
                                                                <span className="bi bi-gear-fill"></span> Settings
                                                            </button>
                                                        </div>

                                                    </div>

                                                    {renderCourse()}
                                                </div>

                                                :

                                                <>
                                                    <div className='pt-8 pt-lg-16'>

                                                        <Player
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={false}
                                                            src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                                                            style={{ height: "250px", width: "250px" }}
                                                        ></Player>

                                                        <div className="text-center mt-5">
                                                            <div className="text-purple2 fs-t fw-bold">
                                                                Access to this service is restricted.
                                                            </div>

                                                            <div className="text-gray-500 fs-4 mt-2">
                                                                For more details contact Smart Edu Team.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            }

                                        </div>

                                    </div>

                                </div>
                            </StyleRoot>
                        </>
                    )}

                    <div
                        class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
                    >
                    </div>

                    {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?

                        <>
                            <div class="modal fade shadow" id="HandleName" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content" style={{ borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mt-0 text-center">
                                                <i class="bi bi-tags-fill text-danger fs-2"></i>

                                                <div className="fs-10 font-display2 text-dark mt-2">
                                                    Edit Course Name
                                                </div>

                                                <form className="mt-lg-0" onSubmit={HandleCourseName}>
                                                    <div class="mb-3 mt-3 px-lg-8 px-4">
                                                        <input type="text" id="textInput" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            value={coursename}
                                                            required
                                                            onChange={(e) => setCourseName(e.target.value)}
                                                            placeholder="Input Course Name" />
                                                    </div>

                                                    <div class="mb-3 mt-5">
                                                        <button className="btn btn-purple3 text-purple2"
                                                            style={{ borderRadius: "30px" }}>

                                                            {status ? (
                                                                <>
                                                                    Executing...
                                                                    <span
                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Save
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="HandleNew" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                                    role="document">
                                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body py-0">

                                            <form onSubmit={HandleNew}>
                                                <div className="mt-0 text-center">

                                                    <i class="bi bi-plus-lg fs-1 text-success"></i>

                                                    <div className="fs-10 font-display2 text-dark mt-0">
                                                        Add New Course
                                                    </div>

                                                    <div class="mb-5 mt-5 px-lg-8 px-4">
                                                        <input type="text" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="name"
                                                            value={new_coursename}
                                                            placeholder="Input Course Name"
                                                            required
                                                            onChange={(e) => setNewCoursename(e.target.value)}
                                                        />
                                                    </div>

                                                    <div class="mb-3 mt-3 px-lg-8 px-4 text-start">
                                                        <input type="file" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="audio"
                                                            accept="audio/*"
                                                            required
                                                            onChange={handleFileSelect}
                                                            placeholder="Input Audio File" />

                                                        <p className="mt-2 fs-4 text-dark">
                                                            N.B: (Upload file limit - 40MB)
                                                        </p>
                                                    </div>

                                                    <div class="mb-3 mt-3 px-lg-8 px-4 text-start">
                                                        <input type="text" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="video"
                                                            onChange={(e) => setVideo(e.target.value)}
                                                            placeholder="Input Video Link" />

                                                        <p className="mt-2 fs-4 text-dark">
                                                            N.B: Input youtube, vimoe video link.
                                                        </p>
                                                    </div>

                                                    <div class="mb-3 mt-4">
                                                        <button className="btn btn-dark btn-lg"
                                                            type="submit"
                                                            data-bs-dismiss={new_coursename && audio ? "modal" : ""}
                                                            style={{ borderRadius: "30px" }}>
                                                            {status ? (
                                                                <>
                                                                    Executing...
                                                                    <span
                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Create Course
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="ShareRoom" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                                    <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body">
                                            <div className="mt-8 mt-lg-0 text-center">
                                                <i class="bi bi-share fs-1 text-success"></i>

                                                <div className="fs-10 font-display2 text-dark mt-2">
                                                    Share {media_task.course} Note
                                                </div>

                                                <div className="row px-lg-6 py-4 g-4">

                                                    <div className="col-lg-4 col-4">
                                                        <div className="btn btn-light-primary rounded-circle btn-md">
                                                            <span className="fas fa-copy fs-3 py-2"></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-4">
                                                        <div className="btn btn-light-success rounded-circle btn-md">
                                                            <span className="bi bi-whatsapp fs-3 py-2"></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4 col-4">
                                                        <div className="btn btn-light-info rounded-circle btn-md">
                                                            <span className="bi bi-telegram fs-3 py-2"></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12 text-dark">
                                                        OR
                                                    </div>

                                                    <div className="col-lg-6 offset-lg-3 col-8 offset-2 text-center">
                                                        <div className="border border-dark py-lg-3 py-2 rounded-3">
                                                            <QRCode
                                                                value={url_link}
                                                                size={"150"}
                                                                fgColor={"#2c4f40"}
                                                            />
                                                        </div>
                                                        <div className="fs-4 text-dark mt-2">
                                                            Scan QR Code.
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="offcanvas offcanvas-end"
                                data-bs-backdrop="true"
                                style={isMobile ? {} : { width: "450px" }}
                                tabindex="-1"
                                id="edit_room"
                                aria-labelledby="offcanvasRightLabel"
                            >
                                <div class="offcanvas-header" style={{ padding: "0px" }}>
                                    <h5 id="offcanvasRightLabel"></h5>
                                    <button
                                        type="button"
                                        class="btn btn-white text-success2 fs-2"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"
                                    >
                                        {" "}
                                        <i class="bi bi-x text-dark"></i>
                                    </button>
                                </div>
                                <div class="offcanvas-body">
                                    <div className="px-lg-2">

                                        <div class="mb-3 d-grid">
                                            <form className="mt-lg-0" onSubmit={HandleEdit}>
                                                <div className="mt-0 text-center">

                                                    <i class="bi bi-bullseye fs-1 text-primary"></i>

                                                    <div className="fs-10 font-display2 text-dark mt-0">
                                                        Edit Room
                                                    </div>

                                                    <div class="mb-5 mt-5 px-lg-4 px-2">
                                                        <input type="text" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="name"
                                                            value={media_task.title}
                                                            required
                                                            onChange={(e) => setRoomName(e.target.value)}
                                                            placeholder="Enter Media Quiz Name" />
                                                    </div>

                                                    <div class="mb-5 mt-5 px-lg-4 px-2">
                                                        <select
                                                            class="form-select form-select-lg text-dark"
                                                            aria-label="Default select example"
                                                            required
                                                            onChange={(e) => setSubject(e.target.value)}
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                        >
                                                            <option value={media_task.subject}>{media_task.subject}</option>
                                                            {renderSubject()}
                                                        </select>
                                                    </div>

                                                    <div class="mb-5 mt-5 px-lg-4 px-2">
                                                        <select
                                                            class="form-select form-select-lg text-dark"
                                                            aria-label="Default select example"
                                                            name="studclass"
                                                            required
                                                            onChange={(e) => setStudclass(e.target.value)}
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                        >
                                                            <option value={media_task.class}>{media_task.class}</option>
                                                            <option value="PlayGroup">PlayGroup</option>
                                                            <option value="Reception">Reception</option>
                                                            <option value="Pre Nursery">Pre Nursery</option>
                                                            <option value="NUR 1">NUR 1</option>
                                                            <option value="NUR 2">NUR 2</option>
                                                            <option value="KG 1">KG 1</option>
                                                            <option value="KG 2">KG 2</option>
                                                            <option value="Grade 1">Grade 1</option>
                                                            <option value="Grade 2">Grade 2</option>
                                                            <option value="Grade 3">Grade 3</option>
                                                            <option value="Grade 4">Grade 4</option>
                                                            <option value="Grade 5">Grade 5</option>
                                                            <option value="Grade 6">Grade 6</option>
                                                            <option value="Jss1">JSS 1</option>
                                                            <option value="Jss2">JSS 2</option>
                                                            <option value="Jss3">JSS 3</option>
                                                            <option value="sss1">SSS 1</option>
                                                            <option value="sss2">SSS 2</option>
                                                            <option value="sss3">SSS 3</option>
                                                        </select>
                                                    </div>

                                                    <div class="mb-5 mt-5 px-lg-4 px-2">
                                                        <input type="password" class="form-control form-control-lg"
                                                            style={{ borderRadius: "35px", borderColor: "#d2dae2" }}
                                                            id="password"
                                                            required
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            placeholder="Enter Quiz Password" />
                                                    </div>

                                                    <div class="mb-3 mt-4">
                                                        <button className="btn btn-success text-white btn-lg"
                                                            data-bs-dismiss={password && roomname && subject && studclass ? "offcanvas" : ""}
                                                            style={{ borderRadius: "30px" }}>
                                                            Create Course
                                                        </button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" id="HandleSettings" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                                aria-hidden="true">
                                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                                    role="document">
                                    <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                        <div class="modal-header border-0">
                                            <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                                aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                        </div>
                                        <div class="modal-body py-0">

                                            <form onSubmit={HandleNew}>
                                                <div className="mt-0 text-center">

                                                    <i class="bi bi-gear fs-1 text-danger"></i>

                                                    <div className="fs-10 font-display2 text-dark mt-0">
                                                        Settings
                                                    </div>

                                                    <div className="row py-4 px-lg-8">
                                                        <div className="col-lg-12">
                                                            <div class="row mb-3">
                                                                <div className="text-start col-lg-4">
                                                                <label class="form-label" for="email">Switch Payment</label>
                                                                    <div class="form-check form-switch mt-2">
                                                                        <input class="form-check-input form-check-input-lg" type="checkbox" 
                                                                        checked={PaymentStat === "ON" ? true : false}
                                                                        value={PaymentStat == "ON" ? 'OFF' : 'ON'}
                                                                        onChange={(e) => SetPaymentStat(e.target.value)}
                                                                        role="switch" id="flexSwitchCheckChecked"/>
                                                                        <label class="form-check-label" for="flexSwitchCheckChecked"></label>
                                                                    </div>
                                                                </div>

                                                                <div className="text-start col-lg-8">
                                                                    <label class="form-label" for="email">Amount</label>
                                                                    <input type="email" id="email" class="form-control form-control-lg"
                                                                    disabled={PaymentStat === "ON" ? false : true}
                                                                        style={{ borderRadius: "35px", borderColor: "#000" }}
                                                                        placeholder="Set Amount Value" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="">

                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </>
                        :

                        <></>
                    }

                    <Mobile />

                    <ToastContainer />

                    <Switcher />


                    <div class="btn btn-orange btn-md fs-t text-white shadow-lg rounded-br-lg2 rounded-bl-sm2 
                    rounded-t-lg2 float mb-lg-0 d-block d-lg-none"
                        data-bs-toggle="modal"
                        data-bs-target="#MobileMenu">
                        <i class="bi bi-columns-gap"></i>
                    </div>


                    <div class="modal fade" id="MobileMenu" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                            <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0">
                                    <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                        aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                    <button class="btn btn-pink btn-md shadow-lg text-white font-display fw-medium"
                                        style={{
                                            borderRadius: "30px"
                                        }}
                                        type="button"><span className="bi bi-share"></span> Share Now </button>
                                </div>
                                <div class="modal-body py-0">
                                    <div className="mt-0 mt-lg-0 text-center">
                                        <i class="bi bi-columns-gap fs-1 text-success"></i>

                                        <div className="fs-10 font-display2 text-dark mt-2">
                                            Dashboard Menu
                                        </div>

                                        <div className="row px-lg-6 pt-4 g-4 pb-10">

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-light"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/home"}>
                                                        <div className="text-dark fs-t text-center">
                                                            <div class="bi bi-house"></div>
                                                            <div className="fs-3">Home</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-dark-info"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/result"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-ui-checks"></div>
                                                            <div className="fs-3">Result</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-3 shadow-none cursor-pointer bg-success"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#HandleNew"
                                                    style={{ borderRadius: "25px" }}>
                                                    <div>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-plus-lg"></div>
                                                            <div className="fs-3">Add New Course</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-4 shadow-none cursor-pointer bg-blue"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/preview"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-easel2"></div>
                                                            <div className="fs-3">Preview Media</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-4 shadow-none cursor-pointer bg-purple3"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/billing"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-cash-coin"></div>
                                                            <div className="fs-3">Billing Area</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>


                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-2 shadow-none cursor-pointer bg-yellow"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/invite"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-person-plus"></div>
                                                            <div className="fs-3">Invite Learners</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="modal-footer border-0">
                                    <p class="mb-0 text-dark fs-4"> Powered by <a href="https://smartedung.com" target="_blank"
                                        className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        </>
    );
};

export default Media;
