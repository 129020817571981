import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Footer from "../Footer";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { isMobile } from "react-device-detect";

import Chart from "react-apexcharts";

const Home_Work = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const { token } = useParams();

    axios.defaults.baseURL = id;

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-success shadow-none py-3";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-success shadow-none py-3 px-6";
    }


    const [school, setSchool] = useState([]);

    const [task, setTask] = useState([]);

    const [lock, setLock] = useState(false);

    const [loaders, setLoaders] = useState(true);

    const fetchSchool = () => {
        axios({
            method: "post",
            url: "./v2/school/",
            data: {
                school_id: id,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSchool(view);
                } else {
                    setLock(true);
                }
            })
            .catch((err) => {
                //toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const FetchCBT = () => {
        axios({
            method: "post",
            url: "./v2/exercise/",
            data: {
                token
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "failed") {
                } else {
                    setTask(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const teachid = localStorage.getItem("id");

    const studid = localStorage.getItem("studid");

    const username = localStorage.getItem("studname");

    const studclass = task.class;

    useEffect(() => {
        fetchSchool();
        FetchCBT();
        FetchCBTResult();
        if (!teachid || !studid || cbt_task.token) {
            navigate("../" + id + "/work/" + token)
        }

        setTimeout(function () {
            {
                SearchExerciseList();
            }
        }, 3000);

    }, [id, teachid, token, task.class]);


    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 4000);

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 2s",
            animationName: Radium.keyframes(slideInUp, "bounce"),
        },
    };

    const [folders, setFolders] = useState([]);

    const SearchExerciseList = () => {
        axios({
            method: "post",
            url: "./v2/exercise/lists/",
            data: {
                q: task.class,
                task: task.type
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setFolders(view);
                } else {
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };


    const renderFolders = () => {
        if (folders == "") {
            return (
                <>

                </>
            );
        } else if (folders) {
            return folders.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {task.token === token ?

                            <></>
                            :
                            <Link to={"../" + id + "/work/" + task.token} className="col-lg-4 me-0 me-lg-6 mb-6 cursor-pointer">
                                <div className={(index + 1) % 4 == 0 || index === 0 ? "card bg-light-info shadow-none"
                                    : (index + 1) % 2 == 0 ? "card bg-light-warning shadow-none"
                                        : "card bg-light-success shadow-none"
                                }
                                    style={{ borderRadius: "18px" }}>
                                    <div className="card-body">
                                        <div className="col-4 col-lg-3">
                                            <img src="../../assets/images/background/2.png" alt=""
                                                class="img-fluid" />
                                        </div>
                                        <div className="row d-flex fs-3 text-dark fw-medium mt-2">
                                            <div className="col-10 text-start text-uppercase">{truncate(task.title, 15)}</div>
                                            <div className="col-2 text-end">
                                                <i class="bi bi-arrow-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Link>
                        }
                    </>
                );
            });
        } else {
            return (
                <>

                </>
            );
        }
    };


    const [cbt_task, setCBT] = useState([]);

    const FetchCBTResult = () => {
        axios({
            method: "post",
            url: "./v2/exercise/fetch/",
            data: {
                token,
                teachid,
                studid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "failed") {
                } else {
                    setCBT(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };


    const config_ = {
        series: [100 - cbt_task.total],
        options: {
            plotOptions: {
                radialBar: {
                    startAngle: -30,
                    endAngle: 330,
                    background: "#000",
                    hollow: {
                        margin: 0,
                        size: "80%",
                        background: "transparent",
                        image: undefined,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        position: "front",
                        dropShadow: {
                            enabled: false,
                            top: 3,
                            left: 0,
                            blur: 4,
                            opacity: 0.24,
                        },
                    },
                    track: {
                        background: "#38a169",
                        strokeWidth: "90%",
                        margin: 0, // margin is in pixels
                        dropShadow: {
                            enabled: false,
                            top: -3,
                            left: 0,
                            blur: 4,
                            opacity: 0.35,
                        },
                    },

                    tooltips: {
                        enabled: true,
                        intersect: true,
                        mode: "nearest",
                        bodySpacing: 5,
                        yPadding: 10,
                        xPadding: 10,
                        caretPadding: 0,
                        displayColors: true,
                        cornerRadius: 4,
                        footerSpacing: 0,
                        titleSpacing: 0,
                    },

                    dataLabels: {
                        showOn: "always",
                        name: {
                            offsetY: -20,
                            show: false,
                            color: "transparent",
                            fontSize: "8px",
                        },
                        value: {
                            formatter: function (val) {
                                return 100 - val + "%";
                            },
                            color: "#40196d",
                            fontSize: isMobile ? "50px" : "45px",
                            show: true,
                        },
                    },
                },
            },
            theme: {
                palette: "palette7",
            },
            stroke: {
                lineCap: "round",
            },
            labels: ["Total Score Mark"],
        },
    };


    return (
        <>
            {loaders ?

                <>
                    <div className="container py-lg-20 py-18">
                        <div className="col-lg-12 pt-20 pt-lg-15">
                            <span class="loader_2"></span>
                            <div className="text-center fs-5 text-dark font-display">
                                Loading, Please wait...
                            </div>
                        </div>
                    </div>
                </> :

                <>
                    {lock ?

                        <>

                        </>

                        :
                        <>
                            <div
                                className={isMobile ? `bg-white` : ``}
                            >

                                <nav class={headers}
                                    style={
                                        isMobile
                                            ? {}
                                            : {}
                                    }>
                                    <div className="container-fluid">
                                        <div class="navbar-brand fw-medium">
                                            <span className="fs-t text-white"><i class="bi bi-app-indicator"></i>
                                                {" "}
                                                {isMobile ? truncate(task.subject, 15) : task.subject}</span>
                                        </div>

                                        <div className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                                            <Link to={"../" + id + "/work/logout"}
                                                class="btn btn-white rounded-pill"
                                            >
                                                Logout
                                            </Link>
                                        </div>

                                    </div>
                                </nav>

                                <div className="">
                                    <div className="py-8">
                                        <div className="container pt-9 pt-md-9 px-4 px-lg-0">
                                            <div className="row g-3 align-items-center">

                                                <div className="col-12 mb-4 text-start">
                                                    <div className="fs-t fw-bold text-purple3">
                                                        Hello <span className="text-success">{username}</span>,
                                                    </div>
                                                </div>

                                                <div className="col-6 mb-2 text-start">
                                                    <div className="fs-5 text-dark">
                                                        Exercise Type
                                                    </div>
                                                    <div className="fs-t fw-bold text-purple3">
                                                        {task.type}
                                                    </div>
                                                </div>

                                                <div className="col-6 mb-2 text-end">
                                                    <div className="fs-5 text-dark">
                                                        Exercise Time
                                                    </div>
                                                    <div className="fs-t fw-bold text-purple3">
                                                        <i class="bi bi-alarm"></i> {task.time} Mins
                                                    </div>
                                                </div>



                                                {cbt_task.status === "Completed" ?

                                                    <>
                                                            <div className="col-12 mt-8 mb-2">
                                                                <div className="fs-t text-purple3 fw-medium">
                                                                    Your {cbt_task.title} Result
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 mt-3 mt-lg-0">
                                                                <div className="row text-center g-5">
                                                                    <div className="col-6">
                                                                        <div className="card bg-back shadow-none"
                                                                            style={{ borderRadius: "18px" }}>
                                                                            <div className="card-body text-center px-2">
                                                                                <div className="fs-1 text-success">
                                                                                    {cbt_task.total}%
                                                                                </div>
                                                                                <div className="fs-3 text-dark">
                                                                                    Pass Mark
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-6">
                                                                        <div className="card bg-back shadow-none"
                                                                            style={{ borderRadius: "18px" }}>
                                                                            <div className="card-body text-center">
                                                                                <div className="fs-1 text-danger">
                                                                                    {100 - cbt_task.total}%
                                                                                </div>
                                                                                <div className="fs-3 text-dark">
                                                                                    Fail Mark
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 mt-5 mt-lg-0">
                                                                <Chart
                                                                    options={config_.options}
                                                                    series={config_.series}
                                                                    type="radialBar"
                                                                    height={ isMobile ? 280 : 200 }
                                                                />
                                                            </div>
                                                    </>

                                                    :
                                                    <>

                                                        <div className="col-12 mt-5 mb-2">
                                                            <div className={isMobile ? "fs-4 text-dark" : "fs-3 text-dark"}>
                                                                Click on the card below to begin exercise. Make sure to read the instructions and
                                                                questions correctly before attempting exercise. Good Luck Champ!
                                                            </div>
                                                        </div>

                                                        {cbt_task.timer !== task.time * 60 ?
                                                            <div className="col-lg-4 cursor-pointer me-lg-8 mb-4">
                                                                <Link to={"../" + id + "/work/" + token + "/start"} className="card bg-light-warning shadow-none"
                                                                    style={{ borderRadius: "18px" }}>
                                                                    <div className="card-body">
                                                                        <div className="col-4 col-lg-3">
                                                                            <img src="../../assets/images/background/7.png" alt=""
                                                                                class="img-fluid" />
                                                                        </div>
                                                                        <div className="row d-flex fs-3 text-dark fw-medium mt-2">
                                                                            <div className="col-10 text-start text-uppercase">Continue {truncate(task.title, 15)}</div>
                                                                            <div className="col-2 text-end">
                                                                                <i class="bi bi-arrow-right"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="col-lg-4 cursor-pointer me-lg-8 mb-4">
                                                                    <Link to={"../" + id + "/work/" + token + "/start"} className="card bg-light-primary shadow-none"
                                                                        style={{ borderRadius: "18px" }}>
                                                                        <div className="card-body">
                                                                            <div className="col-4 col-lg-3">
                                                                                <img src="../../assets/images/background/7.png" alt=""
                                                                                    class="img-fluid" />
                                                                            </div>
                                                                            <div className="row d-flex fs-3 text-dark fw-medium mt-2">
                                                                                <div className="col-10 text-start text-uppercase">Begin {truncate(task.title, 15)}</div>
                                                                                <div className="col-2 text-end">
                                                                                    <i class="bi bi-arrow-right"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }

                                            </div>

                                            <div className="row g-5 pt-8">
                                                <div className="col-12">
                                                    <div className="fs-t text-purple3 fw-medium">
                                                        Other {task.type} For {task.class}
                                                    </div>
                                                </div>

                                                {renderFolders()}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <ToastContainer />

                                <Footer />

                                <div
                                    class="btn btn-light-primary fs-10 text-dark shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2 float mb-lg-0"
                                    data-bs-toggle="modal"
                                    data-bs-target="#Handlesettings"
                                >
                                    <i class="bi bi-chat-left-quote-fill"></i>
                                </div>

                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default Home_Work