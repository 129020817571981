import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { isMobile } from "react-device-detect";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

import Forms from "./Forms";

import LoadingBar from "react-top-loading-bar";

import Auth from "../../Auth";

import Activate from "../Activate";

import parse from 'html-react-parser';

const Preview = () => {
  const { quill, quillRef } = useQuill();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const cbt = getusers.cbt;

  const teachid = getusers.teachid;

  const app_status = getusers.app_status;

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const [progress, setProgress] = useState(20);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3";
  } else {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3 px-6";
  }

  const [typing, setTyping] = useState(false);

  const [panel, setPanel] = useState(false);

  const [ans1, setA] = useState(null);

  const [ans2, setB] = useState(null);

  const [ans3, setC] = useState(null);

  const [ans4, setD] = useState(null);

  const [anscor, setAns] = useState(null);

  const [question, setQues] = useState(null);

  const [status, setStat] = useState(false);

  const [task, setTask] = useState('Instruction');

  const { token } = useParams();

  const { type } = useParams();

  const [cbt_task, setCBT] = useState([]);

  const FetchCBT = () => {
    axios({
      method: "post",
      url: "./v2/exercise/fetchs/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (!view.token) {
          setTimeout(function () {
            //navigate("./denied");
          }, 3000);
        } else if (view.message === "failed") {
        } else {
          setCBT(view);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };


  const [files, setFile] = useState([]);

  useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 1000);
    FetchCBT();
  }, [token, teachid]);


  const SearchCBTQues = (search) => {
    axios({
      method: "post",
      url: "./v2/exercise/question_list/",
      data: {
        token,
        q: search,
        teachid
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setFile(view);

          setLoader(true);

          FetchCBT();

          setTimeout(function () {
            setLoader(false);
          }, 500);
        } else {
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [loader, setLoader] = useState(false);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const renderFiles = () => {
    if (files == "") {
      return (
        <>

        </>
      );
    } else if (files) {
      return files.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            {task.info === "Instruction" ? (
              <>

                <div className="col-12 col-lg-6 mb-6">
                  <div className="rounded-3 bg-light-primary">
                    <div className="p-4">
                      <h2 class="h2 text-dark">Instruction</h2>

                      <div className="fs-4 text-inherit">
                        Click the button below to view and edit this instruction.
                      </div>
                      {isMobile ?
                        <a class="btn btn-white mt-4" data-bs-toggle="modal" data-bs-target="#MobileEdit"
                          onClick={() => FetchQuesid(task.row)}>Preview & Edit</a>
                        :
                        <a class="btn btn-white mt-4" onClick={() => FetchQuesid(task.row)}>Preview & Edit</a>
                      }
                    </div>
                  </div>
                </div>

              </>
            ) : task.info === "Question" ? (
              <>
                <div className="col-12 col-lg-6 mb-6">
                  <div className="rounded-3 bg-light-success">
                    <div className="p-4">
                      <h2 class="h2 text-dark">Question {task.id}</h2>

                      <div className="fs-4 text-inherit">
                        Click the button below to view and edit this Question.
                      </div>
                      {isMobile ?
                        <a class="btn btn-white mt-4" data-bs-toggle="modal" data-bs-target="#MobileEdit"
                          onClick={() => FetchQuesid(task.row)}>Preview & Edit</a>
                        :
                        <a class="btn btn-white mt-4" onClick={() => FetchQuesid(task.row)}>Preview & Edit</a>
                      }
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (quill) {
      //quill.clipboard.dangerouslyPasteHTML("<h1>React Hook for Quill!</h1>");

      quill.on("text-change", (delta, oldDelta, source) => {
        //console.log("Text change!");
        //console.log(quill.root.innerHTML); // Get innerHTML using quill
        //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        setQues(quillRef.current.firstChild.innerHTML);

      });

    }
  }, [quill]);

  const setQuestion = (ques) => {
    setQues(ques);
    setQuest(null);
  };

  const [info, setInfo] = useState(null);

  const [quesid, setID] = useState(null);

  const [rowid, setRowID] = useState(null);

  const [quest, setQuest] = useState(null);

  const FetchQuesid = (numid) => {
    axios({
      method: "post",
      url: "./v2/exercise/question_fetch/",
      data: {
        token,
        q: numid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        if (quill) {
          quill.clipboard.dangerouslyPasteHTML(view.ques);
        }

        if (view.message !== "failed") {
          setQuest(view.ques);
          setInfo(view.info);
          setTask(view.info);
          setID(view.id);

          setA(view.ans1);
          setB(view.ans2);
          setC(view.ans3);
          setD(view.ans4);

          setRowID(numid);

          setTyping(true);

          setAns(view.anscor);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [search, setSearch] = useState(null);

  const onSubmit_ = (e) => {
    e.preventDefault();

    const form = document.getElementById("form");

    setStat(true);

    const cbt = {
      ans1,
      ans2,
      ans3,
      ans4,
      anscor,
      question,
      teachid,
      token,
      quesid: rowid,
      status: task,
    };

    //console.table(cbt);

    if (question === null) {
      toast.error("Empty: Field can't be empty!", {
        hideProgressBar: true,
        draggable: true,
        position: "bottom-right",
        icon: true,
        autoClose: 6000,
      });

      setTimeout(function () {
        setStat(false);
      }, 3000);
    } else {
      axios({
        method: "post",
        url: "/v2/exercise/question_edit/",
        data: cbt,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          //console.log(response);
          if (response.data.message === "success") {
            toast.success("Success: CBT Question updated successfully!", {
              hideProgressBar: true,
              draggable: true,
              position: "bottom-right",
              icon: true,
              autoClose: 6000,
            });

            SearchCBTQues(search);

            FetchCBT();

            setTyping(false);

            setTimeout(function () {
              setStat(false);
            }, 2000);

            localStorage.setItem("token", response.data.token);
          } else {
            toast.error("Failed: Request Failed! Please try again", {
              hideProgressBar: true,
              draggable: true,
              position: "bottom-right",
              icon: true,
              autoClose: 6000,
            });

            setTimeout(function () {
              setStat(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error("Error: Request Failed! Check network connection.");
        });

      form.reset();
    }
  };

  const DeleteQues = (quesid) => {

    axios({
      method: "post",
      url: "/v2/exercise/question_delete/",
      data: {
        token,
        quesid,
        teachid
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: CBT Question updated successfully!", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });

          SearchCBTQues(null);

        } else {
          toast.error("Failed: Request Failed! Please try again", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });

  }

  const [anscorr, setAnsw] = useState(null);

  const Selected = (anscorr) => {

    if (anscorr == anscor) {
      toast.success("Passed: Correct answer has been selected!", {
        hideProgressBar: true,
        draggable: true,
        position: "bottom-left",
        icon: true,
        autoClose: 6000,
      });

    } else {
      toast.error("Failed: Wrong answer has been selected", {
        hideProgressBar: true,
        draggable: true,
        position: "bottom-left",
        icon: true,
        autoClose: 6000,
      });

    }

  }


  const [cbt_name, setName] = useState(null);

  const [studclass, setStudclass] = useState(null);

  const [subject, setSubject] = useState(null);

  const [duration, setTime] = useState(null);

  const [durations, setTimes] = useState(null);

  const [password, setPassword] = useState(null);


  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  if (duration > 10) {
    setTime("10");
    toast.error("Timer can't be more than 10 minutes", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 4000,
    });
  } else {
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      cbt_name,
      studclass,
      subject,
      duration,
      password,
      teachid,
      cbt_type: cbt_task.type,
      token
    };

    axios({
      method: "post",
      url: "/v2/exercise/edit/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success(
            "Success: " + subject + " has been updated!",
            {
              hideProgressBar: true,
              draggable: true,
              position: "top-right",
              icon: true,
              autoClose: 6000,
            }
          );

          setTimeout(function () {
            setStat(false);
          }, 2000);

          localStorage.setItem("token", response.data.token);

        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });


          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [subjects, setSubjects] = useState([{}]);

  useEffect(() => {
    axios({
      method: "post",
      url: "./v2/subject/list/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSubjects(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  }, [teachid]);

  const renderSubject = () => {
    if (subjects == "") {
      return <></>;
    } else if (subjects) {
      return subjects.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <option value={task.subject}>{task.subject}</option>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Auth />
      <div
        className={isMobile ? `bg-white` : ``}
        onLoad={() => setProgress(100)}
      >

        <nav class={headers}
          style={
            isMobile
              ? {}
              : {}
          }>
          <div className="container-fluid">
            <div class="navbar-brand">

              <span className="fs-t text-purple3"><i class="bi bi-window-sidebar"></i> Exercise Workspace</span>

            </div>

            <div className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
              <Link to={"../" + id + "/exercise"}
                class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
              >
                <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
              </Link>
            </div>

          </div>
        </nav>

        <div className="">

          <div class="container-fluid px-0 px-md-6 py-8">

            {app_status === "InActive" ? (
              <div class="container-fluid px-4 px-lg-4">
                <Activate />
              </div>
            ) : (
              <>
                <div class="container-fluid px-4 px-md-2">
                  <div className="row pt-md-8 pt-8 g-4">

                    <div className="col-lg-7">

                      <div className="row">
                        <div className="col-7 col-lg-8">

                          <button className="btn btn-light-success rounded-pill px-2">
                            {cbt_task.class} - {truncate(cbt_task.subject, 12)} {!isMobile ? <>; {truncate(cbt_task.title, 16)}</> : <></>}
                          </button>

                        </div>

                        <div className="col-5 col-lg-4 text-end">
                          <button className="btn btn-dark rounded-pill"
                            data-bs-toggle="modal" data-bs-target="#HandleEdit">
                            <i class="bi bi-sliders"></i> Edit Exercise
                          </button>
                        </div>

                        <div className="col-lg-12 pt-8">
                          <div className="text-dark fs-3">
                            <span className="text-dark">Recent Uploads</span> <i class="bi bi-upload"></i>
                          </div>
                        </div>

                        <div className="col-lg-12 mt-4">

                          {!panel ?
                          <div className="text-center py-lg-12 py-20" onClick={()=>setPanel(true)}
                          onTouchMove={() => SearchCBTQues(null)} onMouseOver={() => SearchCBTQues(null)}>
                          <div class="lds-heart"><div></div></div>
                          <div className="mt-3 pb-20 pb-lg-0">
                            <div className="fs-3 text-dark">
                              Touch the heart to open uploads. Remember we love you! 🥰 
                            </div>
                          </div>
                          </div>
                          :
                          <div className="row mb-20 mb-lg-0">

                            {renderFiles()}

                            <Link to={"../" + id + "/exercise/" + token + "/edit/" + cbt_task.task_type} 
                            className="col-12 col-lg-6 mb-20 mb-6">
                              <div className={isMobile ? "rounded-3 bg-back" : "rounded-3 bg-white"}>
                                <div className="py-lg-6 px-lg-4 py-4 px-4 text-center text-dark">
                                  <div class="display-1"><i class="bi bi-plus-lg"></i></div>
                                  <p class="mt-3">
                                    Add New Work
                                  </p>
                                </div>
                              </div>
                            </Link>

                          </div>
                          }

                        </div>

                      </div>

                    </div>

                    {isMobile ?
                      <></> :
                      <div className="col-lg-5">

                        <div className="card bg-white position-fixed ms-3 mt-n8 shadow-none">

                          <div className="card-body mb-12">
                            {typing ?
                              <div className="py-20">

                                <div className="mt-n20">

                                  <div className="mb-2 col-6">
                                    <div className="fs-t text-purple3">
                                      Edit {task}{" "}
                                      {task === "Question" ? <>{quesid}</> : <></>}
                                    </div>
                                    <hr className="my-1 text-danger" />
                                  </div>

                                  <div className="">
                                    <form className="mt-lg-0" onSubmit={onSubmit_} id="form">

                                      <Forms setQuestion={setQuestion} quest={quest} />

                                      {task === "Instruction" ? (
                                        <div className="pt-4 mt-12 mt-lg-6 mt-md-6"></div>
                                      ) : task === "Question" ? (
                                        <>
                                          {cbt_task.task_type === "Multi" ?
                                            <div className="row g-2 mt-6 mt-md-10">
                                              <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                  Option A
                                                </label>
                                                <input
                                                  type="text"
                                                  autoComplete={false}
                                                  class="form-control form-control-lg bg-back text-dark"
                                                  style={{
                                                    borderRadius: "24px"
                                                  }}
                                                  placeholder="Enter option A"
                                                  required
                                                  value={ans1}
                                                  onChange={(e) => setA(e.target.value)}
                                                />
                                              </div>

                                              <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                  Option B
                                                </label>
                                                <input
                                                  type="text"
                                                  autoComplete={false}
                                                  class="form-control form-control-lg bg-back text-dark"
                                                  style={{
                                                    borderRadius: "24px"
                                                  }}
                                                  placeholder="Enter option B"
                                                  required
                                                  value={ans2}
                                                  onChange={(e) => setB(e.target.value)}
                                                />
                                              </div>

                                              <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                  Option C
                                                </label>
                                                <input
                                                  type="text"
                                                  autoComplete={false}
                                                  class="form-control form-control-lg bg-back text-dark"
                                                  style={{
                                                    borderRadius: "24px"
                                                  }}
                                                  placeholder="Enter option C"
                                                  required
                                                  value={ans3}
                                                  onChange={(e) => setC(e.target.value)}
                                                />
                                              </div>

                                              <div className="col-md-6">
                                                <label class="form-label fs-5 text-dark" for="number">
                                                  Option D
                                                </label>
                                                <input
                                                  type="text"
                                                  autoComplete={false}
                                                  class="form-control form-control-lg bg-back text-dark"
                                                  style={{
                                                    borderRadius: "24px"
                                                  }}
                                                  placeholder="Enter option D"
                                                  required
                                                  value={ans4}
                                                  onChange={(e) => setD(e.target.value)}
                                                />
                                              </div>

                                              <div className="col-md-12">
                                                <label class="form-label fs-4 text-primary fw-bold">
                                                  Choose Correct Answer Option
                                                </label>
                                                <select
                                                  class="form-select form-select-lg bg-back text-dark"
                                                  style={{
                                                    borderRadius: "24px"
                                                  }}
                                                  aria-label="Default select example"
                                                  value={anscor}
                                                  onChange={(e) => setAns(e.target.value)}
                                                >
                                                  <option value="">Select Correct Option</option>
                                                  <option value="A">A</option>
                                                  <option value="B">B</option>
                                                  <option value="C">C</option>
                                                  <option value="D">D</option>
                                                </select>
                                              </div>
                                            </div>
                                            :
                                            <div className="mb-12"></div>
                                          }
                                        </>
                                      ) : task === "Media" ? (
                                        <div className="pt-4 mt-2 mt-lg-4 mt-md-6">
                                          <div className="col-12 col-md-12 col-lg-6 offset-lg-3 text-center">
                                            <img
                                              src="../../assets/images/background/coming.svg"
                                              className=""
                                              width="100%"
                                            />
                                            <div className="text-purple2 fs-3">
                                              Amazing Feature Coming soon...
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}

                                      <div
                                        className={
                                          task === "Media"
                                            ? "col-lg-12 mb-lg-0 mb-5 text-end d-none"
                                            : "col-lg-12 mt-3 mb-lg-20 mb-5 text-end"
                                        }
                                      >
                                       
                                          <button
                                            className={
                                              task === "Instruction"
                                                ? "btn btn-dark-primary mt-3 btn-md shadow-none rounded-3"
                                                : "btn btn-dark-success btn-md shadow-none rounded-3"
                                            }
                                            disabled={status ? true : false}
                                          >
                                            {status ? (
                                              <>
                                                Creating...
                                                <span
                                                  class="spinner-border spinner-border-sm ms-3"
                                                  role="status"
                                                  aria-hidden="true"
                                                ></span>
                                              </>
                                            ) : (
                                              <>
                                                Save {task}{" "}
                                                <i class="bi bi-arrow-right ms-1"></i>
                                              </>
                                            )}
                                          </button>
                                        
                                      </div>
                                    </form>
                                  </div>

                                </div>

                                {cbt_task.task_type === "Multi" ?
                                <></>
                                :
                                <div className="mt-n16">
                                  <div className="col-lg-12">
                                    <div class="container bg-light-warning rounded-3">

                                      <div class="row align-items-center py-3">

                                        <div class="col-lg-5 col-12 d-none d-lg-block">
                                          <div class="d-flex justify-content-center ">

                                            <div class="">
                                              <img src="../../assets/images/background/admin.png" alt="" class="img-fluid mt-n8" style={{ maxWidth: '100%' }} />
                                            </div>
                                          </div>
                                        </div>
                                        <div class="col-lg-7 col-12">
                                          <div class="text-white p-5 p-lg-0">
                                            <div class="mb-0 fs-4 text-dark">
                                              Hello there!
                                            </div>

                                            <div class="mb-0 fs-4 text-dark mt-2">
                                              This is to inform you that this feature allows you score the exercise
                                              yourself! Leaving our AI system completely out of the equation. Now you completely
                                              take full control of the marks and the scoring.
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                }

                              </div>
                              :
                              <div className="">
                                <div className="text-center mb-lg-20">

                                  <div className="p-5">
                                    <div className="py-8">
                                      <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "60%"} alt="" />
                                    </div>
                                    <div className="text-dark fs-t">
                                      Waiting for you....
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </div>

                        </div>

                      </div>
                    }

                  </div>
                </div>
              </>
            )}


            <div class="modal" id="HandleEdit" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true">
              <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                role="document">
                <div class="modal-content curve1" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                  <div class="modal-header border-0 py-4">
                    <div class="cursor-pointer fs-t text-dark" id="exampleModalCenterTitle"
                    ><i class="bi bi-sliders"></i> Edit {cbt_task.title} </div>

                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                      data-bs-dismiss="modal" aria-label="Close">
                      <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                    </button>

                  </div>
                  <div class="modal-body py-0">

                    <div className="px-lg-6 px-2">

                      <div className="pb-5 pt-3">
                        <div className="fs-3 text-dark">
                          Fill the details below to edit the exercise below.
                        </div>
                      </div>
                      <form className='pt-2 pt-lg-1' onSubmit={onSubmit}>
                        <div className="row g-3">
                          <div class="col-lg-12">
                            <label class="form-label fs-4 text-dark" for="number">
                              Exercise Quiz Name
                            </label>
                            <input
                              type="text"
                              id="name-input1"
                              autoComplete={false}
                              class="form-control form-control-lg bg-back"
                              placeholder="Enter preferred name"
                              required
                              style={{
                                borderRadius: "24px"
                              }}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>

                          <div class="col-lg-12">
                            <label class="form-label fs-4 text-dark" for="number">
                              Exercise Subject
                            </label>
                            <select
                              class="form-select form-select-lg bg-back text-dark"
                              aria-label="Default select example"
                              style={{
                                borderRadius: "24px"
                              }}
                              onChange={(e) => setSubject(e.target.value)}
                            >
                              <option value="">Select Subject</option>
                              {renderSubject()}
                            </select>
                          </div>

                          <div class="col-lg-6">
                            <label class="form-label fs-4 text-dark" for="number">
                              Exercise Class Type
                            </label>
                            <select
                              class="form-select form-select-lg bg-back text-dark"
                              aria-label="Default select example"
                              name="studclass"
                              style={{
                                borderRadius: "24px"
                              }}
                              onChange={(e) => setStudclass(e.target.value)}
                            >
                              <option value="">Select Class</option>
                              <option value="PlayGroup">PlayGroup</option>
                              <option value="Reception">Reception</option>
                              <option value="Pre Nursery">Pre Nursery</option>
                              <option value="NUR 1">NUR 1</option>
                              <option value="NUR 2">NUR 2</option>
                              <option value="KG 1">KG 1</option>
                              <option value="KG 2">KG 2</option>
                              <option value="Grade 1">Grade 1</option>
                              <option value="Grade 2">Grade 2</option>
                              <option value="Grade 3">Grade 3</option>
                              <option value="Grade 4">Grade 4</option>
                              <option value="Grade 5">Grade 5</option>
                              <option value="Grade 6">Grade 6</option>
                              <option value="Jss1">JSS 1</option>
                              <option value="Jss2">JSS 2</option>
                              <option value="Jss3">JSS 3</option>
                              <option value="sss1">SSS 1</option>
                              <option value="sss2">SSS 2</option>
                              <option value="sss3">SSS 3</option>
                            </select>
                          </div>

                          <div class="col-lg-6">
                            <label class="form-label fs-4 text-dark" for="number">
                              Exercise Time Duration
                            </label>
                            <input
                              type="number"
                              maxLength={2}
                              value={duration}
                              autoComplete={false}
                              class="form-control form-control-lg bg-back"
                              placeholder="Enter Quiz Time"
                              required
                              style={{
                                borderRadius: "24px"
                              }}
                              onChange={(e) => setTime(e.target.value)}
                            />
                          </div>

                          <div class="col-lg-12">
                            <label class="form-label fs-4 text-dark" for="number">
                              Exercise Access Code
                            </label>
                            <div class="input-group" style={{
                              borderRadius: "24px"
                            }}>
                              <input
                                type={passwordType}
                                class="form-control form-control-lg bg-back"
                                placeholder="New password"
                                required
                                style={{
                                  borderRadius: "24px"
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <span
                                class="input-group-text bg-back cursor-pointer"
                                id="basic-addon2"
                                onClick={togglePassword}
                              >
                                {passwordType === "text" ? (
                                  <>
                                    <i className="bi bi-eye-slash me-2"></i> hide
                                  </>
                                ) : (
                                  <>
                                    <i class="bi bi-eye me-2"></i> show
                                  </>
                                )}
                              </span>
                            </div>
                          </div>

                          <div class="col-lg-12 mb-4 mt-6 text-center">
                            <button
                              type="submit"
                              class="btn btn-lg btn-dark text-white mb-2 px-8"
                              disabled={status ? true : false}
                              data-bs-dismiss={duration ? "offcanvas" : ""}
                              style={{
                                borderRadius: "24px"
                              }}
                            >
                              {status ? (
                                <>
                                  Creating...
                                  <span
                                    class="spinner-border spinner-border-sm ms-3"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                <>
                                  Edit Task Exercise
                                </>
                              )}
                            </button>
                          </div>
                        </div>

                      </form>
                    </div>

                    <div className={isMobile ? "" : ""}>
                      <div class="pb-8 pt-6">
                        <div className="col-lg-12">
                          <div class="container-fluid bg-success" style={{ borderRadius: "25px" }}>
                            <div class="row align-items-center py-lg-3 py-0">

                              <div class="col-lg-2 col-4 text-end">
                                <div class="d-flex justify-content-center ">

                                  <div class="">
                                    <img src="../../assets/images/background/2.png" alt="" class="img-fluid mt-lg-n4 mt-n6" style={{ maxWidth: '100%' }} />
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-10 col-8 text-start">
                                <div class="text-white p-3 p-lg-0">
                                  <div class="mb-0 fs-4 text-white">
                                    Hello there! You can only share the exercise by clicking the share button from the
                                    exercise tiles on exercise page.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
            </div>


            <div class="modal" id="MobileEdit" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true">
              <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                role="document">
                <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                  <div class="modal-header border-0 py-4">
                    <div class="cursor-pointer fs-t text-dark" id="exampleModalCenterTitle">
                      <div className="fs-t text-purple3">
                        Edit {task}{" "}
                        {task === "Question" ? <>{quesid}</> : <></>}
                      </div>
                      <hr className="my-1 text-danger" />
                    </div>

                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                      data-bs-dismiss="modal" aria-label="Close">
                      <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                    </button>

                  </div>
                  <div class="modal-body py-0">

                    <div className="">

                      <div className="">
                        <form className="mt-lg-0" onSubmit={onSubmit_} id="form">

                          <Forms setQuestion={setQuestion} quest={quest} />

                          {task === "Instruction" ? (
                            <div className="pt-4 mt-12 mt-lg-6 mt-md-6"></div>
                          ) : task === "Question" ? (
                            <>
                              {cbt_task.task_type === "Multi" ?
                                <div className="row g-2 mt-5 mt-md-10">
                                  <div className="col-md-6">
                                    <label class="form-label fs-5 text-dark" for="number">
                                      Option A
                                    </label>
                                    <input
                                      type="text"
                                      autoComplete={false}
                                      class="form-control form-control-lg bg-back text-dark"
                                      style={{
                                        borderRadius: "24px"
                                      }}
                                      placeholder="Enter option A"
                                      required
                                      value={ans1}
                                      onChange={(e) => setA(e.target.value)}
                                    />
                                  </div>

                                  <div className="col-md-6">
                                    <label class="form-label fs-5 text-dark" for="number">
                                      Option B
                                    </label>
                                    <input
                                      type="text"
                                      autoComplete={false}
                                      class="form-control form-control-lg bg-back text-dark"
                                      style={{
                                        borderRadius: "24px"
                                      }}
                                      placeholder="Enter option B"
                                      required
                                      value={ans2}
                                      onChange={(e) => setB(e.target.value)}
                                    />
                                  </div>

                                  <div className="col-md-6">
                                    <label class="form-label fs-5 text-dark" for="number">
                                      Option C
                                    </label>
                                    <input
                                      type="text"
                                      autoComplete={false}
                                      class="form-control form-control-lg bg-back text-dark"
                                      style={{
                                        borderRadius: "24px"
                                      }}
                                      placeholder="Enter option C"
                                      required
                                      value={ans3}
                                      onChange={(e) => setC(e.target.value)}
                                    />
                                  </div>

                                  <div className="col-md-6">
                                    <label class="form-label fs-5 text-dark" for="number">
                                      Option D
                                    </label>
                                    <input
                                      type="text"
                                      autoComplete={false}
                                      class="form-control form-control-lg bg-back text-dark"
                                      style={{
                                        borderRadius: "24px"
                                      }}
                                      placeholder="Enter option D"
                                      required
                                      value={ans4}
                                      onChange={(e) => setD(e.target.value)}
                                    />
                                  </div>

                                  <div className="col-md-12">
                                    <label class="form-label fs-4 text-primary fw-bold">
                                      Choose Correct Answer Option
                                    </label>
                                    <select
                                      class="form-select form-select-lg bg-back text-dark"
                                      style={{
                                        borderRadius: "24px"
                                      }}
                                      aria-label="Default select example"
                                      value={anscor}
                                      onChange={(e) => setAns(e.target.value)}
                                    >
                                      <option value="">Select Correct Option</option>
                                      <option value="A">A</option>
                                      <option value="B">B</option>
                                      <option value="C">C</option>
                                      <option value="D">D</option>
                                    </select>
                                  </div>
                                </div>
                                :
                                <div className="pt-4 mt-12 mt-lg-6 mt-md-6"></div>
                              }
                            </>
                          ) : task === "Media" ? (
                            <div className="pt-4 mt-2 mt-lg-4 mt-md-6">
                              <div className="col-12 col-md-12 col-lg-6 offset-lg-3 text-center">
                                <img
                                  src="../../assets/images/background/coming.svg"
                                  className=""
                                  width="100%"
                                />
                                <div className="text-purple2 fs-3">
                                  Amazing Feature Coming soon...
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div
                            className={
                              task === "Media"
                                ? "col-lg-12 mb-lg-0 mb-5 text-end d-none"
                                : "col-lg-12 mt-3 mb-lg-0 mb-5 text-end"
                            }
                          >
                            {cbt_task.id === 10 ?
                              <></> :
                              <button
                                className={
                                  task === "Instruction"
                                    ? "btn btn-dark-primary mt-3 btn-md shadow-none rounded-3"
                                    : "btn btn-dark-success mt-3 btn-md shadow-none rounded-3"
                                }
                                disabled={status ? true : false}
                              >
                                {status ? (
                                  <>
                                    Creating...
                                    <span
                                      class="spinner-border spinner-border-sm ms-3"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </>
                                ) : (
                                  <>
                                    Save & Next Question{" "}
                                    <i class="bi bi-arrow-right ms-1"></i>
                                  </>
                                )}
                              </button>
                            }
                          </div>
                        </form>
                      </div>

                      <div className="pt-6 pb-8">
                        <div className="col-lg-12">
                          <div class="container bg-light-warning rounded-3">

                            <div class="row align-items-center py-lg-3">

                              <div class="col-lg-5 col-4">
                                <div class="d-flex justify-content-center ">

                                  <div class="">
                                    <img src="../../assets/images/background/admin.png" alt="" class="img-fluid mt-n8" style={{ maxWidth: '100%' }} />
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-7 col-8">
                                <div class="text-white p-3 p-lg-0">
                                  <div class="mb-0 fs-4 text-dark">
                                    Hello there!
                                  </div>

                                  <div class="mb-0 fs-4 text-dark mt-2">
                                    This is to inform you that this feature allows you score the exercise
                                    yourself! Leaving our AI system completely out of the equation. Now you completely
                                    take full control of the marks and the scoring.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>


          </div>

        </div>

        <ToastContainer />

      </div>
    </>
  );
};

export default Preview;
