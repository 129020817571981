import React, { useState, useEffect, useRef } from "react";

import Aside from "../../Aside";

import Footer from "../../Footer";

import Auth from "../../../Auth";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import Activate from "../../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import Header_ from "../../Header_";

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import 'react-quill/dist/quill.bubble.css';

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Chat_list from "./Chat_list";

import parse from 'html-react-parser';

const Response = () => {

    const [loaders, setLoaders] = useState(true);

    const navigate = useNavigate();

    const { id } = useParams();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const teachid = getusers.teachid;

    const phone = getusers.phone;

    const email = getusers.email;

    const imageid = getusers.image;

    const school_name = getusers.school_name;

    const [progress, setProgress] = useState(20);

    const [fullscreen, setFullscreen] = useState(false);

    window.history.pushState(null, "", window.location.href);

    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-2";
    }

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    const [loader, setLoader] = useState(true);

    const [loader_, setLoader_] = useState(false);


    useEffect(() => {
        setTimeout(function () {
            setLoader(false);
        }, 4000);

        localStorage.setItem("id", teachid);

        FetchUsers(null);

        FetchUser1(null);

        FetchUser2(null);

        FetchUser3(null);

        Fetch_Active_users(null);

    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const [users, setUsers] = useState([]);

    const FetchUsers = (search) => {
        axios({
            method: "post",
            url: "./v2/note/user/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setUsers(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const renderFolders = () => {
        if (users == "") {
            return (
                <>
                    <div className="col-12">
                        <div className="shadow-none text-center" style={{ borderRadius: "15px" }}>
                            <div className="p-5">
                                <div className="">
                                    <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "25%"} alt="" />
                                </div>
                                <div className="text-dark fs-t mt-lg-4 mt-6">
                                    No Recent Comments..
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (users) {
            return users.slice(0, count).map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <div className="col-12 col-lg-6">
                            <div className={(index + 1) % 4 == 0 || index === 0 ? "card bg-light-primary shadow-none curve3"
                                : (index + 1) % 2 == 0 ? "card bg-light-info shadow-none curve1"
                                    : "card bg-light-warning shadow-none curve3"
                            }
                                style={{ borderRadius: "25px" }}>
                                <div className="px-lg-4 py-lg-5 px-4 py-5">
                                    <div className="fs-t text-purple2">
                                        {task.fname}
                                    </div>

                                    <div className="mt-3 mb-2">
                                        <div className="fs-4 text-purple2">
                                            {truncate(task.comment.replaceAll(/<\/?[^>]+(>|$)/gi, ""), 34)}
                                        </div>
                                    </div>

                                    <div className="text-end mt-n4 mt-lg-0">
                                        <button className="btn btn-dark rounded-pill" data-bs-toggle="modal"
                                            data-bs-target="#Handle_Comments" onClick={() => {
                                                Fetch_comment(task.token);
                                                setToken(task.token)
                                            }}>
                                            <span className="bi bi-arrow-right fs-3"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                );
            });
        } else {
            return (
                <>
                    <div className="">
                        <div className="shadow-none text-center" style={{ borderRadius: "15px" }}>
                            <div className="p-5">
                                <div className="">
                                    <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "60%"} alt="" />
                                </div>
                                <div className="text-dark fs-t">
                                    No Recent Comments..
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    };


    const [note, setNote] = useState([]);


    const Fetch_comment = (token) => {

        axios({
            method: "post",
            url: "./v2/note/getuser/",
            data: {
                teachid,
                token
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setNote(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });

    }

    const [comment, setCom] = useState(null);

    const [tokens, setToken] = useState(null);

    const HandleCom = (e) => {

        e.preventDefault();

        setStat_(true);

        const task = {
            comment,
            token: tokens,
            teachid
        };

        //console.log(task);
        axios({
            method: "post",
            url: "/v2/note/response/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    toast.success("Success: Message sent!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    FetchUsers(null);

                    Fetch_comment(tokens);

                } else {
                    toast.error("Failed: Request Failed! Please try again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    }

    const [menu, setMenu] = useState('Note');

    const [open, setOpen] = useState(true);

    const [status, setStat] = useState(false);

    const [count, setCount] = useState(2);

    const FetchData = (num) => {

        setCount(num);

        setStat(true);

        setTimeout(function () {
            setStat(false);
            FetchUsers(null);
        }, 6000);

    }

    const [studs, setStuds] = useState([]);

    const [teachs, setTeachs] = useState([]);

    const [admins, setAdmins] = useState([]);

    const [stud, setStud] = useState([]);

    const FetchUser1 = (search) => {
        axios({
            method: "post",
            url: "./v2/students/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setStuds(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const FetchUser2 = (search) => {

        axios({
            method: "post",
            url: "./v2/teachers/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setTeachs(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const FetchUser3 = (search) => {

        axios({
            method: "post",
            url: "./v2/admins/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setAdmins(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const [name, setName] = useState(null);

    const [seen, setSeen] = useState(null);


    const getUsers = (studid) => {

        setSeen(null);

        axios({
            method: "post",
            url: "./v2/student/",
            data: {
                teachid,
                q: studid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setStud(view);

                setSeen(view.seen);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    }

    const RefgetUsers = (studid) => {

        getUsers(studid);

        if (stud.seen === 'Online') {
            setInterval(function () {
                getUsers(studid);
            }, 6000);
        } else {

        }
    }

    const [actives, setActives] = useState([]);

    const Fetch_Active_users = (search) => {
        axios({
            method: "post",
            url: "./v2/chat/users/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                setActives(view);
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const Delete_User = (chatid) => {

        const task = {
            teachid,
            chatid
        };

        axios({
            method: "post",
            url: "/v2/chat/delete/user/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    toast.success("Success: User Chat has been deleted!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    Fetch_Active_users(null);

                } else {
                    toast.error("Failed: Request Failed! Please try again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    };

    const renderActiveUsers = () => {
        if (actives == "") {
            return (
                <>

                </>
            );
        } else if (actives) {
            return actives.map((active, index) => {
                //console.log(school.tm_date)

                return (
                    <>
                        <li class="py-4 py-lg-3 px-lg-4 chat-item contacts-item" onClick={() => {
                            setUser(active.receiv_id);
                            setImage(active.receiv_id);
                            RefgetUsers(active.receiv_id);
                            setName(active.receive);
                            setValue('Hello ' + active.receive + ',');
                            FetchChats(null, active.receiv_id);
                            setLoader_(true);
                            setTimeout(function () {
                                setLoader_(false);
                            }, 2000);
                        }}>

                            <div data-bs-toggle="modal" data-bs-target="#Handle_Msg">
                                <Chat_list chatid={active.chatid} />
                            </div>

                            <div class="chat-actions">

                                <div class="dropdown dropstart">
                                    <a href="#" class="btn btn-white btn-icon btn-sm rounded-circle primary-hover" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fe fe-more-horizontal fs-3"></i>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                        <a class="dropdown-item cursor-pointer" data-bs-toggle="modal"
                                            data-bs-target="#Handle_Msg"><i class="bi-chat dropdown-item-icon"></i>Open</a>
                                        <a class="dropdown-item cursor-pointer" onClick={() => Delete_User(active.chatid)}>
                                            <i class="bi-trash dropdown-item-icon"></i>Delete</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </>
                );
            });
        } else {
            return (
                <>

                </>
            );
        }
    };

    const renderStuds = () => {
        if (studs == "") {
            return (
                <>

                </>
            );
        } else if (studs) {
            return studs.slice(0, 2).map((stud, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <li><a class="dropdown-item d-flex align-items-center gap-2 py-2 cursor-pointer" onClick={() => {
                            setUser(stud.studid);
                            setImage(stud.image);
                            RefgetUsers(stud.studid);
                            setName(stud.fname + ' ' + stud.lname);
                            setValue('Hello ' + stud.fname + ' ' + stud.lname + ',');
                            FetchChats(null, stud.studid);
                            setLoader_(true);
                            setTimeout(function () {
                                setLoader_(false);
                            }, 2000);
                        }} data-bs-toggle="modal" data-bs-target="#Handle_Msg">
                            <span><img src={"../" + id + "/profiles/" + stud.image + (stud.image === "MALE" ||
                                stud.image === "FEMALE" ? ".png" : ".jpg")} alt=""
                                class="rounded-circle avatar-md" /></span>
                            {stud.fname + ' ' + stud.lname}
                        </a></li>
                    </>
                );
            });
        } else {
            return (
                <>

                </>
            );
        }
    };

    const renderTeachs = () => {
        if (teachs == "") {
            return (
                <>

                </>
            );
        } else if (teachs) {
            return teachs.slice(0, 2).map((teach, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <li><a class="dropdown-item d-flex align-items-center gap-2 py-2 cursor-pointer" onClick={() => {
                            setUser(teach.teachid);
                            setImage(teach.image);
                            RefgetUsers(teach.studid);
                            setName(teach.fname + ' ' + teach.lname);
                            setValue('Hello ' + teach.fname + ' ' + teach.lname + ',');
                            FetchChats(null, teach.studid);
                            setLoader_(true);
                            setTimeout(function () {
                                setLoader_(false);
                            }, 2000);
                        }} data-bs-toggle="modal" data-bs-target="#Handle_Msg">
                            <span><img src={"../" + id + "/profiles/" + teach.image + (teach.image === "MALE" ||
                                teach.image === "FEMALE" ? ".png" : ".jpg")} alt=""
                                class="rounded-circle avatar-md" /></span>
                            {teach.fname + ' ' + teach.lname}
                        </a></li>
                    </>
                );
            });
        } else {
            return (
                <>

                </>
            );
        }
    };

    const renderAdmins = () => {
        if (admins == "") {
            return (
                <>

                </>
            );
        } else if (admins) {
            return admins.slice(0, 2).map((admin, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <li><a class="dropdown-item d-flex align-items-center gap-2 py-2 cursor-pointer" onClick={() => {
                            setUser(admin.fname);
                            setImage(admin.image);
                            RefgetUsers(admin.fname);
                            setName(admin.fname);
                            setValue('Hello ' + admin.fname + ',');
                            FetchChats(null, admin.studid);
                            setLoader_(true);
                            setTimeout(function () {
                                setLoader_(false);
                            }, 2000);
                        }} data-bs-toggle="modal" data-bs-target="#Handle_Msg">
                            <span><img src={"../" + id + "/profiles/" + admin.image + (admin.image === "MALE" ||
                                admin.image === "FEMALE" ? ".png" : ".jpg")} alt=""
                                class="rounded-circle avatar-md" /></span>
                            {admin.fname}
                        </a></li>
                    </>
                );
            });
        } else {
            return (
                <>

                </>
            );
        }
    };

    const [user, setUser] = useState('');

    const [image, setImage] = useState('');

    const handleKeyDown = event => {

        if (event.key === 'Backspace') {
            // 👇️ your logic here
            //console.log('Backspace key pressed ✅');
            setUser(null)
        }

    };

    const [value, setValue] = useState('Hello [Null],');

    const [status_, setStat_] = useState(false);

    const HandleMsg = (e) => {

        e.preventDefault();

        setStat_(true);

        const task = {
            id: user,
            message: value,
            teachid
        };

        //console.log(task);

        axios({
            method: "post",
            url: "/v2/chat/send/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.data.status === "success") {

                    Credit(response.data.data.cost);

                    FetchChats(null, user);

                    setValue(null);

                    Fetch_Active_users(null);

                } else {
                    toast.error("Failed: Request Failed! Please try again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat_(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");

                setTimeout(function () {
                    setStat_(false);
                }, 3000);

            });

    }

    const Credit = (credit) => {

        const task = {
            credit,
            teachid
        };

        axios({
            method: "post",
            url: "/v2/credit/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    setTimeout(function () {
                        setStat_(false);
                    }, 5000);

                } else {
                    toast.error("Failed: Request Failed! Please try again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");

                setTimeout(function () {
                    setStat_(false);
                }, 3000);
            });

    }


    const Refresh_Chat = () => {

        setTimeout(function () {
            FetchChats(null, user)
        }, 6000);

    }

    const [chats, setChats] = useState([]);

    const FetchChats = (search, user) => {

        axios({
            method: "post",
            url: "./v2/chat/",
            data: {
                teachid,
                user,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setChats(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const Delete_Chat = (token) => {

        const task = {
            teachid,
            token
        };

        axios({
            method: "post",
            url: "/v2/chat/delete/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    FetchChats(null, user);

                } else {
                    toast.error("Failed: Request Failed! Please try again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    };

    const renderChats = () => {
        if (chats == "") {
            return (
                <>

                </>
            );
        } else if (chats) {
            return chats.map((chat, index) => {
                //console.log(chat.time)
                const TimeDate = new Date(chat.time).toDateString();

                const TimeDate_ = new Date(chat.time);

                const time = TimeDate_.toLocaleTimeString('default', {
                    hour: '2-digit',
                    minute: '2-digit',
                });

                return (
                    <>
                        {chat.sender === teachid ?
                            <div className="col-12 mb-10">
                                <div class="d-flex justify-content-end mb-4">

                                    <div class="d-flex w-lg-60">

                                        <div class=" me-3 text-end">
                                            <small> {TimeDate}, {time}</small>
                                            <div class="d-flex">
                                                <div class="me-2 mt-2">

                                                    <div class="dropdown dropstart">
                                                        <a class="text-link" href="#" role="button" id="dropdownMenuLinkTwo" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fe fe-more-vertical"></i>
                                                        </a>

                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLinkTwo">
                                                            <a class="dropdown-item cursor-pointer" onClick={() => { navigator.clipboard.writeText(chat.message.replaceAll(/<\/?[^>]+(>|$)/gi, "")) }}>
                                                                <i class="fe fe-copy dropdown-item-icon"></i>
                                                                Copy
                                                            </a>
                                                            <a class="dropdown-item cursor-pointer" onClick={() => Delete_Chat(chat.token)}>
                                                                <i class="fe fe-trash dropdown-item-icon"></i>
                                                                Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="card mt-2 rounded-top-md-end-0 bg-light-primary">
                                                    <div class="card-body text-start p-3">
                                                        <div dangerouslySetInnerHTML={{ __html: chat.message }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <img src={"../" + id + "/profiles/" + imageid + (imageid === "MALE" ||
                                            imageid === "FEMALE" ? ".png" : ".jpg")} alt="" class="rounded-circle avatar-md" />

                                    </div>
                                </div>
                            </div>
                            : chat.sender === user ?
                                <div className="col-12 mb-10">
                                    <div class="d-flex w-lg-60 mb-4">
                                        <img src={"../" + id + "/profiles/" + user + ".jpg"} alt="" class="rounded-circle avatar-md" />

                                        <div class=" ms-3">
                                            <small>{TimeDate}, {time}</small>
                                            <div class="d-flex">
                                                <div class="card mt-2 rounded-top-md-left-0">
                                                    <div class="card-body p-3">
                                                        <div dangerouslySetInnerHTML={{ __html: chat.message }}></div>
                                                    </div>
                                                </div>
                                                <div class="ms-2 mt-2">

                                                    <div class="dropdown dropend">
                                                        <a class="text-link" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                            <a class="dropdown-item cursor-pointer" onClick={() => { navigator.clipboard.writeText(chat.message.replaceAll(/<\/?[^>]+(>|$)/gi, "")) }}>
                                                                <i class="fe fe-copy dropdown-item-icon"></i>
                                                                Copy
                                                            </a>
                                                            <a class="dropdown-item cursor-pointer" onClick={() => Delete_Chat(chat.token)}>
                                                                <i class="fe fe-trash dropdown-item-icon"></i>
                                                                Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                    </>
                );
            });
        } else {
            return (
                <>

                </>
            );
        }
    };


    return (
        <>
            <Auth />
            <div
                id="db-wrapper"
                className={isMobile ? `bg-white` : `bg-back`}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#ff793f"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <div class="">
                    <nav
                        class={headers}
                        style={
                            isMobile
                                ? {}
                                : {}
                        }
                    >
                        <div className={isMobile ? "container" : "container-fluid"}>

                            <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">

                                <div class="navbar-brand ms-0">
                                    <div className="fs-10 text-purple2 mt-1 mt-lg-0 ">{school_name}</div>
                                </div>

                            </div>

                            <div class="ms-auto d-flex d-lg-none d-md-none align-items-center">
                                <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2" type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#menu"
                                    data-bs-backdrop="static"
                                    data-bs-keyboard="false">
                                    <i class="bi bi-list fw-bold fs-10 text-purple2"></i>
                                </button>
                            </div>

                            {isMobile ? <></> : <Header_ />}
                        </div>

                    </nav>
                </div>

                <>
                    <nav class="navigation navbar-vertical-compact navbar-light bg-primary shadow-none">
                        <Aside />
                    </nav>

                    <div id={`page-content-for-mini`} className="">

                        <div class="container py-8">

                            {app_status === "InActive" ? (
                                <StyleRoot>
                                    <div class="px-4 px-lg-4" style={styles.bounce}>
                                        <Activate />
                                    </div>
                                </StyleRoot>
                            ) : (
                                <>
                                    <StyleRoot>
                                        <div className="row pt-lg-6 pt-6 pb-lg-8 px-lg-8 px-3">

                                            <div className="col-lg-12">

                                                <div className="row g-6 g-lg-4">

                                                    <div className="col-lg-12 d-flex mt-10 mt-lg-10 px-lg-2 px-4">
                                                        <div className="col-auto">
                                                            <div className="fs-t text-purple2">
                                                                Note Response <i class="bi bi-chat-right-dots-fill fs3"></i>
                                                            </div>
                                                        </div>

                                                        <div className="col text-end">
                                                            
                                                        </div>
                                                    </div>


                                                    {menu === 'Message' ?
                                                        <>
                                                            <div className="col-12">
                                                                <div className="card bg-white shadow-none curve" style={{ borderRadius: "25px" }}>
                                                                    <div className="px-lg-4 px-5 py-lg-4 py-5">

                                                                        <div className="px-lg-2 mb-3 mt-lg-4">
                                                                            <span className="fs-3 fw-medium text-purple2">
                                                                                Recent Messages
                                                                            </span>
                                                                        </div>

                                                                        <div className="px-lg-2 mb-3 mt-lg-4">
                                                                            <div class="col-lg-6 mt-3 mb-4">
                                                                                <div class="d-flex align-items-center mb-2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    <span class="position-absolute ps-3 search-icon">
                                                                                        <i class="fe fe-search"></i>
                                                                                    </span>
                                                                                    <input type="search" class="form-control form-control-lg py-3 ps-6 fs-4 bg-back rounded-pill text-gray-700"
                                                                                        placeholder="Search for a user..." onChange={(e) => Fetch_Active_users(e.target.value)} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="mb-6">
                                                                            <ul class="list-unstyled contacts-list">
                                                                                {loader ? (
                                                                                    <div className="col-lg-12 text-center py-lg-10 py-8">
                                                                                        <div
                                                                                            class="spinner-border text-purple3"
                                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                                            role="status"
                                                                                        >
                                                                                            <span class="visually-hidden">Loading...</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : <>
                                                                                    {renderActiveUsers()}
                                                                                </>}
                                                                            </ul>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        menu === 'Note' ?
                                                            <div onMouseMove={FetchUsers} onTouchMove={FetchUsers}>
                                                                {loader ? (
                                                                    <div className="col-lg-12 text-center py-lg-10 py-8">
                                                                        <div
                                                                            class="spinner-border text-purple3"
                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                            role="status"
                                                                        >
                                                                            <span class="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                ) :
                                                                    renderFolders()
                                                                }

                                                                {users == "" ?
                                                                    <></>
                                                                    :
                                                                    <div className="col-lg-12 text-center mt-lg-14 mt-10">
                                                                        <button className="btn btn-lg btn-light-primary rounded-pill 
                                                                         text-purple2"  disabled={status ? true : false}
                                                                            onClick={() => FetchData(count + 4)}>
                                                                            {status ? (
                                                                                <>
                                                                                    Fetching...
                                                                                    <span
                                                                                        class="spinner-border spinner-border-sm ms-3"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                    ></span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    Load More
                                                                                </>
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            <>
                                                            </>
                                                    }

                                                </div>

                                            </div>

                                        </div>
                                    </StyleRoot>
                                </>
                            )}

                            <Footer />

                        </div>

                    </div>


                    <div class="modal" id="Handle_Msg" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true" onMouseOver={() => Refresh_Chat(null, user)} onTouchMove={() => Refresh_Chat(null, user)}>
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                            role="document">
                            <div class="modal-content" style={isMobile ? { border: "#fff" } :
                                { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-1 px-lg-6 pt-5">
                                    <div class="d-flex">
                                        <div class="avatar avatar-md">
                                            <img src={"../" + id + "/profiles/" + image + (image === "MALE" ||
                                                image === "FEMALE" ? ".png" : ".jpg")} alt="" class="rounded-circle" />
                                        </div>

                                        <div class="ms-3">
                                            <h5 class="mb-0 fw-bold text-purple3">
                                                {name}
                                            </h5>
                                            <p class="fs-5 mb-0 text-muted fw-medium text-truncate">
                                                {seen === 'Online' ? 'Online' : <>Last seen {seen} ago</>}
                                            </p>
                                        </div>
                                    </div>

                                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                        data-bs-dismiss="modal" aria-label="Close">
                                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                    </button>
                                </div>
                                <div class="modal-body bg-back">

                                    {loader_ ? (
                                        <div className="col-lg-12 text-center pt-lg-10 pt-20">
                                            <div
                                                class="spinner-border text-purple3"
                                                style={{ width: "4rem", height: "4rem" }}
                                                role="status"
                                            >
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) :
                                        <div>
                                            {isMobile ?
                                                <>{renderChats()}</>
                                                :
                                                <SimpleBar
                                                    style={isMobile ? { maxHeight: 600, padding: "0px" }
                                                        : { maxHeight: 350, padding: "0px" }}
                                                    forceVisible="y"
                                                    autoHide={true}
                                                    className={chats == '' ? "px-lg-4 py-lg-14" : "px-lg-4 py-lg-4"}
                                                >
                                                    {renderChats()}

                                                </SimpleBar>
                                            }
                                        </div>
                                    }
                                </div>

                                <div className="modal-footer border-0">
                                    <div className="col-12 mb-4">
                                        <div className={isMobile ? "fixed-bottom" : ''}>
                                            <div class={isMobile ? "bg-white p-2 shadow-sm"
                                                : "bg-back p-2 shadow-sm"} style={{ borderRadius: "25px" }}>
                                                <form onSubmit={HandleMsg}>
                                                    <div class="position-relative">
                                                        <ReactQuill theme="bubble" style={isMobile ?
                                                            { height: 50 } : { height: 50 }}
                                                            value={value} onChange={setValue} />
                                                    </div>
                                                    <div class="position-absolute end-0 mt-n7 me-4">
                                                        <button type="submit" class="fs-3 btn text-primary btn-focus-none">
                                                            <i class="fe fe-send"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal" id="Handle_Users" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"}
                            role="document">
                            <div class="modal-content" style={isMobile ? { border: "#fff" } :
                                { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0 pt-5">
                                    <div className="fs-t text-purple2">
                                        <i class="bi bi-person"></i> Contact List
                                    </div>

                                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                        data-bs-dismiss="modal" aria-label="Close">
                                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                    </button>
                                </div>
                                <div class="modal-body py-0">

                                    <div className="px-lg-4 mb-3 mt-3">
                                        <div className="mb-3">
                                            <span className="fs-4 fw-medium text-purple2">
                                                Search user and send message
                                            </span>
                                        </div>

                                        <div class="d-flex align-items-center mb-2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="position-absolute ps-3 search-icon">
                                                <i class="fe fe-search"></i>
                                            </span>
                                            <input type="search" class="form-control form-control-lg py-3 ps-6 fs-4 bg-back rounded-pill text-gray-700"
                                                onKeyUp={(e) => {
                                                    FetchUser1(e.target.value); FetchUser2(e.target.value);
                                                    FetchUser3(e.target.value);
                                                }}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Search and select a user..." />
                                        </div>
                                    </div>

                                    <div className="mb-4">

                                        <ul class="list-unstyled mb-0 px-lg-2 py-2">
                                            <SimpleBar
                                                style={isMobile ? { maxHeight: 1500, padding: "0px" } : { maxHeight: 300, padding: "4px" }}
                                                forceVisible="y"
                                                autoHide={true}
                                            >

                                                {loader ? (
                                                    <div className="col-lg-12 text-center py-lg-10 py-8">
                                                        <div
                                                            class="spinner-border text-purple3"
                                                            style={{ width: "4rem", height: "4rem" }}
                                                            role="status"
                                                        >
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                ) :
                                                    <>

                                                        {studs.length != 0 ?
                                                            <>
                                                                <li><span class="dropdown-item d-flex align-items-center text-purple2 gap-2 py-2">
                                                                    Students List
                                                                </span></li>

                                                                {renderStuds()}

                                                                <div className="fs-5 text-dark-danger px-4 py-2">
                                                                    Total number of students ({studs.length})
                                                                </div>
                                                            </>
                                                            : <></>
                                                        }


                                                        {teachs.length != 0 ?
                                                            <>
                                                                <li><span class="dropdown-item d-flex align-items-center text-purple2 gap-2 py-2">
                                                                    Teachers List
                                                                </span></li>

                                                                {renderTeachs()}

                                                                <div className="fs-5 text-dark-danger px-4 py-2">
                                                                    Total number of teachers ({teachs.length})
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                        }

                                                        {admins.length != 0 ?
                                                            <>
                                                                <li><span class="dropdown-item d-flex align-items-center text-purple2 gap-2 py-2">
                                                                    Admins List
                                                                </span></li>

                                                                {renderAdmins()}

                                                                <div className="fs-5 text-dark-danger px-4 py-2">
                                                                    Total number of admins ({admins.length})
                                                                </div>

                                                            </>
                                                            :
                                                            <></>
                                                        }

                                                    </>
                                                }

                                            </SimpleBar>
                                        </ul>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="modal" id="Handle_Comments" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                            role="document">
                            <div class="modal-content" style={isMobile ? { border: "#fff" } :
                                { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0 pt-5 py-0">
                                    <div className="fs-t text-purple2">
                                        <i class="bi bi-chat-square-dots"></i> <span>Comment Response</span>
                                    </div>

                                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                        data-bs-dismiss="modal" aria-label="Close">
                                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                    </button>
                                </div>
                                <div class="modal-header border-0 py-0 pt-3 px-lg-7">
                                    <div className="fs-3 text-purple2">
                                        {note.subject} - {note.title}
                                    </div>
                                </div>
                                <div class="modal-body py-0">
                                    <div className="px-lg-4 pt-3 pb-5">

                                        <div className="col-lg-12 mb-2 fs-4 text-purple2">
                                            Note Comment
                                        </div>

                                        <div className="d-flex">
                                            <div class="col-auto d-flex" style={isMobile ? { height: "100px" }
                                                : { height: "70px" }}>
                                                <div class="vr text-success" style={{ width: "4px" }}></div>
                                            </div>

                                            <div className="col ms-2">
                                                <div className="fs-4 text-purple3">
                                                    {note != '' ? note.comment.replaceAll(/<\/?[^>]+(>|$)/gi, "") : ''}
                                                </div>
                                            </div>
                                        </div>

                                        {note.respond ? 
                                        <>
                                        <div className="col-12 mb-2 fs-5 text-dark">
                                            Replies
                                        </div>

                                        <div className="d-flex">
                                            <div class="col-auto d-flex" style={isMobile ? { height: "100px" }
                                                : { height: "200px" }}>
                                                <div class="vr text-warning" style={{ width: "4px" }}></div>
                                            </div>

                                            <div className="col-12 ms-2">
                                                <div className="fs-4 text-purple3">
                                                    {note != '' ? parse(note.respond) : ''}
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <></>
                                        }

                                        <div className={isMobile ? "fixed-bottom px-4" : ''}>
                                            <div className="col-lg-12 mt-2 fs-4 text-purple2 mb-3">
                                                Send Response
                                            </div>

                                            <div class={isMobile ? "bg-back py-3 shadow-sm mb-4"
                                                : "bg-back shadow-sm mb-4"} style={{ borderRadius: "25px" }}>
                                                <form onSubmit={HandleCom}>
                                                    <div class="position-relative">
                                                        <ReactQuill theme="bubble" style={isMobile ?
                                                            { height: 50 } : { height: 80 }}
                                                            onChange={setCom} />
                                                    </div>
                                                    <div class="position-absolute end-0 mt-lg-n8 mt-n6 me-4 me-lg-7">
                                                        <button type="submit" class="fs-3 btn text-primary btn-focus-none">
                                                            <i class="fe fe-send"></i>
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastContainer />
                </>
            </div >
        </>
    );
};

export default Response;
