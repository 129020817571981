import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

const Create_Report = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const phone_num = getusers.phone;

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("");

  useEffect(() => {
    setTimeout(function () {
      setfullname(
        admin_name.split(" ")[0].charAt(0).toUpperCase() +
          admin_name.split(" ")[1].charAt(0).toUpperCase()
      );
    }, 1000);
  }, [admin_name]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [steps, setStep] = useState(1);

  const [status, setStat] = useState(false);

  const [progress, setProgress] = useState(20);

  const [studclass, setStudclass] = useState(null);

  const [subject, setSubject] = useState(null);

  const [teach, setTeach] = useState(null);

  const onSubmit_ = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      studclass,
      subject,
      teach,
    };

    axios({
      method: "post",
      url: "/v2/report/create/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Result created successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStep(3);
            setStat(false);
            navigate("../" + id + "/report/create/" + response.data.token);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStep(4);
          setStat(false);
        }, 3000);
      });
  };

  const [subjects, setSubjects] = useState([{}]);

  useEffect(() => {
    axios({
      method: "post",
      url: "./v2/subject/list/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSubjects(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  }, [teachid]);

  let newArray = [];

  let objTitle;

  let i;
 
    // Declare an empty object
    let uniqueObject = {};
 
    // Loop for the array elements
    for (let i in subjects) {
 
        // Extract the title
        objTitle = subjects[i]['subject'];
 
        // Use the title as the index
        uniqueObject[objTitle] = subjects[i];
    }
 
    // Loop to push unique object into array
    for (i in uniqueObject) {
        newArray.push(uniqueObject[i]);
    }

  const renderSubject = () => {
    if (newArray == "") {
      return <></>;
    } else if (newArray) {
      return [...new Set(newArray.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <option value={task.subject}>{task.subject}</option>
          </>
        );
      }))]
    } else {
      return <></>;
    }
  };

  const [teacher, setTeacher] = useState([{}]);

  const FetchTeach = (studclass) => {
    axios({
      method: "post",
      url: "./v2/report/teacher/",
      data: {
        studclass,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setTeacher(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderTeacher = () => {
    if (teacher == "") {
      return <></>;
    } else if (teacher) {
      return teacher.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <option value={task.teachid}>
              {task.fname + " " + task.username}
            </option>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <div className="" onLoad={() => setProgress(100)}>
      <Auth />
     
      {isMobile ? (
        <div class="docs-header">
          <nav class="navbar navbar-expand-lg navbar-default bg-white fixed-top ms-0 py-3 py-lg-3 shadow-none">
            <Link to={"../" + id + "/report/results"} class="fs-t text-purple2">
              <i className="bi bi-arrow-left fs-10"></i> Back
            </Link>

            <div class="navbar-nav flex-row ms-4">
              <Tippy
                content={admin_name}
                interactive={true}
                interactiveBorder={400}
                delay={100}
              >
                <span class="avatar avatar-lg avatar-warning">
                  <span
                    class="avatar-initials rounded-circle text-white fs-3 fw-medium"
                  >
                    {fullname}
                  </span>
                </span>
              </Tippy>
            </div>
          </nav>
        </div>
      ) : (
        <Header />
      )}

      <Sidebar />
      {isMobile ? (
        <div className="px-lg-10">
          <div className="col-12">
            <div
              className="card bg-white py-20 mt-n14"
              style={{ borderRadius: "16px" }}
            >
              <div className="card-body mb-20 px-3">
                <div className="text-start">
                  <div className="fs-t text-purple3 fw-medium mb-2">
                    <i class="bi bi-plus-lg"></i> Create Report
                  </div>
                </div>

                <form className="mt-6 px-5" onSubmit={onSubmit_}>
                  <div class="col-lg-12 mb-4">
                    <label
                      class="form-label fs-3 fw-medium text-purple2"
                      for="number"
                    >
                      Exercise Subject
                    </label>
                    <select
                      class="form-select form-select-lg bg-back text-dark"
                      aria-label="Default select example"
                      required
                      style={{
                        borderRadius: "24px",
                      }}
                      onChange={(e) => setSubject(e.target.value)}
                    >
                      <option value="">Select Subject</option>
                      {renderSubject()}
                    </select>
                  </div>

                  <div class="col-lg-12 mb-4">
                    <label
                      class="form-label fs-3 fw-medium text-purple2"
                      for="number"
                    >
                      Exercise Class Type
                    </label>
                    <select
                      class="form-select form-select-lg bg-back text-dark"
                      aria-label="Default select example"
                      name="studclass"
                      style={{
                        borderRadius: "24px",
                      }}
                      required
                      onChange={(e) => {
                        setStudclass(e.target.value);
                        FetchTeach(e.target.value);
                      }}
                    >
                      <option value="">Select Class</option>
                      <option value="PlayGroup">PlayGroup</option>
                      <option value="Reception">Reception</option>
                      <option value="Pre Nursery">Pre Nursery</option>
                      <option value="NUR 1">NUR 1</option>
                      <option value="NUR 2">NUR 2</option>
                      <option value="KG 1">KG 1</option>
                      <option value="KG 2">KG 2</option>
                      <option value="Grade 1">Grade 1</option>
                      <option value="Grade 2">Grade 2</option>
                      <option value="Grade 3">Grade 3</option>
                      <option value="Grade 4">Grade 4</option>
                      <option value="Grade 5">Grade 5</option>
                      <option value="Grade 6">Grade 6</option>
                      <option value="Jss1">JSS 1</option>
                      <option value="Jss2">JSS 2</option>
                      <option value="Jss3">JSS 3</option>
                      <option value="sss1">SSS 1</option>
                      <option value="sss2">SSS 2</option>
                      <option value="sss3">SSS 3</option>
                    </select>
                  </div>

                  <div class="col-lg-12 mb-4">
                    <label
                      class="form-label fs-3 fw-medium text-purple2"
                      for="number"
                    >
                      Class Teacher
                    </label>
                    <select
                      class="form-select form-select-lg bg-back text-dark"
                      aria-label="Default select example"
                      style={{
                        borderRadius: "24px",
                      }}
                      required
                      onChange={(e) => setTeach(e.target.value)}
                    >
                      <option value="">Select Teacher</option>
                      {renderTeacher()}
                    </select>
                  </div>

                  <div
                    className="card fixed-bottom bg-back shadow-lg"
                    style={{
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                    }}
                  >
                    <div className="d-grid mt-3 px-4 mb-3">
                      <button
                        type="submit"
                        disabled={status ? true : false}
                        className="btn btn-dark-primary rounded-pill fs-3"
                      >
                        {status ? (
                          <>
                            <span
                              class="spinner-border spinner-border-md mt-1 text-white"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          <>
                            Create <i className="bi bi-arrow-right"></i>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="pt-15 pt-lg-20">
            <div className="px-6">
              <div className="col-lg-8 offset-lg-3">
                <div
                  className="card bg-white shadow-sm py-4"
                  style={{ borderRadius: "16px" }}
                >
                  <div className="card-body px-lg-8 px-2">
                    <div className="text-start">
                      <div className="fs-t text-purple3 fw-medium mb-2">
                        <i class="bi bi-plus-lg"></i> Create Report
                      </div>
                    </div>

                    <form className="mt-4 px-lg-6" onSubmit={onSubmit_}>
                      <div class="col-lg-12 mb-4">
                        <label
                          class="form-label fs-3 fw-medium text-purple2"
                          for="number"
                        >
                          Exercise Subject
                        </label>
                        <select
                          class="form-select form-select-lg bg-back text-dark"
                          aria-label="Default select example"
                          style={{
                            borderRadius: "24px",
                          }}
                          required
                          onChange={(e) => setSubject(e.target.value)}
                        >
                          <option value="">Select Subject</option>
                          {renderSubject()}
                        </select>
                      </div>

                      <div class="col-lg-12 mb-4">
                        <label
                          class="form-label fs-3 fw-medium text-purple2"
                          for="number"
                        >
                          Exercise Class Type
                        </label>
                        <select
                          class="form-select form-select-lg bg-back text-dark"
                          aria-label="Default select example"
                          name="studclass"
                          style={{
                            borderRadius: "24px",
                          }}
                          required
                          onChange={(e) => {
                            setStudclass(e.target.value);
                            FetchTeach(e.target.value);
                          }}
                        >
                          <option value="">Select Class</option>
                          <option value="PlayGroup">PlayGroup</option>
                          <option value="Reception">Reception</option>
                          <option value="Pre Nursery">Pre Nursery</option>
                          <option value="NUR 1">NUR 1</option>
                          <option value="NUR 2">NUR 2</option>
                          <option value="KG 1">KG 1</option>
                          <option value="KG 2">KG 2</option>
                          <option value="Grade 1">Grade 1</option>
                          <option value="Grade 2">Grade 2</option>
                          <option value="Grade 3">Grade 3</option>
                          <option value="Grade 4">Grade 4</option>
                          <option value="Grade 5">Grade 5</option>
                          <option value="Grade 6">Grade 6</option>
                          <option value="Jss1">JSS 1</option>
                          <option value="Jss2">JSS 2</option>
                          <option value="Jss3">JSS 3</option>
                          <option value="sss1">SSS 1</option>
                          <option value="sss2">SSS 2</option>
                          <option value="sss3">SSS 3</option>
                        </select>
                      </div>

                      <div class="col-lg-12 mb-4">
                        <label
                          class="form-label fs-3 fw-medium text-purple2"
                          for="number"
                        >
                          Class Teacher
                        </label>
                        <select
                          class="form-select form-select-lg bg-back text-dark"
                          aria-label="Default select example"
                          style={{
                            borderRadius: "24px",
                          }}
                          required
                          onChange={(e) => setTeach(e.target.value)}
                        >
                          <option value="">Select Teacher</option>
                          {renderTeacher()}
                        </select>
                      </div>

                      <div className="d-grid mt-8 mt-lg-6 mb-4">
                        <button
                          type="submit"
                          disabled={status ? true : false}
                          className="btn btn-warning rounded-pill fs-3 text-dark"
                        >
                          {status ? (
                            <>
                              <span
                                class="spinner-border spinner-border-md mt-1 text-white"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          ) : (
                            <>
                              Create <i className="bi bi-arrow-right"></i>
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Create_Report;
