import React from "react";

import { isMobile } from "react-device-detect";

import QRCode from "react-qr-code";

const Mobile = () => {
  let fullscreen_;

  if (isMobile) {
    fullscreen_ = "modal-dialog modal-dialog-centered modal-fullscreen";
  } else {
    fullscreen_ = "modal-dialog modal-dialog-centered modal-lg";
  }

  const token = localStorage.getItem("token");

  const url_link = "app.smartedung.com/#/"+token;

  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <div class="modal" tabindex="-1" id="kt_modal_1">
          <div className={`${fullscreen_}`}>
            <div class="modal-content">
              <div
                style={{
                  borderBottom: "1px solid #fff",
                }}
                class="modal-header px-4 pb-0"
              >
                <div class="fs-3 text-dark">
                  <i class="bi bi-arrow-repeat text-purple me-2"></i> Mobile
                  phone synchronization{" "}
                </div>

                <div
                  class="btn btn-icon btn-sm btn-active-light-danger ms-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span class="svg-icon svg-icon-2x">
                    <i class="bi bi-x-lg text-dark fs-3"></i>
                  </span>
                </div>
              </div>

              <div class="modal-body px-5">
                <div className="row">
                  <div className="col-lg-5">
                    <div>
                      <img
                        src="../../assets/images/background/phone.png"
                        width="80%"
                      />
                    </div>
                  </div>

                  <div className="col-lg-7">
                    <div
                      className="border rounded-br-lg rounded-bl-sm rounded-t-lg border-purple 
                  text-center p-3"
                    >
                      <div className="fs-4 text-dark mb-6">
                        Scan Qr_Code below
                      </div>

                      <hr className="my-3" />

                      <QRCode
                        value={url_link}
                        size={"150"}
                        fgColor={"#2c4f40"}
                      />

                      <hr className="my-3" />

                      <div className="fs-4 text-dark mb-6">
                        Device information appers below
                      </div>

                      <div className="text-center">
                        <div class="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Mobile;
