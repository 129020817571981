import React, { useState, useEffect, useRef } from "react";

import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Sync = () => {
  const { token } = useParams();

  const { id } = useParams();

  const navigate = useNavigate();

  const [users, setUser] = useState({});

  const fetchData = () => {
    axios({
      method: "post",
      url: "/v2/auth/",
      data: {
        token: token,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
          toast.error("Error: Request Failed! Check your connection..");
        } else {
          setUser(view.user_data.data);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check your connection..");
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();

    localStorage.setItem("token", token);

    setTimeout(function () {
      if (users) {
        navigate("../" + id + "/report/start");
      } else {
        navigate("../");
      }
    }, 4000);
  }, []);

  //console.log(users);

  const [school, setSchool] = useState([]);

  const fetchSchool = () => {
    axios({
      method: "post",
      url: "./v2/school/",
      data: {
        school_id: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSchool(view);
        } else {
          
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    fetchSchool();
  }, [id]);

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default shadow-none fixed-top bg-back">
        <div className="container-fluid px-0 pt-2">
          <div class="navbar-brand">
            <span className="fs-t text-dark">{school.schoolname}</span>
          </div>

          <div class="text-dark fs-5 d-block d-xl-none">Loading..</div>

          <div class="collapse navbar-collapse" id="navbar-default">
            <div class="ms-auto mt-3 mt-lg-0">
              <div class="text-dark fs-4">Loading..</div>
            </div>
          </div>
        </div>
      </nav>

      <div class="py-15 py-lg-10 text-center">
        <div class="col-md-12 text-center py-20">
          <span class="loader"></span>
          <div class="display-6 fw-bold mb-3 text-dark ls-sm mt-8">
            <span class="headingTyped text-dark"></span>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Sync;
