import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { isMobile } from "react-device-detect";

import { wobble, bounceInLeft } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { useSelector } from "react-redux";

import { motion } from "framer-motion";
import Settings from "./Settings";

const Footer = () => {
  const year = new Date().getFullYear();

  const { token } = useParams();

  const { id } = useParams();

  const navigate = useNavigate();

  const styles = {
    bounce: {
      animation: "x 3s",
      animationName: Radium.keyframes(wobble, "bounce"),
    },
  };

  const [move, setMove] = useState(false);

  const hash = window.location.hash.slice(2);

  //console.log(hash)

  const GoLink = (url) => {
    navigate(url);
  };

  const getusers = useSelector((state) => state.user.value);

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const teachclass = getusers.teachclass;

  const [fullname, setfullname] = useState("");

  useEffect(() => {
    setfullname(!admin_name ? "" :
      admin_name.split(" ")[0].charAt(0).toUpperCase() +
      admin_name.split(" ")[1].charAt(0).toUpperCase()
    );
  }, [admin_name]);

  return (
    <>
      <div className="mb-4">
        <div className="row pt-12 pt-lg-8 px-lg-10 px-3">
          <div className="col-lg-6">
            <div class="mb-4">
              <img
                src="../assets/smlogo.png"
                width={isMobile ? "100" : "80"}
                alt=""
              />
            </div>
            <div className="text-purple2 fs-4">
              © 2022 Powered and developed with ❤️. All rights reserved
            </div>
          </div>
        </div>
      </div>

      <div class="modal" tabindex="-1" id="menu">
        <div className="modal-dialog modal-dialog-centered modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header border-0" style={{ padding: "1rem" }}>
              <div class="modal-title fs-10 text-purple3">Menu</div>

              <button
                type="button"
                class="btn btn-white text-danger fs-t"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {" "}
                <i class="bi bi-x-lg text-purple2"></i>
              </button>
            </div>

            <div class="modal-body px-4 text-center py-6">
              <div className="row align-items-center gy-6 pt-0">
                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className={
                      hash === id + "/report"
                        ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary"
                        : "card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    }
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/report")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-laptop"></div>
                        <div className="fs-3">Dashboard</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className={
                      hash === id + "/report/assign"
                        ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary"
                        : "card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    }
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/report/assign")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-person"></div>
                        <div className="fs-3">Assign</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className={
                      hash === id + "/report/results"
                        ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary"
                        : "card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    }
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/report/results")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-ui-checks"></div>
                        <div className="fs-3">Results</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className={
                      hash === id + "/report/review"
                        ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary"
                        : "card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    }
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/report/review")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-bounding-box"></div>
                        <div className="fs-3">Review</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className={
                      hash === id + "/report/send"
                        ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary"
                        : "card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    }
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/report/send")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-send-check"></div>
                        <div className="fs-3">Dispatch</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className={
                      hash === id + "/report/transfer"
                        ? "card py-4 px-2 shadow-sm cursor-pointer bg-light-primary"
                        : "card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    }
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/report/transfer")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-bezier2"></div>
                        <div className="fs-3">Transfer</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-md-6 col-lg-4">
                  <div
                    className="card py-4 px-2 shadow-sm cursor-pointer bg-back"
                    style={{ borderRadius: "25px" }}
                  >
                    <div
                      onClick={() => GoLink("../" + id + "/logout")}
                      data-bs-dismiss="modal"
                    >
                      <div className="text-purple2 fs-2 text-center">
                        <div class="bi bi-power"></div>
                        <div className="fs-3">Logout</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  onClick={() => GoLink("../" + id + "/home")}
                  data-bs-dismiss="modal"
                  className="col-lg-12"
                >
                  <div
                    class="container bg-start shadow-sm"
                    style={{ borderRadius: "25px" }}
                  >
                    <div class="row align-items-center py-3">
                      <div class="col-4">
                        <div class="">
                          <img
                            src="../../assets/images/background/7.png"
                            alt=""
                            class="img-fluid mt-n5"
                          />
                        </div>
                      </div>

                      <div class="col-lg-6 col-8">
                        <div class="text-start p-2 p-lg-0">
                          <div class="fs-t text-white fw-medium">
                            Visit E-Learning App
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Settings />

      {isMobile ? (
        <div
          class="btn btn-purple4 fs-10 text-white shadow-lg btn-md end-0 me-4 me-lg-2 rounded-circle float mb-3"
          data-bs-toggle="modal"
          data-bs-target="#settings"
        >
          <i class="bi bi-sliders"></i>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Footer;
