import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";
import Footer from "../Footer";

const Report_Review = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const admin_name = getusers.username;

  const term = getusers.term;

  const [fullname, setfullname] = useState("{}");

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0]);
    }, 1000);

    localStorage.setItem("id", teachid);
  }, [admin_name, teachid]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [report, setReport] = useState([]);

  const [loader, setLoader] = useState(true);

  const FetchReport = () => {
    axios({
      method: "post",
      url: "./v2/report/fetchs/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setReport(view);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport();
  }, [teachid]);

  const renderFolders = () => {
    if (report == "") {
      return <></>;
    } else if (report != "") {
      return report.map((report, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="d-flex align-items-center py-3 py-lg-3">
              <div className="col-1 col-lg-1">
                <div className="fs-4 text-purple2 fw-medium">{index + 1}</div>
              </div>

              <div className="col-3 col-lg-3 text-start">
                <div className="fs-4 text-purple2 fw-medium">
                  {report.subject}
                </div>
              </div>

              <div className="col-1 col-lg-3 text-start fs-4 text-purple2 ms-1 ms-lg-0">
                {report.class}
              </div>

              <div className="col-3 col-lg-3 text-start fs-4 text-purple2 ms-3 ms-lg-0">
                {report.teacher}
              </div>

              <div
                className="col-2 col-lg-1 text-start text-lg-start"
                data-bs-toggle="modal"
                data-bs-target="#delete_menu"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => {
                  setToken(report.token);
                  setSubject(report.subject);
                }}
              >
                <button className="btn btn-danger rounded-pill">
                  <i className="bi bi-trash"></i>
                </button>
              </div>

              <div
                className="col-2 col-lg-1 text-start text-lg-start"
                data-bs-toggle="modal"
                data-bs-target="#view_result"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => {
                  FetchStudents(report.token);
                  setSubject(report.subject);
                }}
              >
                <button className="btn btn-primary rounded-pill">
                  <i className="bi bi-arrow-right-circle-fill text-white"></i>
                </button>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [tokenid, setToken] = useState(null);

  const [subject, setSubject] = useState(null);

  const Remove = (tokenid) => {
    const task = {
      teachid,
      tokenid,
    };

    axios({
      method: "post",
      url: "/v2/report/delete/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Deleted successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 2000,
          });

          FetchReport();
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [students, setStudents] = useState([]);

  const FetchStudents = (token) => {
    axios({
      method: "post",
      url: "./v2/report/student/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudents(view);

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderStudents = () => {
    if (students == "") {
      return <></>;
    } else if (students != "") {
      return students.map((stud, index) => {
        //console.log(school.tm_date)
        return (
          <tr className="">
            <th scope="row">{index + 1}</th>
            <td>{stud.fname}</td>
            <td>{stud.studid}</td>
            {term === "First Term" ? (
              <>
                <td>{parseInt(stud.test1) + parseInt(stud.test2)}</td>
                <td>{stud.score1}</td>
                <td>
                  {parseInt(stud.test1) +
                    parseInt(stud.test2) +
                    parseInt(stud.score1)}
                </td>
              </>
            ) : term === "Second Term" ? (
              <>
                <td>{parseInt(stud.test3) + parseInt(stud.test4)}</td>
                <td>{stud.score2}</td>
                <td>
                  {parseInt(stud.test3) +
                    parseInt(stud.test4) +
                    parseInt(stud.score2)}
                </td>
              </>
            ) : term === "Third Term" ? (
              <>
                <td>{parseInt(stud.test5) + parseInt(stud.test6)}</td>
                <td>{stud.score3}</td>
                <td>
                  {parseInt(stud.test5) +
                    parseInt(stud.test6) +
                    parseInt(stud.score3)}
                </td>
              </>
            ) : (
              <></>
            )}
          </tr>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <Header />

      <Sidebar />

      <div class="docs-wrapper bg-back">
        <div class="container-fluid px-lg-10 pt-lg-14 pt-4">
          <div className="d-flex align-items-center">
            <div className="col">
              <div className="fs-t text-purple3">
                Review |{" "}
                <span className="text-dark fs-5">
                  <i className="bi bi-bounding-box"></i> -- Review
                </span>
              </div>
            </div>

            <div className="col-end text-center">
              <Link
                to={"../" + id + "/report/create"}
                className="btn btn-warning rounded-pill text-dark fs-4 fw-medium"
              >
                New Report <i class="bi bi-arrow-right-circle-fill"></i>
              </Link>
            </div>
          </div>

          <div className="px-lg-12 mt-lg-6 mt-8">
            <div className="col-lg-12 mb-5 mb-lg-8">
              <div
                className="card bg-white shadow-none"
                style={{ borderRadius: "20px" }}
              >
                <div className="card-body px-1">
                  <div className="row align-items-center">
                    <div className="col-4 text-center ms-lg-n3 ms-n1">
                      <div className="fs-5 text-purple3">
                        No. of Results Uploads
                      </div>
                      <div className="fs-t text-success fw-bold">
                        {report.length}
                      </div>
                    </div>

                    <div className="col-6 ms-lg-n8 fw-medium ms-n3">
                      <div
                        className={
                          isMobile
                            ? "fs-5 text-purple2"
                            : "fs-4 text-start text-purple2"
                        }
                      >
                        Click on button to view all subject result uploads
                      </div>
                    </div>

                    <div className="col-2">
                      <Link
                        to={"../" + id + "/report/results"}
                        className="btn btn-success rounded-pill text-white fs-3"
                      >
                        <i class="bi bi-arrow-right-circle-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12" onMouseOver={() => FetchReport()}>
            <div
              className="card bg-white shadow-none"
              style={{ borderRadius: "20px" }}
            >
              <div className="card-body px-lg-10">
                <div className="fs-t text-purple3 mb-4 mt-2">
                  List of results uploads
                </div>

                <div className="mb-4">
                  <input
                    className="form-control form-control-lg bg-back rounded-pill"
                    placeholder="Search Lists"
                    onChange={(e) => FetchReport(e.target.value)}
                  />
                </div>

                <>
                  {loader ? (
                    <div className="col-lg-12 text-center py-lg-10 py-20">
                      <div
                        class="spinner-border text-purple3"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>{renderFolders()}</>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>

        <Footer />

        <div
          class="modal"
          id="delete_menu"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-md modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-t text-purple2">
                  <i class="bi bi-trash"></i> Remove Student{" "}
                </div>

                <button
                  class="btn btn-warning btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                </button>
              </div>
              <div class="modal-body py-2">
                <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-4 text-center">
                  <img
                    src="../../assets/images/background/remove.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>

                <div className="text-center px-lg-8 px-2 pt-2 mb-6 mb-lg-4">
                  <div className="fs-t text-purple3 fw-medium mb-2">
                    Do you want to remove {subject} result from list?
                  </div>
                </div>

                <div className="row text-center mb-lg-8">
                  <div className="col-6">
                    <button
                      className="btn btn-dark rounded-pill px-6"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      className="btn btn-danger rounded-pill px-6"
                      data-bs-dismiss="modal"
                      onClick={() => Remove(tokenid)}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal"
          id="view_result"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-lg modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-t text-purple2">{subject}</div>

                <button
                  class="btn btn-dark btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                </button>
              </div>
              <div class="modal-body py-0 px-lg-8">
                <div className="mb-6">
                  <div className="table-responsive py-5">
                    <table class="table caption-top fs-4 text-purple2">
                      <thead className="text-purple2">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Student</th>
                          <th scope="col">Student ID</th>
                          <th scope="col">Test</th>
                          <th scope="col">Exam</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>{renderStudents()}</tbody>
                    </table>
                  </div>

                  <div
                    className={isMobile ? "fixed-bottom bg-back" : ""}
                    style={{
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                    }}
                  >
                    <div className="d-grid px-4 mb-2 mt-3">
                      <button className="btn btn-warning text-dark rounded-pill fs-3">
                        Print Result
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Report_Review;
