import React, { useState, useEffect } from "react";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { Player } from "@lottiefiles/react-lottie-player";

import Footer from "./Footer";

const Quiz = () => {
  const [loaders, setLoaders] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();

  axios.defaults.baseURL = id;

  const [progress, setProgress] = useState(20);

  const [status, setStat] = useState(false);

  const [loader1, setLoader1] = useState(true);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers =
      "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
  }

  const username = localStorage.getItem("studname");

  const studid = localStorage.getItem("studid");

  const studclass = localStorage.getItem("studclass");

  const plan = localStorage.getItem("plan");

  if (!studid) {
    navigate("../" + id + "/quiz/");
  }

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  useEffect(() => {
    FetchCBTList();
    FetchCBT();

    setInterval(function () {
      FetchCBT();
    }, 12000);
  }, []);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "." : str;
  };

  const [folders, setFolders] = useState([]);

  const [subject, setSubject] = useState(null);

  const [token, setToken] = useState(null);

  const [teach, setTeach] = useState(null);

  const [timer, setTimer] = useState(null);

  const FetchCBTList = () => {
    axios({
      method: "post",
      url: "./v2/cbt/cbt/",
      data: {
        teachid: "NONE",
        q: studclass,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setFolders(view);

        setTimeout(function () {
          //navigate("/student");
          setLoader1(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderFolders = () => {
    if (folders == "") {
      return <></>;
    } else if (folders) {
      return folders.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            {task.access === "DELETED" ? (
              <></>
            ) : (
              <div className="col-lg-4">
                <div
                  className="card shadow-none bg-white cursor-pointer curve"
                  data-bs-toggle="modal"
                  data-bs-target="#HandleLogin"
                  style={{ borderRadius: "25px" }}
                  onClick={() => {
                    setSubject(task.subject);
                    setToken(task.token);
                    setTeach(task.teach);
                    setTimer(task.time);
                  }}
                >
                  <div className="card-body">
                    <div className="py-5">
                      <div className="col-12">
                        <div className="fs-t text-dark">
                          {task.class} {task.subject}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [password, setPassword] = useState(null);

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!password) {
      toast.error("Empty: Fill in the password!", {
        hideProgressBar: true,
        draggable: true,
        position: "bottom-right",
        icon: true,
        autoClose: 6000,
      });
    } else {
      setStat(true);

      const user = {
        teachid: studid,
        token,
        password,
        status: 1,
      };

      axios({
        method: "post",
        url: "/v2/login/",
        data: user,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          //console.log(response);
          if (response.data.message === "success") {
            setStat(true);

            setTimeout(function () {
              setStat(false);
              navigate("/" + id + "/quiz/exam/" + token + "/start");
            }, 8000);

            localStorage.setItem("id", response.data.teachid);
          } else if (response.data.message === "results") {
            setStat(true);

            setTimeout(function () {
              setStat(false);
              navigate("/" + id + "/quiz/exam/" + token + "/end");
            }, 3000);

            localStorage.setItem("id", response.data.teachid);
          } else {
            toast.error("Failed: Login Failed! Incorrect details.", {
              hideProgressBar: true,
              draggable: true,
              position: "top-right",
              icon: true,
              autoClose: 6000,
            });

            setTimeout(function () {
              setStat(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error("Error: Request Failed! Check network connection.");
          setTimeout(function () {
            setStat(false);
          }, 3000);
        });
    }
  };

  const [cbt_task, setCBT] = useState([]);

  const FetchCBT = () => {
    axios({
      method: "post",
      url: "./v2/portal/cbt/",
      data: {
        studid,
        studclass,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setCBT(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  return (
    <>
      <div
        id="docs-main-wrapper"
        className={isMobile ? `` : ``}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#706fd3"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <StyleRoot>
            <div style={styles.bounce}>
              <div class="docs-header">
                <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-4 shadow-none">
                  <a
                    class="fs-10 text-purple2 font-display2 cursor-pointer"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#edit_room"
                    aria-controls="offcanvasLeft"
                  >
                    <span class="avatar avatar-md avatar-primary-soft">
                      <img
                        alt="avatar"
                        src={"../assets/images/school/" + id + ".png"}
                        class="rounded-circle"
                      />
                    </span>
                    <span className="fs-t text-dark ms-2">CBT Portal Home</span>
                  </a>
                  <ul class="navbar-nav ms-auto flex-row">
                    <li class="nav-item docs-header-btn d-none d-lg-block">
                      <Link
                        to={"../" + id + "/quiz/logout"}
                        className="btn btn-danger btn-md fw-medium font-display"
                        style={{ borderRadius: "24px" }}
                      >
                        <span className="bi bi-power"></span> Logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="docs-nav-sidebar border-0">
                <div class="py-5"></div>
                <div class=" docs-nav " data-simplebar>
                  <nav class="navigation navbar navbar-expand-sm">
                    <div class="collapse navbar-collapse " id="navbarNav">
                      <ul class="navbar-nav flex-column text-start" id="sidebarnav">
                        <li class="navbar-header mt-6">
                          <h5 class="heading"></h5>
                        </li>

                        {plan === "Activated" ? (
                          <>
                            <li class="nav-item cursor-pointer mb-4">
                              <Link
                                to={"../" + id + "/quiz/home"}
                                class="nav-link fs-4 text-dark"
                              >
                                <i class="bi bi-house fs-3"></i> Dashboard
                              </Link>
                            </li>

                            <li class="nav-item cursor-pointer">
                              <Link
                                to={"../" + id + "/quiz/result"}
                                class="nav-link fs-4 text-dark"
                              >
                                <i class="bi bi-ui-checks fs-3"></i> Result
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
                <Footer />
              </div>

              <div class="docs-wrapper bg-back">
                <div class="container-fluid px-3 px-lg-12">
                  {status ? (
                    <div className="row py-lg-20 pt-20">
                      <div className="col-lg-12 text-center py-lg-12">
                        <div
                          class="spinner-border text-pink"
                          style={{ width: "4rem", height: "4rem" }}
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>

                        <div className="text-dark fs-3 font-display mt-5">
                          {" "}
                          Setting Up! Good Luck Champ....{" "}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {plan === "Activated" ? (
                        <>
                          <div className="row g-4 py-lg-15 py-3">
                            <div className="col-lg-12">
                              <div
                                className="card shadow-none bg-dark curve"
                                style={{ borderRadius: "18px" }}
                              >
                                <div className="card-body px-lg-6 py-6">
                                  <div className="mb-4">
                                    <div className="fs-t text-white">
                                      Hello {username},
                                    </div>
                                  </div>

                                  <div className="mb-4">
                                    <div className="fs-4 text-white">
                                      Below is your progress status based on the
                                      number of test(s) you have to write.
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <div
                                      class="progress"
                                      style={{ height: "15px" }}
                                    >
                                      <div
                                        class="progress-bar bg-success"
                                        role="progressbar"
                                        style={{ width: cbt_task.total + "%" }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      >
                                        {cbt_task.total}%
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-lg-8">
                              <div className="fs-3 text-dark">
                                All Tests/Exams
                              </div>
                            </div>

                            {loader1 ? (
                              <div className="col-lg-12 text-center py-lg-10 py-8">
                                <div
                                  class="spinner-border text-purple3"
                                  style={{ width: "4rem", height: "4rem" }}
                                  role="status"
                                >
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <>{renderFolders()}</>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pt-8 pt-lg-16">
                            <Player
                              autoplay={true}
                              loop={true}
                              controls={false}
                              src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                              style={{ height: "250px", width: "250px" }}
                            ></Player>

                            <div className="text-center mt-5">
                              <div className="text-purple2 fs-t fw-bold">
                                Access to this service is restricted.
                              </div>

                              <div className="text-gray-500 fs-4 mt-2">
                                For more details contact Smart Edu Team.
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </StyleRoot>

          <div
            class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
          ></div>

          <ToastContainer />

          <div
            class="btn btn-primary btn-md fs-t text-white shadow-lg rounded-br-lg2 rounded-bl-sm2 
                    rounded-t-lg2 float mb-lg-0 d-block d-lg-none"
            data-bs-toggle="modal"
            data-bs-target="#MobileMenu"
          >
            <i class="bi bi-columns-gap"></i>
          </div>

          <div
            class="modal fade"
            id="HandleLogin"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-md modal-dialog-centered"
              role="document"
            >
              <div
                class="modal-content"
                style={
                  isMobile
                    ? { border: "#fff" }
                    : { borderRadius: "35px", border: "#fff" }
                }
              >
                <div class="modal-header border-0 mt-3">
                  <div
                    class="cursor-pointer text-dark"
                    id="exampleModalCenterTitle"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg"></i>
                    <span className="text-purple3 fs-t ms-2">
                      {subject} Security Check
                    </span>
                  </div>
                </div>
                <div class="modal-body py-0">
                  <div className="px-lg-4 pb-5 pt-2">
                    <div className="d-flex mb-2">
                      <div className="fs-4 mb-4 text-dark">
                        <span className="text-dark">
                          <i class="bi bi-person-fill text-primary"></i>{" "}
                          Questioner
                        </span>{" "}
                        - {teach}
                      </div>

                      <div className="fs-4 mb-4 ms-auto text-dark">
                        <span className="text-dark">
                          <i class="bi bi-stopwatch text-danger"></i> Time
                        </span>{" "}
                        - {timer} Mins
                      </div>
                    </div>

                    <form onSubmit={onSubmit}>
                      <div class="mb-4">
                        <div
                          class="fs-4 mb-2 fw-medium text-dark"
                          for="textInput"
                        >
                          Enter Student ID
                        </div>
                        <input
                          type="text"
                          id="studentid"
                          class="form-control form-control-lg border border-secondary"
                          required
                          placeholder="Enter your student ID"
                          value={studid}
                          style={{ borderRadius: "24px" }}
                        />
                      </div>

                      <div class="mb-4">
                        <div
                          class="fs-4 mb-2 fw-medium text-dark"
                          for="textInput"
                        >
                          Enter Exam Password
                        </div>
                        <div class="input-group">
                          <input
                            type={passwordType}
                            class="form-control form-control-lg border border-secondary"
                            placeholder="Enter Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                            style={{ borderRadius: "24px" }}
                          />
                          <span
                            class="input-group-text bg-back cursor-pointer rounded-3"
                            id="basic-addon2"
                            onClick={togglePassword}
                          >
                            {passwordType === "text" ? (
                              <>
                                <i className="bi bi-eye-slash me-2"></i> hide
                              </>
                            ) : (
                              <>
                                <i class="bi bi-eye me-2"></i> show
                              </>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="">
                        <div className="mt-6">
                          <div class="mb-3 text-start text-lg-center">
                            <button
                              type="submit"
                              className="btn btn-success btn-sm font-display fs-4"
                              style={{ borderRadius: "24px" }}
                              disabled={status ? true : false}
                              data-bs-dismiss={password ? "modal" : ""}
                            >
                              {status ? (
                                <>
                                  Executing...
                                  <span
                                    class="spinner-border spinner-border-sm ms-3"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <i class="bi bi-activity"></i> Start Quiz
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="MobileMenu"
            tabindex="-1"
            role="dialog"
            data-bs-backdrop="static"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
          >
            <div
              class={
                isMobile
                  ? "modal-dialog modal-dialog-centered modal-fullscreen"
                  : "modal-dialog modal-md modal-dialog-centered"
              }
              role="document"
            >
              <div
                class="modal-content"
                style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}
              >
                <div class="modal-header border-0">
                  <h3
                    class="cursor-pointer text-dark"
                    id="exampleModalCenterTitle"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="bi bi-x-lg"></i>
                  </h3>
                  <button
                    class="btn btn-pink btn-md shadow-lg text-white font-display fw-medium"
                    style={{
                      borderRadius: "30px",
                    }}
                    type="button"
                  >
                    <span className="bi bi-power"></span> Logout{" "}
                  </button>
                </div>
                <div class="modal-body py-0">
                  <div className="mt-0 mt-lg-0 text-center">
                    <i class="bi bi-columns-gap fs-1 text-success"></i>

                    <div className="fs-10 font-display2 text-dark mt-2">
                      Dashboard Menu
                    </div>

                    <div className="row px-lg-6 pt-4 g-4 pb-10">
                      <div className="col-6 col-md-6 col-lg-6">
                        <div
                          className="card py-4 px-1 shadow-none cursor-pointer bg-light"
                          data-bs-dismiss="modal"
                          style={{ borderRadius: "25px" }}
                        >
                          <Link to={"../" + id + "/quiz/home"}>
                            <div className="text-dark fs-t text-center">
                              <div class="bi bi-house"></div>
                              <div className="fs-3">Dashboard</div>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div className="col-6 col-md-6 col-lg-6">
                        <div
                          className="card py-4 px-1 shadow-none cursor-pointer bg-dark-info"
                          data-bs-dismiss="modal"
                          style={{ borderRadius: "25px" }}
                        >
                          <Link to={"../" + id + "/quiz/result"}>
                            <div className="text-white fs-t text-center">
                              <div class="bi bi-ui-checks"></div>
                              <div className="fs-3">Result</div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer border-0">
                  <p class="mb-0 text-dark fs-4">
                    {" "}
                    Powered by{" "}
                    <a
                      href="https://smartedung.com"
                      target="_blank"
                      className="fw-bold text-success font-display"
                    >
                      <img
                        src="../assets/smlogo.png"
                        width={isMobile ? "100" : "80"}
                        alt=""
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Quiz;
