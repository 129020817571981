import React, { useState, useEffect } from "react";

import { Link, useParams } from "react-router-dom";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { useSelector } from "react-redux";

const Aside = () => {
  const hash = window.location.hash.slice(2);

  const { id } = useParams();

  const getusers = useSelector((state) => state.user.value);

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("");

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0].charAt(0).toUpperCase() +
        admin_name.split(" ")[1].charAt(0).toUpperCase());
    }, 1000);
  }, [admin_name]);

  return (
    <>
      <div class="mb-0 mb-md-0 pt-8 px-0 ms-3">

      </div>
      <div className="py-3 px-0">
        <SimpleBar
          style={{ maxHeight: 600, padding: "4px" }}
          forceVisible="y"
          autoHide={true}
        >
          <ul
            class="py-4 px-0"
            id="sideNavbar"
            style={{ padding: "0 !important" }}
          >
            <li class="nav-item py-2">
              <Link class="font-display" to={"../" + id + "/home"}>
                <a className="text-center">
                  <div class="bi bi-columns-gap fs-3"></div>
                  <div className="fs-6">Home</div>
                </a>
              </Link>
            </li>

            <li class="nav-item py-2">
              <Link class="font-display" to={"../" + id + "/exercise"}>
                <a className="text-center">
                  <div class="bi bi-ui-radios fs-3"></div>
                  <div className="fs-6">Exercise</div>
                </a>
              </Link>
            </li>

            <li class="nav-item py-2">
              <Link class="font-display" to={"../" + id + "/note"}>
                <a className="text-center ">
                  <div class="bi bi-card-text fs-3"></div>
                  <div className="fs-6">Note Maker</div>
                </a>
              </Link>
            </li>

            <li class="nav-item py-2">
              <Link class="font-display" to={"../" + id + "/cbt"}>
                <a className="text-center">
                  <div class="bi bi-list-check fs-3"></div>
                  <div className="fs-6">CBT Center</div>
                </a>
              </Link>
            </li>

            <li class="nav-item py-2">
              <Link class="font-display" to={"../" + id + "/settings"}>
                <a className="text-center ">
                  <div class="bi bi-gear fs-3"></div>
                  <div className="fs-6">Settings</div>
                </a>
              </Link>
            </li>

            <li class="nav-item py-2">
              <Link class="font-display" to={"../" + id + "/logout"}>
                <a className="text-center ">
                  <div class="bi bi-power fs-3"></div>
                  <div className="fs-6">Logout</div>
                </a>
              </Link>
            </li>
          </ul>
        </SimpleBar>
       
      </div>
    </>
  );
};

export default Aside;
