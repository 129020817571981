import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../Auth";

import axios from "axios";

const Starter = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const teachclass = getusers.test_type;

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [steps, setStep] = useState(1);

  const [status, setStat] = useState(false);

  const [progress, setProgress] = useState(20);

  const [class_type, setClass_Type] = useState(null);

  const [stud_class, setStudclass] = useState(null);

  const [stud_num, setStudNum] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      class_type,
      stud_class,
      stud_num,
    };

    axios({
      method: "post",
      url: "/v2/report/manage/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStep(3);
            setStat(false);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const [phone, setWhatsapp] = useState(null);

  const onSubmit_ = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      phone,
    };

    axios({
      method: "post",
      url: "/v2/report/phone/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStep(4);
            setStat(false);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const [test_type, setTestType] = useState(null);

  const [termsession, setSession] = useState(null);

  const [term, setTerm] = useState(null);

  const onSubmit_0 = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      teachid,
      test_type,
      termsession,
      term,
    };

    axios({
      method: "post",
      url: "/v2/report/settings/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update successful!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            navigate("../" + id + "/report");
            setStat(false);
          }, 2000);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };


  useEffect(() => {
    if(teachclass){
      setTimeout(function () {
        navigate("../" + id + "/report");
      }, 4000);
    }else{

    }
  }, [teachid]);


  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      {teachclass ? (
        <>
         
          <div class="py-15 py-lg-10 text-center">
            <div class="col-md-12 text-center py-20">
              <span class="loader"></span>
              <div class="display-6 fw-bold mb-3 text-dark ls-sm mt-8">
                <span class="headingTyped text-dark"></span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {steps === 1 ? (
            <div className="bg-start pt-lg-0 pt-5">
              <div className="py-15 py-md-9">
                <div className="col-12 mt-5 mt-lg-3">
                  <div className="text-center">
                    <div className="fs-t2 text-white fw-bold">
                      E-Report <span className="text-warning">Center</span>
                    </div>
                    <div className="fs-4 text-white mt-3 mb-20 mb-lg-4">
                      Welcome to school result center. You can now manage,
                      configure and control student's results.
                    </div>
                  </div>
                </div>

                <div className={isMobile ? "fixed-bottom" : "px-lg-20"}>
                  <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div
                      className="card shadow-sm"
                      style={{ borderRadius: "16px" }}
                    >
                      <div className="card-body">
                        <div className="text-center px-lg-8 px-2">
                          <div className="fs-t text-purple3 fw-bold mb-3">
                            Getting Started
                          </div>

                          <div className="fs-4 text-purple2 fw-medium">
                            Here's what we'll need to set up your account in
                            just a few minutes.
                          </div>
                        </div>

                        <div className="col-12 px-lg-10 px-4 pt-lg-4 pt-5">
                          <div className="d-flex align-items-center mb-4">
                            <div className="col-auto">
                              <i class="bi bi-person fs-3 text-primary"></i>
                            </div>

                            <div className="col-auto ms-3">
                              <div className="fs-4 fw-bold text-purple2">
                                Class Managing
                              </div>
                              <div className="fs-5 text-dark">
                                Enter the class you're handling
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center mb-4">
                            <div className="col-auto">
                              <i class="bi bi-whatsapp fs-3 text-success"></i>
                            </div>

                            <div className="col-auto ms-3">
                              <div className="fs-4 fw-bold text-purple2">
                                Whatsapp Number
                              </div>
                              <div className="fs-5 text-dark">
                                Enter your accurate Whatsapp number
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center">
                            <div className="col-auto">
                              <i class="bi bi-gear fs-3 text-danger"></i>
                            </div>

                            <div className="col-auto ms-3">
                              <div className="fs-4 fw-bold text-purple2">
                                Settings
                              </div>
                              <div className="fs-5 text-dark">
                                Configure E-Report settings
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-grid mt-5 px-2">
                          <button
                            className="btn btn-warning rounded-pill text-dark fs-3"
                            onClick={() => setStep(2)}
                          >
                            Let's go
                          </button>

                          <div className="fs-5 text-center text-dark mt-2">
                            Click the button to begin the setup process.
                          </div>
                        </div>
                      </div>

                      {isMobile ? (
                        <></>
                      ) : (
                        <div className="mb-2 mt-3 mb-lg-5 mt-lg-3">
                          <div className="px-lg-7 px-4">
                            <div className="col-auto">
                              <div class="mb-2">
                                <img
                                  src="../assets/smlogo.png"
                                  width={isMobile ? "100" : "80"}
                                  alt=""
                                />
                              </div>

                              <div className="text-purple2 fs-4">
                                © 2022 Powered and developed with ❤️. All rights
                                reserved
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : steps === 2 ? (
            <>
              {isMobile ? (
                <div className="px-lg-10">
                  <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div
                      className="card bg-white py-20 mt-n20"
                      style={{ borderRadius: "16px" }}
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                          <div
                            className="col-auto cursor-pointer"
                            onClick={() => setStep(1)}
                          >
                            <i class="bi bi-arrow-left fs-3 text-dark"></i>
                          </div>

                          <div className="col text-end">
                            <div className="fs-5 text-dark">1 of 3</div>
                          </div>
                        </div>

                        <div className="text-center px-lg-8 px-2">
                          <div className="fs-t text-purple3 fw-bold mb-2">
                            Are you a class teacher?
                          </div>
                        </div>

                        <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                          <img
                            src="../../assets/images/background/teach.png"
                            alt=""
                            class="img-fluid"
                          />
                        </div>

                        <form className="mt-4 mb-20" onSubmit={onSubmit}>
                          <div class="col-auto px-lg-6">
                            <select
                              class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                              aria-label="Default select example"
                              name="class_type"
                              required
                              onChange={(e) => setClass_Type(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          {class_type === "Yes" ? (
                            <>
                              <div class="col-auto px-lg-6">
                                <select
                                  class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                                  aria-label="Default select example"
                                  name="stud_class"
                                  required
                                  onChange={(e) => setStudclass(e.target.value)}
                                >
                                  <option value="">Select Class</option>
                                  <option value="PlayGroup">PlayGroup</option>
                                  <option value="Reception">Reception</option>
                                  <option value="Pre Nursery">
                                    Pre Nursery
                                  </option>
                                  <option value="NUR 1">NUR 1</option>
                                  <option value="NUR 2">NUR 2</option>
                                  <option value="KG 1">KG 1</option>
                                  <option value="KG 2">KG 2</option>
                                  <option value="Grade 1">Grade 1</option>
                                  <option value="Grade 2">Grade 2</option>
                                  <option value="Grade 3">Grade 3</option>
                                  <option value="Grade 4">Grade 4</option>
                                  <option value="Grade 5">Grade 5</option>
                                  <option value="Grade 6">Grade 6</option>
                                  <option value="Jss1">JSS 1</option>
                                  <option value="Jss2">JSS 2</option>
                                  <option value="Jss3">JSS 3</option>
                                  <option value="sss1">SSS 1</option>
                                  <option value="sss2">SSS 2</option>
                                  <option value="sss3">SSS 3</option>
                                </select>
                              </div>

                              <div class="col-auto px-lg-6">
                                <input
                                  type="number"
                                  autoComplete={false}
                                  class="form-control form-control-lg text-dark rounded-3 border-dark mb-4"
                                  placeholder="No. of students"
                                  required
                                  onChange={(e) => setStudNum(e.target.value)}
                                />
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          <div className="d-grid mt-5 px-lg-6">
                            <button
                              type="submit"
                              disabled={status ? true : false}
                              className="btn btn-warning rounded-pill fs-3 text-dark"
                            >
                              {status ? (
                                <>
                                  <span
                                    class="spinner-border spinner-border-md mt-1 text-success"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                <>
                                  Next <i className="bi bi-arrow-right"></i>
                                </>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-manage pt-lg-0 px-lg-10 pt-5 px-3">
                  <div className="py-15 py-md-5">
                    <div className="col-12 mt-5 mt-lg-0 mb-4">
                      <div className="text-center">
                        <div className="fs-t2 text-success fw-bold">
                          Class Managing
                        </div>
                      </div>
                    </div>

                    <div className="px-lg-10">
                      <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div
                          className="card bg-white sh"
                          style={{ borderRadius: "16px" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-4">
                              <div
                                className="col-auto cursor-pointer"
                                onClick={() => setStep(1)}
                              >
                                <i class="bi bi-arrow-left fs-3 text-dark"></i>
                              </div>

                              <div className="col text-end">
                                <div className="fs-5 text-dark">1 of 3</div>
                              </div>
                            </div>

                            <div className="text-center px-lg-8 px-2">
                              <div className="fs-t text-purple3 fw-bold mb-2">
                                Are you a class teacher?
                              </div>
                            </div>

                            <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                              <img
                                src="../../assets/images/background/teach.png"
                                alt=""
                                class="img-fluid"
                                width={"90%"}
                              />
                            </div>

                            <form className="mt-4" onSubmit={onSubmit}>
                              <div class="col-auto px-lg-6">
                                <select
                                  class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                                  aria-label="Default select example"
                                  name="class_type"
                                  required
                                  onChange={(e) =>
                                    setClass_Type(e.target.value)
                                  }
                                >
                                  <option value="">Select Option</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>

                              {class_type === "Yes" ? (
                                <>
                                  <div class="col-auto px-lg-6">
                                    <select
                                      class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                                      aria-label="Default select example"
                                      name="stud_class"
                                      required
                                      onChange={(e) =>
                                        setStudclass(e.target.value)
                                      }
                                    >
                                      <option value="">Select Class</option>
                                      <option value="PlayGroup">
                                        PlayGroup
                                      </option>
                                      <option value="Reception">
                                        Reception
                                      </option>
                                      <option value="Pre Nursery">
                                        Pre Nursery
                                      </option>
                                      <option value="NUR 1">NUR 1</option>
                                      <option value="NUR 2">NUR 2</option>
                                      <option value="KG 1">KG 1</option>
                                      <option value="KG 2">KG 2</option>
                                      <option value="Grade 1">Grade 1</option>
                                      <option value="Grade 2">Grade 2</option>
                                      <option value="Grade 3">Grade 3</option>
                                      <option value="Grade 4">Grade 4</option>
                                      <option value="Grade 5">Grade 5</option>
                                      <option value="Grade 6">Grade 6</option>
                                      <option value="Jss1">JSS 1</option>
                                      <option value="Jss2">JSS 2</option>
                                      <option value="Jss3">JSS 3</option>
                                      <option value="sss1">SSS 1</option>
                                      <option value="sss2">SSS 2</option>
                                      <option value="sss3">SSS 3</option>
                                    </select>
                                  </div>

                                  <div class="col-auto px-lg-6">
                                    <input
                                      type="number"
                                      autoComplete={false}
                                      class="form-control form-control-lg text-dark rounded-3 border-dark mb-4"
                                      placeholder="No. of students"
                                      required
                                      onChange={(e) =>
                                        setStudNum(e.target.value)
                                      }
                                    />
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              <div className="d-grid mt-5 px-lg-6">
                                <button
                                  type="submit"
                                  disabled={status ? true : false}
                                  className="btn btn-warning rounded-pill fs-3 text-dark"
                                >
                                  {status ? (
                                    <>
                                      <span
                                        class="spinner-border spinner-border-md mt-1 text-white"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    <>
                                      Next <i className="bi bi-arrow-right"></i>
                                    </>
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>

                          {isMobile ? (
                            <></>
                          ) : (
                            <div className="mb-2 mt-3 mb-lg-5 mt-lg-3">
                              <div className="px-lg-7 px-4">
                                <div className="col-auto">
                                  <div class="mb-2">
                                    <img
                                      src="../assets/smlogo.png"
                                      width={isMobile ? "100" : "80"}
                                      alt=""
                                    />
                                  </div>

                                  <div className="text-purple2 fs-4">
                                    © 2022 Powered and developed with ❤️. All
                                    rights reserved
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : steps === 3 ? (
            <>
              {isMobile ? (
                <div className="px-lg-10">
                  <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div
                      className="card bg-white py-20 mt-n20"
                      style={{ borderRadius: "16px" }}
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                          <div
                            className="col-auto cursor-pointer"
                            onClick={() => setStep(2)}
                          >
                            <i class="bi bi-arrow-left fs-3 text-dark"></i>
                          </div>

                          <div className="col text-end">
                            <div className="fs-5 text-dark">2 of 3</div>
                          </div>
                        </div>

                        <div className="text-center px-lg-8 px-2">
                          <div className="fs-t text-purple3 fw-bold mb-2">
                            Enter your valid WhatsApp number.
                          </div>
                        </div>

                        <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                          <img
                            src="../../assets/images/background/whatsapp.png"
                            alt=""
                            class="img-fluid"
                          />
                        </div>

                        <form className="mt-4" onSubmit={onSubmit_}>
                          <div class="col-auto px-lg-6">
                            <div class="input-group">
                              <span
                                class="input-group-text text-dark border border-0 fw-bold cursor-pointer"
                                id="basic-addon2"
                              >
                                +234
                              </span>
                              <input
                                type="number"
                                autoComplete={false}
                                class="form-control form-control-lg text-dark border-dark rounded-3"
                                placeholder="Enter Phone Number"
                                required
                                onChange={(e) => setWhatsapp(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="d-grid mt-5 px-lg-6">
                            <button
                              type="submit"
                              disabled={status ? true : false}
                              className="btn btn-warning rounded-pill fs-3 text-dark"
                            >
                              {status ? (
                                <>
                                  <span
                                    class="spinner-border spinner-border-md mt-1 text-white"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                <>
                                  Next <i className="bi bi-arrow-right"></i>
                                </>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>

                      {isMobile ? (
                        <></>
                      ) : (
                        <div className="mb-2 mt-3 mb-lg-5 mt-lg-3">
                          <div className="px-lg-7 px-4">
                            <div className="col-auto">
                              <div class="mb-2">
                                <img
                                  src="../assets/smlogo.png"
                                  width={isMobile ? "100" : "80"}
                                  alt=""
                                />
                              </div>

                              <div className="text-purple2 fs-4">
                                © 2022 Powered and developed with ❤️. All rights
                                reserved
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-light-primary pt-lg-0 px-lg-10 pt-5 px-3">
                  <div className="py-15 py-md-5">
                    <div className="col-12 mt-5 mt-lg-0 mb-4">
                      <div className="text-center">
                        <div className="fs-t2 text-purple3 fw-bold">
                          WhatsApp Number
                        </div>
                      </div>
                    </div>

                    <div className="px-lg-10">
                      <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div
                          className="card bg-white sh"
                          style={{ borderRadius: "16px" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-4">
                              <div
                                className="col-auto cursor-pointer"
                                onClick={() => setStep(2)}
                              >
                                <i class="bi bi-arrow-left fs-3 text-dark"></i>
                              </div>

                              <div className="col text-end">
                                <div className="fs-5 text-dark">2 of 3</div>
                              </div>
                            </div>

                            <div className="text-center px-lg-8 px-2">
                              <div className="fs-t text-purple3 fw-bold mb-2">
                                Enter your valid WhatsApp number.
                              </div>
                            </div>

                            <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-5 text-center">
                              <img
                                src="../../assets/images/background/whatsapp.png"
                                alt=""
                                class="img-fluid"
                              />
                            </div>

                            <form className="mt-4" onSubmit={onSubmit_}>
                              <div class="col-auto px-lg-6">
                                <div class="input-group">
                                  <span
                                    class="input-group-text text-dark border border-0 fw-bold cursor-pointer"
                                    id="basic-addon2"
                                  >
                                    +234
                                  </span>
                                  <input
                                    type="number"
                                    autoComplete={false}
                                    class="form-control form-control-lg text-dark border-dark rounded-3"
                                    placeholder="Enter Phone Number"
                                    required
                                    onChange={(e) =>
                                      setWhatsapp(e.target.value)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="d-grid mt-5 px-lg-6">
                                <button
                                  type="submit"
                                  disabled={status ? true : false}
                                  className="btn btn-warning rounded-pill fs-3 text-dark"
                                >
                                  {status ? (
                                    <>
                                      <span
                                        class="spinner-border spinner-border-md mt-1 text-white"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    <>
                                      Next <i className="bi bi-arrow-right"></i>
                                    </>
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>

                          {isMobile ? (
                            <></>
                          ) : (
                            <div className="mb-2 mt-3 mb-lg-5 mt-lg-3">
                              <div className="px-lg-7 px-4">
                                <div className="col-auto">
                                  <div class="mb-2">
                                    <img
                                      src="../assets/smlogo.png"
                                      width={isMobile ? "100" : "80"}
                                      alt=""
                                    />
                                  </div>

                                  <div className="text-purple2 fs-4">
                                    © 2022 Powered and developed with ❤️. All
                                    rights reserved
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : steps === 4 ? (
            <>
              {isMobile ? (
                <div className="px-lg-10">
                  <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <div
                      className="card bg-white py-20 mt-n20"
                      style={{ borderRadius: "16px" }}
                    >
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-4">
                          <div
                            className="col-auto cursor-pointer"
                            onClick={() => setStep(3)}
                          >
                            <i class="bi bi-arrow-left fs-3 text-dark"></i>
                          </div>

                          <div className="col text-end">
                            <div className="fs-5 text-dark">3 of 3</div>
                          </div>
                        </div>

                        <div className="text-center px-lg-8 px-2">
                          <div className="fs-t text-purple3 fw-bold mb-2">
                            Configure E-Report settings.
                          </div>
                        </div>

                        <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-2 text-center">
                          <img
                            src="../../assets/images/background/settings.webp"
                            alt=""
                            class="img-fluid"
                          />
                        </div>

                        <form className="mt-4" onSubmit={onSubmit_0}>
                          <div class="col-auto px-lg-6">
                            <select
                              class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                              aria-label="Default select example"
                              name="class_type"
                              required
                              onChange={(e) => setTestType(e.target.value)}
                            >
                              <option value="">Select Test Type</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                          </div>

                          <div class="col-auto px-lg-6">
                            <select
                              class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                              aria-label="Default select example"
                              name="term"
                              required
                              onChange={(e) => setTerm(e.target.value)}
                            >
                              <option value="">Select Term</option>
                              <option value="First Term">First Term</option>
                              <option value="Second Term">Second Term</option>
                              <option value="Third Term">Third Term</option>
                            </select>
                          </div>

                          <div class="col-auto px-lg-6">
                            <div class="input-group">
                              <input
                                type="text"
                                autoComplete={false}
                                class="form-control form-control-lg text-dark border-dark rounded-3"
                                placeholder="What session? E.g: 20**/20**"
                                required
                                onChange={(e) => setSession(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="d-grid mt-5 px-lg-6">
                            <button
                              type="submit"
                              disabled={status ? true : false}
                              className="btn btn-warning rounded-pill fs-3 text-dark"
                            >
                              {status ? (
                                <>
                                  <span
                                    class="spinner-border spinner-border-md mt-1 text-white"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </>
                              ) : (
                                <>
                                  Next <i className="bi bi-arrow-right"></i>
                                </>
                              )}
                            </button>
                          </div>
                        </form>
                      </div>

                      {isMobile ? (
                        <></>
                      ) : (
                        <div className="mb-2 mt-3 mb-lg-5 mt-lg-3">
                          <div className="px-lg-7 px-4">
                            <div className="col-auto">
                              <div class="mb-2">
                                <img
                                  src="../assets/smlogo.png"
                                  width={isMobile ? "100" : "80"}
                                  alt=""
                                />
                              </div>

                              <div className="text-purple2 fs-4">
                                © 2022 Powered and developed with ❤️. All rights
                                reserved
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bg-light-info pt-lg-0 px-lg-10 pt-5 px-3">
                  <div className="py-15 py-md-5">
                    <div className="col-12 mt-5 mt-lg-0 mb-4">
                      <div className="text-center">
                        <div className="fs-t2 text-purple3 fw-bold">
                          Adjust Settings
                        </div>
                      </div>
                    </div>

                    <div className="px-lg-10">
                      <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div
                          className="card bg-white sh"
                          style={{ borderRadius: "16px" }}
                        >
                          <div className="card-body">
                            <div className="d-flex align-items-center mb-4">
                              <div
                                className="col-auto cursor-pointer"
                                onClick={() => setStep(3)}
                              >
                                <i class="bi bi-arrow-left fs-3 text-dark"></i>
                              </div>

                              <div className="col text-end">
                                <div className="fs-5 text-dark">3 of 3</div>
                              </div>
                            </div>

                            <div className="text-center px-lg-8 px-2">
                              <div className="fs-t text-purple3 fw-bold mb-2">
                                Configure E-Report settings.
                              </div>
                            </div>

                            <div className="col-12 px-lg-10 px-4 pt-lg-0 pt-2 text-center">
                              <img
                                src="../../assets/images/background/settings.webp"
                                alt=""
                                class="img-fluid"
                              />
                            </div>

                            <form className="mt-4" onSubmit={onSubmit_0}>
                              <div class="col-auto px-lg-6">
                                <select
                                  class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                                  aria-label="Default select example"
                                  name="class_type"
                                  required
                                  onChange={(e) => setTestType(e.target.value)}
                                >
                                  <option value="">Select Test Type</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                </select>
                              </div>

                              <div class="col-auto px-lg-6">
                                <select
                                  class="form-select form-select-lg text-dark rounded-3 border-dark mb-4"
                                  aria-label="Default select example"
                                  name="term"
                                  required
                                  onChange={(e) => setTerm(e.target.value)}
                                >
                                  <option value="">Select Term</option>
                                  <option value="First Term">First Term</option>
                                  <option value="Second Term">
                                    Second Term
                                  </option>
                                  <option value="Third Term">Third Term</option>
                                </select>
                              </div>

                              <div class="col-auto px-lg-6">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    autoComplete={false}
                                    class="form-control form-control-lg text-dark border-dark rounded-3"
                                    placeholder="What session? E.g: 20**/20**"
                                    required
                                    onChange={(e) => setSession(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="d-grid mt-5 px-lg-6">
                                <button
                                  type="submit"
                                  disabled={status ? true : false}
                                  className="btn btn-warning rounded-pill fs-3 text-dark"
                                >
                                  {status ? (
                                    <>
                                      <span
                                        class="spinner-border spinner-border-md mt-1 text-white"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    </>
                                  ) : (
                                    <>
                                      Next <i className="bi bi-arrow-right"></i>
                                    </>
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>

                          {isMobile ? (
                            <></>
                          ) : (
                            <div className="mb-2 mt-3 mb-lg-5 mt-lg-3">
                              <div className="px-lg-7 px-4">
                                <div className="col-auto">
                                  <div class="mb-2">
                                    <img
                                      src="../assets/smlogo.png"
                                      width={isMobile ? "100" : "80"}
                                      alt=""
                                    />
                                  </div>

                                  <div className="text-purple2 fs-4">
                                    © 2022 Powered and developed with ❤️. All
                                    rights reserved
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default Starter;
