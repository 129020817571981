import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

const Assign = () => {
  const { id } = useParams();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const teachclass = getusers.teachclass;

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [loader, setLoader] = useState(true);

  const [students, setStudents] = useState([]);

  const [search, setSearch] = useState(null);

  const FetchStudents = (q) => {
    axios({
      method: "post",
      url: "./v2/students/",
      data: {
        teachid,
        q,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudents(view);

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 1000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const FetchStudent = (q) => {
    axios({
      method: "post",
      url: "./v2/students/",
      data: {
        teachid,
        q,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudents(view);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderStudents = () => {
    if (students == "") {
      return <></>;
    } else if (students != "") {
      return students.map((stud, index) => {
        //console.log(school.tm_date)
        return (
          <tr className="">
            <th scope="row">{index + 1}</th>
            <td>
              {stud.fname} {stud.lname}
            </td>
            <td>{stud.studid}</td>
            <td>
              {stud.teachid === teachid ? (
                <button
                  className="btn btn-white border-danger text-danger rounded-3"
                  onClick={() => Assign(1, stud.studid)}
                >
                  Unassign
                </button>
              ) : (
                <button
                  className="btn btn-white border-success text-dark rounded-3"
                  onClick={() => Assign(0, stud.studid)}
                >
                  Assign
                </button>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    FetchStudents(null);
  }, [teachid]);

  const Assign = (status, studid) => {
    axios({
      method: "post",
      url: "./v2/assign_students/",
      data: {
        teachid,
        status,
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        if (response.data.message === "success") {
          toast.success("Success: Student assigned!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          FetchStudent(search);
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  return (
    <>
      <div>
        <Auth />
        {isMobile ? (
          <>
            <div className="bg-white">
              <div className="col-12">
                <div className="card fixed-top shadow-none">
                  <div className="d-flex align-items-center px-4 py-4">
                    <div className="col">
                      <div className="fs-10 fw-medium text-purple3">
                        Assign Students
                      </div>
                    </div>

                    <div className="col-end text-center">
                      <Link
                        to={"../" + id + "/report"}
                        className="btn btn-dark-primary rounded-pill text-white fs-4 fw-medium"
                      >
                        Go Home <i class="bi bi-arrow-right-circle-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="card bg-white mt-6">
                  <div className="card-body mb-10">
                    {teachclass === "" || teachclass === "NONE" ? (
                      <>
                        <div class="container-fluid px-lg-10 pt-lg-14 pt-4">
                          <div className="row text-center">
                            <div className="col-lg-10">
                              <img
                                src="../../assets/images/background/exist.png"
                                alt=""
                                class="img-fluid"
                                width={isMobile ? "100%" : "45%"}
                              />
                            </div>

                            <div className="fs-10 text-purple3 fw-medium">
                              You are not a class teacher.
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="col-12 mt-n15">
                        <div
                          className="card shadow-none py-20"
                          style={{ borderRadius: "20px" }}
                        >
                          <div className="card-body px-2">
                            <div className="mb-4">
                              <span className="fs-t text-purple3">
                                {teachclass} Students
                              </span>
                            </div>

                            <div className="col-12 mb-4">
                              <input
                                className="form-control form-control-lg bg-back rounded-pill"
                                placeholder="Search Lists"
                                onChange={(e) => {
                                  FetchStudent(e.target.value);
                                  setSearch(e.target.value);
                                }}
                                type="search"
                              />
                            </div>

                            <div className="col-12 table-responsive mb-lg-4">
                              <table class="table caption-top fs-4 text-purple2">
                                <thead className="text-purple2">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Student ID</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    {loader ? (
                                      <td
                                        colSpan="4"
                                        className="text-center py-lg-10 py-20 mb-20"
                                      >
                                        <div
                                          class="spinner-border text-purple3"
                                          style={{
                                            width: "4rem",
                                            height: "4rem",
                                          }}
                                          role="status"
                                        >
                                          <span class="visually-hidden">
                                            Loading...
                                          </span>
                                        </div>
                                      </td>
                                    ) : (
                                      <>{renderStudents()}</>
                                    )}
                                  </>
                                </tbody>
                              </table>
                            </div>

                            <div
                              className="card py-4 shadow-lg fixed-bottom"
                              style={{
                                borderTopRightRadius: "40px",
                                borderTopLeftRadius: "40px",
                              }}
                            >
                              <div className="d-grid px-4">
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <Header />
            <div className="container">
              <div className="px-lg-8 pt-lg-16 pt-18 px-2">
                <div className="d-flex align-items-center">
                  <div className="col">
                    <div className="fs-t text-purple3">Assign Students</div>
                  </div>

                  <div className="col-end text-center">
                    <Link
                      to={"../" + id + "/report"}
                      className="btn btn-dark rounded-pill text-white fs-4 fw-medium"
                    >
                      Go Home <i class="bi bi-arrow-right-circle-fill"></i>
                    </Link>
                  </div>
                </div>
              </div>

              {teachclass === "" || teachclass === "NONE" ? (
                <>
                  <div class="container-fluid px-lg-10 pt-lg-14 pt-4">
                    <div className="row text-center">
                      <div className="col-lg-10">
                        <img
                          src="../../assets/images/background/exist.png"
                          alt=""
                          class="img-fluid"
                          width={isMobile ? "100%" : "45%"}
                        />
                      </div>

                      <div className="fs-10 text-purple3 fw-medium">
                        You are not a class teacher.
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="row px-lg-18 pt-lg-6 pt-6">
                  <div className="col-12">
                    <div
                      className="card shadow-none"
                      style={{ borderRadius: "20px" }}
                    >
                      <div className="card-body px-lg-6">
                        <div className="mb-4">
                          <span className="fs-t text-purple3">
                            {teachclass} Students
                          </span>
                        </div>

                        <div className="col mb-4">
                          <input
                            className="form-control form-control-lg bg-back rounded-pill"
                            placeholder="Search Lists"
                            onChange={(e) => {
                              FetchStudent(e.target.value);
                              setSearch(e.target.value);
                            }}
                            type="search"
                          />
                        </div>

                        <div className="col-12 table-responsive mb-lg-4">
                          <table class="table caption-top fs-4 text-purple2">
                            <thead className="text-purple2">
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Student ID</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {loader ? (
                                  <td
                                    colSpan="4"
                                    className="text-center py-lg-10 py-20"
                                  >
                                    <div
                                      class="spinner-border text-purple3"
                                      style={{ width: "4rem", height: "4rem" }}
                                      role="status"
                                    >
                                      <span class="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  </td>
                                ) : (
                                  <>{renderStudents()}</>
                                )}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Assign;
