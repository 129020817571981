import React, { useState, useEffect } from "react";

import Footer from "../Footer";

import Mobile from "../../Mobile";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { Player } from "@lottiefiles/react-lottie-player";

const Start = () => {
    const [loaders, setLoaders] = useState(true);

    const navigate = useNavigate();

    const { id } = useParams();

    const { token } = useParams();

    axios.defaults.baseURL = id;

    const [progress, setProgress] = useState(20);

    const audio = new Audio("./assets/sound/done.ogg")

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-dark-success shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-dark-success shadow-none px-6";
    }

    const teachid = localStorage.getItem("id");

    const studid = localStorage.getItem("studid");

    const username = localStorage.getItem("studname");

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const [loader, setLoader] = useState(true);

    const [quesid, setQuesid] = useState(0);

    const [rowid, setRowid] = useState(1);

    const [cbt_task, setCBT] = useState([]);

    const FetchCBT = () => {
        axios({
            method: "post",
            url: "./v2/exercise/fetch/",
            data: {
                token,
                teachid,
                studid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "failed") {
                } else {
                    setCBT(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    useEffect(() => {
        FetchCBT();

        setTimeout(function () {
            {
                setLoaders(false);
            }
        }, 2000);

    }, [token, teachid, studid]);

    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
            return <div className="timer text-white">Too late..</div>;
        }

        const hours = Math.floor(remainingTime / 3600);
        const minutes = Math.floor((remainingTime % 3600) / 60);
        const seconds = remainingTime % 60;

        return (
            <div className="timer text-center">
                <div className="value fs-3 fw-bold text-white">
                    {minutes}:{seconds}
                </div>
            </div>
        );
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 20,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 18,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 12,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 6,
        },
    };

    const [cbt_id, setCBTID] = useState([]);

    const FetchCBTID = () => {
        axios({
            method: "post",
            url: "./v2/exercise/fetch_quiz/",
            data: {
                token,
                teachid: studid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "failed") {
                } else {
                    setCBTID(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const renderCBTID = () => {
        if (cbt_id == "") {
            return <></>;
        } else if (cbt_id) {
            return cbt_id.map((cbtid, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {cbtid.id === cbt_ques.quesid && cbt_ques.type !== "Instruction" ? (
                            <button
                                className="btn btn-orange btn-sm text-white rounded-br-lg3 rounded-bl-sm3 rounded-t-lg3 fs-4"
                                onClick={() => {
                                    FetchCBTQues(cbtid.id + 1);
                                    setQuesid(cbtid.id + 1);
                                }}
                            >
                                {cbtid.id}
                            </button>
                        ) : (
                            <button
                                className="btn btn-light btn-sm text-purple2 rounded-br-lg3 rounded-bl-sm3 rounded-t-lg3 fs-4"
                                onClick={() => {
                                    FetchCBTQues(cbtid.id + 1);
                                    setQuesid(cbtid.id + 1);
                                }}
                            >
                                {cbtid.id}
                            </button>
                        )}
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const num = localStorage.getItem("quesid");

    const [cbt_ques, setCBTQues] = useState([]);

    const [anscor, setAns] = useState(null);

    const FetchCBTQues = (click, qid) => {
        window.scrollTo(50, 50);

        if (anscor) {
            SubmitQues(qid, anscor);
        } else {
        }

        axios({
            method: "post",
            url: "./v2/exercise/quiz/",
            data: {
                token,
                quesid: click,
                type: cbt_task.exam_type
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "failed") {
                } else {
                    setTimeout(function () {
                        setLoader(false);
                    }, 3000);

                    setRowid(click);

                    localStorage.setItem("quesid", quesid);

                    setCBTQues(view);

                    setAns(null);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const renderCBTQues = () => {
        if (cbt_ques == "") {
            return <>
                <div className="col-lg-8 text-center py-lg-16 py-8">
                    <div
                        class="spinner-border text-danger"
                        style={{ width: "4rem", height: "4rem" }}
                        role="status"
                    >
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <div className="mt-4 fs-t text-dark">
                        Loading...
                    </div>
                </div>
            </>;
        } else if (cbt_ques) {
            return (
                <>
                    {cbt_ques.type === "Instruction" ? (
                        <>
                            <div className="col-lg-12 py-lg-4">
                                <div className="card p-2 p-lg-3 bg-purple2 shadow-none">
                                    <div className="card p-3 p-lg-4 bg-white shadow">
                                        <div className="fs-t fw-medium py-2 text-purple2">
                                            {cbt_ques.type}
                                        </div>
                                        <div className="fs-3 text-purple2 mt-2">
                                            <div
                                                dangerouslySetInnerHTML={{ __html: cbt_ques.question }}
                                            ></div>
                                        </div>
                                        <div className="row d-flex align-items-center pt-lg-6 pt-3">
                                            <div className="col-lg-6 mb-3">
                                                {cbt_ques.rowid === 1 ? (
                                                    <></>
                                                ) : (
                                                    <>
                                                        <button
                                                            type="button"
                                                            class="btn btn-dark-success rounded-pill text-white px-5"
                                                            onClick={() => {
                                                                FetchCBTQues(cbt_ques.rowid - 1);
                                                                setQuesid(cbt_ques.quesid - 1);
                                                            }}
                                                        >
                                                            <i class="bi bi-arrow-bar-left"></i> Prev
                                                        </button>
                                                    </>
                                                )}
                                            </div>

                                            <div className="col-lg-6 text-lg-end mb-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-dark-success text-white rounded-pill px-5"
                                                    onClick={() => {
                                                        FetchCBTQues(cbt_ques.rowid + 1);
                                                        setQuesid(
                                                            parseInt(cbt_ques.rowid <= 1 ? num + 1 : num)
                                                        );
                                                    }}
                                                >
                                                    Go To Question
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            {cbt_ques.message !== "end" ? (
                                <div className="col-lg-12 mb-0 mb-lg-10">

                                    <div className="fs-t text-dark mb-4">
                                        Question {cbt_ques.quesid}
                                    </div>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: cbt_ques.question }}
                                    ></div>

                                    <div className="row g-2 g-lg-5 pt-4">

                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                            <div
                                                className={
                                                    anscor === "A"
                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white rounded-pill"
                                                        : isMobile ? "card shadow-none p-3 bg-back text-dark rounded-pill"
                                                            : "card shadow-none p-3 bg-white text-dark rounded-pill"
                                                }
                                                onClick={() => setAns("A")}
                                            >
                                                <div className="fs-3">A. {cbt_ques.ans1}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                            <div
                                                className={
                                                    anscor === "B"
                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white rounded-pill"
                                                        : isMobile ? "card shadow-none p-3 bg-back text-dark rounded-pill"
                                                            : "card shadow-none p-3 bg-white text-dark rounded-pill"
                                                }
                                                onClick={() => setAns("B")}
                                            >
                                                <div className="fs-3">B. {cbt_ques.ans2}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                            <div
                                                className={
                                                    anscor === "C"
                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white rounded-pill"
                                                        : isMobile ? "card shadow-none p-3 bg-back text-dark rounded-pill"
                                                            : "card shadow-none p-3 bg-white text-dark rounded-pill"
                                                }
                                                onClick={() => setAns("C")}
                                            >
                                                <div className="fs-3">C. {cbt_ques.ans3}</div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                            <div
                                                className={
                                                    anscor === "D"
                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white rounded-pill"
                                                        : isMobile ? "card shadow-none p-3 bg-back text-dark rounded-pill"
                                                            : "card shadow-none p-3 bg-white text-dark rounded-pill"
                                                }
                                                onClick={() => setAns("D")}
                                            >
                                                <div className="fs-3">D. {cbt_ques.ans4}</div>
                                            </div>
                                        </div>

                                        <div className="col-6 pt-5 text-start">
                                            {cbt_ques.rowid === 1 ? (
                                                <></>
                                            ) : (
                                                <>
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary rounded-pill text-white px-5"
                                                        onClick={() => {
                                                            FetchCBTQues(cbt_ques.rowid - 1, cbt_ques.quesid);
                                                            setQuesid(cbt_ques.quesid - 1);
                                                        }}
                                                    >
                                                        <i class="bi bi-arrow-bar-left"></i> Prev
                                                    </button>
                                                </>
                                            )}
                                        </div>

                                        <div className="col-6 pt-5 text-end">
                                            <button
                                                type="button"
                                                class="btn btn-success text-white px-5 rounded-pill"
                                                onClick={() => {
                                                    FetchCBTQues(cbt_ques.rowid + 1, cbt_ques.quesid);
                                                    setQuesid(cbt_ques.quesid + 1);
                                                }}
                                            >
                                                Next <i class="bi bi-arrow-bar-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="col-12 col-md-12 mb-2 mb-lg-8 pt-lg-4 pt-2">
                                        <Player
                                            autoplay={true}
                                            loop={true}
                                            controls={false}
                                            src="https://assets10.lottiefiles.com/datafiles/MmpCm2kvWjgU0Ai/data.json"
                                            style={{ height: "200px", width: "200px" }}
                                        ></Player>
                                        <div className="text-center mt-lg-3 mt-6">
                                            <div
                                                className={
                                                    isMobile
                                                        ? "fs-10 text-purple2"
                                                        : "fs-10 text-purple2"
                                                }
                                            >
                                                Hurray! You have reached the end!
                                            </div>

                                            <div className="mt-6">
                                                <button
                                                    className="btn btn-dark text-white rounded-pill 
                                                    text-uppercase px-10 shadow-none"
                                                    onClick={() => Submit_Quiz()}
                                                >
                                                    Submit Now
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            );
        } else {
            return <></>;
        }
    };

    const SubmitQues = (qid, anscor) => {
        axios({
            method: "post",
            url: "./v2/exercise/submit/",
            data: {
                token,
                quesid: qid,
                teachid: studid,
                answer: anscor,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "success") {
                    // FetchCBTID();
                } else {

                }
            })
            .catch((err) => {
                toast.error("Network: Request Failed! Check connection", {
                    hideProgressBar: true,
                    draggable: true,
                    position: "bottom-right",
                    icon: true,
                    autoClose: 6000,
                });
            });
    };

    useEffect(() => {
        FetchCBTID();
        FetchCBTQues(rowid);
    }, [token, teachid, rowid]);

    const timed = window.localStorage.getItem("timer");

    const timer = (time) => {
        console.log(time);

        if (cbt_task.status === "Completed") {

        } else {

            axios({
                method: "post",
                url: "./v2/exercise/timer/",
                data: {
                    timer: time,
                    token,
                    teachid: studid,
                },
                config: { headers: { "Content-Type": "multiport/form-data" } },
            })
                .then(function (response) {
                    const view = response.data;
                    if (view.message == "success") {
                    } else {
                    }
                })
                .catch((err) => {
                    toast.error("Network: Request Failed! Check connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "bottom-right",
                        icon: true,
                        autoClose: 6000,
                    });
                });
        }

    };

    const Submit_Quiz = () => {
        axios({
            method: "post",
            url: "./v2/exercise/end/",
            data: {
                token,
                status: 1,
                teachid: studid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                const view = response.data;
                if (response.data.message === "success") {
                    toast.success("Success: Quiz was submitted successfully", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "bottom-center",
                        icon: true,
                        autoClose: 6000,
                    });

                    audio.play();

                    setTimeout(function () {
                         navigate("../" + id + "/work/" + token + "/home");
                    }, 3000);

                } else {
                    toast.error("Error: Request Failed! System Reloading...", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "bottom-center",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        Submit_Quiz();
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Connection lost!", {
                    hideProgressBar: true,
                    draggable: true,
                    position: "bottom-center",
                    icon: true,
                    autoClose: 6000,
                });

                setTimeout(function () {
                    Submit_Quiz();
                }, 3000);
            });
    };

    useEffect(() => {

        setTimeout(function () {
            timer(timed);
        }, 3000);

        if (!teachid || !studid) {
            navigate("../" + id + "/work/" + token)
        }

        const interval = setInterval(() => {
            timer(timed);
        }, 5000);
        return () => clearInterval(interval);
    }, [timed]);

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    return (
        <>
            {cbt_task.status === "Completed" ? (
                navigate("../" + id + "/work/" + token + "/home")
            ) : (
                <>
                    <div onLoad={() => setProgress(100)} className={isMobile ? "bg-white" : "bg-back"}>
                        <LoadingBar
                            color="#474787"
                            progress={progress}
                            onLoaderFinished={() => setProgress(0)}
                        />

                        <>
                            <div className="">
                                <div class="container">
                                    <div class="header py-lg-4 py-2 px-0">
                                        <nav
                                            class={headers}
                                        >
                                            <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-lg-0">
                                                <div className="fs-t text-white fw-medium">
                                                    {truncate(cbt_task.subject, 15)}
                                                </div>
                                            </div>

                                            <div className="navbar-nav ms-auto d-flex nav-top-wrap text-end">
                                                <CountdownCircleTimer
                                                    isPlaying
                                                    size={75}
                                                    duration={cbt_task.timer}
                                                    strokeWidth={5}
                                                    colors={[
                                                        "#fff",
                                                        "#f6b93b",
                                                        "#ff793f",
                                                    ]}
                                                    colorsTime={[600, 300, 150]}
                                                    onComplete={() => (
                                                        {
                                                            shouldRepeat: false,
                                                            delay: 1,
                                                        },
                                                        Submit_Quiz()
                                                    )}
                                                    onUpdate={(remainingTime) => {
                                                        localStorage.setItem("timer", remainingTime);
                                                    }}
                                                >
                                                    {renderTime}
                                                </CountdownCircleTimer>
                                            </div>
                                        </nav>
                                    </div>

                                    <StyleRoot>
                                        <div
                                            style={styles.bounce}
                                            className="row px-4 px-lg-10 px-md-4 py-lg-8 py-md-8 pt-16"
                                        >
                                            {loader ? (
                                                <div className="col-lg-12 text-center py-lg-16 py-8">
                                                    <div
                                                        class="spinner-border text-danger"
                                                        style={{ width: "4rem", height: "4rem" }}
                                                        role="status"
                                                    >
                                                        <span class="visually-hidden">Loading...</span>
                                                    </div>
                                                    <div className="mt-4 fs-t text-dark">
                                                        Loading...
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div className="col-12 mb-6 text-start mt-2 mt-lg-6">
                                                        <div className="fs-t fw-medium text-purple3">
                                                            Hello <span className="text-success">{username}</span>,
                                                        </div>
                                                    </div>

                                                    {renderCBTQues()}</>
                                            )}

                                            <div className="col-lg-1"></div>
                                        </div>
                                    </StyleRoot>
                                </div>

                                <nav class="fixed-bottom shadow-none bg-white">
                                    <Carousel
                                        responsive={responsive}
                                        itemClass="carousel-item-padding-10-px"
                                        className="px-4 py-2"
                                        arrows={false}
                                    >
                                        {renderCBTID()}
                                    </Carousel>
                                </nav>

                            </div>

                            <Mobile />

                            <ToastContainer />

                            <Footer />
                        </>
                    </div>
                </>
            )}
        </>
    );
};

export default Start;
