import React, { useState, useEffect } from "react";

import axios from "axios";

import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

import { isMobile } from "react-device-detect";

const Switcher = () => {
  const getusers = useSelector((state) => state.user.value);

  const token = getusers.token;

  const teachid = getusers.teachid;

  const terms = getusers.term;

  const [term, setTerm] = useState("First Term");

  useEffect(() => {
    setTimeout(function () {
      setTerm(terms);
    }, 1000);
  }, [terms]);

  const HandleSwitcher = (click) => {
    const user = {
      term: click,
      teachid,
      token,
    };

    axios({
      method: "post",
      url: "/v3/switcher/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Term has been switched to " + click, {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTerm(click);

          localStorage.setItem("token", response.data.token);
        } else {
          toast.error("Failed: Service failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [username, setUsername] = useState(null);

  const [status, setStat] = useState(false);

  const [alert, setAlert] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    setAlert(false);

    const user = {
      username,
      teachid,
    };

    axios({
      method: "post",
      url: "/v2/profile/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update was successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);

          localStorage.setItem("token", response.data.token);
        } else {
          toast.error("Failed: Username Already Exists!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setAlert(true);

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  return (
    <>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="term_change"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header" style={{ padding: "0px" }}>
          <h5 id="offcanvasRightLabel"></h5>
          <button
            type="button"
            class="btn btn-white text-success2 fs-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            {" "}
            <i class="bi bi-x text-dark"></i>
          </button>
        </div>
        <div class="offcanvas-body">
          <div className="px-2">
            <div className="fs-t fw-medium font-display text-dark">
              Switch Term
            </div>
            <div className="mt-3 fs-6 font-display">
              You can change term to any of your choice of operation. But
              remember that change of term would affect all result and learning
              activities. Select the term you want to operate below.
            </div>

            <div class="mb-3 mt-4 d-grid">
              <div className="row g-lg-4 g-3">
                <div
                  className="col-lg-12 cursor-pointer"
                  onClick={() => HandleSwitcher("First Term")}
                >
                  <div
                    className={
                      term === "First Term"
                        ? "card shadow-none p-4 p-lg-3 bg-light-success border border-success rounded-pill"
                        : "card shadow-none p-4 p-lg-3 bg-white border border-dark rounded-pill"
                    }
                  >
                    <div class="form-check form-check-lg fs-3 text-center">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={term === "First Term" ? true : false}
                      />
                      <label
                        class="form-check-label text-dark text-center"
                        for="flexRadioDefault1"
                      >
                        First Term{" "}
                        {term === "First Term" ? (
                          <i class="bi bi-patch-check-fill text-success"></i>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-12 cursor-pointer"
                  onClick={() => HandleSwitcher("Second Term")}
                >
                  <div
                    className={
                      term === "Second Term"
                        ? "card shadow-none p-4 p-lg-3 bg-light-success border border-success rounded-pill"
                        : "card shadow-none p-4 p-lg-3 bg-white border border-dark rounded-pill"
                    }
                  >
                    <div class="form-check form-check-lg fs-3 text-center">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={term === "Second Term" ? true : false}
                      />
                      <label
                        class="form-check-label text-dark text-center"
                        for="flexRadioDefault2"
                      >
                        Second Term{" "}
                        {term === "Second Term" ? (
                          <i class="bi bi-patch-check-fill text-success"></i>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-12 cursor-pointer"
                  onClick={() => HandleSwitcher("Third Term")}
                >
                  <div
                    className={
                      term === "Third Term"
                        ? "card shadow-none p-4 p-lg-3 bg-light-success border border-success rounded-pill"
                        : "card shadow-none p-4 p-lg-3 bg-white border border-dark rounded-pill"
                    }
                  >
                    <div class="form-check form-check-lg fs-3 text-center">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault3"
                        checked={term === "Third Term" ? true : false}
                      />
                      <label
                        class="form-check-label text-dark text-center"
                        for="flexRadioDefault3"
                      >
                        Third Term{" "}
                        {term === "Third Term" ? (
                          <i class="bi bi-patch-check-fill text-success"></i>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-12 cursor-pointer"
                  onClick={() => HandleSwitcher("Demo Term")}
                >
                  <div
                    className={
                      term === "Demo Term"
                        ? "card shadow-none p-4 p-lg-3 bg-light-success border border-success rounded-pill"
                        : "card shadow-none p-4 p-lg-3 bg-white border border-dark rounded-pill"
                    }
                  >
                    <div class="form-check form-check-lg fs-3 text-center">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault4"
                        checked={term === "Demo Term" ? true : false}
                      />
                      <label
                        class="form-check-label text-dark text-center"
                        for="flexRadioDefault4"
                      >
                        Demo Term{" "}
                        {term === "Demo Term" ? (
                          <i class="bi bi-patch-check-fill text-success"></i>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="modal" id="set_username" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true">
        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"}
          role="document">
          <div class="modal-content" style={isMobile ? { border: "#fff" } :
            { borderRadius: "35px", border: "#fff" }}>
            <div class="modal-header border-0 pt-5 py-0">
              <div className="fs-t text-purple2">

              </div>

              <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                data-bs-dismiss="modal" aria-label="Close">
                <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
              </button>
            </div>
            <div class="modal-body py-0">
              <div className="px-lg-8 pt-lg-0 pt-8">
                <div className="fs-10 text-purple2">
                  Configure @Username
                </div>
                <div className="mt-3 fs-5 font-display">
                  Enter your desired username this way it would be very easy to
                  connect with you via multi-channels of the App services.
                </div>

                <div class="mb-3 mt-6 mt-lg-5 d-grid">
                  <form className="mt-lg-0" onSubmit={onSubmit}>
                    <div class="mb-3">
                      <label class="form-label fs-4 text-dark" for="number">
                        Enter Preferred Username
                      </label>
                      <div class="">
                        <input
                          type="text"
                          autoComplete={false}
                          id="name-input2"
                          name="username"
                          class="form-control form-control-lg bg-back rounded-pill"
                          placeholder="Enter your preferred username"
                          required
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      {alert ?
                        <div className="mt-2 fs-4 text-danger">
                          Username chosen already exist!
                        </div>
                        :
                        <></>
                      }
                    </div>

                    <div class="mb-3 mt-5 d-grid">
                      <button
                        type="submit"
                        class="btn btn-lg btn-success text-white mb-2 px-7 rounded-pill"
                        disabled={status ? true : false}
                      >
                        {status ? (
                          <>
                            Executing...
                            <span
                              class="spinner-border spinner-border-sm ms-3"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </>
                        ) : (
                          "Save Username"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Switcher;
