import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

const Sidebar = () => {
  const { id } = useParams();

  const hash = window.location.hash.slice(5);

  //console.log(hash);

  const getusers = useSelector((state) => state.user.value);

  const school = getusers.school_name;

  return (
    <>
      <div class="docs-nav-sidebar border-0">
        <div class="py-5"></div>
        <div class=" docs-nav " data-simplebar>
          <nav class="navigation navbar navbar-expand-md">
            <div class="collapse navbar-collapse " id="navbarNav">
              <ul class="navbar-nav flex-column text-start" id="sidebarnav">
                <li class="navbar-header mt-7 mb-0">
                  <span className="fs-5 text-purple2">
                    School {"--"}{" "}
                    <span className="text-success fs-4">{school}</span>
                  </span>
                  <div className="">
                    <span className="fs-5 text-purple2">Status {"--"}</span>{" "}
                    <span class="badge rounded-pill bg-light-success text-success">
                      Active
                    </span>
                  </div>
                  <hr />
                </li>

                <li class="nav-item cursor-pointer mt-3">
                  <Link
                    to={"../" + id + "/report"}
                    class={hash === "/report" ? "nav-link active" : "nav-link"}
                  >
                    <i className="bi bi-laptop text-purple3 fs-3"></i>
                    <span className="fs-4 ms-2">Dashboard</span>
                  </Link>
                </li>

                <li class="nav-item cursor-pointer mt-1">
                  <Link
                    to={"../" + id + "/report/results"}
                    class={
                      hash === "/report/results" || hash === "/report/create"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    <i className="bi bi-ui-checks text-purple3 fs-3"></i>
                    <span className="fs-4 ms-2">Results</span>
                  </Link>
                </li>

                <li class="nav-item cursor-pointer mt-1">
                  <Link
                    to={"../" + id + "/report/review"}
                    class={
                      hash === "/report/review" ? "nav-link active" : "nav-link"
                    }
                  >
                    <i class="bi bi-bounding-box text-purple3 fs-3"></i>
                    <span className="fs-4 ms-2">Review</span>
                  </Link>
                </li>

                <li class="nav-item cursor-pointer mt-1">
                  <Link
                    to={"../" + id + "/report/send"}
                    class={
                      hash === "/report/send" ? "nav-link active" : "nav-link"
                    }
                  >
                    <i className="bi bi-send-check text-purple3 fs-3"></i>
                    <span className="fs-4 ms-2">Dispatch</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <Link to={"../" + id + "/home"} class="nav-footer border-0 px-4 bg-transparent py-0">
          <div className="bg-back cursor-pointer" style={{ borderRadius: "20px" }}>
            <div className="px-2 py-3">
              <div className="text-center">
                <div className="fs-3 text-purple3">Visit E-Learning App</div>
              </div>
              <div className="text-center">
                <img
                  src="../../assets/images/background/6.png"
                  alt=""
                  class="img-fluid"
                  width={'40%'}
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Sidebar;
