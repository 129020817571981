import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import { CopyToClipboard } from "react-copy-to-clipboard";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
} from "react-share";

const Note = () => {

    const [loaders, setLoaders] = useState(true);

    const navigate = useNavigate();

    const { id } = useParams();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const teachid = getusers.teachid;

    const school_name = getusers.school_name;

    const [progress, setProgress] = useState(20);

    const [fullscreen, setFullscreen] = useState(false);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-2";
    }

    useEffect(() => {
        localStorage.setItem("id", teachid);
    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const [subjects, setSubjects] = useState([{}]);

    useEffect(() => {
        axios({
            method: "post",
            url: "./v2/subject/list/",
            data: {
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSubjects(view);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    }, [teachid]);

    const renderSubject = () => {
        if (subjects == "") {
            return <></>;
        } else if (subjects) {
            return subjects.map((task, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <option value={task.subject}>{task.subject}</option>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [title, setTitle] = useState(null);

    const [studclass, setStudclass] = useState(null);

    const [subject, setSubject] = useState(null);

    const [status, setStat] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();

        setStat(true);

        const task = {
            title,
            studclass,
            subject,
            teachid,
        };

        axios({
            method: "post",
            url: "/v2/note/create/",
            data: task,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    toast.success(
                        "Success: " + subject + " Note has been created!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    setTimeout(function () {
                        setStat(false);
                        navigate("../" + id + "/note/create/" + response.data.token);
                    }, 5000);



                } else {
                    toast.error("Failed: Request Failed! Note might already exists", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });


                    setTimeout(function () {
                        setStat(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });
    };

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setTimeout(function () {
            setLoader(false);
        }, 4000);
        FetchNotes(null);
    }, [teachid]);

    const [note_task, setNoteTask] = useState([]);

    const FetchNotes = (search) => {
        axios({
            method: "post",
            url: "./v2/note/list/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setNoteTask(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const [users, setUsers] = useState([]);

    const FetchUsers = (token, search) => {
        axios({
            method: "post",
            url: "./v2/note/users/",
            data: {
                token,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setUsers(view);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const [noteid, setNoteid] = useState([]);

    const FetchNote = (token) => {
        axios({
            method: "post",
            url: "./v2/note/",
            data: {
                teachid,
                token
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                setNoteid(view);

                fetchData();

                FetchUsers(token, null);

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const renderNotes = () => {
        if (note_task == "") {
            return (
                <div className="col-12">
                    <div className="text-center mb-lg-20">

                        <div className="p-5">
                            <div className="py-4">
                                <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "20%"} alt="" />
                            </div>
                            <div className="text-dark fs-3">
                                No Attempts yet! Try reaching out to your learners!
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else if (note_task) {
            return note_task.slice(0, 10).map((note, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <div className="col-lg-4 me-0 me-lg-6 mb-6 cursor-pointer" data-bs-toggle="modal" data-bs-target="#Open_Note">
                            <div className={(index + 1) % 4 == 0 || index === 0 ? "card bg-light-primary shadow-none"
                                : (index + 1) % 2 == 0 ? "card bg-light-warning shadow-none"
                                    : "card bg-light-info shadow-none"
                            }
                                onClick={() => FetchNote(note.token)}
                                style={{ borderRadius: "25px" }}>
                                <div className="card-body">
                                    <div className="col-4 col-lg-3">
                                        <img src="../../assets/images/background/4.png" alt=""
                                            class="img-fluid" />
                                    </div>
                                    <div className="row">
                                        <div className="col-10 fs-t text-dark">
                                            {truncate(note.title, 20)}
                                        </div>
                                        <div className="col-2 fs-t text-dark fw-medium text-end">
                                            <span className="bi bi-arrow-right"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };


    const renderUsers = () => {
        if (users == "") {
            return (
                <></>
            );
        } else if (users) {
            return users.slice(0, 4).map((users, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <span class="avatar avatar-lg">
                            <img src={"../" + id + "/students/" + users.studid + ".jpg"} alt="" class="rounded-circle avatar-md" />
                        </span>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const renderUsers_ = () => {
        if (users == "") {
            return (
                <></>
            );
        } else if (users) {
            return users.slice(0, 4).map((users, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <tr>
                            <th scope="row">1</th>
                            <td>
                                <span class="avatar avatar-md">
                                    <img src={"../" + id + "/students/" + users.studid + ".jpg"} alt="" class="rounded-circle avatar-md" />
                                </span>
                            </td>
                            <td className="pt-3">{users.fname}</td>
                            <td className="pt-3">{users.studid}</td>
                        </tr>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const renderComment = () => {
        if (users == "") {
            return (
                <></>
            );
        } else if (users) {
            return users.slice(0, 1).map((users, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <div className="col-lg-10 col-12 me-4">
                            <div className="card bg-back shadow-none" style={{ borderRadius: "25px" }}>
                                <div className="px-4 py-3">
                                    <div className="fs-3 text-dark fw-medium mb-3">
                                        {users.fname}
                                    </div>

                                    <div className="mt-4">
                                        <div dangerouslySetInnerHTML={{ __html: users.comment }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };

    const [shortenedLink, setShortenedLink] = useState("");

    const userInput = 'https://app.smartedung.com/note/' + id + '/index.php?id=' + noteid.token + '&q=' + id;

    const fetchData = async () => {

        try {

            const response = await axios(

                `https://api.shrtco.de/v2/shorten?url=${userInput}`

            );

            setShortenedLink(response.data.result.full_short_link);

        } catch (e) {

            console.log(e);

        }

    };


    const text = noteid.title + "\n\n" + noteid.subject + ". Click the link below to access the note; \n\n" + shortenedLink + "\n\n Using your student ID to access the note";

    const [copied, setCopied] = useState(false);

    const GoLink = (url) => {
        navigate(url);
      };

    return (
        <>
            <Auth />
            <div
                id="db-wrapper"
                className={isMobile ? `bg-white` : `bg-back`}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#ff793f"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <>
                    <nav class="navigation navbar-vertical-compact navbar-light bg-primary shadow-none">
                        <Aside />
                    </nav>

                    <div class="">
                        <nav
                            class={headers}
                            style={
                                isMobile
                                    ? {}
                                    : {}
                            }
                        >
                            <div className={isMobile ? "container" : "container-fluid"}>

                                <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">

                                    <div class="navbar-brand ms-0">
                                        <div className="fs-10 text-purple2 mt-1 mt-lg-0 ">{school_name}</div>
                                    </div>

                                </div>

                                <div class="ms-auto d-flex d-lg-none align-items-center">
                                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2" type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#menu"
                                        data-bs-backdrop="static"
                                        data-bs-keyboard="false">
                                        <i class="bi bi-list fw-bold fs-10 text-purple2"></i>
                                    </button>
                                </div>

                                {isMobile ? <></> : <Header_ />}
                            </div>

                        </nav>
                    </div>

                    <div id={`page-content-for-mini`} className="">

                        <div class="container py-8">

                            {app_status === "InActive" ? (
                                <StyleRoot>
                                    <div class="px-4 px-lg-4" style={styles.bounce}>
                                        <Activate />
                                    </div>
                                </StyleRoot>
                            ) : (
                                <>
                                    <StyleRoot>
                                        <div class="px-3 px-lg-6 mb-lg-0">
                                            <div className="row pt-lg-12 pt-5 g-6">

                                                <div className="col-lg-12 mt-4 mb-0 mb-lg-0">

                                                    <div className="row pt-8 pt-lg-4">
                                                        <div className="col-lg-8 col-6">
                                                            <div className="fs-t text-purple3">
                                                                Note Maker
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-4 col-6 mt-2 text-end">
                                                            <div data-bs-toggle="modal" data-bs-target="#HandleOpen"
                                                                className="btn btn-dark-success btn-md text-white rounded-3">
                                                                <span className="bi bi-plus-lg text-white"></span> {" "}Create Note
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="pt-lg-6 pt-6">
                                                        <div className="col-lg-8">
                                                            <form class="d-flex align-items-center">
                                                                <span class="position-absolute ps-3 search-icon">
                                                                    <i class="fe fe-search"></i>
                                                                </span>
                                                                <input type="search"
                                                                    class={isMobile ? "form-control form-control-lg py-3 ps-6 fs-3 text-dark bg-back rounded-pill" :
                                                                        "form-control form-control-lg ps-6 fs-4 bg-white rounded-pill"}
                                                                    onChange={(e) => FetchNotes(e.target.value)}
                                                                    placeholder="Search For Note..." />
                                                            </form>
                                                        </div>

                                                    </div>

                                                    <div className="pt-8 pt-lg-8">

                                                        <SimpleBar forceVisible="y" autoHide={true}>
                                                            <div class="d-md-flex d-block align-items-center hoverable mb-2">
                                                                {loader ? (
                                                                    <div className="col-lg-12 text-center py-lg-10 py-8">
                                                                        <div
                                                                            class="spinner-border text-purple3"
                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                            role="status"
                                                                        >
                                                                            <span class="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                ) :
                                                                    renderNotes()
                                                                }

                                                            </div>
                                                        </SimpleBar>

                                                    </div>

                                                </div>


                                                <div class="col-lg-12 col-12 mt-lg-6 mt-8 mb-4">
                                                    <div class="text-dark">
                                                        <h2 class="h3 text-dark">Need Help?</h2>
                                                        <p class="mb-0 fs-4">Do you want to know how to use the App more better? Don't
                                                            worry we got you covered.</p>
                                                        <a class="btn btn-primary rounded-3 mt-3">Help me?</a>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </StyleRoot>

                                </>
                            )}

                            <Footer />

                        </div>

                        <div class="modal" id="HandleOpen" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true">
                            <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"}
                                role="document">
                                <div class="modal-content curve" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                    <div class="modal-header border-0 py-4">
                                        <div className="fs-t text-purple2">
                                            <i class="bi bi-plus-lg"></i> Create Note {" "}
                                        </div>

                                        <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                            data-bs-dismiss="modal" aria-label="Close">
                                            <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                        </button>

                                    </div>
                                    <div class="modal-body py-0">
                                        <form className="row g-6 g-lg-4 px-lg-4 px-2 pb-5" onSubmit={onSubmit}>
                                            <div className="col-12">
                                                <div className="fs-4 text-dark mt-3 mt-lg-1">
                                                    Fill the details below to create note.
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <label class="form-label fs-4 font-display2" for="textInput">Note Title</label>
                                                <input type="text" id="title" class="form-control form-control-lg bg-back"
                                                    required placeholder="What's your note title?"
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    style={{ borderRadius: "24px" }} />
                                            </div>

                                            <div className="col-lg-12">
                                                <label class="form-label fs-4 font-display2" for="textInput">Note Subject</label>
                                                <select
                                                    class="form-select form-select-lg bg-back text-dark"
                                                    aria-label="Default select example"
                                                    required
                                                    style={{
                                                        borderRadius: "24px"
                                                    }}
                                                    onChange={(e) => setSubject(e.target.value)}
                                                >
                                                    <option value="">Select Subject</option>
                                                    {renderSubject()}
                                                </select>
                                            </div>

                                            <div className="col-lg-12">
                                                <label class="form-label fs-4 font-display2" for="textInput">Note Class</label>
                                                <select
                                                    class="form-select form-select-lg bg-back text-dark"
                                                    aria-label="Default select example"
                                                    name="studclass"
                                                    required
                                                    style={{
                                                        borderRadius: "24px"
                                                    }}
                                                    onChange={(e) => setStudclass(e.target.value)}
                                                >
                                                    <option value="">Select Class</option>
                                                    <option value="PlayGroup">PlayGroup</option>
                                                    <option value="Reception">Reception</option>
                                                    <option value="Pre Nursery">Pre Nursery</option>
                                                    <option value="NUR 1">NUR 1</option>
                                                    <option value="NUR 2">NUR 2</option>
                                                    <option value="KG 1">KG 1</option>
                                                    <option value="KG 2">KG 2</option>
                                                    <option value="Grade 1">Grade 1</option>
                                                    <option value="Grade 2">Grade 2</option>
                                                    <option value="Grade 3">Grade 3</option>
                                                    <option value="Grade 4">Grade 4</option>
                                                    <option value="Grade 5">Grade 5</option>
                                                    <option value="Grade 6">Grade 6</option>
                                                    <option value="Jss1">JSS 1</option>
                                                    <option value="Jss2">JSS 2</option>
                                                    <option value="Jss3">JSS 3</option>
                                                    <option value="sss1">SSS 1</option>
                                                    <option value="sss2">SSS 2</option>
                                                    <option value="sss3">SSS 3</option>
                                                </select>
                                            </div>

                                            <div className="col-lg-12 text-end mt-6">
                                                <button
                                                    type="submit"
                                                    class="btn btn-md btn-dark-success text-white px-4"
                                                    disabled={status ? true : false}
                                                    data-bs-dismiss={studclass && subject && title ? "modal" : ""}
                                                    style={{
                                                        borderRadius: "24px"
                                                    }}
                                                >
                                                    {status ? (
                                                        <>
                                                            Creating...
                                                            <span
                                                                class="spinner-border spinner-border-sm ms-3"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            Create Note <i class="bi bi-arrow-right ms-1"></i>
                                                        </>
                                                    )}
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal" id="Open_Note" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true">
                            <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ?
                                "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                                role="document">
                                <div class="modal-content curve" style={isMobile ? { border: "#fff" } : fullscreen ? { border: "#fff" }
                                    : { borderRadius: "35px", border: "#fff" }}>
                                    <div class={fullscreen ? "modal-header border-0 py-4 px-lg-15 " : "modal-header border-0 py-4"}>
                                        <div className="fs-t text-purple2">
                                            <i class="bi bi-sliders"></i> {isMobile ? truncate(noteid.title, 20) : noteid.title}{" "}
                                        </div>

                                        <div className="d-none d-lg-block text-end cursor-pointer" onClick={() => setFullscreen(fullscreen ? false : true)}>
                                            {fullscreen ? <i class="bi bi-fullscreen fs-t text-purple2"></i> :
                                                <i class="bi bi-arrows-fullscreen fs-t text-purple2"></i>}
                                        </div>

                                        <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 
                                        rounded-t-lg2 d-block d-lg-none cursor-pointer"
                                            data-bs-dismiss="modal" aria-label="Close">
                                            <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                        </button>

                                    </div>
                                    <div class="modal-body py-0">
                                        <div className={fullscreen ? "px-lg-20 pb-6" : "px-lg-5 pb-6"}>

                                            <div className="pt-3 pt-lg-2">

                                                <div className="row g-3">
                                                    <div className="col-6 mb-4">
                                                        <div className="fs-5 text-purple2">
                                                            Total Readers
                                                        </div>
                                                        <div className="fs-t fw-bold text-purple3">
                                                            {noteid.users} <span className="fs-4 fw-medium text-success cursor-pointer"
                                                                data-bs-toggle="modal" data-bs-target="#Open_Users">
                                                                <u>View Readers</u></span>
                                                        </div>
                                                    </div>

                                                    <div className="col-6 text-end">
                                                        <div className="fs-5 text-purple2">
                                                            Readers Avatars
                                                        </div>
                                                        <div class="">
                                                            <div class="avatar-group">
                                                                {noteid.title ? renderUsers() : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <SimpleBar forceVisible="y" autoHide={true}>
                                                <div class="d-flex hoverable mb-4 pt-5 pt-lg-6 px-lg-6">

                                                    <div className="col-12 col-lg-12 me-4">

                                                        <div className="col-lg-12 col-12">
                                                            <div className="card bg-light-info shadow-none cursor-pointer"
                                                                style={{ borderRadius: "25px" }}>
                                                                <div className="px-4 px-lg-6 py-3 py-lg-3">

                                                                    <div className="row align-items-center">
                                                                        <div className="col-10 d-flex row align-items-center">
                                                                            <div className="col-5 mt-lg-n8">
                                                                                <img src="../../assets/images/background/7.png" alt=""
                                                                                    style={isMobile ? { width: "100%" } : { width: "70%" }}
                                                                                    class="img-fluid" />
                                                                            </div>
                                                                            <div className="col-7 fs-t text-purple2 mb-3 ms-lg-n8 mt-3">
                                                                                Open Note
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-2 text-end"
                                                                            data-bs-dismiss="modal" aria-label="Close" >
                                                                            <Link to={"../" + id + "/note/create/" + noteid.token} className="btn btn-dark rounded-pill">
                                                                                <span className="bi bi-arrow-right text-white fs-3"></span>
                                                                            </Link>
                                                                        </div>

                                                                    </div>

                                                                    <div className="mt-2 mt-lg-2 text-start">
                                                                        <div className="fs-4 text-dark">
                                                                            Click on arrow to open note to make changes, and edit note details.
                                                                            Auto-Save Note is activated, therefore whatever you type saves automatically.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </SimpleBar>

                                            <div className="pt-4 pt-lg-4">
                                                <div className="mb-3">
                                                    <div className="fs-3 text-purple2">
                                                        Recent Comment on Note
                                                    </div>
                                                </div>

                                                <SimpleBar forceVisible="y" autoHide={true}>
                                                    <div class="d-flex align-items-center hoverable mb-4">
                                                        <div className="col-lg-12 col-12 me-4">
                                                            <div className="card bg-light-primary shadow-none" style={{ borderRadius: "25px" }}>
                                                                <div className="row py-5 py-lg-3 px-2 px-lg-6 d-flex row align-items-center">
                                                                    <div className="col-6 col-lg-4 mt-lg-n1">
                                                                        <img src="../../assets/images/background/2PSD.png" alt=""
                                                                            style={isMobile ? { width: "100%" } : { width: "70%" }}
                                                                            class="img-fluid" />
                                                                    </div>

                                                                    <div className="col-6 col-lg-8 text-start">
                                                                        <div className="fs-4 text-dark">
                                                                            Respond to all comments now. Click button below to begin.
                                                                        </div>

                                                                        <div onClick={() => GoLink("../" + id + "/chatroom/response")}
                                                                        data-bs-dismiss="modal" className="btn btn-dark mt-2 rounded-pill">
                                                                            Respond
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </SimpleBar>

                                            </div>

                                            <div className="row pt-4 pt-lg-4">

                                                <div className="d-none d-lg-block col-lg-6 col-12 text-start">
                                                    <div className="btn btn-light btn-lg rounded-pill me-4"
                                                        data-bs-dismiss="modal" aria-label="Close">
                                                        Close
                                                    </div>
                                                </div>

                                                <div className="d-grid d-lg-block col-lg-6 col-12 text-lg-end">
                                                    <div className="btn btn-light-danger btn-lg rounded-pill">
                                                        <i class="bi bi-trash3"></i> Delete Note
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal" id="Open_Users" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true">
                            <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ?
                                "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                                role="document">
                                <div class="modal-content curve_" style={isMobile ? { border: "#fff" } : fullscreen ? { border: "#fff" }
                                    : { borderRadius: "35px", border: "#fff" }}>
                                    <div class={fullscreen ? "modal-header border-0 py-4 px-lg-15 " : "modal-header border-0 py-4"}>
                                        <div className="fs-t text-purple2">

                                        </div>

                                        <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                            data-bs-dismiss="modal" aria-label="Close">
                                            <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                        </button>

                                    </div>
                                    <div class="modal-body py-0">
                                        <div className="px-lg-6 pb-6">
                                            <div className="fs-t2 text-purple3 mt-0">
                                                All Readers
                                            </div>

                                            <div className="pt-lg-4 pt-6">
                                                <div className="col-lg-8">
                                                    <form class="d-flex align-items-center">
                                                        <span class="position-absolute ps-3 search-icon">
                                                            <i class="fe fe-search"></i>
                                                        </span>
                                                        <input type="search"
                                                            class="form-control form-control-lg py-3 ps-6 fs-3 text-dark bg-back rounded-pill"
                                                            onChange={(e) => FetchUsers(noteid.token, e.target.value)}
                                                            placeholder="Search For Note..." />
                                                    </form>
                                                </div>
                                            </div>

                                            <div className="col-12 table-responsive pt-lg-4 pt-6">

                                                <table class="table table-borderless fs-4 text-purple2">
                                                    <thead className="border-bottom">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Image</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Student ID</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {renderUsers_()}
                                                    </tbody>
                                                </table>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <Mobile />

                    <ToastContainer />

                    <Switcher />
                </>
            </div>
        </>
    );
};

export default Note;
