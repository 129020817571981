import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const Login_Student = () => {

  const navigate = useNavigate();

  const { id } = useParams();

  const { token } = useParams();

  axios.defaults.baseURL = id;

  const [studid, setID] = useState(null);

  const [password, setPassword] = useState(null);

  const [status, setStat] = useState(false);

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const onSubmit_ = (e) => {
    e.preventDefault();

    setStat(true);

    const user = {
      teachid: studid,
      password,
      token,
      status: 1
    };

    axios({
      method: "post",
      url: "/v2/login/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          setTimeout(function () {
            setStat(false);
            navigate("./start");
          }, 2000);

          localStorage.setItem("id", response.data.teachid);

          localStorage.setItem("studname", response.data.name);

          localStorage.setItem("studid", studid);

        } else if (response.data.message === "end") {
          setTimeout(function () {
            setStat(false);
            navigate("./end");
          }, 2000);

          localStorage.setItem("id", response.data.teachid);

          localStorage.setItem("studid", studid);
        }
        else {
          toast.error("Failed: Login Failed! Incorrect details.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");

        setTimeout(function () {
          setStat(false);
        }, 3000);
      });
  };

  const [school, setSchool] = useState([]);

  const [task, setTask] = useState([]);

  const [lock, setLock] = useState(false);

  const fetchSchool = () => {
    axios({
      method: "post",
      url: "./v2/school/",
      data: {
        school_id: id,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSchool(view);
        } else {
          setLock(true);
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const FetchCBT = () => {
    axios({
      method: "post",
      url: "./v2/cbt/",
      data: {
        token
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
        } else {
          setTask(view);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    fetchSchool();
    FetchCBT();
  }, [id]);

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-default">
        <div class="container-fluid px-lg-4 px-0">
          <a class="navbar-brand fs-10 text-dark">{school.schoolname}</a>
        </div>
      </nav>

      <div class="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-gradient-mix-shade">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-7 col-lg-7 col-md-12">
              <div>
                <div class="text-white display-4 mb-4 fw-medium">
                  {task.subject} Test
                </div>
                <p class="text-white mb-6 fs-3">
                  Hi there, welcome to Online-Based MId Test. Please make sure to read the instructions
                  before attempting test, and take note of the time you have
                  been awarded. You would get your result immediately after
                  the test! Good Luck Champ!
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-10">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12 col-12 mt-n8 mb-4 mb-lg-0">
              <div class="card rounded-3">
                <div class="card-header border-bottom-3 p-4">
                  <div className="fs-3 fw-bold text-purple3">
                    Read the Instruction below
                  </div>
                </div>

                <div class="card-body">
                  <div className="fs-4 mb-4">
                    <span className="fw-bold">
                      <i class="bi bi-person-fill text-primary"></i>{" "}
                      Questioner
                    </span>{" "}
                    - {task.teach}
                  </div>

                  <hr />

                  <div className="fs-4 mb-4">
                    <span className="fw-bold">
                      <i class="bi bi-stopwatch text-warning"></i> Time
                    </span>{" "}
                    - {task.time} Mins
                  </div>

                  <hr />

                  <div className="fs-4">
                    <span className="fw-bold">
                      <i class="fe fe-calendar align-middle me-2 text-info"></i>
                      Number of questions
                    </span>{" "}
                    - {task.id} Questions
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-12 col-12 mt-lg-n20">
              <div class="card mb-3 mb-4">
                <div class="p-1">
                  <div
                    class="d-flex justify-content-center position-relative rounded py-15 border-white border rounded-3 bg-cover"
                    style={{
                      backgroundImage:
                        "url(../assets/images/background/cbt.jpeg)",
                    }}
                  ></div>
                </div>

                <form class="card-body" onSubmit={onSubmit_}>
                  <div class="mb-3">
                    <span class="text-purple3 fw-bold h2">
                      {task.class} Test
                    </span>
                  </div>
                  <div class="mb-3">
                    <label class="form-label" for="email">
                      Student ID
                    </label>
                    <input
                      type="text"
                      id="stud_id"
                      class="form-control border border-dark"
                      placeholder="Enter your ID e.g. 202XXXXXXX"
                      onChange={(e) => setID(e.target.value)}
                    />
                  </div>

                  <div class="mb-3">
                    <label class="form-label" for="email">
                      Password
                    </label>
                    <div class="input-group mt-2 input-group-lg border border-dark">
                      <input
                        type={passwordType}
                        class="form-control"
                        placeholder="Enter quiz password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        class="input-group-text cursor-pointer"
                        id="basic-addon2"
                        onClick={togglePassword}
                      >
                        {passwordType === "text" ? (
                          <>
                            <i className="bi bi-eye-slash me-2"></i>{" "}
                            hide
                          </>
                        ) : (
                          <>
                            <i class="bi bi-eye me-2"></i> show
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <div class="d-grid mt-6">
                    <button
                      type="submit"
                      class="btn btn-purple4 text-white rounded-pill mb-2 px-7 text-uppercase shadow"
                      disabled={status ? true : false}
                    >
                      {status ? (
                        <>
                          Executing...
                          <span
                            class="spinner-border spinner-border-sm ms-3"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </>
                      ) : (
                        "Start Now"
                      )}
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  )
}

export default Login_Student