import React, { useState, useEffect, useRef } from "react";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import Typed from "typed.js";

import axios from "axios";

const GetStart = () => {
    const [loaders, setLoaders] = useState(true);

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const teachid = getusers.teachid;

    const app_id = getusers.app_id;

    const [progress, setProgress] = useState(20);

    const [fullname, setfullname] = useState("{}");

    const { id } = useParams();

    const { token } = useParams();

    const navigate = useNavigate();

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    const el = useRef(null);

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: [
                "You Ready",
                "Started",
                "You Prepared!"
            ], // Strings to display
            // Speed settings, try diffrent values untill you get good results
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 300,
            smartBackspace: true,
            loop: true,
            showCursor: true,
            cursorChar: "",
        });

        // Destropying
        return () => {
            typed.destroy();
        };
    }, []);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3 px-6";
    }

    useEffect(() => {
        setTimeout(function () {
            setfullname(admin_name.split(" ")[0]);
        }, 1000);

        localStorage.setItem("id", teachid);
    }, [admin_name, teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const [QuesType, SetQuesType] = useState('Multi');

    const UpdateTask = (questype) => {

        axios({
            method: "post",
            url: "/v2/exercise/update/",
            data: { token, questype, teachid },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    navigate("../" + id + "/exercise/" + token + "/preview");

                } else {
                    toast.error("Failed: Request Failed! Please try again", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "bottom-right",
                        icon: true,
                        autoClose: 6000,
                    });

                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    }

    return (
        <>
            <Auth />
            <div
                className={isMobile ? `` : ``}
                onLoad={() => setProgress(100)}
            >

                <div className="">
                    <div className="container">
                        <div className="row pt-lg-3 pt-3">
                            <div className="col-10 text-start">

                                <div className="text-purple3 fs-1">
                                    Let's <br />
                                    Get<br />
                                </div>

                                <div className="text-purple3 fs-1" ref={el}>

                                </div>
                            </div>

                            <div className="col-2 text-end">
                                <Link to={"../" + id + "/exercise"}
                                    class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                >
                                    <i class="bi bi-x-lg fw-bold fs-3 text-dark"></i>
                                </Link>
                            </div>
                        </div>

                        <div className="container px-lg-20 fixed-bottom mb-20">
                            <div className="row px-lg-20 text-center mb-18 mb-lg-8">

                                <div className="col-6 col-lg-6 cursor-pointer">
                                    <div className={QuesType === "Multi" ? "card bg-primary p-4 shadow-none border text-white" : "card bg-white p-4 shadow-none border text-dark"}
                                        style={{ borderRadius: "15px" }}
                                        onClick={() => SetQuesType('Multi')}>
                                        <i class="bi bi-ui-checks-grid fs-t"></i>
                                        <div className="fs-3">
                                            Set Multi-choice Questions
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6 col-lg-6 cursor-pointer">
                                    <div className={QuesType === "Open" ? "card bg-primary p-4 shadow-none  border text-white" : "card bg-white p-4 shadow-none border text-dark"}
                                        style={{ borderRadius: "15px" }}
                                        onClick={() => SetQuesType('Open')}>
                                        <i class="bi bi-card-text fs-t"></i>
                                        <div className="fs-3">
                                            Set Open-ended Questions
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="row pt-lg-3 pt-3 fixed-bottom px-3 px-lg-10">
                            <div className="col-12 text-end mb-5">
                                <button
                                    onClick={() => UpdateTask(QuesType)}
                                    className="btn btn-success btn-lg rounded-pill text-white">
                                    Get Started
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                <ToastContainer />

            </div>
        </>
    );
};

export default GetStart;

