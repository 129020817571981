import React, { useState, useEffect } from "react";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams, Link } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { Player } from "@lottiefiles/react-lottie-player";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Header from "./Header";

import { CopyToClipboard } from "react-copy-to-clipboard";

const Invite = () => {
    const [loaders, setLoaders] = useState(true);

    const { id } = useParams();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const getusers = useSelector((state) => state.user.value);

    const app_status = getusers.app_status;

    const admin_name = getusers.username;

    const plan = getusers.plan;

    const wallet = getusers.wallet;

    const income = getusers.income;

    const teachid = getusers.teachid;

    const media = getusers.media;

    const media_per = media / 100 * 100

    const [progress, setProgress] = useState(20);

    const [status, setStat] = useState(false);

    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);

        toast.error("Oop! sorry you can not go back!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-center",
            icon: true,
            autoClose: 6000,
        });
    };

    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 2000);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none border border-light py-3 px-6";
    }

    useEffect(() => {
        localStorage.setItem("id", teachid);

        fetchUrl();

    }, [teachid]);

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(zoomInUp, "bounce"),
        },
    };

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + ".." : str;
    };

    const [task, setTask] = useState('Email');

    const [fullscreen, SetFullScreen] = useState(false);

    const [shortenedLink, setShortenedLink] = useState("");

    const userInput = 'https://app.smartedung.com/' + id + '/invite/index.php?id=' + teachid + '&q=' + id;

    const fetchUrl = async () => {

        try {

            const response = await axios(

                `https://api.shrtco.de/v2/shorten?url=${userInput}`

            );

            setShortenedLink(response.data.result.full_short_link);

        } catch (e) {

            console.log(e);

        }

    };

    const text = admin_name + " Has invited you to join Virtual Classroom. \n\n Click the link below to join now; \n\n" + shortenedLink;

    useEffect(() => {
        FetchUsers(null);

        setTimeout(function () {
            setLoader(false);
        }, 2000);
    }, [teachid]);

    const [loader, setLoader] = useState(true);

    const [users, setUsers] = useState([]);

    const FetchUsers = (search) => {
        axios({
            method: "post",
            url: "./v2/media/users/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                if (view == 'failed') {

                } else {
                    setUsers(view);
                }

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };


    const RemoveLearner = (studid) => {

        axios({
            method: "post",
            url: "./v2/media/students/remove/",
            data: {
                teachid,
                studid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {

                if (response.data.message === "success") {
                    toast.success(
                        "Success: Learner has been successfully deleted!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    setLoader(true);

                    setTimeout(function () {
                        setLoader(false);
                    }, 4000);

                    FetchUsers(null);

                    localStorage.setItem("token", response.data.token);

                } else if (response.data.message === "failed") {
                    toast.error("Failed: Request Failed. Please Try Again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                } else {

                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                }

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });

    }

    const renderUsers = () => {
        if (users == "") {
            return (
                <tr>
                    <th colSpan="6">
                        <div className="text-center pt-12">
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </th>
                </tr>
            );
        } else if (users) {
            return users.map((user, index) => {
                //console.log(school.tm_date)
                return (

                    <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{user.fname}</td>
                        <td>{user.studid}</td>
                        <td>{user.class}</td>
                        <td>{user.phone} <br /> {user.email}</td>
                        <td>
                            <button type="button" className="btn btn-light border border-danger"
                                onClick={() => RemoveLearner(user.studid)}
                                style={{ borderRadius: "30px" }}>
                                Remove
                            </button>
                        </td>
                    </tr>

                );
            });
        } else {
            return (
                <tr>
                    <th colSpan="6">
                        <div className="text-center pt-12">
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </th>
                </tr>
            );
        }
    };

    const [search, setSearch] = useState(null);

    const [stud, setStud] = useState([]);

    const FetchStud = (search) => {
        axios({
            method: "post",
            url: "./v2/students/",
            data: {
                teachid,
                q: search
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;

                if (view == 'failed') {

                } else {
                    setStud(view);
                }

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const AddLearner = (studid) => {
        axios({
            method: "post",
            url: "./v2/media/students/",
            data: {
                teachid,
                studid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {

                if (response.data.message === "success") {
                    toast.success(
                        "Success: Learner has been successfully added!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    setLoader(true);

                    setTimeout(function () {
                        setLoader(false);
                    }, 4000);

                    FetchUsers(null);

                    localStorage.setItem("token", response.data.token);

                } else if (response.data.message === "failed") {
                    toast.error("Failed: Request Failed. Please Try Again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });
                } else {

                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                }

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const renderStudent = () => {
        if (stud == "") {
            return (
                <tr>
                    <th colSpan="6">
                        <div className="text-center pt-12">
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </th>
                </tr>
            );
        } else if (stud) {
            return stud.map((studs, index) => {
                //console.log(school.tm_date)
                return (

                    <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{studs.fname + " " + studs.lname}</td>
                        <td>{studs.studid}</td>
                        <td>{studs.class}</td>
                        <td>{studs.phone} <br /> {studs.email}</td>
                        <td>
                            <button type="button" className="btn btn-light border border-dark"
                                onClick={() => AddLearner(studs.studid)}
                                style={{ borderRadius: "30px" }}>
                                Add
                            </button>
                        </td>
                    </tr>

                );
            });
        } else {
            return (
                <tr>
                    <th colSpan="6">
                        <div className="text-center pt-12">
                            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </th>
                </tr>
            );
        }
    };

    const [email, setEmail] = useState(null);

    const SendEmail = (e) => {

        e.preventDefault();

        setStat(true);

        axios({
            method: "post",
            url: "./v2/media/invite/",
            data: {
                teachid,
                email
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {

                if (response.data.message === "success") {
                    toast.success(
                        "Success: Email has been sent!",
                        {
                            hideProgressBar: true,
                            draggable: true,
                            position: "top-right",
                            icon: true,
                            autoClose: 6000,
                        }
                    );

                    setLoader(true);

                    setTimeout(function () {
                        setLoader(false);
                        setStat(false);
                    }, 4000);

                    FetchUsers(null);

                    localStorage.setItem("token", response.data.token);

                } else if (response.data.message === "failed") {
                    toast.error("Failed: Request Failed. Please Try Again!", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setLoader(false);
                        setStat(false);
                    }, 4000);

                } else {

                    toast.error("Failed: Request Failed! Check your connection", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setLoader(false);
                        setStat(false);
                    }, 4000);

                }

            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });

    }


    return (
        <>
            <Auth />
            <div
                id="docs-main-wrapper"
                className={isMobile ? `` : ``}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#474787"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <>

                    {app_status === "InActive" ? (
                        <StyleRoot>
                            <div class="container-fluid px-4 px-lg-4" style={styles.bounce}>
                                <Activate />
                            </div>
                        </StyleRoot>
                    ) : (
                        <>
                            <StyleRoot>

                                <div style={styles.bounce}>
                                    <div class="docs-header">
                                        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-3 shadow-sm">
                                            <a class="fs-10 text-purple2 font-display2 cursor-pointer" data-bs-toggle="offcanvas"
                                                data-bs-target="#edit_room" aria-controls="offcanvasLeft">
                                                <i class="bi bi-file-earmark-play-fill fs-t text-pink"></i> Media Quiz Invite </a>
                                            <ul class="navbar-nav ms-auto flex-row">
                                                <li class="nav-item docs-header-btn d-none d-lg-block">
                                                    <Header />
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>

                                    <div class="docs-nav-sidebar">
                                        <div class="py-5"></div>
                                        <div class=" docs-nav " data-simplebar>
                                            <nav class="navigation navbar navbar-expand-md">
                                                <div class="collapse navbar-collapse " id="navbarNav">
                                                    <ul class="navbar-nav flex-column" id="sidebarnav">
                                                        <li class="navbar-header mt-4">
                                                            <h5 class="heading"></h5>
                                                        </li>

                                                        {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?
                                                            <>
                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/home"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-house"></i> Home</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/preview"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-easel2"></i> Preview Media</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/result"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-ui-checks"></i> Result</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/billing"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-cash-coin"></i> Billing Area</Link></li>

                                                                <li class="nav-item cursor-pointer"><Link to={"../" + id + "/media/invite"}
                                                                    class="nav-link fs-4 text-dark
                                                   py-4 fw-medium"><i class="bi bi-person-plus"></i> Invite Learners</Link></li>

                                                            </> : <></>}

                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                        <div class="nav-footer">
                                            <p class="mb-0 text-dark"> Powered by <a href="https://smartedung.com" target="_blank"
                                                className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                        </div>
                                    </div>

                                    <div class="docs-wrapper bg-back Home" >

                                        <div class="container-fluid px-3 px-lg-4">

                                            {plan === "DIAMOND PLUS" || plan === "DIAMOND" ?

                                                <>

                                                    <div className="pt-lg-12 pt-4 py-12 py-lg-8">
                                                        <div className="row g-4">

                                                            <div className="col-lg-6">
                                                                <div className="card shadow-none"
                                                                    style={{ borderRadius: "15px", background: "#3742fa" }}>
                                                                    <div className="card-body">
                                                                        <div className="display-6 fw-medium text-white font-display2">
                                                                            Invite Learner
                                                                        </div>
                                                                        <div className="row pt-3 align-items-center">
                                                                            <div className="col-5">
                                                                                <img src="../assets/images/background/invite.png" alt="" />
                                                                            </div>

                                                                            <div className="col-7 text-end">
                                                                                <div className="fs-t text-white">
                                                                                    Click and copy link below and share.
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="mt-3 d-grid">
                                                                                    <CopyToClipboard text={text}
                                                                                        onCopy={() => toast.success("Success: Link copied successfully!", {
                                                                                            hideProgressBar: true,
                                                                                            draggable: true,
                                                                                            position: "bottom-right",
                                                                                            icon: true,
                                                                                            autoClose: 3000,
                                                                                        })}>

                                                                                        <button className="btn border-0 cursor-pointer text-dark fw-medium fs-4"
                                                                                            style={{ background: "#ffffffa3", borderRadius: "18px" }} >
                                                                                            {shortenedLink}
                                                                                        </button>
                                                                                    </CopyToClipboard>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="card shadow-none bg-white"
                                                                    style={{ borderRadius: "15px" }}>
                                                                    <div className="card-header py-0">
                                                                        <ul class="nav nav-lb-tab py-0">
                                                                            <li class="nav-item ms-0 me-3 fs-4 font-display2"
                                                                                onClick={() => setTask('Email')}>
                                                                                <div class={task === 'Email' ? "nav-link active px-3" : "nav-link px-3"}>
                                                                                    Email Invite
                                                                                </div>
                                                                            </li>

                                                                            <li class="nav-item ms-0 me-3 fs-4 font-display2"
                                                                                onClick={() => setTask('Student')}>
                                                                                <div class={task === 'Student' ? "nav-link active px-3" : "nav-link px-3"}>
                                                                                    Student Invite
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>

                                                                    {task === 'Email' ?
                                                                        <>
                                                                            <form onSubmit={SendEmail}>
                                                                                <div className="card-body py-0">
                                                                                    <div class="py-6 px-lg-4">
                                                                                        <p>Enter learner email address and send the link via email to learner</p>
                                                                                        <input type="email" id="email" class="form-control form-control-lg 
                                                                                            border border-secondary" placeholder="Enter Email Address"
                                                                                            required
                                                                                            onChange={(e) => setEmail(e.target.value)}
                                                                                            style={{ borderRadius: "18px" }} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card-footer text-center">
                                                                                    <button className="btn btn-dark-success text-white btn-sm
                                                                                fw-medium font-display2 fs-4" type="submit"
                                                                                        style={{ borderRadius: "30px" }}>
                                                                                        {status ? (
                                                                                            <>
                                                                                                Sending...
                                                                                                <span
                                                                                                    class="spinner-border spinner-border-sm ms-3"
                                                                                                    role="status"
                                                                                                    aria-hidden="true"
                                                                                                ></span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <span className="bi bi-send-plus-fill"></span> Send Email Invite
                                                                                            </>
                                                                                        )}

                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="card-body">
                                                                                <div className="row align-items-center py-3">
                                                                                    <div className="col-5 text-center">
                                                                                        <img src="../assets/images/background/stud.png" alt="" />
                                                                                    </div>

                                                                                    <div className="col-7 text-end">
                                                                                        <div className="fs-t text-dark fw-medium">
                                                                                            Click button below to add students with the school
                                                                                        </div>

                                                                                        <div className="mt-3">
                                                                                            <button className="btn btn-dark-success text-white fw-medium fs-4"
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#AddStudent"
                                                                                                onClick={() => FetchStud(search)}
                                                                                                style={{ borderRadius: "20px" }}>
                                                                                                Add Students
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12 py-8">

                                                                <ul class="nav nav-lb-tab">
                                                                    <li class="nav-item ms-0 me-3 fs-3 font-display2">
                                                                        <div class="nav-link active px-3">
                                                                            All Linked Users
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                                {loader ? (
                                                                    <div className="text-center pt-lg-16 pt-8">
                                                                        <div
                                                                            class="spinner-border text-danger"
                                                                            style={{ width: "4rem", height: "4rem" }}
                                                                            role="status"
                                                                        >
                                                                            <span class="visually-hidden">Loading...</span>
                                                                        </div>
                                                                        <div className="mt-4 fs-4 text-dark">Loading...</div>
                                                                    </div>
                                                                ) : (
                                                                    <div class="table-responsive mt-2">
                                                                        <table class="table fs-4 text-dark fw-medium">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Name</th>
                                                                                    <th scope="col">Student ID</th>
                                                                                    <th scope="col">Class</th>
                                                                                    <th scope="col">Contact</th>
                                                                                    <th scope="col"></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {renderUsers()}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                )}
                                                            </div>

                                                        </div>

                                                    </div>
                                                </>
                                                :

                                                <>
                                                    <div className='pt-8 pt-lg-16'>

                                                        <Player
                                                            autoplay={true}
                                                            loop={true}
                                                            controls={false}
                                                            src="https://assets4.lottiefiles.com/datafiles/ZRZcJoF7bk7uphc/data.json"
                                                            style={{ height: "250px", width: "250px" }}
                                                        ></Player>

                                                        <div className="text-center mt-5">
                                                            <div className="text-purple2 fs-t fw-bold">
                                                                Access to this service is restricted.
                                                            </div>

                                                            <div className="text-gray-500 fs-4 mt-2">
                                                                For more details contact Smart Edu Team.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                            }

                                        </div>

                                    </div>

                                </div>
                            </StyleRoot>
                        </>
                    )}

                    <div
                        class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
                    >
                    </div>



                    <Mobile />

                    <ToastContainer />

                    <Switcher />


                    <div class="modal shadow-lg fade" id="AddStudent" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : fullscreen ? "modal-dialog modal-xl modal-dialog-centered" : "modal-dialog modal-lg modal-dialog-centered"}
                            role="document">
                            <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0 pt-4">
                                    <h3 class="cursor-pointer text-dark-success fs-t" id="exampleModalCenterTitle">
                                        <span className="bi bi-plus-lg"></span> Add New Student</h3>
                                    <div className="cursor-pointer d-none d-lg-block" onClick={() => SetFullScreen(fullscreen ? false : true)}>
                                        <i class="bi bi-arrows-fullscreen text-primary fs-3"></i>
                                    </div>
                                </div>

                                <div class="modal-body py-0 pb-6">
                                    <div className="mt-2">
                                        <div className="text-purple3 fs-4 fw-medium mb-2">
                                            Search box
                                        </div>
                                        <input className="form-control form-control-md border border-secondary"
                                            onKeyUp={(e) => { FetchStud(e.target.value); setSearch(e.target.value) }}
                                            placeholder="Search out student(s)" style={{ borderRadius: "18px" }} />
                                    </div>

                                    <div className="mt-4">

                                        <SimpleBar
                                            style={{ maxHeight: 450, padding: "4px" }}
                                            forceVisible="y"
                                            autoHide={true}
                                        >
                                            <div class="table-responsive mt-2">
                                                <table class="table fs-4 text-dark fw-medium">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Student ID</th>
                                                            <th scope="col">Class</th>
                                                            <th scope="col">Contact</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>



                                                    <tbody>
                                                        {renderStudent()}
                                                    </tbody>

                                                </table>
                                            </div>
                                        </SimpleBar>
                                    </div>

                                    <div className="text-end mt-5">
                                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"
                                            style={{ borderRadius: "30px" }}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="btn btn-purple btn-md fs-t text-white shadow-lg rounded-br-lg2 rounded-bl-sm2 
                    rounded-t-lg2 float mb-lg-0 d-block d-lg-none"
                        data-bs-toggle="modal"
                        data-bs-target="#MobileMenu">
                        <i class="bi bi-columns-gap"></i>
                    </div>


                    <div class="modal fade" id="MobileMenu" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"} role="document">
                            <div class="modal-content" style={isMobile ? {} : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0">
                                    <h3 class="cursor-pointer text-dark" id="exampleModalCenterTitle" data-bs-dismiss="modal"
                                        aria-label="Close"><i class="bi bi-x-lg"></i></h3>
                                    <button class="btn btn-pink btn-md shadow-lg text-white font-display fw-medium"
                                        style={{
                                            borderRadius: "30px"
                                        }}
                                        type="button"><span className="bi bi-share"></span> Share Now </button>
                                </div>
                                <div class="modal-body py-0">
                                    <div className="mt-0 mt-lg-0 text-center">
                                        <i class="bi bi-columns-gap fs-1 text-success"></i>

                                        <div className="fs-10 font-display2 text-dark mt-2">
                                            Dashboard Menu
                                        </div>

                                        <div className="row px-lg-6 pt-4 g-4 pb-10">

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-light"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/home"}>
                                                        <div className="text-dark fs-t text-center">
                                                            <div class="bi bi-house"></div>
                                                            <div className="fs-3">Home</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-1 shadow-none cursor-pointer bg-dark-info"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/result"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-ui-checks"></div>
                                                            <div className="fs-3">Result</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-4 shadow-none cursor-pointer bg-blue"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/preview"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-easel2"></div>
                                                            <div className="fs-3">Preview Media</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-4 shadow-none cursor-pointer bg-purple3"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/billing"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-cash-coin"></div>
                                                            <div className="fs-3">Billing Area</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>


                                            <div className="col-6 col-md-6 col-lg-6">
                                                <div className="card py-4 px-2 shadow-none cursor-pointer bg-yellow"
                                                    data-bs-dismiss="modal"
                                                    style={{ borderRadius: "25px" }}>
                                                    <Link to={"../" + id + "/media/invite"}>
                                                        <div className="text-white fs-t text-center">
                                                            <div class="bi bi-person-plus"></div>
                                                            <div className="fs-3">Invite Learners</div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="modal-footer border-0">
                                    <p class="mb-0 text-dark fs-4"> Powered by <a href="https://smartedung.com" target="_blank"
                                        className="fw-bold text-success font-display"><img src="../assets/smlogo.png" width={isMobile ? "100" : "80"} alt="" /></a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </>
            </div>
        </>
    );
};

export default Invite;
