import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { isMobile } from "react-device-detect";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.snow.css";

import Forms from "./Forms";

import LoadingBar from "react-top-loading-bar";

import Auth from "../../Auth";

import Activate from "../Activate";

const Edit = () => {
    const { quill, quillRef } = useQuill();

    const navigate = useNavigate();

    const getusers = useSelector((state) => state.user.value);

    const cbt = getusers.cbt;

    const teachid = getusers.teachid;

    const app_status = getusers.app_status;

    const { id } = useParams();

    axios.defaults.baseURL = id;

    const [progress, setProgress] = useState(20);

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3 px-6";
    }


    const [typing, setTyping] = useState(false);

    const [ans1, setA] = useState(null);

    const [ans2, setB] = useState(null);

    const [ans3, setC] = useState(null);

    const [ans4, setD] = useState(null);

    const [anscor, setAns] = useState(null);

    const [question, setQues] = useState(null);

    const [status, setStat] = useState(false);

    const [task, setTask] = useState(null);

    const { token } = useParams();

    const { type } = useParams();

    const [cbt_task, setCBT] = useState([]);

    const FetchCBT = () => {
        axios({
            method: "post",
            url: "./v2/exercise/fetchs/",
            data: {
                token,
                teachid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (!view.token) {
                    setTimeout(function () {
                        //navigate("./denied");
                    }, 3000);
                } else if (view.message === "failed") {
                } else {
                    setCBT(view);

                    if (view.id >= 1) {
                        setTask("Question");
                    }

                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const form = document.getElementById("form");

        setStat(true);

        const cbt = {
            ans1,
            ans2,
            ans3,
            ans4,
            anscor,
            question,
            teachid,
            token,
            status: task,
        };

        const cbt_ = {
            ans1: 'NONE',
            ans2: 'NONE',
            ans3: 'NONE',
            ans4: 'NONE',
            anscor: 'NONE',
            question,
            teachid,
            token,
            status: task,
        };

        //console.table(cbt);

        if (question === null) {
            toast.error("Empty: Field can't be empty!", {
                hideProgressBar: true,
                draggable: true,
                position: "bottom-right",
                icon: true,
                autoClose: 6000,
            });

            setTimeout(function () {
                setStat(false);
            }, 3000);
        } else {
            axios({
                method: "post",
                url: "/v2/exercise/question/",
                data: type === "Multi" ? cbt : cbt_,
                config: { headers: { "Content-Type": "multiport/form-data" } },
            })
                .then(function (response) {
                    //console.log(response);
                    if (response.data.message === "success") {
                        toast.success("Success: CBT Question created successfully!", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        FetchCBT();

                        setTimeout(function () {
                            setStat(false);
                        }, 2000);

                        setQues(null);

                        setTask("Question");

                        setTyping(false);

                        if (quill) {
                            quill.clipboard.dangerouslyPasteHTML("");
                        }

                        localStorage.setItem("token", response.data.token);
                    } else {
                        toast.error("Failed: Request Failed! Please try again", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 6000,
                        });

                        setTimeout(function () {
                            setStat(false);
                        }, 3000);
                    }
                })
                .catch((err) => {
                    toast.error("Error: Request Failed! Check network connection.");
                });

            form.reset();
        }
    };


    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setTimeout(function () {
            setLoader(false);
        }, 1000);
        FetchCBT();

        if (type !== cbt_task.task_type) {
            navigate("../" + id + "/exercise/" + token + "/edit/" + cbt_task.task_type);
        } else {

        }

        if (cbt_task.id === 0 || cbt_task.id === '') {
            setTask('Instruction');
        } else {
            setTask('Question');
        }

    }, [token, teachid, type, cbt_task.task_type]);


    useEffect(() => {
        if (quill) {
            //quill.clipboard.dangerouslyPasteHTML("<h1>React Hook for Quill!</h1>");

            quill.on("text-change", (delta, oldDelta, source) => {
                //console.log("Text change!");
                //console.log(quill.root.innerHTML); // Get innerHTML using quill
                //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
                setQues(quillRef.current.firstChild.innerHTML);

                setTyping(true);

            });
        }
    }, [quill]);

    const [anscorr, setAnsw] = useState(null);

    const Selected = (anscorr) => {

        if (anscorr == anscor) {
            toast.success("Passed: Correct answer has been selected!", {
                hideProgressBar: true,
                draggable: true,
                position: "bottom-left",
                icon: true,
                autoClose: 6000,
            });

        } else {
            toast.error("Failed: Wrong answer has been selected", {
                hideProgressBar: true,
                draggable: true,
                position: "bottom-left",
                icon: true,
                autoClose: 6000,
            });

        }

    }

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    return (
        <>
            <Auth />
            <div
                className={isMobile ? `bg-white` : ``}
                onLoad={() => setProgress(100)}
            >
                <LoadingBar
                    color="#474787"
                    progress={progress}
                    onLoaderFinished={() => setProgress(0)}
                />

                <nav class={headers}
                    style={
                        isMobile
                            ? {}
                            : {}
                    }>
                    <div className="container-fluid">
                        <div class="navbar-brand">

                            <span className="fs-t text-purple3"><i class="bi bi-window-sidebar"></i> Exercise Workspace</span>

                        </div>

                        <div className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                            <Link to={"../" + id + "/exercise"}
                                class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                            >
                                <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                            </Link>
                        </div>

                    </div>
                </nav>

                <div className="">

                    <div class="container-fluid px-0 px-md-6 py-8">

                        {app_status === "InActive" ? (
                            <div class="container-fluid px-4 px-lg-4">
                                <Activate />
                            </div>
                        ) : (
                            <>
                                <div class="container-fluid px-4 px-md-2">
                                    <div className="row pt-md-8 pt-8 g-4">

                                        <div className="col-lg-7">

                                            <div className="row">

                                                <div className="col-6 col-lg-6 text-start">
                                                    <Link to={"../" + id + "/exercise/" + token + "/preview"} className="btn btn-dark rounded-pill">
                                                        <i class="bi bi-arrow-left"></i> Back
                                                    </Link>
                                                </div>

                                                <div className="col-6 col-lg-6 text-end">

                                                    <button className="btn btn-light-success rounded-pill px-2">
                                                        {cbt_task.class} - {truncate(cbt_task.subject, 12)} {!isMobile ? <>; {truncate(cbt_task.title, 16)}</> : <></>}
                                                    </button>

                                                </div>

                                                <div className="col-6 col-lg-4 text-start mt-8">
                                                    <div className="fs-3 text-dark nav-link active">
                                                        Upload {cbt_task.type}
                                                    </div>
                                                    <hr className="text-success mt-3" />
                                                </div>

                                            </div>

                                        </div>

                                        {isMobile ?
                                            <></> :
                                            <div className="col-lg-5">

                                                <div className="card bg-white position-fixed ms-3 mt-n8 shadow-none">

                                                    <div className="card-body mb-12">
                                                        {typing ?
                                                            <div className="py-20">

                                                                <div className="mt-n20 mb-20">

                                                                    <div className="pt-2 me-20 mb-4">
                                                                        <div className="fs-10 text-purple3">
                                                                            Preview Board
                                                                        </div>
                                                                    </div>

                                                                    <div className="rounded-3">

                                                                        <div className="col-lg-12 pt-2">

                                                                            <div className="px-4 py-3">
                                                                                <div>
                                                                                    <div className="fs-t text-dark mb-3 col-4">
                                                                                        {task}{" "} {task === "Question" ? <>{cbt_task.id + 1}</>
                                                                                            : <></>}
                                                                                        <hr className="text-danger my-1" />
                                                                                    </div>

                                                                                    <div className="" spellCheck="true"
                                                                                        dangerouslySetInnerHTML={{ __html: question }}
                                                                                    ></div>
                                                                                </div>

                                                                                {type === "Multi" ?
                                                                                    <div className="row g-2 mt-3">
                                                                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                                                                            <div
                                                                                                className={
                                                                                                    anscorr === "A"
                                                                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                                                        : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                                                }
                                                                                                onClick={() => { Selected("A"); setAnsw("A") }}
                                                                                            >
                                                                                                <div className="fs-4"><span className="fw-bold">A.</span> {ans1}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                                                                            <div
                                                                                                className={
                                                                                                    anscorr === "B"
                                                                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                                                        : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                                                }
                                                                                                onClick={() => { Selected("B"); setAnsw("B") }}
                                                                                            >
                                                                                                <div className="fs-4"><span className="fw-bold">B.</span> {ans2}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                                                                            <div
                                                                                                className={
                                                                                                    anscorr === "C"
                                                                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                                                        : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                                                }
                                                                                                onClick={() => { Selected("C"); setAnsw("C") }}
                                                                                            >
                                                                                                <div className="fs-4"><span className="fw-bold">C.</span> {ans3}</div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div class="col-lg-6 mb-4 cursor-pointer">
                                                                                            <div
                                                                                                className={
                                                                                                    anscorr === "D"
                                                                                                        ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                                                        : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                                                }
                                                                                                onClick={() => { Selected("D"); setAnsw("D") }}
                                                                                            >
                                                                                                <div className="fs-4"><span className="fw-bold">D.</span> {ans4}</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="pt-6">
                                                                                        <div className="col-lg-12">
                                                                                            <div class="container bg-success rounded-3">

                                                                                                <div class="row align-items-center py-3">

                                                                                                    <div class="col-lg-5 col-12 d-none d-lg-block">
                                                                                                        <div class="d-flex justify-content-center ">

                                                                                                            <div class="">
                                                                                                                <img src="../../assets/images/background/admin.png" alt="" class="img-fluid mt-n8" style={{ maxWidth: '100%' }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-lg-7 col-12">
                                                                                                        <div class="text-white p-5 p-lg-0">
                                                                                                            <div class="mb-0 fs-4 text-white">
                                                                                                                Hello there!
                                                                                                            </div>

                                                                                                            <div class="mb-0 fs-4 text-white mt-2">
                                                                                                                This is to inform you that this feature allows you score the exercise
                                                                                                                yourself! Leaving our AI system completely out of the equation. Now you completely
                                                                                                                take full control of the marks and the scoring.
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                            :
                                                            <div className="">
                                                                <div className="text-center mb-lg-20">
                                                                    <div className="p-5">
                                                                        <div className="py-8">
                                                                            <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "60%"} alt="" />
                                                                        </div>
                                                                        <div className="text-dark fs-t">
                                                                            Waiting for you....
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="container-fluid pb-18 pb-lg-0">

                                    <div className="col-lg-7 pt-2 pt-lg-5">
                                        <div className="card shadow-none p-3 p-lg-4 rounded-3">
                                            <div className="">
                                                <ul class="nav nav-lb-tab">
                                                    <li
                                                        class="nav-item ms-0 me-lg-5 fs-4"
                                                        onClick={() => setTask("Question")}
                                                    >
                                                        <div
                                                            class={
                                                                task === "Question"
                                                                    ? "nav-link active px-3"
                                                                    : "nav-link px-3"
                                                            }
                                                        >
                                                            Question
                                                        </div>
                                                    </li>

                                                    <li
                                                        class="nav-item ms-0 me-lg-5 fs-4"
                                                        onClick={() => setTask("Instruction")}
                                                    >
                                                        <div
                                                            class={
                                                                task === "Instruction"
                                                                    ? "nav-link active px-3"
                                                                    : "nav-link px-3"
                                                            }
                                                        >
                                                            Instruction
                                                        </div>
                                                    </li>

                                                    <li
                                                        class="nav-item ms-0 me-lg-5 fs-4"
                                                        onClick={() => setTask("Media")}
                                                    >
                                                        <div
                                                            class={
                                                                task === "Media" ? "nav-link active px-3" : "nav-link px-3"
                                                            }
                                                        >
                                                            Media
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="px-lg-2 mt-3">
                                                <div className="fs-t text-purple2 mb-2">
                                                    Create Exercise {task}{" "}
                                                    {task === "Question" ? <>{cbt_task.id + 1}</> : <></>}
                                                </div>

                                                {task === "Media" ? (
                                                    <div className="fs-5 font-display">
                                                        Select the file type you want to send, either video, audio, or
                                                        document.
                                                    </div>
                                                ) : (
                                                    <div className="fs-5 font-display">
                                                        Fill in the details below. Remember you can always make changes.
                                                    </div>
                                                )}

                                                <form className="mt-lg-0" onSubmit={onSubmit} id="form">
                                                    <div
                                                        className={
                                                            task === "Media" ? "col-lg-12 pt-3 d-none" : "col-lg-12 pt-3"
                                                        }
                                                        style={{ height: 100 }}
                                                    >
                                                        <div ref={quillRef} />
                                                    </div>

                                                    {task === "Instruction" ? (
                                                        <div className="pt-4 mt-12 mt-lg-6 mt-md-6"></div>
                                                    ) : task === "Question" ? (
                                                        <>
                                                            {type === "Multi" ?
                                                                <div className="row g-3 pt-12 mt-5 mt-lg-0 mt-md-0">
                                                                    <div className="col-md-6">
                                                                        <label class="form-label fs-5 text-dark" for="number">
                                                                            Option A
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete={false}
                                                                            class="form-control form-control-lg bg-back"
                                                                            style={{
                                                                                borderRadius: "24px"
                                                                            }}
                                                                            placeholder="Enter option A"
                                                                            required
                                                                            onChange={(e) => setA(e.target.value)}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label class="form-label fs-5 text-dark" for="number">
                                                                            Option B
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete={false}
                                                                            class="form-control form-control-lg bg-back"
                                                                            style={{
                                                                                borderRadius: "24px"
                                                                            }}
                                                                            placeholder="Enter option B"
                                                                            required
                                                                            onChange={(e) => setB(e.target.value)}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label class="form-label fs-5 text-dark" for="number">
                                                                            Option C
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete={false}
                                                                            class="form-control form-control-lg bg-back"
                                                                            style={{
                                                                                borderRadius: "24px"
                                                                            }}
                                                                            placeholder="Enter option C"
                                                                            required
                                                                            onChange={(e) => setC(e.target.value)}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <label class="form-label fs-5 text-dark" for="number">
                                                                            Option D
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            autoComplete={false}
                                                                            class="form-control form-control-lg bg-back"
                                                                            style={{
                                                                                borderRadius: "24px"
                                                                            }}
                                                                            placeholder="Enter option D"
                                                                            required
                                                                            onChange={(e) => setD(e.target.value)}
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-12">
                                                                        <label class="form-label fs-4 text-primary fw-bold">
                                                                            Choose Correct Answer Option
                                                                        </label>
                                                                        <select
                                                                            class="form-select form-select-lg bg-back text-dark"
                                                                            style={{
                                                                                borderRadius: "24px"
                                                                            }}
                                                                            aria-label="Default select example"
                                                                            onChange={(e) => setAns(e.target.value)}
                                                                        >
                                                                            <option value="">Select Correct Option</option>
                                                                            <option value="A">A</option>
                                                                            <option value="B">B</option>
                                                                            <option value="C">C</option>
                                                                            <option value="D">D</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="row g-3 pt-8 mt-5 mt-lg-0 mt-md-0"></div>
                                                            }
                                                        </>
                                                    ) : task === "Media" ? (
                                                        <div className="pt-4 mt-2 mt-lg-4 mt-md-6">
                                                            <div className="col-12 col-md-12 col-lg-6 offset-lg-3 text-center">
                                                                <img
                                                                    src="../../assets/images/background/coming.svg"
                                                                    className=""
                                                                    width="100%"
                                                                />
                                                                <div className="text-purple2 fs-3">
                                                                    Amazing Feature Coming soon...
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <div
                                                        className={
                                                            task === "Media"
                                                                ? "col-lg-12 py-5 mb-lg-0 mb-5 text-end d-none"
                                                                : "col-lg-12 py-5 mb-lg-20 mb-5 text-end"
                                                        }
                                                    >
                                                        {cbt_task.id === 10 ?
                                                            <></> :
                                                            <button
                                                                className={
                                                                    task === "Instruction"
                                                                        ? "btn btn-success btn-md shadow-sm rounded-pill"
                                                                        : "btn btn-primary btn-md shadow-sm rounded-pill"
                                                                }
                                                                disabled={status ? true : false}
                                                            >
                                                                {status ? (
                                                                    <>
                                                                        Creating...
                                                                        <span
                                                                            class="spinner-border spinner-border-sm ms-3"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Save & Next Question{" "}
                                                                        <i class="bi bi-arrow-right ms-1"></i>
                                                                    </>
                                                                )}
                                                            </button>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {isMobile ?
                                    <div className="">
                                        <button className="btn btn-purple btn-lg preview rounded-pill" 
                                        data-bs-toggle="modal" data-bs-target="#MobileEdit">
                                            Preview Task
                                        </button>
                                    </div>
                                    :
                                    <></>
                                }

                            </>
                        )}

                    </div>

                    <div class="modal" id="MobileEdit" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true">
                        <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                            role="document">
                            <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                                <div class="modal-header border-0 py-4">
                                    <div class="cursor-pointer fs-t text-dark" id="exampleModalCenterTitle">
                                        <div className="fs-t text-purple3">
                                            Preview Board
                                        </div>
                                    </div>

                                    <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                                        data-bs-dismiss="modal" aria-label="Close">
                                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                                    </button>

                                </div>
                                <div class="modal-body py-0">

                                    <div className="">
                                        {typing ?
                                            <div className="col-lg-12 pt-2">

                                                <div className="py-3">
                                                    <div>
                                                        <div className="fs-t text-dark mb-3 col-4">
                                                            {task}{" "} {task === "Question" ? <>{cbt_task.id + 1}</>
                                                                : <></>}
                                                            <hr className="text-danger my-1" />
                                                        </div>

                                                        <div className="" spellCheck="true"
                                                            dangerouslySetInnerHTML={{ __html: question }}
                                                        ></div>
                                                    </div>

                                                    {type === "Multi" ?
                                                        <div className="row g-2 mt-3">
                                                            <div class="col-lg-6 mb-4 cursor-pointer">
                                                                <div
                                                                    className={
                                                                        anscorr === "A"
                                                                            ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                            : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                    }
                                                                    onClick={() => { Selected("A"); setAnsw("A") }}
                                                                >
                                                                    <div className="fs-4"><span className="fw-bold">A.</span> {ans1}</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 mb-4 cursor-pointer">
                                                                <div
                                                                    className={
                                                                        anscorr === "B"
                                                                            ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                            : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                    }
                                                                    onClick={() => { Selected("B"); setAnsw("B") }}
                                                                >
                                                                    <div className="fs-4"><span className="fw-bold">B.</span> {ans2}</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 mb-4 cursor-pointer">
                                                                <div
                                                                    className={
                                                                        anscorr === "C"
                                                                            ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                            : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                    }
                                                                    onClick={() => { Selected("C"); setAnsw("C") }}
                                                                >
                                                                    <div className="fs-4"><span className="fw-bold">C.</span> {ans3}</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 mb-4 cursor-pointer">
                                                                <div
                                                                    className={
                                                                        anscorr === "D"
                                                                            ? "card shadow p-3 bg-gradient-mix-shade text-white"
                                                                            : "card shadow-none p-3 bg-white text-dark bg-back"
                                                                    }
                                                                    onClick={() => { Selected("D"); setAnsw("D") }}
                                                                >
                                                                    <div className="fs-4"><span className="fw-bold">D.</span> {ans4}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="pt-6">
                                                            <div className="col-lg-12">
                                                                <div class="container bg-success rounded-3">

                                                                    <div class="row align-items-center">

                                                                        <div class="col-lg-5 col-4">
                                                                            <div class="d-flex justify-content-center ">

                                                                                <div class="">
                                                                                    <img src="../../assets/images/background/admin.png" alt="" class="img-fluid mt-n8" style={{ maxWidth: '100%' }} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-7 col-8">
                                                                            <div class="text-white p-3 p-lg-0">
                                                                                <div class="mb-0 fs-4 text-white">
                                                                                    Hello there!
                                                                                </div>

                                                                                <div class="mb-0 fs-4 text-white mt-2">
                                                                                    This is to inform you that this feature allows you score the exercise
                                                                                    yourself! Leaving our AI system completely out of the equation. Now you completely
                                                                                    take full control of the marks and the scoring.
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <div className="pt-12">
                                                <div className="text-center">
                                                    <div className="p-5">
                                                        <div className="py-8">
                                                            <img src="../../assets/images/svg/3d-girl-seeting.svg" width={isMobile ? "80%" : "60%"} alt="" />
                                                        </div>
                                                        <div className="text-dark fs-t">
                                                            Waiting for you....
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <ToastContainer />

            </div>
        </>
    );
};

export default Edit;
