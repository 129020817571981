import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import Activity from "./Activity";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { Player } from "@lottiefiles/react-lottie-player";

import Statistic from "./Statistic";

import Forms from "./Forms";

const HomeApp = () => {
  const [loaders, setLoaders] = useState(true);

  const getusers = useSelector((state) => state.user.value);

  const app_status = getusers.app_status;

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const app_id = getusers.app_id;

  const school_name = getusers.school_name;

  const [progress, setProgress] = useState(20);

  const [fullname, setfullname] = useState("{}");

  const [menu, setMenu] = useState("Home");

  const { id } = useParams();

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-2";
  }

  useEffect(() => {
    setTimeout(function () {
      setfullname(admin_name.split(" ")[0]);
    }, 1000);

    localStorage.setItem("id", teachid);
  }, [admin_name, teachid]);

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
    },
  };

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 4000);
  }, []);

  const [message, setMessage] = useState(true);

  const [notes, setNotes] = useState([]);

  const [results, setResults] = useState([]);

  return (
    <>
      <Auth />
      <div
        id="db-wrapper"
        className={isMobile ? `bg-white` : `bg-back`}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#ff793f"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <nav class="navigation navbar-vertical-compact navbar-light bg-success shadow-none">
            <Aside />
          </nav>

          <div class="">
            <nav
              class={headers}
              style={
                isMobile
                  ? {}
                  : {}
              }
            >
              <div className={isMobile ? "container" : "container-fluid"}>

                <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">

                  <div class="navbar-brand ms-0">
                    <div className="fs-10 text-purple2 mt-1 mt-lg-0">{school_name}</div>
                  </div>

                </div>

                <div class="ms-auto d-flex d-lg-none d-md-none align-items-center">
                  <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2" type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#menu"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false">
                    <i class="bi bi-list fw-bold fs-10 text-purple2"></i>
                  </button>
                </div>

                {isMobile ? <></> : <Header_ />}
              </div>

            </nav>
          </div>

          <div id={`page-content-for-mini`} className="">

            <div class="container py-8">

              {app_status === "InActive" ? (
                <StyleRoot>
                  <div class="px-4 px-lg-4" style={styles.bounce}>
                    <Activate />
                  </div>
                </StyleRoot>
              ) : (
                <>
                  <StyleRoot>
                    <div class="px-4 px-md-6 mb-lg-12" style={styles.bounce}>
                      <div className="row pt-md-10 pt-5 g-4">

                        <div className="col-lg-8 mt-4">

                          <div className="row g-4">

                            <div className="col-lg-12 mb-2 pt-5 pt-lg-1">
                              <div className="fs-10 text-purple2">
                                Welcome <span className="text-purple3">{app_id ? app_id : fullname}</span>! 👋
                              </div>
                            </div>

                            {app_id ?
                              <div className="col-lg-12">
                                <div className={isMobile ? "card bg-back shadow-none cursor-pointer" :
                                  "card shadow-none bg-white cursor-pointer"} style={{ borderRadius: "25px" }}>
                                  <div className="card-body row align-items-center">
                                    <div className="col-md-2 col-4">
                                      <div className="ms-n8 mt-n8">
                                        <Player
                                          autoplay={true}
                                          loop={true}
                                          controls={false}
                                          src="https://assets2.lottiefiles.com/packages/lf20_LJ6eMa.json"
                                          style={{ width: "200px" }}
                                        ></Player>
                                      </div>
                                    </div>
                                    <div className="col-md-10 col-8 text-dark">
                                      <span className="fs-3">
                                        Thank you for being part of our growing community!
                                      </span>
                                      <div className="fs-5 font-display">

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="col-lg-12" data-bs-toggle="modal"
                              data-bs-target="#set_username" role="button">
                                <div className={isMobile ? "card bg-back shadow-none cursor-pointer" :
                                  "card shadow-none bg-white cursor-pointer"} style={{ borderRadius: "25px" }}>
                                  <div className="card-body row align-items-center">
                                    <div className="col-2">
                                      <div className="display-3 text-success">
                                        @
                                      </div>
                                    </div>
                                    <div className="col-10 text-purple3">
                                      <span className="fs-3 fw-medium font-display">
                                        Create your @username
                                      </span>
                                      <div className="fs-4 font-display">
                                        Easy connection with everyone via your
                                        username.
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }



                           
                                <div className="col-lg-12 mt-lg-8 mt-8 mb-lg-8">
                                  <div className="">
                                    <div className="text-purple2 fs-10">Quick Start</div>

                                    <p className="fs-3 text-dark mt-3">
                                      Create and set your classwork, homework questions with ease and share to students seemlessly.
                                      Likewise you can now set you CBT Test and Exam questions ahead of time!
                                    </p>
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div class={isMobile ? "container bg-light-primary" : "container bg-white"} 
                                  style={{ borderRadius: "25px" }}>

                                    <div class="row align-items-center py-3">

                                      <div class="col-lg-5 col-12 d-none d-lg-block">
                                        <div class="d-flex justify-content-center ">

                                          <div class="position-relative">
                                            <img src="../../assets/images/background/4.png" alt="" class="img-fluid mt-n12" />
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-12">
                                        <div class="text-dark p-5 p-lg-0">

                                          <h2 class="h2 text-dark">Create a new task now.</h2>
                                          <div class="mb-0 fs-4">Set up parameters for classwork, homework and CBT. Just follow the steps and
                                            instructions</div>
                                          <Link to={"../" + id + "/new/Classwork"} class="btn btn-dark mt-4 rounded-pill">Begin New Task</Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            



                           
                              <div className="col-lg-12 mt-lg-15 mt-12">
                                <div class={isMobile ? "container bg-light-success" : "container bg-white"}
                                style={{ borderRadius: "25px" }}>

                                  <div class="row align-items-center py-3">

                                    <div class="col-lg-5 col-12 d-none d-lg-block">
                                      <div class="d-flex justify-content-center ">

                                        <div class="position-relative">
                                          <img src="../../assets/images/background/admin.png" alt="" class="img-fluid mt-n12" />

                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                      <div class="text-dark p-5 p-lg-0">

                                        <h2 class="h2 text-dark">Make/Upload Notes.</h2>
                                        <div class="mb-0 fs-4">Set up parameters for classwork, homework and CBT. Just follow the steps and
                                          instructions</div>
                                        <Link to={"../" + id + "/note"} class="btn btn-dark mt-4 rounded-pill">Create New Note</Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          

                            <div className="col-lg-12 mt-lg-18 mt-12 mb-lg-13">
                              <div className="">
                                <span className="text-purple2 fw-medium fs-t">What's new on Smart Edu?</span>

                                <p className="fs-3 text-dark mt-3">
                                  Explore with us as we introduce new and amazing features of our App! Our aim is to
                                  advance learning, give you chance to earn and also make work as seamless as possible.
                                </p>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div class="container bg-dark-success" style={{ borderRadius: "25px" }}>

                                <div class="row align-items-center py-3">

                                  <div class="col-lg-5 col-4">
                                    <div class="d-flex justify-content-center ">

                                      <div class="position-relative">
                                        <img src="../../assets/images/png/teacher3.png" alt="" class="img-fluid mt-n17" />
                                        <div class="ms-n9 ms-lg-n8 position-absolute bottom-0 start-0 mb-6">
                                          <img src="../../assets/images/svg/dollor.svg" alt="" />
                                        </div>

                                        <div class="me-n4 position-absolute top-0 end-0">
                                          <img src="../../assets/images/svg/graph.svg" alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-6 col-8">
                                    <div class="text-white p-2 p-lg-0">

                                      <h2 class="h2 text-white">Become a private instructor today!</h2>
                                      <div class="mb-0 fs-4 text-white">Instructors connects with students on Smart Edu App across schools to teach and earn money.</div>
                                      <a class="btn btn-white mt-4 rounded-pill">Start Teaching Today</a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>

                        </div>

                        {isMobile ?
                          <></> :
                          <div className="col-lg-4">

                            <div className="card bg-white position-fixed ms-3 mt-n8 shadow-none">

                              <div className="card-body mb-12">
                                <Statistic />
                              </div>

                            </div>

                          </div>
                        }

                      </div>
                    </div>
                  </StyleRoot>

                </>
              )}

              <Forms />

              <Footer />

            </div>

          </div>

          <Mobile />

          <ToastContainer />

          <Switcher />
        </>
      </div>
    </>
  );
};

export default HomeApp;
