import React from 'react'

export const Toggle = () => {
    return (

        <div
            class="btn btn-dark-success fs-10 text-white shadow-lg btn-sm end-0 me-4 me-lg-2 rounded-circle float mb-lg-2 mb-10"
            data-bs-toggle="modal"
            data-bs-target="#Handlesettings"
        >
            <i class="bi bi-sliders"></i>
        </div>

    )
}
