import React, { useState, useEffect } from "react";

import Mobile from "../../Mobile";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useNavigate, useParams, Link } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import axios from "axios";

import Chart from "react-apexcharts";

import Footer from "./Footer";

const End_Test = () => {
  const [loaders, setLoaders] = useState(true);

  const { id } = useParams();

  const { token } = useParams();

  const navigate = useNavigate();

  axios.defaults.baseURL = id;

  const [progress, setProgress] = useState(20);

  const teachid = localStorage.getItem("id");

  const studid = localStorage.getItem("studid");

  const username = localStorage.getItem("studname");

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const plan = localStorage.getItem("plan");

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none";
  } else {
    headers = "navbar-default navbar navbar-expand-lg shadow-none";
  }

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  const [cbt_task, setCBT] = useState([]);

  const FetchCBT = () => {
    axios({
      method: "post",
      url: "./v2/cbt/fetch/",
      data: {
        token,
        teachid,
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
        } else {
          setCBT(view);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchCBT();
    FetchCBTID();

    window.scrollTo(50, 50);
  }, [teachid, token]);

  const check = localStorage.getItem("studname");

  if (!check) {
    navigate("../" + id + "/quiz");
  }

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const config_ = {
    series: [100 - cbt_task.total],
    options: {
      plotOptions: {
        radialBar: {
          startAngle: -30,
          endAngle: 330,
          background: "#000",
          hollow: {
            margin: 0,
            size: "80%",
            background: "transparent",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: false,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#10ac84",
            strokeWidth: "90%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: false,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          tooltips: {
            enabled: true,
            intersect: true,
            mode: "nearest",
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: true,
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -20,
              show: false,
              color: "transparent",
              fontSize: "8px",
            },
            value: {
              formatter: function (val) {
                return 100 - val + "%";
              },
              color: "#000",
              fontSize: "50px",
              show: true,
            },
          },
        },
      },
      theme: {
        palette: "palette7",
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Total Score Mark"],
    },
  };

  const [cbt_id, setCBTID] = useState([]);

  const FetchCBTID = () => {
    axios({
      method: "post",
      url: "./v2/cbt/fetch_quiz/",
      data: {
        token,
        teachid: studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
        } else {
          setCBTID(view);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderCBTID = () => {
    if (cbt_id == "") {
      return <></>;
    } else if (cbt_id) {
      return cbt_id.map((cbtid, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="col-lg-6">
              <div className="card p-4 shadow-sm bg-white rounded-3 mb-4">
                <div className="fs-t text-dark">Question {cbtid.id}</div>
                {!cbtid.answer ? (
                  <>
                    <div className="col-lg-6 fs-3 text-dark mt-2">
                      You didn't answer question {cbtid.id}{" "}
                      <i class="bi bi-x-lg text-danger fw-bold"></i>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="fs-3 text-dark mt-4"
                      dangerouslySetInnerHTML={{ __html: cbtid.question }}
                    ></div>
                    {cbtid.answer === cbtid.anscor ? (
                      <>
                        <div className="d-lg-flex row align-items-center">
                          <div className="col-lg-6 fs-3 text-dark mt-2">
                            You answered: {cbtid.answer}{" "}
                            <i class="bi bi-check-lg text-success fw-bold"></i>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="d-lg-flex row align-items-center">
                        <div className="col-lg-6 fs-3 text-dark mt-2">
                          You answered: {cbtid.answer}{" "}
                          <i class="bi bi-x-lg text-danger fw-bold"></i>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div
        id="docs-main-wrapper"
        className={isMobile ? `` : ``}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#706fd3"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <StyleRoot>
            <div style={styles.bounce}>
              <div class="docs-header">
                <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top ms-0 py-3 py-lg-4 shadow-none">
                  <a
                    class="fs-10 text-purple2 font-display2 cursor-pointer"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#edit_room"
                    aria-controls="offcanvasLeft"
                  >
                    <span class="avatar avatar-md avatar-primary-soft">
                      <img
                        alt="avatar"
                        src={"../assets/images/school/" + id + ".png"}
                        class="rounded-circle"
                      />
                    </span>
                    <span className="fs-t text-dark ms-2">
                      {isMobile ? (
                        <>{truncate(cbt_task.subject, 14)} Results</>
                      ) : (
                        <>
                          Hi {username}, {cbt_task.subject} - {cbt_task.type}{" "}
                          Results
                        </>
                      )}
                    </span>
                  </a>
                  <ul class="navbar-nav ms-auto flex-row">
                    <li class="nav-item docs-header-btn d-none d-lg-block">
                      <Link
                        to={"../" + id + "/quiz/logout"}
                        className="btn btn-danger btn-md fw-medium font-display"
                        style={{ borderRadius: "24px" }}
                      >
                        <span className="bi bi-power"></span> Logout
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>

              <div class="docs-nav-sidebar border-0">
                <div class="py-5"></div>
                <div class=" docs-nav " data-simplebar>
                  <nav class="navigation navbar navbar-expand-sm">
                    <div class="collapse navbar-collapse " id="navbarNav">
                      <ul
                        class="navbar-nav flex-column text-start"
                        id="sidebarnav"
                      >
                        <li class="navbar-header mt-6">
                          <h5 class="heading"></h5>
                        </li>

                        {plan === "Activated" ? (
                          <>
                            <li class="nav-item cursor-pointer mb-4">
                              <Link
                                to={"../" + id + "/quiz/home"}
                                class="nav-link fs-4 text-dark"
                              >
                                <i class="bi bi-house fs-3"></i> Dashboard
                              </Link>
                            </li>

                            <li class="nav-item cursor-pointer">
                              <Link
                                to={"../" + id + "/quiz/result"}
                                class="nav-link fs-4 text-dark"
                              >
                                <i class="bi bi-ui-checks fs-3"></i> Result
                              </Link>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
                <Footer />
              </div>

              <div class="docs-wrapper bg-back">
                <div className="Home_ bg-back">
                  <div class="container-fluid px-4 px-lg-4">
                    <StyleRoot>
                      <div
                        style={styles.bounce}
                        className="row px-lg-4 pt-lg-15 py-7 align-items-center"
                      >
                        <div className="col-lg-12 mb-lg-0 mb-3">
                          <div className="col-12 fs-t fw-medium text-dark-success mb-2 font-display">
                            Accessment details
                          </div>

                          <div class={isMobile ? "" : "row hstack gap-3"}>
                            <div class="col-lg-6 mb-lg-0 mb-6">
                              <div className="card rounded-3 shadow-none bg-primary">
                                <div className="card-body">
                                  <div className="fs-3 text-white mb-4">
                                    Total No. of Question: {cbt_task.id}
                                  </div>

                                  <div className="fs-3 text-white mb-4">
                                    No. of Answer Question: {cbt_task.num_ques}
                                  </div>

                                  <div className="fs-3 text-white mb-3">
                                    Time left: {cbt_task.time_left} mins left
                                    out of {cbt_task.time} mins
                                  </div>
                                  {cbt_task.total < 50 ? (
                                    <>
                                      <div className="fs-3 text-white mb-2">
                                        Ranking:{" "}
                                        <span className="fs-2">
                                          <i class="bi bi-award-fill text-primary"></i>{" "}
                                          Amateur
                                        </span>
                                      </div>
                                    </>
                                  ) : (
                                    <div className="fs-3 text-white mb-2">
                                      Ranking:{" "}
                                      <span className="fs-2">
                                        <i class="bi bi-award-fill text-warning"></i>{" "}
                                        Master
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-1 d-lg-flex d-none"
                              style={{ height: "200px" }}
                            >
                              <div class="vr"></div>
                            </div>
                            <div class="col-lg-4">
                              <div className="mb-5">
                                <div className="mb-3">
                                  <div className="fs-t text-dark">
                                    You Scored:
                                  </div>
                                </div>
                                <Chart
                                  options={config_.options}
                                  series={config_.series}
                                  type="radialBar"
                                  height={280}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 pt-4">
                          <div className="col-12 display-6 text-purple2 pb-6 font-display">
                            Question History
                          </div>

                          <div className="row align-items-center">
                            {renderCBTID()}
                          </div>
                        </div>
                      </div>
                    </StyleRoot>
                  </div>
                </div>
              </div>

              <div
                class="btn btn-purple4 fs-10 text-white shadow-lg btn-sm rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2
                         float mb-lg-0 beamerTrigger d-none"
              ></div>

              <ToastContainer />
            </div>
          </StyleRoot>
        </>
      </div>
    </>
  );
};

export default End_Test;
