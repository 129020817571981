import React, { useState, useEffect } from "react";

import Aside from "../Aside";

import Footer from "../Footer";

import Auth from "../../Auth";

import Mobile from "../../Mobile";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams } from "react-router-dom";

import Activate from "../Activate";

import LoadingBar from "react-top-loading-bar";

import { isMobile } from "react-device-detect";

import Header_ from "../Header_";

import Switcher from "../Switcher";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import CBT_Action from "./CBT_Action";

import axios from "axios";
import Test from "./Test";
import Exam from "./Exam";

const CBT = () => {
  const [loaders, setLoaders] = useState(true);

  const { id } = useParams();

  axios.defaults.baseURL = id;

  const getusers = useSelector((state) => state.user.value);

  const app_status = getusers.app_status;

  const admin_name = getusers.username;

  const teachid = getusers.teachid;

  const [progress, setProgress] = useState(20);

  const school_name = getusers.school_name;

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  setTimeout(function () {
    {
      setLoaders(false);
    }
  }, 2000);

  let headers;

  if (isMobile) {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none position-fixed";
  } else {
    headers = "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-2";
  }

  useEffect(() => {
    localStorage.setItem("id", teachid);
  }, [teachid]);

  const styles = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(fadeIn, "bounce"),
    },
  };

  const styles1 = {
    bounce: {
      animation: "x 4s",
      animationName: Radium.keyframes(zoomInUp, "bounce"),
    },
  };

  const [cbt_name, setName] = useState(null);

  const [cbt_type, setCBTType] = useState(null);

  const [studclass, setStudclass] = useState(null);

  const [subject, setSubject] = useState(null);

  const [duration, setTime] = useState(null);

  const [password, setPassword] = useState(null);

  const [status, setStat] = useState(false);

  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  if (duration > 90) {
    setTime("90");
    toast.error("Timer can't be more than 90 minutes", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 4000,
    });
  } else {
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    const task = {
      cbt_name,
      cbt_type,
      studclass,
      subject,
      duration,
      password,
      teachid,
    };

    axios({
      method: "post",
      url: "/v2/cbt/create/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success(
            "Success: " + subject + " CBT Folder has been created!",
            {
              hideProgressBar: true,
              draggable: true,
              position: "top-right",
              icon: true,
              autoClose: 6000,
            }
          );

          SearchCBTList(cbt_name, cbt_type);

          setToggle(cbt_type);

          setTimeout(function () {
            setStat(false);
          }, 2000);

          localStorage.setItem("token", response.data.token);
        } else {
          toast.error("Failed: Request Failed! CBT Folder already exists", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setSearch(cbt_name);

          SearchCBTList(cbt_name, cbt_type);

          setToggle(cbt_type);

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [toggle, setToggle] = useState('Test');

  const [subjects, setSubjects] = useState([{}]);

  useEffect(() => {
    axios({
      method: "post",
      url: "./v2/subject/list/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSubjects(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  }, [teachid]);

  const renderSubject = () => {
    if (subjects == "") {
      return <></>;
    } else if (subjects) {
      return subjects.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <option value={task.subject}>{task.subject}</option>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [loader, setLoader] = useState(true);

  const [folders, setFolders] = useState([]);

  const SearchCBTList = (search, toggle) => {
    axios({
      method: "post",
      url: "./v2/cbt/list/",
      data: {
        teachid,
        q: search,
        task: toggle === "Test" ? 'TEST' : 'EXAM',
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setFolders(view);

          setLoader(true);

          setTimeout(function () {
            setLoader(false);
          }, 1000);

        } else {
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [loader_, setLoader_] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 4000);
    SearchCBTList(null, toggle);
  }, [teachid, toggle]);

  const [search, setSearch] = useState(null);

  const [cbt_task, setCBT] = useState([]);

  const FetchCBT = (token) => {

    setLoader_(true);

    axios({
      method: "post",
      url: "./v2/cbt/fetch/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "failed") {
        } else {
          setCBT(view);

          setTimeout(function () {
            setLoader_(false);
          }, 2000);

        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const deleteCBT = () => {
    axios({
      method: "post",
      url: "./v2/cbt/delete/",
      data: {
        token: cbt_task.token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "success") {
          toast.success("Success: CBT has been deleted", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });

          SearchCBTList(null, toggle);

        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const [loader1, setLoader1] = useState(true);

  const [bin, setBin] = useState([]);

  const SearchBinList = (search) => {
    axios({
      method: "post",
      url: "./v2/cbt/lists/",
      data: {
        teachid,
        q: search,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {

          setBin(view);

          setLoader1(true);

          setTimeout(function () {
            setLoader1(false);
          }, 1000);

        } else {
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };


  const renderFolders = () => {
    if (bin == "") {
      return (
        <>

        </>
      );
    } else if (bin) {
      return bin.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            {task.access === 'DELETED' ?
              <tr>
                <td><span className="text-danger">{task.title}</span></td>
                <td><span className="text-dark">{task.subject} - {task.class}</span></td>
                <td class="">
                  <div className="text-end">
                  <button className="btn btn-primary rounded-pill" onClick={()=>{HandleRestore(task.token, task.title); 
                    SearchBinList(null)}}>
                    Restore
                  </button>
                  </div>
                </td>
              </tr>
              :
              <></>
            }
          </>
        );
      });
    } else {
      return (
        <>

        </>
      );
    }
  };

  const HandleRestore = (token, name) => {

    axios({
      method: "post",
      url: "./v2/cbt/restore/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message === "success") {
          toast.success("Success: "+name+" CBT has been RESTORED!", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });

          SearchCBTList(name, toggle);

          setSearch(name);

        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        //toast.error("Error: Request Failed! Refresh page..");
      });

  }

  return (
    <>
      <Auth />

      <div
        id="db-wrapper"
        className={isMobile ? `bg-white` : `bg-back`}
        onLoad={() => setProgress(100)}
      >
        <LoadingBar
          color="#ff793f"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />

        <>
          <nav class="navigation navbar-vertical-compact navbar-light bg-primary shadow-none">
            <Aside />
          </nav>

          <div class="">
            <nav
              class={headers}
              style={
                isMobile
                  ? {}
                  : {}
              }
            >
              <div className={isMobile ? "container" : "container-fluid"}>

                <div class="navbar-nav navbar-left-wrap ms-0 text-start mt-1 mt-lg-0">

                  <div class="navbar-brand ms-0">
                    <div className="fs-10 text-purple2 mt-1 mt-lg-0">{school_name}</div>
                  </div>

                </div>

                <div class="ms-auto d-flex d-lg-none d-md-none align-items-center">
                  <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2" type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#menu"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false">
                    <i class="bi bi-list fw-bold fs-10 text-purple2"></i>
                  </button>
                </div>

                {isMobile ? <></> : <Header_ />}
              </div>

            </nav>
          </div>

          <div id={`page-content-for-mini`} className="">

            <div class="container py-8">

              {app_status === "InActive" ? (
                <StyleRoot>
                  <div class="px-4 px-lg-4" style={styles.bounce}>
                    <Activate />
                  </div>
                </StyleRoot>
              ) : (
                <>
                  <StyleRoot>
                    <div class="px-3 px-lg-6 mb-lg-0">
                      <div className="row pt-10 pt-lg-12">
                        <div className="col-lg-6 col-6">
                          <div className="fs-t text-purple3">
                            CBT Center
                          </div>
                        </div>

                        <div className="col-lg-6 col-6 mt-2 text-end">
                          <button className={toggle === 'Test' ? "btn btn-success btn-sm rounded-pill me-lg-15 me-5" :
                            isMobile ? "btn btn-light btn-sm rounded-pill me-lg-15 me-5" :
                              "btn btn-white btn-sm rounded-pill me-lg-15 me-5"}
                            onClick={() => { setToggle('Test'); SearchCBTList(null, toggle) }}>
                            Test
                          </button>

                          <button className={toggle === 'Exam' ? "btn btn-success btn-sm rounded-pill" :
                            isMobile ? "btn btn-light btn-sm rounded-pill" :
                              "btn btn-white btn-sm rounded-pill"} onClick={() => { setToggle('Exam'); SearchCBTList(null, toggle) }}>
                            Exam
                          </button>
                        </div>


                        <div className="col-lg-7 mt-lg-10 mt-8">
                          <form class="d-flex align-items-center">
                            <span class="position-absolute ps-3 search-icon">
                              <i class="fe fe-search"></i>
                            </span>
                            <input type="search"
                              class={isMobile ? "form-control form-control-lg py-3 ps-6 fs-4 bg-back rounded-pill" :
                                "form-control form-control-lg ps-6 fs-4 bg-white rounded-pill"}
                              onKeyUp={(e) => { SearchCBTList(e.target.value, toggle); setSearch(e.target.value) }}
                              placeholder="Search CBT with reference..." />
                          </form>
                        </div>


                        <div className="col-lg-5 mt-lg-10 mt-6 text-end">
                          <button data-bs-toggle="modal" data-bs-target="#HandleOpen"
                            className="btn btn-dark btn-md text-white rounded-3">
                            <span className="bi bi-plus-lg text-white"></span> {" "} Create CBT
                          </button>

                          <button data-bs-toggle="modal" data-bs-target="#HandleBin"
                            className="btn btn-primary btn-md text-white rounded-3 ms-4"
                            onClick={() => SearchBinList(null)}>
                            <span className="bi bi-recycle text-white"></span> {" "} Recycle Bin
                          </button>

                        </div>

                      </div>

                      <div className="pt-8 pt-lg-8">

                        {toggle === "Test" ?
                          <Test folders={folders} loader={loader} FetchCBT={FetchCBT} />
                          :
                          <Exam folders={folders} loader={loader} FetchCBT={FetchCBT} />
                        }

                        <div class="col-lg-12 col-12 mt-lg-0 mt-5 mb-lg-8 mb-6">
                          <div class="text-dark">
                            <h2 class="h3 text-dark">Need Help?</h2>
                            <p class="mb-0 fs-4">Do you want to know how to use the App more better? Don't
                              worry we got you covered.</p>
                            <a class="btn btn-primary rounded-3 mt-3">Help me?</a>
                          </div>
                        </div>

                      </div>


                    </div>
                  </StyleRoot>

                </>
              )}


              <div class="modal" id="HandleOpen" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                  role="document">
                  <div class="modal-content curve3" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                    <div class="modal-header border-0 py-4">
                      <div className="fs-t text-purple2">
                        <i class="bi bi-plus-lg"></i> Create CBT {" "}
                      </div>

                      <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                        data-bs-dismiss="modal" aria-label="Close">
                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                      </button>

                    </div>
                    <div class="modal-body py-2">
                      <form className="row g-6 g-lg-4 px-lg-6 px-2 pb-5 " onSubmit={onSubmit}>

                        <div className="col-12">
                          <div className="fs-4 text-dark mt-3 mt-lg-1">
                            Fill the details below to create CBT.
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <label class="form-label fs-5 text-dark" for="number">
                            CBT Quiz Name
                          </label>
                          <input
                            type="text"
                            id="name-input1"
                            autoComplete={false}
                            class="form-control form-control-lg bg-back"
                            style={{ borderRadius: "24px" }}
                            placeholder="Enter preferred name"
                            required
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div class="col-lg-6">
                          <label class="form-label fs-5 text-dark" for="number">
                            CBT Quiz Type
                          </label>
                          <select
                            class="form-select form-select-lg bg-back text-dark"
                            style={{ borderRadius: "24px" }}
                            aria-label="Default select example"
                            onChange={(e) => setCBTType(e.target.value)}
                          >
                            <option value="">Select CBT Type</option>
                            <option value="Test">Test</option>
                            <option value="Exam">Exam</option>
                          </select>
                        </div>

                        <div class="col-lg-6">
                          <label class="form-label fs-5 text-dark" for="number">
                            CBT Class Type
                          </label>
                          <select
                            class="form-select form-select-lg bg-back text-dark"
                            style={{ borderRadius: "24px" }}
                            aria-label="Default select example"
                            name="studclass"
                            onChange={(e) => setStudclass(e.target.value)}
                          >
                            <option value="">Select Class</option>
                            <option value="PlayGroup">PlayGroup</option>
                            <option value="Reception">Reception</option>
                            <option value="Pre Nursery">Pre Nursery</option>
                            <option value="NUR 1">NUR 1</option>
                            <option value="NUR 2">NUR 2</option>
                            <option value="KG 1">KG 1</option>
                            <option value="KG 2">KG 2</option>
                            <option value="Grade 1">Grade 1</option>
                            <option value="Grade 2">Grade 2</option>
                            <option value="Grade 3">Grade 3</option>
                            <option value="Grade 4">Grade 4</option>
                            <option value="Grade 5">Grade 5</option>
                            <option value="Grade 6">Grade 6</option>
                            <option value="Jss1">JSS 1</option>
                            <option value="Jss2">JSS 2</option>
                            <option value="Jss3">JSS 3</option>
                            <option value="sss1">SSS 1</option>
                            <option value="sss2">SSS 2</option>
                            <option value="sss3">SSS 3</option>
                          </select>
                        </div>

                        <div class="col-lg-6">
                          <label class="form-label fs-5 text-dark" for="number">
                            CBT Subject
                          </label>
                          <select
                            class="form-select form-select-lg bg-back text-dark"
                            style={{ borderRadius: "24px" }}
                            aria-label="Default select example"
                            onChange={(e) => setSubject(e.target.value)}
                          >
                            <option value="">Select Subject</option>
                            {renderSubject()}
                          </select>
                        </div>

                        <div class="col-lg-6">
                          <label class="form-label fs-5 text-dark" for="number">
                            CBT Time Duration
                          </label>
                          <input
                            type="number"
                            maxLength={2}
                            value={duration}
                            autoComplete={false}
                            class="form-control form-control-lg bg-back"
                            style={{ borderRadius: "24px" }}
                            placeholder="Enter Quiz Time"
                            required
                            onChange={(e) => setTime(e.target.value)}
                          />
                        </div>

                        <div class="col-lg-6">
                          <label class="form-label fs-5 text-dark" for="number">
                            CBT Access Code
                          </label>
                          <div class="input-group">
                            <input
                              type={passwordType}
                              class="form-control form-control-lg bg-back"
                              style={{ borderRadius: "24px" }}
                              placeholder="New password"
                              required
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              class="input-group-text bg-back cursor-pointer"
                              id="basic-addon2"
                              onClick={togglePassword}
                            >
                              {passwordType === "text" ? (
                                <>
                                  <i className="bi bi-eye-slash me-2"></i> hide
                                </>
                              ) : (
                                <>
                                  <i class="bi bi-eye me-2"></i> show
                                </>
                              )}
                            </span>
                          </div>
                        </div>

                        <div class="col-lg-12 text-end mt-6 mt-lg-5">
                          <button
                            type="submit"
                            class="btn btn-success btn-lg text-white mb-2 px-7 text-uppercase rounded-pill"
                            disabled={status ? true : false}
                            data-bs-dismiss={duration && password ? "modal" : ""}
                          >
                            {status ? (
                              <>
                                Creating...
                                <span
                                  class="spinner-border spinner-border-sm ms-3"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </>
                            ) : (
                              <>
                                Create <i class="bi bi-arrow-right ms-1"></i>
                              </>
                            )}
                          </button>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>


              <div class="modal" id="Open_CBT" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-md modal-dialog-centered"}
                  role="document">
                  <div class="modal-content" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                    <div class="modal-header border-0 py-4">
                      <div className="fs-t text-purple2">
                        <i class="bi bi-sliders"></i> {isMobile ? truncate(cbt_task.title, 20) : cbt_task.title} {" "}
                      </div>

                      <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                        data-bs-dismiss="modal" aria-label="Close">
                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                      </button>

                    </div>
                    <div class="modal-body py-0">

                      {loader_ ? (
                        <div className="col-lg-12 text-center pt-lg-14 pt-20 pb-lg-15">
                          <div
                            class="spinner-border text-purple3"
                            style={{ width: "4rem", height: "4rem" }}
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="row px-lg-6 px-2 pb-lg-4 pt-lg-2 pt-4 pb-5 align-items-center">
                            <div className="col-6">
                              <div className="fs-5 text-purple2">
                                Exam Status
                              </div>
                              <div className="mt-2">
                                {cbt_task.status === '' ?
                                  <button className="btn btn-light-warning btn-sm rounded-pill text-dark">
                                    Building
                                  </button>
                                  :
                                  cbt_task.status === 'Completed' ?
                                    <button className="btn btn-light-info btn-sm rounded-pill text-dark">
                                      Verifying
                                    </button>
                                    : cbt_task.status === 'Verified' ?
                                      <button className="btn btn-light-primary btn-sm rounded-pill text-dark">
                                        Verified
                                      </button>
                                      : cbt_task.status === 'Live' ?
                                        <button className="btn btn-light-success btn-sm rounded-pill text-dark">
                                          Verified
                                        </button>
                                        : cbt_task.status === 'Done' ?
                                          <button className="btn btn-dark btn-sm rounded-pill text-dark">
                                            Completed
                                          </button>
                                          : <></>
                                }
                              </div>
                            </div>

                            <div className="col-6 text-end mt-4">
                              <div className="btn btn-danger rounded-pill"
                                data-bs-dismiss="modal" aria-label="Close"
                                onClick={() => deleteCBT()}>
                                Delete CBT
                              </div>
                            </div>

                            <div className="col-12 pt-lg-4 pb-6 pt-5 pt-lg-4 pb-lg-4">
                              <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                                <div className="fs-3 text-dark">
                                  Subject - {cbt_task.subject}
                                </div>
                              </div>
                            </div>

                            <div className="col-12 pb-6 pb-lg-4">
                              <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                                <div className="fs-3 text-dark">
                                  Class - {cbt_task.class}
                                </div>
                              </div>
                            </div>

                            <div className="col-12 pb-6 pb-lg-4">
                              <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                                <div className="fs-3 text-dark">
                                  No. of Questions - {cbt_task.id}
                                </div>
                              </div>
                            </div>

                            <div className="col-12 mb-3">
                              <div className="bg-back py-lg-3 px-lg-3 py-3 px-3" style={{ borderRadius: "25px" }}>
                                <div className="fs-3 text-dark">
                                  Title - {cbt_task.title}
                                </div>
                              </div>
                            </div>

                          </div>

                          <div className="col-lg-12 col-12 pb-6">
                            <div className="card bg-light-primary shadow-none cursor-pointer"
                              style={{ borderRadius: "25px" }}>
                              <div className="px-4 px-lg-4 py-3 py-lg-2">

                                <div className="row align-items-center">
                                  <div className="col-10 d-flex row align-items-center">
                                    <div className="col-5 mt-lg-n8">
                                      <img src="../../assets/images/background/2.png" alt=""
                                        style={isMobile ? { width: "100%" } : { width: "100%" }}
                                        class="img-fluid" />
                                    </div>
                                    <div className="col-7 fs-t text-purple2 mb-3 mt-3">
                                      Open CBT
                                    </div>
                                  </div>

                                  <div className="col-2 text-end"
                                    data-bs-dismiss="modal" aria-label="Close" >
                                    <Link to={"../" + id + "/cbt/preview/" + cbt_task.token} className="btn btn-dark rounded-pill">
                                      <span className="bi bi-arrow-right text-white fs-3"></span>
                                    </Link>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>


              <div class="modal" id="HandleBin" tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class={isMobile ? "modal-dialog modal-dialog-centered modal-fullscreen" : "modal-dialog modal-lg modal-dialog-centered"}
                  role="document">
                  <div class="modal-content curve3" style={isMobile ? { border: "#fff" } : { borderRadius: "35px", border: "#fff" }}>
                    <div class="modal-header border-0 py-4">
                      <div className="fs-t text-purple2">
                        <i class="bi bi-recycle"></i> Recycle Bin {" "}
                      </div>

                      <button class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                        data-bs-dismiss="modal" aria-label="Close">
                        <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                      </button>

                    </div>
                    <div class="modal-body py-2">

                      <div className="row px-lg-5 pb-lg-6">
                        <div className="col-12">
                          <div className="fs-4 text-dark">
                            Restore all deleted CBT folders and access them with just a click of a button.
                          </div>
                        </div>

                        <div className="col-12 col-lg-9 pt-lg-4 pt-4">
                          <form class="d-flex align-items-center">
                            <span class="position-absolute ps-3 search-icon">
                              <i class="fe fe-search"></i>
                            </span>
                            <input type="search"
                              class="form-control form-control-lg py-3 ps-6 fs-4 bg-back rounded-pill"
                              onKeyUp={(e) => SearchBinList(e.target.value)}
                              placeholder="Search CBT with reference..." />
                          </form>
                        </div>

                        <div className="col-12 pt-lg-4 pt-4">

                          {loader1 ? (
                            <div className="col-lg-12 text-center py-lg-10 py-8">
                              <div
                                class="spinner-border text-purple3"
                                style={{ width: "4rem", height: "4rem" }}
                                role="status"
                              >
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          ) : (
                            <div class="table-responsive">
                              <table class="table table-hover table-lg fs-4">
                                <tbody>
                                  {renderFolders()}
                                </tbody>
                              </table>
                            </div>
                          )}

                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>


              <Footer />

            </div>

          </div>

          <Mobile />

          <ToastContainer />

          <Switcher />
        </>
      </div>
    </>
  );
};

export default CBT;
