import React, { useState, useEffect } from "react";

import { useParams, Link, useNavigate } from "react-router-dom";

import axios from "axios";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { isMobile } from "react-device-detect";

const Fetch = () => {

    const getusers = useSelector((state) => state.user.value);

    const cbt = getusers.cbt;

    const teachid = getusers.teachid;

    const { id } = useParams();

    const navigate = useNavigate();

    axios.defaults.baseURL = id;

    const [loader, setLoader] = useState(true);

    const [loaders, setLoaders] = useState(true);

    useEffect(() => {
        setTimeout(function () {
            setLoader(false);
        }, 4000);
        FetchStudent();

        FetchAssigned();

    }, [teachid]);

    const [students, setStudents] = useState([]);

    const [assign, setAssign] = useState([]);

    const FetchStudent = () => {
        axios({
            method: "post",
            url: "./v2/students/",
            data: {
                teachid,
                q: null,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setStudents(view);

                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const FetchAssigned = () => {
        axios({
            method: "post",
            url: "./v2/students_fetch/",
            data: {
                teachid
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setAssign(view);
                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const [search, setSearch] = useState(null);

    const SearchStudents = (search) => {
        axios({
            method: "post",
            url: "./v2/students/",
            data: {
                teachid,
                q: search,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setStudents(view);

                    setLoader(true);

                    setTimeout(function () {
                        setLoader(false);
                    }, 2000);

                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const StudentAssign = (studid) => {

        axios({
            method: "post",
            url: "/v2/assign_students/",
            data: {
                teachid,
                studid,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    toast.success("Success: Student was assigned successfully!.", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    SearchStudents(search);

                    FetchAssigned();

                } else {
                    toast.error("Failed: Request Failed! Please try again later.", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    }

    const [studid, setID] = useState([]);

    const FetchStudents = (search) => {
        axios({
            method: "post",
            url: "./v2/student/",
            data: {
                teachid,
                q: search,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setID(view);

                    setLoaders(true);

                    setTimeout(function () {
                        setLoaders(false);
                    }, 2000);

                } else {
                    setTimeout(function () {
                        //navigate("/student");
                    }, 4000);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const Unassign = (studid) => {

        axios({
            method: "post",
            url: "/v2/assign_students/",
            data: {
                teachid,
                studid: studid,
                status: 1
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {

                    toast.success("Success: Student was unassigned successfully!.", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    SearchStudents(search);

                    FetchAssigned();

                } else {
                    toast.error("Failed: Request Failed! Please try again later.", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");
            });

    }

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 15,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 10,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 5,
        },
    };


    const responsives = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };


    const renderStudents = () => {
        if (students == "") {
            return <></>;
        } else if (students) {
            return students.map((student, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {student.studid ?
                            <div className="col-lg-11 col-md-11 col-12 pt-8">
                                <div className="card shadow-sm bg-white"
                                    style={{
                                        borderTopLeftRadius: "15px",
                                        borderTopRightRadius: "15px",
                                    }}>
                                    <div className="text-center mt-n6">
                                        <span class="avatar bg-light-primary avatar-xl rounded-circle">
                                            <img alt="avatar" src={student.sex === 'Male' ? "../assets/images/avatar/boy_stud.svg"
                                                : "../assets/images/avatar/girl_stud.svg"} class="rounded-circle" loading="lazy" />
                                        </span>
                                    </div>

                                    <div className="text-center py-3 pb-4">
                                        <div className="fs-3 text-purple2 fw-bold font-display">
                                            {student.lname} {student.fname}
                                        </div>

                                        <div className="fs-4 text-gray-700 fw-medium">
                                            @{student.uesrname ? student.uesrname : 'No username yet'}
                                        </div>

                                        <div className="fs-4 text-primary fw-bold">
                                            {student.studid}
                                        </div>

                                        <div className="text-center pt-2">
                                            <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
                                                <label class="btn btn-orange text-white" for="btnradio1">{student.class}</label>

                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" />
                                                <label class="btn btn-white border-secondary" for="btnradio3">{student.depart === 'ALL'
                                                    || student.depart === 'None' || !student.depart ?
                                                    'No Department' : student.depart}</label>
                                            </div>
                                        </div>

                                        {student.teachid ?

                                            <div className='pt-5'>
                                                <button type='button' className='btn btn-sm btn-primary text-white rounded-3 text-uppercase px-10 shadow' disabled>
                                                    Already Assigned <i class="bi bi-arrow-right"></i>
                                                </button>
                                            </div>
                                            :
                                            <div className='pt-5'>
                                                <button type='button' className='btn btn-sm btn-purple4 text-white 
                                            rounded-3 text-uppercase px-10 shadow' onClick={() => StudentAssign(student.studid)}>
                                                    Assign <i class="bi bi-arrow-right"></i>
                                                </button>
                                            </div>
                                        }



                                    </div>


                                </div>
                            </div>
                            :
                            <div className="col-lg-11 col-md-11 col-12 pt-8">
                                <div className="card shadow-sm bg-white"
                                    style={{
                                        borderTopLeftRadius: "15px",
                                        borderTopRightRadius: "15px",
                                    }}>
                                    <div className="text-center mt-n6">
                                        <span class="avatar bg-light-primary avatar-xl rounded-circle">
                                            <img alt="avatar" src={student.sex === 'Male' ? "../assets/images/avatar/boy_stud.svg"
                                                : "../assets/images/avatar/girl_stud.svg"} class="rounded-circle" loading="lazy" />
                                        </span>
                                    </div>

                                    <div className="text-center py-3 pb-4">
                                        <div className="fs-3 text-purple2 fw-bold font-display">
                                            {student.lname} {student.fname}
                                        </div>

                                        <div className="fs-4 text-gray-700 fw-medium">
                                            @{student.uesrname ? student.uesrname : 'No username yet'}
                                        </div>

                                        <div className="fs-4 text-primary fw-bold">
                                            {student.studid ? student.studid : "ID hasn't been assigned"}
                                        </div>

                                        <div className="text-center pt-2">
                                            <div class="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" />
                                                <label class="btn btn-orange text-white" for="btnradio1">{student.class}</label>

                                                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" />
                                                <label class="btn btn-white border-secondary" for="btnradio3">{student.depart === 'ALL'
                                                    || student.depart === 'None' || !student.depart ?
                                                    'No Department' : student.depart}</label>
                                            </div>
                                        </div>

                                        <div className='pt-5'>
                                            <button type='button' className='btn btn-sm btn-purple4 text-white rounded-3 text-uppercase px-10 shadow' disabled>
                                                Can't Be Assigned <i class="bi bi-arrow-right"></i>
                                            </button>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        }
                    </>
                );
            });
        } else {
            return <></>;
        }
    };


    const renderAssigned = () => {
        if (assign == "") {
            return <></>;
        } else if (assign) {
            return assign.map((student, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        {student.studid && student.teachid === teachid ?
                            <div className="">
                                <span class="avatar avatar-lg avatar-indicators avatar-offline avatar-primary cursor-pointer"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#student_info"
                                    aria-controls="offcanvasLeft"
                                    onClick={() => FetchStudents(student.studid)}>
                                    <span class="avatar-initials rounded-circle fs-3">{student.lname.charAt(0) +
                                        student.fname.charAt(0)}</span>
                                </span>
                            </div>
                            :
                            <></>
                        }
                    </>
                );
            });
        } else {
            return <></>;
        }
    };


    const renderTable = () => {
        if (assign == "") {
            return (
                <th colSpan={"6"}>
                    <div className="col-12 col-md-12 col-lg-4 offset-lg-4 pt-8 pt-lg-4">
                        <div className="text-center">
                            <img
                                src="../../assets/images/background/wait_.svg"
                                className=""
                                width="100%"
                            />
                            <div className="pt-4 fs-t text-purple3">
                                No results found...
                            </div>
                        </div>
                    </div>
                </th>
            );
        } else if (assign) {
            return assign.map((table, index) => {
                //console.log(school.tm_date)
                return (
                    <>
                        <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{table.lname} {table.fname}</td>
                            <td>{table.class}</td>
                            <td>{table.depart}</td>
                            <td>{table.sex}</td>
                            <td>
                                <button className="btn btn-purple3 text-purple3 btn-sm rounded-3 shadow-sm"
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#student_info"
                                    aria-controls="offcanvasLeft"
                                    onClick={() => FetchStudents(table.studid)}>
                                    View Profile
                                </button>
                            </td>
                        </tr>
                    </>
                );
            });
        } else {
            return <></>;
        }
    };


    return (
        <>
            {assign == "" ?
                <></> :
                <div className="pb-8">
                    <div className="fs-t text-purple3 fw-medium mb-4">
                        Assigned Students
                    </div>

                    <Carousel
                        responsive={responsive}
                        itemClass="carousel-item-padding-10-px"
                        className=""
                        arrows={true}>
                        {renderAssigned()}
                    </Carousel>
                </div>
            }


            <div className="fs-t text-purple3 fw-medium mb-4">
                All Students
            </div>

            <div className="pb-5">
                <div className="col-md-5 text-start">
                    <div class="input-group input-group-lg has-validation rounded-3 text-dark">
                        <input
                            type="search"
                            class="form-control form-control-md"
                            placeholder="Search with Student Name, ID, class"
                            onKeyUp={(e) => { SearchStudents(e.target.value); setSearch(e.target.value) }}
                        />
                        <span class="input-group-text" id="inputGroupPrepend">
                            <span className="bi bi-search"></span>
                        </span>
                    </div>
                </div>

                {loader ? (
                    <div className="col-lg-12 text-center py-lg-10 py-8">
                        <div
                            class="spinner-border text-danger"
                            style={{ width: "4rem", height: "4rem" }}
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div className="mt-4 fs-t text-dark">Loading...</div>
                    </div>
                ) : (
                    <>
                        <div className="col-12 pt-4">
                            <Carousel
                                responsive={responsives}
                                itemClass="carousel-item-padding-10-px"
                                className="py-2"
                                arrows={true}>
                                {renderStudents()}
                            </Carousel>
                        </div>

                    </>
                )}


            </div>

            <div className="fs-t text-purple3 fw-medium mb-4 pt-8">
                Assigned Students Table
            </div>

            <div className="col-lg-12 mt-3 px-lg-4">
                <>
                    <div class="table-responsive">
                        <table class="table fs-4 text-purple3">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Class</th>
                                    <th scope="col">Department</th>
                                    <th scope="col">Gender</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>{renderTable()}</tbody>
                        </table>
                    </div>
                </>
            </div>


            <div
                class="offcanvas offcanvas-end"
                tabindex="-1"
                id="student_info"
                aria-labelledby="offcanvasRightLabel"
            >
                <div class="offcanvas-header" style={{ padding: "0px" }}>
                    <h5 id="offcanvasRightLabel"></h5>
                    <button
                        type="button"
                        class="btn btn-white text-success2 fs-2"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        {" "}
                        <i class="bi bi-x text-dark"></i>
                    </button>
                </div>
                <div class="offcanvas-body Stud">
                    <div className="px-lg-2">
                        <div className="fs-t fw-bold text-purple2">
                            {studid.lname} {studid.fname} Profile
                        </div>

                        <div className="fs-3 fw-medium text-purple2 mt-4">
                            Class - {studid.class ? studid.class.toUpperCase() : ''}
                        </div>

                        <div className="fs-3 fw-medium text-purple2 mt-4">
                            Department - {studid.depart === 'ALL'
                                || studid.depart === 'None' || !studid.depart ?
                                'No Department' : studid.depart.toUpperCase()}
                        </div>

                        <div className="fs-3 fw-medium text-purple2 mt-4">
                            Student ID - {studid.studid ? studid.studid.toUpperCase() : ''}
                        </div>

                        <div className="fs-3 fw-medium text-purple2 mt-4">
                            Phone Contact - {studid.phone}
                        </div>

                        <div className="fs-3 fw-medium text-purple2 mt-4">
                            Email Contact - {studid.email}
                        </div>

                        <div className="mt-8 fs-5 font-display text-dark">
                            Click the button below to unassign student
                        </div>

                        <div className="d-grid mt-2">
                            <button className="btn btn-danger rounded-3 shadow-lg text-uppercase"
                                data-bs-dismiss="offcanvas"
                                onClick={() => Unassign(studid.studid)}>
                                unassign student
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Fetch