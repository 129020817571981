import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import Footer from "../Footer";

const Report_Send = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const teachclass = getusers.teachclass;

  const term = getusers.term;

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [report, setReport] = useState([]);

  const [loader, setLoader] = useState(true);

  const [loader_, setLoader_] = useState(true);


  const FetchReport = () => {
    axios({
      method: "post",
      url: "./v2/report/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setReport(view);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport();
  }, [teachid]);

  const renderFolders = () => {
    if (report == "") {
      return <></>;
    } else if (report != "") {
      return report.map((report, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="d-flex align-items-center py-3 py-lg-3">
              <div className="col-1 col-lg-1">
                <div className="fs-4 text-purple2 fw-medium">{index + 1}</div>
              </div>

              <div className="col-3 col-lg-3 text-start">
                <div className="fs-4 text-purple2 fw-medium">
                  {report.fname}
                </div>
              </div>

              <div className="col-4 col-lg-3 text-start fs-4 text-purple2">
                {report.studid}
              </div>

              <div className="col-3 col-lg-3 text-start fs-4 text-purple2">
                {report.class}
              </div>

              <div
                className="col-1 col-lg-1 text-start text-lg-start"
                data-bs-toggle="modal"
                data-bs-target="#view_result"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                onClick={() => {
                  FetchStudents(report.studid);
                  setfullname(report.fname);
                  setStudid(report.studid);
                  FetchAvg(report.studid);
                  FetchAvg_(report.studid);
                }}
              >
                <button className="btn btn-primary rounded-pill">
                  <i className="bi bi-arrow-right-circle-fill text-white"></i>
                </button>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [fullname, setfullname] = useState("{}");

  const [students, setStudents] = useState([]);

  const [studid, setStudid] = useState(null);

  const FetchStudents = (studid) => {

    setLoader_(true);

    axios({
      method: "post",
      url: "./v2/report/result/",
      data: {
        studid,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;

        setStudents(view);

        setTimeout(function () {
          setLoader_(false);
        }, 4000);

        FetchAvg(studid);
        //FetchAvg_(studid);


      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const renderStudents = () => {
    if (students == "") {
      return <></>;
    } else if (students != "") {
      return students.map((stud, index) => {
        //console.log(school.tm_date)
        return (
          <tr className="">
            <th scope="row">{index + 1}</th>
            <td>{stud.subject}</td>
            <td>{stud.studid}</td>
            {term === "First Term" ? (
              <td>{parseInt(stud.test1) + parseInt(stud.test2)}</td>
            ) : term === "Second Term" ? (
              <td>{parseInt(stud.test3) + parseInt(stud.test4)}</td>
            ) : term === "Third Term" ? (
              <>
                <td>{parseInt(stud.test5) + parseInt(stud.test6)}</td>
              </>
            ) : (
              <></>
            )}

            {toggle === "Exam" ? (
              <>
                {term === "First Term" ? (
                  <td>{parseInt(stud.score1)}</td>
                ) : term === "Second Term" ? (
                  <td>{parseInt(stud.score2)}</td>
                ) : term === "Third Term" ? (
                  <td>{parseInt(stud.score3)}</td>
                ) : (
                  <></>
                )}

                {term === "First Term" ? (
                  <td>
                    {parseInt(stud.test1) +
                      parseInt(stud.test2) +
                      parseInt(stud.score1)}
                  </td>
                ) : term === "Second Term" ? (
                  <td>
                    {parseInt(stud.test3) +
                      parseInt(stud.test4) +
                      parseInt(stud.score2)}
                  </td>
                ) : term === "Third Term" ? (
                  <>
                    <td>
                      {parseInt(stud.test5) +
                        parseInt(stud.test6) +
                        parseInt(stud.score3)}
                    </td>
                    <td>
                      {parseInt(stud.test1) +
                        parseInt(stud.test2) +
                        parseInt(stud.score1)}
                    </td>
                    <td>
                      {parseInt(stud.test3) +
                        parseInt(stud.test4) +
                        parseInt(stud.score2)}
                    </td>
                    {stud.score1 !== 0 && stud.score2 !== 0 && stud.score3 !== 0 ?

                      <td>{((parseInt(stud.test1) + parseInt(stud.test2) + parseInt(stud.score1)
                        + parseInt(stud.test3) + parseInt(stud.test4) + parseInt(stud.score2)
                        + parseInt(stud.test5) + parseInt(stud.test6) + parseInt(stud.score3))/3).toFixed(2)}</td>

                      : stud.score1 === 0 && stud.score2 !== 0 && stud.score3 !== 0 ?

                        <td>{((parseInt(stud.test3) + parseInt(stud.test4) + parseInt(stud.score2)
                          + parseInt(stud.test5) + parseInt(stud.test6) + parseInt(stud.score3))/2).toFixed(2)}</td>

                        :  stud.score1 !== 0 && stud.score2 === 0 && stud.score3 !== 0 ?

                        <td>{((parseInt(stud.test1) + parseInt(stud.test2) + parseInt(stud.score1)
                          + parseInt(stud.test5) + parseInt(stud.test6) + parseInt(stud.score3))/2).toFixed(2)}</td>

                        :

                        <td>{(parseInt(stud.test5) + parseInt(stud.test6) + parseInt(stud.score3))}</td>

                    }

                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </tr>
        );
      });
    } else {
      return <></>;
    }
  };

  const [toggle, setToggle] = useState(id === 'ebun' || id === 'prm' || id === 'sac' ? "Exam" : "Test");

  const [mark, setMark] = useState(id === 'ebun' ? 20 : 40);

  const [mark_, setMark_] = useState(60);

  const [average, setAverage] = useState([]);

  const [average_, setAverage_] = useState([]);

  const FetchAvg = (studid) => {
    axios({
      method: "post",
      url: "./v2/report/average/",
      data: {
        teachid,
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setAverage(view);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const FetchAvg_ = (studid) => {

    // setAverage([]);

    axios({
      method: "post",
      url: "./v2/report/average_/",
      data: {
        teachid,
        studid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setAverage_(view);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [obtain, setObtain] = useState(null);

  const [obtainable, setObtainable] = useState(null);

  const [avg, setAvg] = useState(null);

  const [remark, setRemark] = useState(null);

  const [comment, setComment] = useState(null);

  const average_per = ((((average.total * 100) / (students.length * mark)) * 10) / 10).toFixed(2);

  const average_per_ = ((((average_.total * 100) / (students.length * 100)) * 10) / 10).toFixed(2);

  const onSubmit = (e) => {
    e.preventDefault();

    const task = {
      teachid,
      obtain,
      obtainable,
      avg,
      remark,
      comment,
      studid,
    };

    axios({
      method: "post",
      url: "/v2/report/send/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Result was successfully dispatched!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const [punctual, setPunctual] = useState(null);

  const [neat, setNeat] = useState(null);

  const [honest, setHonest] = useState(null);

  const [Initiative, setInitiat] = useState(null);

  const [polite, setPolite] = useState(null);

  const [attent, setAttent] = useState(null);

  const [opens, setOpens] = useState(null);

  const [present, setPresent] = useState(null);

  const [resume, setResume] = useState(null);

  const onSubmit_ = (e) => {
    e.preventDefault();

    const task = {
      teachid,
      obtain,
      obtainable,
      avg,
      remark,
      comment,
      studid,
      punctual,
      neat,
      honest,
      Initiative,
      polite,
      attent,
      opens,
      present,
      resume,
    };

    axios({
      method: "post",
      url: "/v2/report/send_/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Result was successfully dispatched!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        } else {
          toast.error("Failed: Request Failed! Please try again.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <Header />

      <Sidebar />

      <div class="docs-wrapper bg-back">
        {teachclass === "" || teachclass === "NONE" ? (
          <>
            <div class="container-fluid px-lg-10 pt-lg-14 pt-4">
              <div className="row text-center">
                <div className="col-lg-10">
                  <img
                    src="../../assets/images/background/exist.png"
                    alt=""
                    class="img-fluid"
                    width={isMobile ? "100%" : "45%"}
                  />
                </div>

                <div className="fs-10 text-purple3 fw-medium">
                  You are not a class teacher.
                </div>
              </div>
            </div>
          </>
        ) : (
          <div class="container-fluid px-lg-10 pt-lg-14 pt-4">
            <div className="d-flex align-items-center">
              <div className="col">
                <div className="fs-t text-purple3">
                  Dispatch |{" "}
                  <span className="text-dark fs-5">
                    <i className="bi bi-bounding-box"></i> -- Dispatch
                  </span>
                </div>
              </div>

              <div className="col-end text-center"></div>
            </div>

            <div className="px-lg-10 mt-lg-6 mt-4">
              <div className="col-lg-12 mb-5 mb-lg-8">
                <div
                  className="card bg-white shadow-none"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="card-body px-1">
                    <div className="row align-items-center">
                      <div className="col-4 col-lg-4 text-center ms-lg-n3 ms-n1">
                        <div className="fs-5 text-purple3">No. of Students</div>
                        <div className="fs-t text-success fw-bold">
                          {report.length}
                        </div>
                      </div>

                      <div className="col-4 col-lg-6 ms-lg-n8 fw-medium">
                        <div
                          className={
                            isMobile
                              ? "fs-5 text-purple2"
                              : "fs-4 text-start text-purple2"
                          }
                        >
                          Click on button to assign students to your class.
                        </div>
                      </div>

                      <div className="col-4 col-lg-2">
                        <Link
                          to={"../" + id + "/report/assign"}
                          className="btn btn-success rounded-pill fs-4"
                        >
                          Assign <i class="bi bi-arrow-right-circle-fill"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div
                className="card bg-white shadow-none"
                style={{ borderRadius: "20px" }}
              >
                <div className="card-body px-lg-10">
                  <div className="fs-t text-purple3 mb-4 mt-2">
                    List of students in your class
                  </div>
                  <>
                    {loader ? (
                      <div className="col-lg-12 text-center py-lg-10 py-20">
                        <div
                          class="spinner-border text-purple3"
                          style={{ width: "4rem", height: "4rem" }}
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>{renderFolders()}</>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />

        <div
          class="modal"
          id="view_result"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-lg modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 pt-4">
                <div className="fs-t text-purple2">{fullname}</div>

                <button
                  class="btn btn-dark btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-white"></i>
                </button>
              </div>

              <div className="modal-header border-0 pt-3">
                <div className="">
                  {id === "sac" ? (
                    <></>
                  ) : (
                    <button
                      className={
                        toggle === "Test"
                          ? "btn btn-success btn-sm rounded-pill me-4"
                          : isMobile
                            ? "btn btn-light btn-sm rounded-pill"
                            : "btn btn-white btn-sm rounded-pill"
                      }
                      onClick={() => setToggle("Test")}
                    >
                      Test Result
                    </button>
                  )}

                  <button
                    className={
                      toggle === "Exam"
                        ? "btn btn-success btn-sm rounded-pill ms-4"
                        : isMobile
                          ? "btn btn-light btn-sm rounded-pill"
                          : "btn btn-white btn-sm rounded-pill"
                    }
                    onClick={() => setToggle("Exam")}
                  >
                    Exam Result
                  </button>
                </div>
              </div>

              <div class="modal-body py-0 px-lg-8">
                {toggle === "Test" ? (
                  <form
                    className="row py-0 mb-15 mb-lg-5 align-items-center"
                    onSubmit={onSubmit}
                  >
                    <div className="col-6 fs-t text-purple3 mt-3 mb-3">
                      Test Result
                    </div>

                    <div className="col-6 mb-4">
                      <label
                        class="form-label fs-4 fw-medium text-dark"
                        for="number"
                      >
                        Mark Grade
                      </label>
                      <input
                        type="text"
                        className="form-control bg-dark rounded-3 text-white fs-3"
                        value={mark}
                        onChange={(e) => setMark(e.target.value)}
                      />
                    </div>

                    <div className="col-12 table-responsive mb-lg-4">
                      <table class="table caption-top fs-4 text-purple2">
                        <thead className="text-purple2">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Student</th>
                            <th scope="col">Student ID</th>
                            <th scope="col">Test</th>
                          </tr>
                        </thead>
                        <tbody>{renderStudents()}</tbody>
                      </table>
                    </div>

                    <div className="col-6 mb-4">
                      <label
                        class="form-label fs-4 fw-medium text-dark"
                        for="number"
                      >
                        Mark Obtainable
                      </label>
                      <input
                        type="text"
                        className="form-control bg-dark rounded-3 text-white fs-3"
                        value={students.length * mark}
                        disabled
                      />
                    </div>

                    <div className="col-6 mb-4">
                      <label
                        class="form-label fs-4 fw-medium text-dark"
                        for="number"
                      >
                        Mark Obtained
                      </label>
                      <input
                        type="text"
                        className="form-control bg-dark-primary rounded-3 text-white fs-3"
                        value={average.total}
                        disabled
                      />
                    </div>

                    <div className="col-6 mb-4">
                      <label
                        class="form-label fs-4 fw-medium text-dark"
                        for="number"
                      >
                        Average
                      </label>
                      <input
                        type="text"
                        className="form-control bg-back rounded-3 text-dark fs-3"
                        value={average_per + "%"}
                        disabled
                      />
                    </div>

                    <div className="col-6 mb-4">
                      <label
                        class="form-label fs-4 fw-medium text-dark"
                        for="number"
                      >
                        Overall Remark
                      </label>
                      <select
                        class="form-select fs-3 bg-back text-dark rounded-3"
                        required
                        onChange={(e) => setRemark(e.target.value)}
                      >
                        <option value="">Select Remark</option>
                        <option value="OUTSTANDING">OUTSTANDING</option>
                        <option value="EXCELLENT">EXCELLENT</option>
                        <option value="VERY GOOD">VERY GOOD</option>
                        <option value="GOOD">GOOD</option>
                        <option value="AVERAGE">AVERAGE</option>
                        <option value="FAIR">FAIR</option>
                        <option value="POOR">POOR</option>
                        <option value="FAIL">FAIL</option>
                      </select>
                    </div>

                    <div className="col-12 mb-4">
                      <label
                        class="form-label fs-4 fw-medium text-dark"
                        for="number"
                      >
                        Class Teacher's Comment
                      </label>
                      <textarea
                        className="form-control bg-back rounded-3 text-dark fs-4"
                        rows={"3"}
                        required
                        onChange={(e) => {
                          setComment(e.target.value);
                          setObtain(students.length * mark);
                          setObtainable(average.total);
                          setAvg(average_per);
                        }}
                      ></textarea>
                    </div>

                    <div
                      className={isMobile ? "fixed-bottom bg-back" : ""}
                      style={{
                        borderTopRightRadius: "40px",
                        borderTopLeftRadius: "40px",
                      }}
                    >
                      <div className="d-grid px-lg-4 mb-2 mt-3 mb-lg-3">
                        <button className="btn btn-warning text-dark rounded-pill fs-3">
                          Dispatch Result
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <>
                    {loader_ ? (
                      <div className="col-lg-12 text-center py-lg-10 py-20">
                        <div
                          class="spinner-border text-purple3"
                          style={{ width: "4rem", height: "4rem" }}
                          role="status"
                        >
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <form
                          className="row py-0 mb-15 mb-lg-5 align-items-center"
                          onSubmit={onSubmit_}
                        >
                          <div className="col-6 fs-t text-purple3 mt-3 mb-3">
                            Exam Result
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Mark Grade
                            </label>
                            <input
                              type="text"
                              className="form-control bg-dark rounded-3 text-white fs-3"
                              value={mark_}
                              onChange={(e) => setMark_(e.target.value)}
                            />
                          </div>

                          <div className="col-12 table-responsive mb-lg-4">
                            <table class="table caption-top fs-4 text-purple2">
                              <thead className="text-purple2">
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">Subject</th>
                                  <th scope="col">Student ID</th>
                                  <th scope="col">Test</th>
                                  <th scope="col">Exam</th>
                                  <th scope="col">Total</th>
                                  {term === 'Third Term' ?
                                  <>
                                  <th scope="col">1st Term</th>
                                  <th scope="col">2nd Term</th>
                                  <th scope="col">Cum Total</th>
                                  </>
                                    :
                                    <></>
                                  }
                                </tr>
                              </thead>
                              <tbody>{renderStudents()}</tbody>
                            </table>
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              First Term Avg
                            </label>
                            <input
                              type="text"
                              className="form-control bg-dark-success rounded-3 text-white fs-3"
                              value={(average_.first)}
                              disabled
                            />
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Second Term Avg
                            </label>
                            <input
                              type="text"
                              className="form-control bg-dark-success rounded-3 text-white fs-3"
                              value={(average_.second)}
                              disabled
                            />
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Mark Obtainable
                            </label>
                            <input
                              type="text"
                              className="form-control bg-dark rounded-3 text-white fs-3"
                              value={students.length * 100}
                              disabled
                            />
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Mark Obtained
                            </label>
                            <input
                              type="text"
                              className="form-control bg-dark-primary rounded-3 text-white fs-3"
                              value={(average_.total).toFixed(2)}
                              disabled
                            />
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Average
                            </label>
                            <input
                              type="text"
                              className="form-control bg-back rounded-3 text-dark fs-3"
                              value={average_per_ + "%"}
                              disabled
                            />
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Overall Remark
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setRemark(e.target.value)}
                            >
                              <option value="">Select Remark</option>
                              <option value="OUTSTANDING">OUTSTANDING</option>
                              <option value="EXCELLENT">EXCELLENT</option>
                              <option value="VERY GOOD">VERY GOOD</option>
                              <option value="GOOD">GOOD</option>
                              <option value="AVERAGE">AVERAGE</option>
                              <option value="FAIR">FAIR</option>
                              <option value="POOR">POOR</option>
                              <option value="FAIL">FAIL</option>
                            </select>
                          </div>

                          <div className="col-4 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Punctuality
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setPunctual(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="-">-</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="col-4 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Neatness
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setNeat(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="-">-</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="col-4 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Honesty
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setHonest(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="-">-</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="col-4 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Initiative
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setInitiat(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="-">-</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="col-4 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Politeness
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setPolite(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="-">-</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="col-4 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Attentive
                            </label>
                            <select
                              class="form-select fs-3 bg-back text-dark rounded-3"
                              required
                              onChange={(e) => setAttent(e.target.value)}
                            >
                              <option value="">Select Option</option>
                              <option value="-">-</option>
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                              <option value="E">E</option>
                            </select>
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              No of Time School Opens
                            </label>
                            <input
                              type="text"
                              className="form-control bg-back rounded-3 text-dark fs-3"
                              required
                              onChange={(e) => setOpens(e.target.value)}
                            />
                          </div>

                          <div className="col-6 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              No of Times Present
                            </label>
                            <input
                              type="text"
                              className="form-control bg-back rounded-3 text-dark fs-3"
                              required
                              onChange={(e) => setPresent(e.target.value)}
                            />
                          </div>

                          <div className="col-12 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Resumption Date
                            </label>
                            <input
                              type="text"
                              className="form-control bg-back rounded-3 text-dark fs-3"
                              required
                              onChange={(e) => setResume(e.target.value)}
                            />
                          </div>

                          <div className="col-12 mb-4">
                            <label
                              class="form-label fs-4 fw-medium text-dark"
                              for="number"
                            >
                              Class Teacher's Comment
                            </label>
                            <textarea
                              className="form-control bg-back rounded-3 text-dark fs-4"
                              rows={"3"}
                              required
                              onChange={(e) => {
                                setComment(e.target.value);
                                setObtain(students.length * 100);
                                setObtainable(average_.total);
                                setAvg(average_per_);
                              }}
                            ></textarea>
                          </div>

                          <div
                            className={isMobile ? "fixed-bottom bg-back" : ""}
                            style={{
                              borderTopRightRadius: "40px",
                              borderTopLeftRadius: "40px",
                            }}
                          >
                            <div className="d-grid px-lg-4 mb-2 mt-3 mb-lg-3">
                              <button className="btn btn-warning text-dark rounded-pill fs-3">
                                Dispatch Result
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default Report_Send;
