import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Footer from "../Footer";

import { fadeIn, slideInUp, zoomInUp } from "react-animations";

import Radium, { StyleRoot } from "radium";

import { isMobile } from "react-device-detect";

const Login_Work = () => {

    const navigate = useNavigate();

    const { id } = useParams();

    const { token } = useParams();

    axios.defaults.baseURL = id;

    let headers;

    if (isMobile) {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-success shadow-none py-3";
    } else {
        headers = "navbar-default navbar navbar-expand-lg fixed-top bg-success shadow-none py-3 px-6";
    }

    const [studid, setID] = useState(null);

    const [password, setPassword] = useState(null);

    const [status, setStat] = useState(false);

    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    const onSubmit_ = (e) => {
        e.preventDefault();

        setStat(true);

        const user = {
            teachid: studid,
            password,
            token,
            status: 1
        };

        axios({
            method: "post",
            url: "/v2/login/work/",
            data: user,
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                //console.log(response);
                if (response.data.message === "success") {
                    setTimeout(function () {
                        setStat(false);
                        navigate("./home");
                    }, 2000);

                    localStorage.setItem("id", response.data.teachid);

                    localStorage.setItem("studname", response.data.name);

                    localStorage.setItem("studid", studid);

                } else if (response.data.message === "end") {
                    setTimeout(function () {
                        setStat(false);
                        navigate("./end");
                    }, 2000);

                    localStorage.setItem("id", response.data.teachid);

                    localStorage.setItem("studid", studid);
                }
                else {
                    toast.error("Failed: Login Failed! Incorrect details.", {
                        hideProgressBar: true,
                        draggable: true,
                        position: "top-right",
                        icon: true,
                        autoClose: 6000,
                    });

                    setTimeout(function () {
                        setStat(false);
                    }, 3000);
                }
            })
            .catch((err) => {
                toast.error("Error: Request Failed! Check network connection.");

                setTimeout(function () {
                    setStat(false);
                }, 3000);
            });
    };

    const [school, setSchool] = useState([]);

    const [task, setTask] = useState([]);

    const [lock, setLock] = useState(false);

    const [loaders, setLoaders] = useState(true);

    const fetchSchool = () => {
        axios({
            method: "post",
            url: "./v2/school/",
            data: {
                school_id: id,
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message !== "failed") {
                    setSchool(view);
                } else {
                    setLock(true);
                }
            })
            .catch((err) => {
                //toast.error("Error: Request Failed! Refresh page..");
            });
    };

    const FetchCBT = () => {
        axios({
            method: "post",
            url: "./v2/exercise/",
            data: {
                token
            },
            config: { headers: { "Content-Type": "multiport/form-data" } },
        })
            .then(function (response) {
                const view = response.data;
                if (view.message === "failed") {
                } else {
                    setTask(view);
                }
            })
            .catch((err) => {
                // toast.error("Error: Request Failed! Refresh page..");
            });
    };

    useEffect(() => {
        fetchSchool();
        FetchCBT();
    }, [id]);


    setTimeout(function () {
        {
            setLoaders(false);
        }
    }, 3000);

    const truncate = (str, n) => {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    };

    const styles = {
        bounce: {
            animation: "x 4s",
            animationName: Radium.keyframes(fadeIn, "bounce"),
        },
    };

    const styles1 = {
        bounce: {
            animation: "x 2s",
            animationName: Radium.keyframes(slideInUp, "bounce"),
        },
    };

    return (
        <>
            {loaders ?

                <>
                    <div className="container py-lg-20 py-18">
                        <div className="col-lg-12 pt-15">
                            <span class="loader_2"></span>
                            <div className="text-center fs-5 text-dark font-display">
                                Loading, Please wait...
                            </div>
                        </div>
                    </div>
                </> :

                <>
                    {lock ?

                        <>
                        
                        </>

                        :
                        <>
                            <div
                                className={isMobile ? `bg-white` : ``}
                            >

                                <nav class={headers}
                                    style={
                                        isMobile
                                            ? {}
                                            : {}
                                    }>
                                    <div className="container-fluid">
                                        <div class="navbar-brand fw-medium">
                                            <span className="fs-t text-white"><i class="bi bi-app"></i>
                                                {" "}
                                                {isMobile ? truncate(task.subject, 17) : task.subject}</span>
                                        </div>

                                    </div>
                                </nav>

                                <div className="">
                                    <div className="py-0">
                                        <div className="container pt-15 pt-md-12 px-4 px-lg-12">
                                            <div className="row">

                                                <div className="col-lg-6 offset-lg-3 mt-6 mt-lg-4">
                                                    <form className="row g-5 mt-lg-0 px-2 px-lg-0" onSubmit={onSubmit_}>

                                                        <div className="col-12 text-center mb-2">
                                                            <div className="fs-t text-purple2">
                                                                Login To Exercise
                                                            </div>
                                                        </div>

                                                        <div class="col-12 mb-3 mt-5">
                                                            <label
                                                                class="form-label fs-4 text-purple2"
                                                                for="number"
                                                            >
                                                                Enter Student ID
                                                            </label>
                                                            <input
                                                                type="text"
                                                                maxLength={10}
                                                                id="name-input"
                                                                autoComplete={false}
                                                                name="adminid"
                                                                className={isMobile ? "form-control form-control-lg border border-light border-1 bg-back" :
                                                                    "form-control form-control-lg border border-1 bg-white"}
                                                                placeholder="Enter your teacher ID"
                                                                required
                                                                style={{ borderRadius: "14px" }}
                                                                onChange={(e) => setID(e.target.value)}
                                                            />
                                                        </div>

                                                        <div class="col-12 mb-3 mt-5">

                                                            <label
                                                                class="form-label fs-4 text-purple2"
                                                                for="number"
                                                            >
                                                                Enter Password
                                                            </label>

                                                            <div class="d-flex align-items-center">
                                                                <span
                                                                    class="position-absolute ps-3 search-icon cursor-pointer fs-4 text-dark"
                                                                    id="basic-addon2"
                                                                    onClick={togglePassword}
                                                                >
                                                                    {passwordType === "text" ? (
                                                                        <>
                                                                            <i className="bi bi-eye-slash"></i> hide
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <i class="bi bi-eye"></i> show
                                                                        </>
                                                                    )}
                                                                </span>
                                                                <input className={isMobile ? "form-control form-control-lg border border-light border-1 bg-back ps-12" :
                                                                    "form-control form-control-lg border border-1 bg-white ps-12"} type={passwordType}
                                                                    placeholder="Enter Exercise Password"
                                                                    style={{ borderRadius: "14px" }}
                                                                    onChange={(e) => setPassword(e.target.value)}
                                                                    required />
                                                            </div>
                                                        </div>

                                                        <div class="col-12 mb-3 mt-6 d-grid">
                                                            <button className='btn btn-lg btn-dark text-white'
                                                                style={{ borderRadius: "24px" }}
                                                                type="submit"
                                                                disabled={status ? true : false}
                                                            >
                                                                {status ? (
                                                                    <>
                                                                        Executing...
                                                                        <span
                                                                            class="spinner-border spinner-border-sm ms-3"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        ></span>
                                                                    </>
                                                                ) : <>Login To Account <span className="bi bi-arrow-right"></span></>
                                                                }
                                                            </button>
                                                        </div>

                                                    </form>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <ToastContainer />

                                <Footer />
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default Login_Work