import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

const Activate = () => {
  const navigate = useNavigate();

  const hash = window.location.hash.slice(2);

  const { id } = useParams();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const email_ = getusers.email;

  const phone_ = getusers.phone;

  const [phone, setPhone] = useState(null);

  const [email, setEmail] = useState(null);

  const [status, setStat] = useState(false);

  console.log(hash)

  const onSubmit = (e) => {
    e.preventDefault();

    setStat(true);

    const user = {
      email,
      phone,
      teachid,
    };

    axios({
      method: "post",
      url: "/v2/profile/",
      data: user,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update was successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);

          localStorage.setItem("token", response.data.token);

          if (email) {
            navigate("../" + id + "/auth/" + response.data.token);
          }
        } else {
          toast.error("Failed: Request Failed! Incorrect details.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            setStat(false);
          }, 3000);
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const Activate = () => {
    axios({
      method: "post",
      url: "/v2/profile/",
      data: { teachid, status: "Activate" },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: Update was successfull!", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });

          setTimeout(function () {
            navigate("../" +hash);
          }, 2000);

          localStorage.setItem("token", response.data.token);
        } else {
          toast.error("Failed: Request Failed! Incorrect details.", {
            hideProgressBar: true,
            draggable: true,
            position: "top-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  return (
    <>
      <div className="row g-6 pt-lg-8 pt-12">
        <div className="col-12 col-md-12 col-lg-6 offset-lg-3">
          <div
            className="card p-4 curve1 shadow-sm cursor-pointer"
            data-bs-toggle="offcanvas"
            data-bs-target="#phone_number"
            aria-controls="offcanvasLeft"
          >
            <div className="d-flex align-items-center">
              <div className="col-2 text-start">
                <span class="btn btn-success btn-icon text-white rounded-br-lg rounded-bl-sm rounded-t-lg">
                  <i class="bi bi-telephone-fill fs-3 text-white"></i>
                </span>
              </div>

              <div className="col-8 text-lg-start text-center">
                <div className="fs-3 text-dark">
                  Configure Your Phone Number
                </div>
              </div>

              <div className="col-2 text-end">
                <span class="btn btn-icon rounded-br-lg rounded-bl-sm rounded-t-lg">
                  {!phone_ ? (
                    <i class="bi bi-arrow-right fs-3 text-dark cursor-pointer"></i>
                  ) : (
                    <i class="bi bi-patch-check-fill fs-3 text-success"></i>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-12 col-lg-6 offset-lg-3">
          <div
            className="card p-4 curve2 shadow-sm cursor-pointer"
            data-bs-toggle="offcanvas"
            data-bs-target="#email_number"
            aria-controls="offcanvasLeft"
          >
            <div className="d-flex align-items-center">
              <div className="col-2 text-start">
                <span class="btn btn-primary btn-icon text-white rounded-br-lg rounded-bl-sm rounded-t-lg">
                  <i class="bi bi-envelope-fill fs-3 text-white"></i>
                </span>
              </div>

              <div className="col-8 text-lg-start text-center">
                <div className="fs-3 text-dark">
                  Configure Your Email Address
                </div>
              </div>

              <div className="col-2 text-end">
                <span class="btn btn-icon rounded-br-lg rounded-bl-sm rounded-t-lg">
                  {!email_ ? (
                    <i class="bi bi-arrow-right fs-3 text-dark cursor-pointer"></i>
                  ) : (
                    <i class="bi bi-patch-check-fill fs-3 text-success"></i>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-12 col-lg-12 pt-6 text-center">
          <button
            className="btn btn-dark btn-md shadow rounded-3 px-6"
            disabled={email_ ? false : true}
            onClick={() => Activate()}
          >
            Get Started <i class="bi bi-arrow-right cursor-pointer"></i>
          </button>
        </div>
      </div>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="phone_number"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header" style={{ padding: "0px" }}>
          <h5 id="offcanvasRightLabel"></h5>
          <button
            type="button"
            class="btn btn-white text-success2 fs-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            {" "}
            <i class="bi bi-x text-dark"></i>
          </button>
        </div>
        <div class="offcanvas-body">
          <div className="px-lg-2">
            <div className="fs-t fw-medium text-purple2">
              Configure Phone Number
            </div>
            <div className="mt-3 fs-5 font-display">
              Enter your phone number that is reachable via the following
              channels; SMS, WhatsApp, and Phone-calls. Please enter without
              first zero.
            </div>

            <div class="mb-3 mt-8 d-grid">
              <form className="mt-lg-0" onSubmit={onSubmit}>
                <div class="mb-3">
                  <label class="form-label fs-4 text-dark" for="number">
                    Enter Phone Number
                  </label>
                  <div class="input-group border-white">
                    <span
                      class="input-group-text cursor-pointer text-dark border-dark"
                      id="basic-addon2"
                    >
                      +234
                    </span>
                    <input
                      type="tel"
                      maxLength={10}
                      id="name-input"
                      autoComplete={false}
                      name="phone"
                      class="form-control form-control-md bg-light border-dark"
                      placeholder="Enter your phone number"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div class="mb-3 mt-8 d-grid">
                  <button
                    type="submit"
                    class="btn btn-success text-white rounded-3 mb-2 px-7 text-uppercase"
                    disabled={status ? true : false}
                    data-bs-dismiss={phone ? "offcanvas" : ""}
                  >
                    {status ? (
                      <>
                        Executing...
                        <span
                          class="spinner-border spinner-border-sm ms-3"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Save Number"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="email_number"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header" style={{ padding: "0px" }}>
          <h5 id="offcanvasRightLabel"></h5>
          <button
            type="button"
            class="btn btn-white text-success2 fs-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            {" "}
            <i class="bi bi-x text-dark"></i>
          </button>
        </div>
        <div class="offcanvas-body">
          <div className="px-lg-2">
            <div className="fs-t fw-medium text-purple2">
              Configure Email Address
            </div>
            <div className="mt-3 fs-5 font-display">
              Enter your email address that is reachable via the following
              channels; Email messaging, Direct Mailing, etc.
            </div>

            <div class="mb-3 mt-8 d-grid">
              <form className="mt-lg-0" onSubmit={onSubmit}>
                <div class="mb-3">
                  <label class="form-label fs-4 text-dark" for="number">
                    Enter Email Address
                  </label>
                  <div class="input-group border-white">
                    <span
                      class="input-group-text cursor-pointer text-dark border-dark fs-3"
                      id="basic-addon2"
                    >
                      @
                    </span>
                    <input
                      type="email"
                      autoComplete={false}
                      name="email"
                      class="form-control form-control-md bg-light border-dark"
                      placeholder="Enter your email address"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div class="mb-3 mt-8 d-grid">
                  <button
                    type="submit"
                    class="btn btn-success text-white rounded-3 mb-2 px-7 text-uppercase"
                    disabled={status ? true : false}
                    data-bs-dismiss={email ? "offcanvas" : ""}
                  >
                    {status ? (
                      <>
                        Executing...
                        <span
                          class="spinner-border spinner-border-sm ms-3"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </>
                    ) : (
                      "Save Email"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Activate;
