import React, { useState, useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";

import axios from "axios";

import { isMobile } from "react-device-detect";

import Tippy from "@tippyjs/react";

import "tippy.js/dist/tippy.css";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { useSelector } from "react-redux";

import { useQuill } from "react-quilljs";

import "quill/dist/quill.bubble.css";

import LoadingBar from "react-top-loading-bar";

import Auth from "../../Auth";

import Activate from "../Activate";

import parse from "html-react-parser";

import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";

import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import "react-quill/dist/quill.bubble.css";

const Create_Note = () => {
  const { quill, quillRef } = useQuill();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const cbt = getusers.cbt;

  const teachid = getusers.teachid;

  const app_status = getusers.app_status;

  const { id } = useParams();

  const { token } = useParams();

  axios.defaults.baseURL = id;

  const [progress, setProgress] = useState(20);

  let headers;

  if (isMobile) {
    headers =
      "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3";
  } else {
    headers =
      "navbar-default navbar navbar-expand-lg fixed-top shadow-none py-3 px-6";
  }

  useEffect(() => {
    FetchNote();
  }, [token, teachid]);

  const [note_task, setNoteTask] = useState([]);

  const FetchNote = () => {
    axios({
      method: "post",
      url: "./v2/note/",
      data: {
        token,
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (!view.token) {
          setTimeout(function () {
            //navigate("./denied");
          }, 3000);
        } else if (view.message === "failed") {
        } else {
          setNoteTask(view);

          setTitle(view.title);

          setSubject(view.subject);

          setStudclass(view.class);

          if (view.note !== '') {
            setNote(view.note);
          }
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  const [note, setNote] = useState("<p>Write note here..</p>");

  useEffect(() => {
    if (quill) {
      //quill.clipboard.dangerouslyPasteHTML(note_task.note);

      quill.on("text-change", (delta, oldDelta, source) => {
        //console.log("Text change!");
        //console.log(quill.root.innerHTML); // Get innerHTML using quill
        //console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef
        //setQues(quillRef.current.firstChild.innerHTML);

        setNote(quillRef.current.firstChild.innerHTML);

        const task = {
          note: quillRef.current.firstChild.innerHTML,
          token,
          teachid,
        };

        axios({
          method: "post",
          url: "/v2/note/upload/",
          data: task,
          config: { headers: { "Content-Type": "multiport/form-data" } },
        })
          .then(function (response) {
            //console.log(response);
            if (response.data.message === "success") {
              setTimeout(function () {}, 8000);
            } else {
            }
          })
          .catch((err) => {
            toast.error("Error: Request Failed! Check network connection.");
          });
      });

      toast.success("Status: Auto-Saved is Active!", {
        hideProgressBar: true,
        draggable: true,
        position: "bottom-right",
        icon: true,
        autoClose: 3000,
      });
    }
  }, [quill]);

  const [status, setStat] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!note) {
      toast.error("Empty: Field can't be empty!", {
        hideProgressBar: true,
        draggable: true,
        position: "top-right",
        icon: true,
        autoClose: 6000,
      });
    } else {
      setStat(true);

      const task = {
        note,
        token,
        teachid,
      };

      axios({
        method: "post",
        url: "/v2/note/upload/",
        data: task,
        config: { headers: { "Content-Type": "multiport/form-data" } },
      })
        .then(function (response) {
          //console.log(response);
          if (response.data.message === "success") {
            toast.success(
              "Success: " + note_task.subject + " Note has been updated!",
              {
                hideProgressBar: true,
                draggable: true,
                position: "bottom-right",
                icon: true,
                autoClose: 6000,
              }
            );

            setTimeout(function () {
              setStat(false);
            }, 3000);
          } else {
            toast.error("Failed: Request Failed! Please try again!", {
              hideProgressBar: true,
              draggable: true,
              position: "bottom-right",
              icon: true,
              autoClose: 6000,
            });

            setTimeout(function () {
              setStat(false);
            }, 3000);
          }
        })
        .catch((err) => {
          toast.error("Error: Request Failed! Check network connection.");
        });
    }
  };

  const [subjects, setSubjects] = useState([{}]);

  useEffect(() => {
    axios({
      method: "post",
      url: "./v2/subject/list/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        if (view.message !== "failed") {
          setSubjects(view);
        } else {
          setTimeout(function () {
            //navigate("/student");
          }, 4000);
        }
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  }, [teachid]);

  const renderSubject = () => {
    if (subjects == "") {
      return <></>;
    } else if (subjects) {
      return subjects.map((task, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <option value={task.subject}>{task.subject}</option>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const [title, setTitle] = useState(null);

  const [studclass, setStudclass] = useState(null);

  const [subject, setSubject] = useState(null);

  const onSubmit_ = (e) => {
    e.preventDefault();

    const task = {
      title,
      studclass,
      subject,
      teachid,
      token,
    };

    axios({
      method: "post",
      url: "/v2/note/edit/",
      data: task,
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        //console.log(response);
        if (response.data.message === "success") {
          toast.success("Success: " + subject + " Note was modified!", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });

          FetchNote();
        } else {
          toast.error("Failed: Request Failed! Please try again", {
            hideProgressBar: true,
            draggable: true,
            position: "bottom-right",
            icon: true,
            autoClose: 6000,
          });
        }
      })
      .catch((err) => {
        toast.error("Error: Request Failed! Check network connection.");
      });
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const Print = () => {
    let printContents = document.getElementById("result_table").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print(); // PRINT THE CONTENTS.

    document.body.innerHTML = originalContents;
  };

  const [shortenedLink, setShortenedLink] = useState("");

  const userInput =
    "https://app.smartedung.com/" +
    id +
    "/note/index.php?id=" +
    token +
    "&q=" +
    id;

  const fetchData = async () => {
    try {
      const response = await axios(
        `https://api.shrtco.de/v2/shorten?url=${userInput}`
      );

      setShortenedLink(response.data.result.full_short_link);
    } catch (e) {
      console.log(e);
    }
  };

  const text =
    "Click the link below to access " +
    note_task.title +
    " note; \n\n" +
    shortenedLink;

  const [copied, setCopied] = useState(false);

  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];

  const module = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <Auth />
      <div
        className={isMobile ? `bg-white` : `bg-white`}
        onLoad={() => setProgress(100)}
      >
        <nav class={headers} style={isMobile ? {} : {}}>
          <div className="container-fluid">
            <div class="navbar-brand">
              <div className="fs-t text-purple2">
                <i class="bi bi-window-sidebar"></i>{" "}
                {isMobile ? truncate(note_task.title, 20) : note_task.title}{" "}
              </div>
            </div>

            <div className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
              <Link
                to={"../" + id + "/note"}
                class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
              >
                <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
              </Link>
            </div>
          </div>
        </nav>

        <div className="">
          <div class="container-fluid px-0 px-md-6 py-8">
            {app_status === "InActive" ? (
              <div class="container-fluid px-4 px-lg-4">
                <Activate />
              </div>
            ) : (
              <>
                <div class="container-fluid">
                  {isMobile ? (
                    <>
                      <form
                        className="row g-3 pb-5 px-md-2 px-lg-15"
                        onSubmit={onSubmit}
                      >
                        <div className="row mt-5 px-2">
                          <div
                            className="bg-white pt-4"
                            style={{ borderRadius: "25px" }}
                          >
                            <div className="px-0">
                              <ReactQuill
                                theme="bubble"
                                modules={module}
                                style={{ height: 600 }}
                                value={note}
                                onChange={setNote}
                              />
                            </div>
                          </div>

                          <div className="px-0 fixed-bottom mb-2">
                            <div
                              className="card py-3 bg-light-primary shadow-none px-4"
                              style={{
                                borderRadius: "40px",
                              }}
                            >
                              <div className="row">
                                <button
                                  className="col-9 px-6 btn btn-dark btn-lg rounded-pill fs-3"
                                  type="submit"
                                  disabled={status ? true : false}
                                >
                                  Save Note
                                </button>

                                <button
                                  type="button"
                                  className="col-2 btn btn-light rounded-pill fs-3 ms-4"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#offcanvasBottom"
                                  aria-controls="offcanvasBottom"
                                >
                                  <span className="bi bi-arrow-up"></span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <div className="row px-2">
                      <div className="col-lg-12">
                        <form
                          className="row g-3 pb-5 px-md-2 px-lg-10"
                          onSubmit={onSubmit}
                        >
                          <div className="col-lg-12 pt-6 pb-4">
                            <div
                              className="bg-back"
                              style={{ borderRadius: "25px" }}
                            >
                              <div className="pt-4">
                                <ReactQuill
                                  theme="bubble"
                                  modules={module}
                                  style={{ height: 500 }}
                                  value={note}
                                  onChange={setNote}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 row align-items-center">
                            
                            <div className="col-9 d-grid">
                              <button
                                type="submit"
                                class="btn btn-md btn-dark rounded-pill fs-3"
                                disabled={status ? true : false}
                              >
                                {status ? (
                                  <>
                                    Creating...
                                    <span
                                      class="spinner-border spinner-border-sm ms-3"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </>
                                ) : (
                                  <>
                                    Save Note{" "}
                                    <i class="bi bi-check-lg ms-1"></i>
                                  </>
                                )}
                              </button>
                            </div>

                            <div className="col-3 d-grid">
                              <button
                                className="btn btn-primary rounded-pill fs-3"
                                data-bs-toggle="modal"
                                data-bs-target="#PreviewNote"
                                onClick={() => FetchNote()}
                              >
                                Preview Note
                              </button>
                            </div>


                          </div>
                        </form>
                      </div>

                      <div class="col-lg-12 col-12 mt-lg-10 mt-6 mb-8 mb-lg-4 px-lg-4">
                        <div class="text-dark">
                          <h2 class="h3 text-dark fw-medium">
                            Edit Note Details
                          </h2>
                          <p class="mb-0 fs-4">
                            Click the button below to edit this note details.
                          </p>
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#HandleOpen"
                            class="btn btn-dark-success rounded-3 mt-3 px-4"
                          >
                            <i class="bi bi-sliders"></i> Edit Note
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        <div
          class="modal"
          id="HandleOpen"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-md modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-t text-purple2">
                  <i class="bi bi-sliders"></i> Edit Note Details{" "}
                </div>

                <button
                  class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                </button>
              </div>
              <div class="modal-body py-0">
                <form
                  className="row g-4 g-lg-4 px-lg-4 px-2 pb-5"
                  onSubmit={onSubmit_}
                >
                  <div className="col-12">
                    <div className="fs-4 text-dark mt-3 mt-lg-1">
                      Fill the details below to create note.
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <label
                      class="form-label fs-4 font-display2"
                      for="textInput"
                    >
                      Note Title
                    </label>
                    <input
                      type="text"
                      id="title"
                      class="form-control form-control-lg bg-back text-dark"
                      required
                      placeholder="What's your note title?"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      style={{ borderRadius: "24px" }}
                    />
                  </div>

                  <div className="col-lg-12">
                    <label
                      class="form-label fs-4 font-display2"
                      for="textInput"
                    >
                      Note Subject
                    </label>
                    <select
                      class="form-select form-select-lg bg-back text-dark"
                      aria-label="Default select example"
                      required
                      value={subject}
                      style={{
                        borderRadius: "24px",
                      }}
                      onChange={(e) => setSubject(e.target.value)}
                    >
                      <option value="">Select Subject</option>
                      {renderSubject()}
                    </select>
                  </div>

                  <div className="col-lg-12">
                    <label
                      class="form-label fs-4 font-display2"
                      for="textInput"
                    >
                      Note Class
                    </label>
                    <select
                      class="form-select form-select-lg bg-back text-dark"
                      aria-label="Default select example"
                      name="studclass"
                      required
                      value={studclass}
                      style={{
                        borderRadius: "24px",
                      }}
                      onChange={(e) => setStudclass(e.target.value)}
                    >
                      <option value="">Select Class</option>
                      <option value="PlayGroup">PlayGroup</option>
                      <option value="Reception">Reception</option>
                      <option value="Pre Nursery">Pre Nursery</option>
                      <option value="NUR 1">NUR 1</option>
                      <option value="NUR 2">NUR 2</option>
                      <option value="KG 1">KG 1</option>
                      <option value="KG 2">KG 2</option>
                      <option value="Grade 1">Grade 1</option>
                      <option value="Grade 2">Grade 2</option>
                      <option value="Grade 3">Grade 3</option>
                      <option value="Grade 4">Grade 4</option>
                      <option value="Grade 5">Grade 5</option>
                      <option value="Grade 6">Grade 6</option>
                      <option value="Jss1">JSS 1</option>
                      <option value="Jss2">JSS 2</option>
                      <option value="Jss3">JSS 3</option>
                      <option value="sss1">SSS 1</option>
                      <option value="sss2">SSS 2</option>
                      <option value="sss3">SSS 3</option>
                    </select>
                  </div>

                  <div className="col-lg-12 text-end">
                    <button
                      type="submit"
                      class="btn btn-md btn-success text-white px-4"
                      data-bs-dismiss={
                        studclass && subject && title ? "modal" : ""
                      }
                      style={{
                        borderRadius: "24px",
                      }}
                    >
                      Save Details <i class="bi bi-arrow-right ms-1"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal"
          id="PreviewNote"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-lg modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-10 text-dark">{note_task.title}</div>

                <button
                  class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                </button>
              </div>
              <div class="modal-body py-0">
                <div className="mt-lg-4 px-lg-6 mt-2" id="result_table">
                  <div
                    dangerouslySetInnerHTML={{ __html: note_task.note }}
                  ></div>
                </div>

                <div className="mt-9 mb-lg-8 mb-10 row">
                  <div className="text-start col-6">
                    <button
                      className="btn btn-success btn-lg rounded-pill"
                      data-bs-toggle="modal"
                      data-bs-target="#ShareNote"
                      onClick={() => fetchData()}
                    >
                      Share <i class="bi bi-share-fill"></i>
                    </button>
                  </div>

                  <div className="text-end col-6">
                    <button
                      className="btn btn-light-primary btn-lg rounded-pill"
                      onClick={() => Print()}
                    >
                      Print Out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal"
          id="ShareNote"
          tabindex="-1"
          role="dialog"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            class={
              isMobile
                ? "modal-dialog modal-dialog-centered modal-fullscreen"
                : "modal-dialog modal-md modal-dialog-centered"
            }
            role="document"
          >
            <div
              class="modal-content curve_"
              style={
                isMobile
                  ? { border: "#fff" }
                  : { borderRadius: "35px", border: "#fff" }
              }
            >
              <div class="modal-header border-0 py-4">
                <div className="fs-t text-purple3"></div>

                <button
                  class="btn btn-light-primary btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg fw-bold fs-4 text-purple3"></i>
                </button>
              </div>
              <div class="modal-body py-0">
                <div className="px-lg-4">
                  <div className="fs-t2 text-purple3 mb-2">Note Sharing </div>

                  <div className="fs-4 text-purple2 mb-4">
                    Share note to students via options below;
                  </div>

                  <div className="mb-3">
                    <div className="col-lg-10 cursor-pointer">
                      <CopyToClipboard
                        text={shortenedLink}
                        onCopy={() => {
                          toast.success("Success: Link copied successfully!", {
                            hideProgressBar: true,
                            draggable: true,
                            position: "bottom-right",
                            icon: true,
                            autoClose: 3000,
                          });
                          setCopied(true);
                          setTimeout(function () {
                            setCopied(false);
                          }, 4000);
                        }}
                      >
                        <div class="d-flex align-items-center">
                          <input
                            type="search"
                            class="form-control form-control-lg py-3 ps-12 fs-4 bg-dark text-white rounded-pill"
                            value={shortenedLink}
                            disabled
                          />
                          <span class="position-absolute ps-6 search-icon text-white fw-bold">
                            Copy:
                          </span>
                        </div>
                      </CopyToClipboard>
                    </div>
                  </div>

                  <div className="mb-lg-5 mt-5 mt-lg-4">
                    <div className="fs-4 text-dark">
                      Click the copy button to copy the link and share now to
                      students to attempt exercise.
                    </div>
                  </div>

                  <div class="text-center mt-5 mt-lg-3 lh-2">
                    <span class="text-dark px-2 fs-3 rounded">OR</span>
                  </div>

                  <div className="row g-3 text-center mt-5 mt-lg-3 mb-lg-6">
                    <div className="col-6">
                      <WhatsappShareButton url={text}>
                        <div
                          class="btn btn-light-success btn-sm shadow-none rounded-circle"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i class="bi bi-whatsapp fs-t"></i>
                        </div>
                      </WhatsappShareButton>
                      <div className="fs-4 text-dark mt-2">WhatsApp</div>
                    </div>

                    <div className="col-6">
                      <TelegramShareButton url={text}>
                        <div
                          class="btn btn-light-info btn-sm shadow-none rounded-circle"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <i class="bi bi-telegram fs-t"></i>
                        </div>
                      </TelegramShareButton>
                      <div className="fs-4 text-dark mt-2">Telegram</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />

        <div
          class="offcanvas offcanvas-bottom offcanvas-lg bg-white"
          tabindex="-1"
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
          style={{
            height: "400px",
            borderTopLeftRadius: "40px",
            borderTopRightRadius: "40px",
          }}
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasBottomLabel"></h5>

            <button
              class="btn btn-white btn-icon rounded-br-lg2 rounded-bl-sm2 rounded-t-lg2"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i class="bi bi-x-lg fw-bold fs-t text-dark"></i>
            </button>
          </div>
          <div class="offcanvas-body py-0">
            <div className="px-2">
              <div className="d-grid mb-10">
                <button
                  className="btn btn-dark rounded-pill fs-3"
                  data-bs-toggle="modal"
                  data-bs-target="#PreviewNote"
                  onClick={() => FetchNote()}
                >
                  Preview Note
                </button>
              </div>

              <div className="fs-3 text-dark mb-3">Edit Note</div>

              <p class="mb-5 fs-4">
                Click the button below to edit this note details.
              </p>

              <div className="d-grid">
                <a
                  data-bs-toggle="modal"
                  data-bs-target="#HandleOpen"
                  class="btn btn-light-primary rounded-pill fs-3"
                >
                  Edit Note
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Create_Note;
