import React, { useState, useEffect } from "react";

import { isMobile } from "react-device-detect";

import { toast, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { Link, useParams, useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import { useSelector } from "react-redux";

import Auth from "../../Auth";

import axios from "axios";

import Header from "../Header";

import Sidebar from "../Sidebar";

import SimpleBar from "simplebar-react";

import "simplebar/dist/simplebar.min.css";

import Footer from "../Footer";

const Report_Home = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const getusers = useSelector((state) => state.user.value);

  const teachid = getusers.teachid;

  const admin_name = getusers.username;

  const [fullname, setfullname] = useState("{}");

  useEffect(() => {
   
    setfullname( !admin_name ? "" : admin_name.split(" ")[0]);

    localStorage.setItem("id", teachid);
  }, [admin_name, teachid]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);

    toast.error("Oop! sorry you can not go back!", {
      hideProgressBar: true,
      draggable: true,
      position: "top-center",
      icon: true,
      autoClose: 6000,
    });
  };

  const [progress, setProgress] = useState(20);

  const [report, setReport] = useState([]);

  const [loader, setLoader] = useState(true);

  const FetchReport = () => {
    axios({
      method: "post",
      url: "./v2/report/fetchs/",
      data: {
        teachid,
      },
      config: { headers: { "Content-Type": "multiport/form-data" } },
    })
      .then(function (response) {
        const view = response.data;
        setReport(view);

        setLoader(true);

        setTimeout(function () {
          setLoader(false);
        }, 4000);
      })
      .catch((err) => {
        // toast.error("Error: Request Failed! Refresh page..");
      });
  };

  useEffect(() => {
    FetchReport();
  }, [teachid]);

  const renderFolders = () => {
    if (report == "") {
      return <></>;
    } else if (report != "") {
      return report.slice(0, 3).map((report, index) => {
        //console.log(school.tm_date)
        return (
          <>
            <div className="col-10 col-lg-4 me-4 me-lg-6 mb-5">
              <div
                class={
                  index % 2 == 0
                    ? "card bg-light-primary shadow-none"
                    : "card bg-light-success shadow-none"
                }
                style={{ borderRadius: "20px" }}
              >
                <div className="card-body py-6">
                  <div className="row align-items-center">
                    <div className="col-4 text-center">
                      <img
                        src="../../assets/images/background/result.png"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                    <div className="col-6">
                      <div className="fs-t text-purple3">{report.subject}</div>
                    </div>

                    <div className="col-2 ms-n2">
                      <Link to={"../" + id + "/report/create/" + report.token} className="btn btn-warning rounded-pill text-dark fs-3">
                        <i class="bi bi-arrow-right-circle-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <div onLoad={() => setProgress(100)}>
      <Auth />
      <LoadingBar
        color="#ff793f"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />

      <Header />

      <Sidebar />

      <div class="docs-wrapper bg-back">
        <div class="container-fluid px-4 px-lg-10 pt-lg-14 pt-4">
          <div className="d-flex align-items-center">
            <div className="col">
              <div className="fs-t text-purple3">
                Dashboard |{" "}
                <span className="text-dark fs-5">
                  <i className="bi bi-house-fill"></i> -- Home
                </span>
              </div>
            </div>

            <div className="col-end text-center">
              
            </div>
          </div>

          <div className="px-lg-12 mt-lg-5 mt-4">
            <div className="col-lg-12 mb-5 mb-lg-8">
              <div className="card bg-white shadow-none" 
              style={{ borderRadius: "20px" }}>
                <div className="card-body px-1">
                  <div className="row align-items-center">
                    <div className="col-4 text-center ms-lg-n3 ms-n1">
                      <div className="fs-5 text-purple3">
                        No. of Results Uploads
                      </div>
                      <div className="fs-t text-success fw-bold">{report.length}</div>
                    </div>

                    <div className="col-6 ms-lg-n8 fw-medium ms-n3">
                      <div
                        className={
                          isMobile
                            ? "fs-5 text-purple2"
                            : "fs-4 text-start text-purple2"
                        }
                      >
                        Click on button to view all subject result uploads
                      </div>
                    </div>

                    <div className="col-2">
                      <Link to={"../" + id + "/report/results"} className="btn btn-success rounded-pill text-white fs-3">
                        <i class="bi bi-arrow-right-circle-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {report == "" ? (
            <>
              {isMobile ? (
                <div className="col-12 fixed-bottom">
                  <div
                    className="card bg-white shadow-lg"
                    style={{
                      borderTopRightRadius: "40px",
                      borderTopLeftRadius: "40px",
                    }}
                  >
                    <div className="card-body mb-0">
                      <div className="text-center">
                        <div className="fs-t text-purple3 fw-medium">
                          Start upload!
                        </div>

                        <div className="col-12 px-4 text-center">
                          <img
                            src="../../assets/images/background/exam.png"
                            alt=""
                            class="img-fluid"
                            width={"70%"}
                          />

                          <div className="fs-4 text-purple2 fw-medium">
                            Hi {fullname}, Welcome to E-Report center. Click on
                            the button below to begin uploads of results.
                          </div>
                        </div>

                        <div className="d-grid mt-3 px-2">
                          <Link
                            to={"../" + id + "/report/create"}
                            className="btn btn-warning rounded-pill text-dark fs-3"
                          >
                            Let's go
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="mt-8">
                    <div className="col-lg-12">
                      <div
                        className="card bg-white shadow-sm curve4"
                        style={{ borderRadius: "16px" }}
                      >
                        <div className="px-1">
                          <div className="text-start">
                            <div className="row align-items-center">
                              <div className="col-lg-4 px-4 text-start">
                                <img
                                  src="../../assets/images/background/exam.png"
                                  alt=""
                                  class="img-fluid mt-n10"
                                />
                              </div>

                              <div className="col-lg-6 text-center ms-n9">
                                <div className="fs-t text-purple2 fw-medium mb-2">
                                  Start upload!
                                </div>
                                <div className="fs-4 text-purple2 fw-medium">
                                  Hi {fullname}, Welcome to E-Report center.
                                  Click on the button below to begin uploads of
                                  results.
                                </div>
                              </div>

                              <div className="col-lg-2 mt-3 px-2">
                                <Link
                                  to={"../" + id + "/report/create"}
                                  className="btn btn-warning rounded-pill text-dark fs-3"
                                >
                                  Let's go
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {loader ? (
                <div className="col-lg-12 text-center py-lg-10 py-20">
                  <div
                    class="spinner-border text-purple3"
                    style={{ width: "4rem", height: "4rem" }}
                    role="status"
                  >
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col mb-3 mt-lg-4">
                    <div className="fs-3 text-purple3">Recent Uploads</div>
                  </div>
                  <SimpleBar forceVisible="y" autoHide={true}>
                    <div class="d-flex align-items-center hoverable">
                      {report ? renderFolders() : ''}
                    </div>
                  </SimpleBar>
                </>
              )}
            </>
          )}

          {isMobile && report != '' ? (
            <div className="col-12 fixed-bottom">
              <div
                className="card bg-white shadow-lg"
                style={{
                  borderTopRightRadius: "40px",
                  borderTopLeftRadius: "40px",
                }}
              >
                <div className="card-body mb-0 py-3">
                  <div className="text-center">
                    <div className="fs-t text-purple3 fw-medium">
                      Start Dispatching!
                    </div>

                    <div className="col-12 px-4 text-center">

                      <div className="fs-4 text-purple2">
                        Click button below to send results to the school admin for processing
                      </div>
                    </div>

                    <div className="d-grid mt-3 px-2">
                      <Link
                        to={"../" + id + "/report/send"}
                        className="btn btn-warning rounded-pill text-dark fs-3"
                      >
                        Let's go
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
            
            </>
          )}

        </div>

        <Footer/>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Report_Home;
